import { 
    SHOW_LOGIN_FORM,
    SHOW_REGISTER_FORM,
    SHOW_PASSWORD_FORM
} from "../actions/types";

export const signPage = (state = {
    showLogin:false,
    showRegister:false,
    showPasswordRecovery:false,
}, action) => {
const { type, payload } = action;

    switch (type) {     
        case SHOW_LOGIN_FORM:
            if(payload){
                return {
                    showLogin:true,
                    showRegister:false,
                    showPasswordRecovery:false
                }
            }else{
                return {
                    showLogin:false,
                    showRegister:false,
                    showPasswordRecovery:false
                }
            }    
        case SHOW_REGISTER_FORM:
            if(payload){
                return {
                    showLogin:false,
                    showRegister:true,
                    showPasswordRecovery:false
                }
            }else{
                return {
                    showLogin:false,
                    showRegister:false,
                    showPasswordRecovery:false
                }
            }    
        case SHOW_PASSWORD_FORM:
            if(payload){
                return {
                    showLogin:false,
                    showRegister:false,
                    showPasswordRecovery:true
                }
            }else{
                return {
                    showLogin:false,
                    showRegister:false,
                    showPasswordRecovery:false
                }
            }    
        default:
        return state;
    }
}