import { useTranslation } from "react-i18next";
import AZSelectItem from "./AZSelectItem";


const AZSelect = (props) => {
    const{
        title,
        error,
        hideHeader=false,
        errorText,
        style="color",
        list,
        isListOpen,
        disabled,
        onToggle,
        onItemClick,
        loading,
        loadingText,
        selectedItem   
    }=props
    const { t,i18n } = useTranslation()
    
    
    return (
        <div>
            <div className={`az-select az-select--${style} ${error&&"az-select--error"} ${disabled&&"az-select--disabled"}`}>
                <button 
                    className={`az-select__header ${hideHeader&&"d-none"}`}
                    onClick={onToggle}
                    type="button"
                > 
                    <div className="az-select__header__title">
                        {
                            loading?
                            <span>
                                {loadingText} <i className="fa fa-spinner fa-spin"></i>
                            </span>
                            :
                            title
                        }
                    </div>               
                    <div className="az-select__header__icon">
                        <span>
                        <i className={isListOpen?"fas fa-chevron-up":"fas fa-chevron-down"}></i>
                        </span>
                    </div>               
                </button>
                <div className="az-select__list">
                    { isListOpen && !hideHeader && <div className="az-select__list__content__blank"></div>}
                    { isListOpen&&                        
                        <div 
                            className="az-select__list__content"
                            role="list">
                            {
                                list.map(
                                    item=>
                                    <AZSelectItem
                                        key={item.id==undefined?item:item.id}
                                        id={item.id==undefined?item:item.id}
                                        model={item}
                                        disabled={item.disabled!==undefined}
                                        value={item.id==undefined?item:item.value}                                      
                                        onItemClick={onItemClick}
                                        selectedItem={selectedItem}
                                    >                                
                                    </AZSelectItem>
                                )
                            }
                        </div>
                    }
                </div>       
            </div>
            {
                error&&
                <div className="az-select__error text-danger">
                    <p>{ t(errorText) }</p>
                </div>
            }
        </div>
    );
}

export default AZSelect;
