import React ,{useEffect} from 'react';


import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es'

const FormatedDate = (props) => {
    const {
        children
    } = props
    const {t,i18n} = useTranslation()

    useEffect(() => {
        moment.locale(i18n.language.substring(0,2))
    }, []);

    const showDate = (children)=>{        
        const childDate = moment.utc(children)       

        return childDate.local().fromNow()
    }

    return (
        <span>
            {showDate(children)}
        </span>
    );
}

export default FormatedDate;
