import "./index.css"

const AZModal = (props) => {
    const {
        children,
        onClose,
        header,
        show=false,
        noClose=false        
    } = props
    
    const setShowClass = (show)=>(
        show?"az-modal":"az-modal az-modal--hide"
    )
    const handleClose = () => {
         if(onClose && !noClose){
            onClose()
         }
    }

    return (
        <div className={setShowClass(show)} onClick={handleClose}>
            <div className="az-modal__body" onClick={(e)=>(e.stopPropagation())}>
                {!noClose&&<button className="az-modal__body__close"
                        onClick={handleClose}>
                    <i className="fa fa-times text-white"></i>
                </button>}
                
                {header&&
                <div className="az-modal__body__header">
                    {header}           
                </div>
                }
                <div className="az-modal__body__content">
                    {children}
                </div>
            </div>  
        </div>
    );
}

export default AZModal;
