import {
    NOTIFICATIONS_ADD,
    NOTIFICATIONS_CLEAN,    
} from "../actions/types"

const initialState = {
    notifSeekers:0,
    notifRequests:0,
    notifConversations:0,
    notifGeneral:0,
    guruRequestsAvaiables:0,
    guruNewConversations:0,
    seekerNewConversations:0,
}

export default (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    //console.log('dispatch logout',type)
    switch (type) {     
        case NOTIFICATIONS_ADD:
        return {
            ...state,
            ...payload
        }
        case NOTIFICATIONS_CLEAN:
        return initialState    
        default:
        return state;
    }
}