const AZSelectItem = (props) => {
    const {
        onItemClick,
        id,
        value,
        model,
        disabled,
        selectedItem
    } = props
    
    return (
        <button 
            type="button"
            className={`az-select__list__content__item ${disabled&&"az-select__list__content__item--disabled"} az-select__list__content__item--${id} ${selectedItem==value&&"text-purple"}`}
            onClick={()=>{
                onItemClick(id,value)
            }}
        >
            {console.log('valueat',`${value} ${selectedItem}`)}
            {value}
        </button>
    );
}

export default AZSelectItem;
