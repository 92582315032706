import React from 'react';

import "./index.css"

const TextWithLine = (props) => {
    const {
        children
    } = props

    return (
        <div className="text-line">
            <div className="text-line__line">                
            </div>
            <div className="text-line__text">
                <span>{children}</span>
            </div>
        </div>
    );
}

export default TextWithLine;
