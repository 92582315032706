import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    UPDATE_PROFILE,
    CLEAN_PROFILE,
    SET_PENDING_RATES
} from "./types";

import {
    apiErrorHandler
} from "../helpers/helpers"
  
import GeneralService from "../services/general.service";
import AuthService from "../services/userAuth.service";

const abortController = new AbortController()
const Service = new GeneralService(abortController)

// export const login = (username, password) => (dispatch) => {
//     return Service.login(username, password).then(
//         (data) => {
//             const {
//                 user,
//                 session
//             } = data
//             dispatch({
//                 type: LOGIN_SUCCESS,
//                 payload: { 
//                     user,
//                     session
//                 },
//             });

//             return Promise.resolve();
//         },
//         (error) => {                               
//             dispatch({
//                 type: LOGIN_FAIL,
//             });           
//             return Promise.reject();
//         }
//     );
// };

// Profile actions...
export const abort = ()=>{
    abortController.abort()
}

export const blockUser =(idUser,typeOfReport,comment)=>{
    return Service.blockUser(
        idUser,
        typeOfReport,
        comment
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            
                return Promise.reject(response.response)
        }
    )
}

export const checkNicknameValid = (nickname) => (dispatch) =>{
    return Service.checkNicknameValid(nickname).then(
        (response)=>{

            return Promise.resolve(response)
        },
        (response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response)
        }
    )
}

export const closeRequest = (idRequest) => {    
    return Service.closeRequest(idRequest).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            return Promise.reject(response.response)
        }
    )
}

export const deleteAccount = () => (dispatch) =>{
    return Service.deleteAccount().then(
        (response)=>{
            dispatch({
                type:LOGOUT
            })
            localStorage.removeItem("auth");
            return Promise.resolve(response)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const getImage = (src,abortController) =>{
    return new GeneralService(abortController).getImage(src,abortController).then((response)=>{
            console.log('here image',response)
            if(response.ok){
                return Promise.resolve(src)
            }
            return Promise.reject()
        }
    )
} 

export const getBloquedUsers = (loadedElements,numberOfRows) =>{
    return Service.getBloquedUsers(loadedElements,numberOfRows).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{

            return Promise.reject(response.response)
        }
    )
}

export const getCategories = () => {
    return Service.getCategories().then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            return Promise.reject(response.response)
        }
    )
}



export const getMyProfile = (idUser) => (dispatch) =>{
    return Service.getMyProfile(idUser).then(
        (response)=>{
            const {
                codeNumber = "",
                codeDescription = "",
                ...profile
            } = response.data
            let auth = JSON.parse(localStorage.getItem("auth"));
            console.log(auth)
            if(auth && auth.user){
                localStorage.setItem("auth", JSON.stringify({
                    ...auth,
                    user:{
                        ...auth.user,
                        ...profile
                    }
                }));

                dispatch({
                    type: UPDATE_PROFILE,
                    payload: { 
                        "user":profile
                    },
                });                
            }else{
                return Promise.reject()
            }

            return Promise.resolve(profile)
        },
        (response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)            
        }
    )
}

export const getRemainingRequests = () => (dispatch) =>{
    return Service.getRemainingRequests().then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            apiErrorHandler(response.response,dispatch)
            return Promise.reject(response.response)
        }
    )
}

export const getReviews = (idUser,type,loadedElements,numberOfRows) =>{
    return Service.getReviews(idUser,type,loadedElements,numberOfRows).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{

            return Promise.reject(response.response)
        }
    )
}

export const getTagsCategory = (categoryId) => {
    return Service.getTagsCategory(categoryId).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{

            return Promise.reject(response.response)
        }
    )

}

export const getUserProfile = (idUser) =>{
    return Service.getUserProfile(idUser).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{

            return Promise.reject(response.response)
        }
    )
}

export const getPendingRates = () => (dispatch) =>{
    return Service.getPendingRates().then(
        (response)=>{
            dispatch({
                type: SET_PENDING_RATES,
                payload:response.data.list
            });
            return Promise.resolve(response.data)
        },
        (response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
            
        }
    )
}

export const reportUser = (idUser,typeOfReport,comment)=>{
    return Service.reportUser(
        idUser,
        typeOfReport,
        comment
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            return Promise.reject(response.response)
        }
    )
}

export const reportRequest = (idRequest,typeOfReport,comment=undefined) =>{
    return Service.reportRequest(idRequest,typeOfReport,comment).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{

            return Promise.reject(response.response)
        }
    )
}

export const sendABugReport = (description,image) => (dispatch) => {
    return Service.sendABugReport(description,image).then(
        (response)=>{
            if(response.data.presignedURL){
                AuthService.uploadProfilePhoto(
                    response.data.presignedURL,
                    image
                ).then(
                    (response)=>{                        
                    },(error)=>{                        
                    }                
                )
            }

            return Promise.resolve(response)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const sendSuggestion = (suggestion,userType="GURU") => {
    return Service.sendSuggestion(suggestion,userType).then(
        (response)=>{
            return Promise.resolve(response)
        },(response)=>{
            return Promise.reject(response.response)
        }
    )
}

export const sendSubcategorySuggestion = (suggestion,idCategory,userType="GURU") => {
    return Service.sendSubcategorySuggestion(suggestion,idCategory,userType).then(
        (response)=>{
            return Promise.resolve(response)
        },(response)=>{
            return Promise.reject(response.response)
        }
    )
}



export const updateLocalProfile = (profile) => (dispatch) => {
    dispatch({
        type: UPDATE_PROFILE,
        payload:profile
    });

    return Promise.resolve()

}
export const cleanLocalProfile = () => (dispatch) => {
    dispatch({
        type: CLEAN_PROFILE
    });
    return Promise.resolve()
}

export const updatePasswordWeb =(idUser,token,auth,password)=>(dispatch)=>{   
    return Service.updatePasswordWeb(idUser,token,auth,password).then(
        (response)=>{
            console.log(response)
            return Promise.resolve(response)
        },
        (error)=>{
            console.log(error)
            return Promise.reject(error)
        }
    )
}

export const updatePassword =(params)=>(dispatch)=>{
    const{
        currentPassword,
        newPassword
    }=params
    return Service.updatePassword(currentPassword,newPassword).then(
        (response)=>{            
            return Promise.resolve(response.data)
        },
        (response)=>{            
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )

}

export const updateGeneralInfo = (params) => (dispatch) => {
    const {
        name,
        lastName,
        nickname,
        description,
        returnURL,
        image
    } = params
    return Service.updateProfile(params).then(
        (response)=>{
            console.log("update response...",response);           
            const auth = JSON.parse(localStorage.getItem('auth'))
            
            const {
                user,
            } = auth
           
                           
            const userUpdate = {
                ...user,
                name,
                lastName,
                nickname,
                description,
            }       

            console.log('h?')

            console.log('return url?',returnURL)

            if(returnURL){
                console.log('has image?')
                AuthService.uploadProfilePhoto(
                    response.data.presignedURL,
                    image
                ).then(
                    (response)=>{
                        console.log('image',response)
                        userUpdate.imageSufix = user.imageSufix+1
                        localStorage.setItem("auth", JSON.stringify({
                            ...auth,
                            user:{
                                ...auth.user,
                                ...userUpdate
                            }
                        }));

                        dispatch({
                            type: UPDATE_PROFILE,
                            payload: { 
                                user:userUpdate                   
                            },
                        });                   
            
                        return Promise.resolve(auth.user);
                    },(error)=>{
                        console.log('imageError',error)
                        return Promise.reject({
                            response:{
                                data:{
                                    'codeNumber':"IMAGE",
                                    'codeDescription':'cant update the image'
                                }
                            },
                            data:{
                            'codeNumber':"IMAGE",
                            'codeDescription':'cant update the image'
                            }
                        })
                    }                
                )
            }else{
                console.log('has image? No it does not')
                localStorage.setItem("auth", JSON.stringify({
                    ...auth,
                    user:{
                        ...auth.user,
                        ...userUpdate
                    }
                }));
                dispatch({
                    type: UPDATE_PROFILE,
                    payload: { 
                        user:userUpdate                   
                    },
                });                   
    
                return Promise.resolve(auth.user);
            }            
            
        },
        (response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response)
        }
    )   
}

export const updateGuruLevel = (level) => (dispatch) =>{
    return Service.updateGuruLevel(level).then(
        (response)=>{
            return Promise.resolve(response)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const updateLanguages = (langs) => (dispatch) =>{
    return Service.updateLanguages(langs).then(
        (response)=>{
            return Promise.resolve(response)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const updateIsIndependent = (isIndependent,companyName) => (dispatch) =>{
    return Service.updateIsIndependent(isIndependent,companyName).then(
        (response)=>{
            let auth = JSON.parse(localStorage.getItem("auth"));
            console.log(auth)
            if(auth && auth.user){
                localStorage.setItem("auth", JSON.stringify({
                    ...auth,
                    user:{
                        ...auth.user,
                        'companyName':companyName
                    }
                }));

                dispatch({
                    type: UPDATE_PROFILE,
                    payload: { 
                        "user":{
                            ...auth.user,
                            'companyName':companyName
                        }
                    },
                });
            }  
            return Promise.resolve(response)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const updateLocation = (locationData) => (dispatch) =>{
    return Service.updateLocation(locationData).then(
        (response)=>{
            return Promise.resolve(response)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const updateSpecialities = (tags,localData) => (dispatch) =>{
    return Service.updateSpecialities(tags).then(
        (response)=>{
            return Promise.resolve(response)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const uploadAudio = (mediaUrl,file) => (dispatch) =>{
    return Service.uploadAudio(mediaUrl,file).then(
        (response)=>{
            return Promise.resolve(response)
        },
        (response)=>{
            return Promise.reject(response)
        }
    )
}

export const uploadMedia = (mediaUrl,file) => (dispatch) =>{
    return AuthService.uploadProfilePhoto(mediaUrl,file).then(
        (response)=>{
            return Promise.resolve(response)
        },
        (error)=>{
            return Promise.reject(error)
        }
    )
}

export const unblockUser = (idUser) =>{
    return Service.unblockUser(idUser).then(
        (response)=>{
            return Promise.resolve(response)
        },(response)=>{
            return Promise.reject(response.response)
        }
    )
}

export const rateUser = (
    idConversation,
    review,
    rating,
) => (dispatch) =>{
    return Service.rateUser(
        idConversation,
        review,
        rating,
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}
export const rateAndTipUser = (
    idConversation,
    review,
    rating,
    paymentMethodId,
    amount
) => (dispatch) => {
    return Service.rateAndTipUser(
        idConversation,
        review,
        rating,
        paymentMethodId,
        amount
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}

export const validateProfile = (
    idUser,token,auth
) => (dispatch) => {
    return Service.validateProfile(
        idUser,token,auth
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },(response)=>{
            return Promise.reject(response.response.data)
        }
    )
}

export const validateTokenForRecovery = (
    idUser,token,auth
) => (dispatch) => {
    return Service.validateTokenForRecovery(
        idUser,token,auth
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },(response)=>{
            return Promise.reject(response.response.data)
        }
    )
}

