import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    UPDATE_PROFILE,
    LOGOUT_WITH_MESSAGE,
    DELETE_SESSION
} from "../actions/types"

const auth = JSON.parse(localStorage.getItem("auth"));

const initialState = auth
? { isLoggedIn: true, session:auth.session, user:auth.user }
: { isLoggedIn: false, session: null, user: null};

export default function (state = initialState, action) {
const { type, payload } = action;
console.log('dispatch logout',type)
switch (type) {     
    

    case LOGIN_SUCCESS:
    return {
        ...state,
        user:payload.user,
        isLoggedIn: true,
        session: payload.session,
    };
    case UPDATE_PROFILE:
    return {
        ...state,
        user:{
            ...state.user,
            ...payload.user
        }
    }
    case LOGIN_FAIL:   
    case LOGOUT:
        return {
            ...state,
            isLoggedIn: false,
            session: null,
            user:null
        };
    case LOGOUT_WITH_MESSAGE:
        localStorage.removeItem("auth");
        return {            
            isLoggedIn: false,
            session: null,
            user:null
        };
    case DELETE_SESSION:
    localStorage.setItem("auth", JSON.stringify({
        ...auth,
        session:""
    }));
    return {
        ...state,        
        session: "",        
    };
    default:
    return state;
}
}