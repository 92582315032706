import {
    SET_LOADING,
} from "../actions/types"

const initialState = {
    "loading":false,
    "loadingText":""
}

export default (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    console.log(action)
    switch (type) {     
        case SET_LOADING:
            return {
                "loading":payload.loading,
                "loadingText":payload.loadingText
            }        
        default:
        return state;
    }
}