import React from 'react';
import C from '../../constants'
import { useTranslation } from 'react-i18next';

const CardItem = ({brand,last4,status,isDefault,selectionMode=true}) => {
    const {t} = useTranslation()
    const renderCardSrc = (brand) => {
        let editedBrand = brand.replace(/\s/g, '').toLowerCase();
        return `${C.resources.webpage}app/cards/${editedBrand}.png`;        
    }
    return (
        <div className="row justify-content-between align-items-center">
            <div className="col-auto row align-items-center">
                <div className="col-auto">
                    <div className="payment-method__card ms-4 my-2">
                        <img src={renderCardSrc(brand)} alt="card brand image" />
                    </div>
                </div>
                <div className="col-auto">
                    <h4>
                        • • • • {last4}
                    </h4>
                </div>
                {
                    status > 1&&
                    <div className="col-auto">
                        <h5 className='ms-3 text-danger'>
                            {
                                status==2?
                                t('PAYMENTS_card_expired')
                                :
                                t('PAYMENTS_card_not_valid')                                
                            }
                        </h5>                        
                    </div>
                }
            </div>
            <div className="col-auto row justify-content-center text-end">
                {selectionMode ? (
                        isDefault && (
                            <h3 className="me-3 text-purple">
                                <i className="fa fa-check">
                                </i>
                            </h3>
                        )
                    ) : (
                        <h3 className="me-3 text-muted">
                            <i className="fa fa-chevron-right">
                            </i>
                        </h3>                               
                    )}
            </div>
        </div>
    );
}

export default CardItem;
