import React from 'react';
import AZField from '../form/AZField';
import AZButton from '../form/AZButton';

import { useState } from 'react'; 

import { useTranslation } from 'react-i18next';

const PasswordRecoveryForm = (props) => {
    const { t, i18n } = useTranslation();      
    const {
        onSubmit,
        loading,        
    } = props

    const [form, setForm] = useState({
        "email":{
            name:"email",
            value:"",
            error:false,
            errorText:""
        }
    })
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");    

    const handleChange = (e)=>{
        const {value} = e.target
        setForm({
            ...form,
            'email':{
                ...form['email'],
                value:value
            }
        })
    }

    const handleSubmit = (e)=>{
        e.preventDefault()
        console.log("on recovery click")
        setMessage("")
        setErrorMessage("")
        Object.keys(form).map(i=>form[i]={
            ...form[i],
            error:false,
            errorText:""
        })
        onSubmit(
            form,
            (data)=>{
                setMessage(`${t('MSG_OK_recovery_password_title')} ${t('MSG_OK_recovery_password_text')}`)
            },
            (response,errors)=>{
                console.log('verificate response',response)
                if(response){
                    setErrorMessage(t("ERROR_"+response.data.codeNumber))
                }
                if(errors!==null){
                    setForm({
                        ...form,
                        ...errors
                    })
                }
            }
        )
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row my-3">
                <div className="col-12">
                    {t('RECOVERY_PASS_text_please_enter_your_email')}
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZField
                        name={form.email.name}
                        value={form.email.value}
                        error={form.email.error}
                        errorText={form.email.errorText}
                        onChange={handleChange}
                    >  
                        {t('RECOVERY_PASS_placeholder_input_email')}               
                    </AZField>
                </div>
            </div>
            {
                message!==""&& <div className="row my-2">
                    <div className="col-12">
                        <p>{message}</p>
                    </div>
                </div>
            }
            {
                errorMessage!==""&& <div className="row my-2">
                    <div className="col-12 text-danger">
                        <p>{errorMessage}</p>
                    </div>
                </div>
            }
            <div className="row justify-content-center my-2">
                <div className="col-5">
                    <AZButton
                        customClass="w-100"
                        type="submit"
                        onClick={()=>{}}
                        loading={loading}
                        loadingText={t('LOADING')}
                    >
                        {t('RECOVERY_PASS_button_recovery')}
                    </AZButton>
                </div>
            </div>
        </form>
    );
}

export default PasswordRecoveryForm;
