import AZField from "../form/AZField"
import AZPassword from "../form/AZPassword"
import AZSelect from "../form/AZSelect"
import AZButton from "../form/AZButton"
import AZButtonLink from "../form/AZButtonLink"

import { useTranslation } from 'react-i18next';

import { useState,useEffect } from 'react'

import { RecaptchaVerifier,getAuth } from "@firebase/auth";

const VerificateForm = (props) => {
    const { t, i18n } = useTranslation();      
    const {
        onSubmit,
        phoneData,
        onResendCode,
        profile,
        loading
    } = props

    const [form, setForm] = useState({
        "verificationCode":{
            name:"verificationCode",
            value:"",
            error:false,
            errorText:"",
        }
    });
    const [sendingCodeLoading, setSendingCodeLoading] = useState(false);

    const handleChange = (e)=>{
        let formName =  e.target.name
        let value = e.target.type === 'checkbox' ? e.target.checked: e.target.value
        console.log(`${formName} ${value}`)
        setForm({  
            ...form,                      
            [formName]:{
                ...form[formName],
                value
            }                               
        })
    }
    const handleSubmit = (e) => {
        console.log('prevent from submit')
        e.preventDefault()
        Object.keys(form).map(i=>form[i]={
            ...form[i],
            error:false,
            errorText:""
        })
        // reset the errors       
        onSubmit(
            form,            
            (errors)=>{
                console.log('errors')
                console.log(errors)
                setForm({
                    ...form,
                    ...errors
                })
                console.log(form)
            }
        )        
    }

    useEffect(() => {
        window.applicationVerifier = new RecaptchaVerifier('recaptcha-container',{
            size: 'invisible'
        },getAuth());
    }, []);

    return (
        <form onSubmit={handleSubmit}>

            <div className="row col-12">
                <div id="recaptcha-container">
                
                </div>                    
            </div>            
            
            <div className="row my-3">
                <div className="col-12 text-center">
                    <p className="az-p text-center">
                        {t('CREATE_ACCOUNT_description_confirmation_modal')}
                    </p>
                </div>
            </div>            
            <div className="row my-2">
                <div className="col-12 text-center">
                    <h3 className="az-subtitle">
                        {"+"+phoneData.phoneCode} {profile.phoneNumber}
                    </h3>
                </div>
            </div>   
                     
            <div className="row justify-content-center my-2">
                <div className="col-8">
                    <AZField
                        name={form.verificationCode.name}
                        value={form.verificationCode.value}
                        error={form.verificationCode.error}
                        errorText={form.verificationCode.errorText}
                        onChange={handleChange}
                    >
                        {t('CREATE_ACCOUNT_placeholder_confirmation_number_modal')}
                    </AZField>
                </div>
            </div>
            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <AZButtonLink                        
                        style="color"
                        underline
                        type="button"      
                        loading={sendingCodeLoading}
                        onLoadText={t('LOADING')}                  
                        onClick={()=>{ 
                            setSendingCodeLoading(true)
                            onResendCode(
                                'phone no use',
                                window.applicationVerifier,                              
                                (success,error)=>{
                                    if(success){
                                        setSendingCodeLoading(false)
                                    }else{
                                        console.log(error)
                                        setSendingCodeLoading(false)
                                    }
                                })
                        }}
                    >
                        {t('CREATE_ACCOUNT_resend_verification_code')}
                    </AZButtonLink>
                </div>
            </div>
            <div className="row my-2 justify-content-center">
                <div className="col-auto text-center">
                    <AZButton
                        onClick={()=>{}}
                        type="submit"
                        loading={loading}
                        onLoadText={t('LOADING')}
                    >
                        {t('CREATE_ACCOUNT_button_verify')}
                    </AZButton>
                </div>
            </div>            
        </form>
    );
}

export default VerificateForm;
