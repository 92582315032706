import React,{ useState } from 'react';
import AZProfileImage from '../profile/AZProfileImage';
import AZButtonLink from '../form/AZButtonLink';
import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';
import FormatedDate from '../format/FormatedDate';
import HorizontalConversationList from './HorizontalConversationList';

const ArchivedRequestItem = (props) => {
    const {
        model,    
        onConversationClick,
        hideResponseIcon                                
    } = props

    const {t,i18n} = useTranslation()

    const {        
        title,  
        requestId,        
        conversations 
    } = model
    
    const handleConversationClick = (conversationData) => {
        onConversationClick({
            ...conversationData,
            title,
            idRequest:requestId
        })
    }   

    return (  
        <div>
            <div className="az-list__content__item az-list__content__item--archived-request align-items-center" >                
                <div className="col-12 p-1">
                    <h4>
                        {title}
                    </h4>
                </div>                    
                <div className="col-12 my-1">
                    <HorizontalConversationList 
                        list={conversations}
                        onItemClick={handleConversationClick}
                        onNext={()=>{}}
                        hideResponseIcon={hideResponseIcon}
                        noDataComponent={<div className='p-1'> <p> {t('SEEKER_ARCHIVED_no_conversations')} </p> </div>}
                    />
                </div>                                                      
            </div>            
        </div>      
    );
}

export default ArchivedRequestItem;

