import { useRef,useState } from "react";
import { useTranslation } from "react-i18next";

import c from "../../constants";


const AZImgInput = (props) => {
    const {
        onChange,        
        error,
        errorText,
        loading,
        placeholder,
        src,
        circled=true,
        square=false
    } = props

    const {t,i18n} = useTranslation()

    console.log("src"+src)    

    const inputFileRef = useRef(null)

    const handleClick = (e) =>{
        inputFileRef.current.click()
    }
    const handleChange = (e) => {
        
        if (e.target.files && e.target.files[0]) {
            if(square){
                var reader = new FileReader();
                reader.onload = function (readerEvent) {
                    var image = new Image();
                    image.onload = function (imageEvent) {                        
                        // Resize the image
                        var canvas = document.createElement('canvas'),
                            max_size = 300,
                            imgSize = Math.min(image.width, image.height),
                            left = (image.width - imgSize) / 2,
                            top = (image.height - imgSize) / 2;
                        
                        canvas.width = max_size;
                        canvas.height = max_size;                            
                        canvas.getContext('2d').fillStyle = 'white'
                        canvas.getContext('2d').fillRect(0,0,max_size,max_size)
                        canvas.getContext('2d').drawImage(image, left, top, imgSize, imgSize,0,0,max_size,max_size);                        
                        var dataUrl = canvas.toDataURL('image/jpeg'),
                            resizedImage = dataURLToBlob(dataUrl);
                        onChange(dataUrl,resizedImage)                        
                        //document.querySelector('#canvas').appendChild(canvas);
                    }
                    image.src = readerEvent.target.result;
                }
                reader.readAsDataURL(e.target.files[0]);
            }else{
                let image = URL.createObjectURL(e.target.files[0])  
                onChange(image,e.target.files[0])  
            }
            // var reader  = new FileReader();

            // reader.onloadend = function () {
            //     onChange(reader.result,e.target.files[0])
            // }
            
            // reader.readAsDataURL(e.target.files[0]);           
                   
            // if(square){  
            //     var dataURLToBlob = function(dataURL) {
            //         var BASE64_MARKER = ';base64,';
            //         if (dataURL.indexOf(BASE64_MARKER) == -1) {
            //             var parts = dataURL.split(',');
            //             var contentType = parts[0].split(':')[1];
            //             var raw = parts[1];
                
            //             return new Blob([raw], {type: contentType});
            //         }
                
            //         var parts = dataURL.split(BASE64_MARKER);
            //         var contentType = parts[0].split(':')[1];
            //         var raw = window.atob(parts[1]);
            //         var rawLength = raw.length;
                
            //         var uInt8Array = new Uint8Array(rawLength);
                
            //         for (var i = 0; i < rawLength; ++i) {
            //             uInt8Array[i] = raw.charCodeAt(i);
            //         }
                
            //         return new Blob([uInt8Array], {type: contentType});
            //     }                                     
                
            //         var MAX_WIDTH = 300;
            //         var MAX_HEIGHT = 300;
                    
            //         var width = image.width;
            //         var height = image.height;
                    
            //         // Change the resizing logic
            //         if (width > height) {
            //             if (width > MAX_WIDTH) {
            //                 height = height * (MAX_WIDTH / width);
            //                 width = MAX_WIDTH;
            //             }
            //         } else {
            //             if (height > MAX_HEIGHT) {
            //                 width = width * (MAX_HEIGHT / height);
            //                 height = MAX_HEIGHT;
            //             }
            //         }
            //         var canvas = document.createElement("canvas");
            //         canvas.width = width;
            //         canvas.height = height;
            //         var ctx = canvas.getContext("2d");
            //         ctx.drawImage(image, 0, 0, width, height);
            //         var dataUrl = canvas.toDataURL('image/jpeg')
            //         onChange(dataUrl,dataURLToBlob(dataUrl))
                    
            // }else{
            //     onChange(image,e.target.files[0])    
            // }
        }else{
            onChange(null,null)
        }
        
    }

    var dataURLToBlob = (dataURL)=>{
        var BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) == -1) {
            var parts = dataURL.split(',');
            var contentType = parts[0].split(':')[1];
            var raw = parts[1];
    
            return new Blob([raw], {type: contentType});
        }
    
        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
    
        var uInt8Array = new Uint8Array(rawLength);
    
        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
    
        return new Blob([uInt8Array], {type: contentType});
    }
    /* End Utility function to convert a canvas to a BLOB      */

    return (
        <div>     
            <div id="canvas"></div>       
            <div className="az-img-input">
                <div className={`az-img-input__img ${!circled&&"az-img-input__img--rect"}`}
                    onClick={handleClick}
                >
                    <img src={src?src:`${c.resources.webpage}app/avatar.png`} alt="image select" />
                </div>
                <input  
                        name={props.name}
                        type="file" 
                        ref={inputFileRef}
                        className="d-none"
                        onChange={handleChange}
                        accept="image/jpeg,image/png"
                />                
                
            </div>
            {   
                placeholder!==""&&
                <div className="az-img-input__label">
                    {placeholder}
                </div>
            }
            {                
                error&&
                <div className="az-img-input__error text-danger">
                    <p>{t(errorText)}</p>
                </div>
            }
        </div>
    );
}

export default AZImgInput;
