import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import AZField from '../form/AZField';
import AZButton from '../form/AZButton';
import { formValidateField } from '../../helpers/formvalidation';
import AZTextArea from '../form/AZTextArea';



const SendMessageForm = (props) => {
    const {
        onSubmit,
        onCancel,
        description,
        loading
    }=props

    const {t} = useTranslation();

    const [form, setForm] = useState({
        "message":{
            "name":"message",
            "value":"",
            "error":false,
            "errorText":""
        }
    });

    const [messages, setMessages] = useState({
        "success":false,
        "error":false
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        const { 
            message
        } = form
        let valid = true

        setForm({
            ...form,
            "message":{
                ...form['message'],
                error:false
            }
        })
        setMessages({
            "success":false,
            "error":false
        })

        let errors = {}

        if(!formValidateField(message.value,'required')){
            valid = false
            errors["message"] = {
                ...form["message"],
                error:true,

            }
        }
        if(valid){
            onSubmit(
                {
                    "message":message.value
                },
                (response)=>{
                    console.log(response)
                    setMessages({
                        'success':t('GURU_proposal_sent_description'),
                        'error':false
                    })
                },
                (error)=>{
                    if(error){
                        setMessages({
                            'success':false,
                            'error':t("ERROR_"+error.data.codeNumber)
                        })
                    }
                }
            )
        }else{
            setForm({
                ...form,
                ...errors
            })
        }
    }

    const handleChange = (e)=>{
        const{
            name,
            value
        } = e.target

        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })
    }

    return (
        <div className='p-3 row justify-content-center'>
            <form onSubmit={handleSubmit} className='col-10'>
                <div className="row my-2">
                    <div className="col-12 my-2">
                        <p>{description}</p>    
                    </div>
                    <div className="col-12 my-2">
                        <AZTextArea 
                            {...form.message}
                            onChange={handleChange}
                        >
                            {t('GURU_modal_proposal_placeholder_input_message')}
                        </AZTextArea>
                    </div>
                    <div className="col-12 my-2">
                        {messages.success&&<p className='text-purple'>{messages.success}</p>}
                        {messages.error&&<p className='text-danger'>{messages.error}</p>}
                    </div>
                </div>
                <div className="row justify-content-between my-2">
                    <div className="col-sm-auto">
                        <AZButton
                            style="gray"
                            onClick={onCancel}
                        >
                            {t('GENERAL_cancel')}
                        </AZButton>
                    </div>
                    <div className="col-sm-auto">
                        <AZButton
                            style="color"
                            type="submit"
                            onClick={()=>{}}
                            loading={loading}
                            onLoadText={t('SUGGESTION_MODAL_sending')}
                        >
                            {t('GURU_modal_proposal_send_proposal_button')}
                        </AZButton>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SendMessageForm;
