import React,{useEffect,useState,useRef} from 'react';
import {useStripe,Elements, useElements, CardElement,PaymentElement,CardNumberElement,CardExpiryElement,CardCvcElement} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useWindowScrollApiList from '../../hooks/useWindowScrollApiList';

import AddPaymentMethodForm from '../../components/payments/AddPaymentMethodForm';
import PaymentsMethodsList from '../../components/payments/PaymentsMethodsList';
import PaymentMethodInfo from '../../components/payments/PaymentMethodInfo';

import { connect } from 'react-redux';

import * as appActions from "../../actions/app.actions"
import * as paymentActions from "../../actions/payments.actions"
import * as navActions from '../../actions/navbar.actions'

import C from "../../constants"

import AZButton from '../../components/form/AZButton';
import AZButtonLink from '../../components/form/AZButtonLink';
import AZModalTitle from '../../components/modal/AZModalTitle';
import { useLocation } from 'react-router-dom';


const PaymentMethods = (props) => {
    const {
        dispatch,
        user,
        currentRate
    }= props
    const history = useHistory()
    const location = useLocation()
    const {t} =  useTranslation()   
    const stripe = useStripe() 
    

    const selectionMode = location.state&&location.state.selectionMode!==undefined?true:false    
    const backpath = location.state&&location.state.backpath!==undefined?location.state.backpath:false    
    
    const giveTipData = location.state&&location.state.giveTipData!==undefined?location.state.giveTipData:{
        selectedButton:'none',
        tip:0,
        lastPay:{
            dateLastTip:null,
            currencyLastTip:null,
            amountLastTip:null,
            loading:true 
        }
    }        

    const [addPaymentModal, setAddPaymentModal] = useState(false);
    const [addPaymentMethodLoading, setAddPaymentMethodLoading] = useState(false);
    const [addPaymentsErrors,setAddPaymentsErrors] = useState([])

    const [paymentMethodInfoModal, setPaymentMethodInfoModal] = useState({
        paymentMethod:false,
        show:false,
    });

    const [detachLoading, setDetachLoading] = useState(false);
    const [detachErrors,setDetachErrors] = useState("")

    const [paymentsList, setPaymentsList] = useState([]);
    const [paymentsLoading, setPaymentsLoading] = useState(false);


    const  getPaymentMethods = () =>{
        setPaymentsLoading(true)
        dispatch(
            paymentActions.getPaymentMethods()
        ).then(
            (response) => {
                const {
                    list
                } = response
                console.log('get payments list response',response)
                setPaymentsLoading(false)
                setPaymentsList(list)
                if(list.length==0 && backpath){
                    setAddPaymentModal(true)
                }
                return 
            },
            (error) => {
                console.log('get payments list error',error)
                setPaymentsLoading(false)
                return 
            }
        )
    }     

    const handleAddPaymentMethod = (paymentMethod) => {
        setAddPaymentMethodLoading(true)
        console.log('payment method cb',paymentMethod)

        const onConfirmCard = ({setupIntent}) => {
            console.log('setup intent',setupIntent)
            setAddPaymentModal(false)          
            const {
                error
            }=setupIntent
            if (setupIntent.status == "succeeded") {
                let newPaymentMethod = {
                    id: paymentMethod.id,
                    brand: paymentMethod.card.brand,
                    expMonth: paymentMethod.card.exp_month,
                    expYear: paymentMethod.card.exp_year,
                    last4: paymentMethod.card.last4,
                    isDefault:paymentsList.length == 0,
                    status: 1,
                };

                //If it is the first payment method, then automatically return back
                
                if (paymentsList.length == 0) {
                    if(backpath){
                        if(currentRate){
                            dispatch(
                                appActions.updateCurrentRate({
                                    ...giveTipData,
                                    paymentMethodForTip:newPaymentMethod
                                })
                            )
                        }
                        history.push(backpath,{
                            giveTipData:{
                                ...giveTipData,
                                paymentMethodForTip:newPaymentMethod
                            }
                        })                        
                    }else{
                        history.goBack()
                    }
                    //history.goBack()
                    //TODO LEGR:i dont know th reason to this functionality
                    // handleUpdateDefault(newPaymentMethod);
                    // handleBackButtonClick();
                }
                let newPaymentsList = paymentsList
                if(paymentsList.length>0){
                    const [
                        firstElement,
                        ...restList
                    ] = paymentsList
                    newPaymentsList = [                        
                        ...paymentsList,
                        newPaymentMethod,
                    ]
                }else{
                    newPaymentsList = [                        
                        newPaymentMethod,
                        ...paymentsList
                    ]
                }


                console.log("::::newPaymentMethod::::", newPaymentMethod);                            
                setPaymentsList(newPaymentsList)                                
                // let pms = Object.assign([], state.paymentMethods);
                // pms.splice(state.paymentMethods.length - 1, 0, newPaymentMethod);
                // //pms.push(newPaymentMethod);
                // setState(previousState => ({ ...previousState, activityIndicator: false, paymentMethods: pms }));
            }else{
                if(error){
                    setAddPaymentsErrors([error.message])
                }else{                    
                    setAddPaymentsErrors([t("ERROR_stripe_config")])
                }
                setAddPaymentMethodLoading(false)
            }
                        
        }

        dispatch(
            paymentActions.attachPaymentMethod(paymentMethod.id)
        ).then(
            ({clientSecret,status}) => {                
                try {
                    stripe.confirmCardSetup(clientSecret)
                    .then(
                        onConfirmCard
                    )
                } catch (error) {
                    console.log('on stripe error',error)
                    setAddPaymentMethodLoading(false)
                    setAddPaymentsErrors([t("ERROR_stripe_config")])
                    setAddPaymentMethodLoading(false)
                }
            },
            (response) => {
                console.log('service error',response)                
                setAddPaymentMethodLoading(false)
                setAddPaymentsErrors([t(`ERROR_${response.codeNumber}`)])
            }  
        )       
    }

    const handleDetach = (paymentId) => {
        setDetachLoading(true)
        dispatch(
            paymentActions.detachPaymentMethod(paymentId)
        ).then(
            (response) => {
                const newList = paymentsList.filter((pm)=>{
                    return pm.id!==paymentId
                })

                setPaymentsList(newList)
                setDetachLoading(false)        
                setPaymentMethodInfoModal({
                    show:false,
                    paymentMethod:false
                })    
                setDetachErrors('')
            },
            (error) => {                
                setDetachErrors(t(`ERROR_${error.codeNumber}`))                
                setDetachLoading(false)
            }
        )
    }
    
    const handleCardClick = (model)=>{
        console.log(model)
        console.log('selection mode?',location)
        if(selectionMode){
            if(!model.isDefault){
                dispatch(appActions.setLoading(true))                
                dispatch(
                    paymentActions.setDefaultPaymentMethod(model.id)
                ).then((response) => {
                    console.log('on default pm')
                    setPaymentsList(
                        paymentsList.map((m)=>{
                            return {
                                ...m,
                                isDefault:m.id == model.id
                            }                        
                        })
                    )   
                    dispatch(appActions.setLoading(false))                 
                    if(backpath){
                        if(currentRate){
                            dispatch(
                                appActions.updateCurrentRate({
                                    ...giveTipData,
                                    paymentMethodForTip:model
                                })
                            )
                        }
                        history.push(backpath,{
                            giveTipData:{
                                ...giveTipData,
                                paymentMethodForTip:model
                            }
                        })                        
                    }else{
                        history.goBack()
                    }
                    
                })            
            }else{
                dispatch(appActions.setLoading(false))
                if(backpath){
                    if(currentRate){
                        dispatch(
                            appActions.updateCurrentRate({
                                ...giveTipData,
                                paymentMethodForTip:model
                            })
                        )
                    }
                    history.push(backpath,{
                        giveTipData:{
                            ...giveTipData,
                            paymentMethodForTip:model
                        }
                    })
                }else{
                    history.goBack()
                }
            }
        }else{

            setPaymentMethodInfoModal({
                paymentMethod:model,
                show:true
            })
        }

    }

    useEffect(() => {
        if(paymentsList.length == 0){
            getPaymentMethods()
        }
        dispatch(navActions.selectIndex(2))
    }, []);
    useEffect(() => {
        if(addPaymentModal==false){
            setAddPaymentMethodLoading(false)
        }
    }, [addPaymentModal]);

    return (
        <div>
            <AZModalTitle
                show={addPaymentModal}
                onClose={()=>{setAddPaymentModal(false);setAddPaymentsErrors([])}}
                title={t('PAYMENTS_add_payment_method')}               
            >
                {addPaymentModal&&
                    <div className="p-3">
                        <AddPaymentMethodForm 
                            user={user}
                            onSubmit={handleAddPaymentMethod}
                            errors={addPaymentsErrors}
                            loading={addPaymentMethodLoading}                            
                            onError={(errors)=>{console.log('show this error?',errors);setAddPaymentsErrors([...errors])}}
                        />  
                    </div>
                }
            </AZModalTitle>
            {
                paymentMethodInfoModal.show&&
                <AZModalTitle 
                    show={paymentMethodInfoModal.show}
                    title={t('PAYMENTS_credit_debit_card')}
                    onClose={()=>{setDetachErrors("");setPaymentMethodInfoModal({show:false,paymentMethod:false})}}
                >
                    {
                        paymentMethodInfoModal.paymentMethod&&<PaymentMethodInfo 
                            paymentMethod={paymentMethodInfoModal.paymentMethod}
                            onDetach={handleDetach}                        
                            loading={detachLoading}
                            errors={detachErrors}
                        />
                    }
                </AZModalTitle>
            }
            <div className="container-lg">
                <div className="row justify-content-center" >
                    <div className="col-md-6 col-12">
                        <AZButtonLink
                                style="color"
                                type="button"
                                underline
                                onClick={()=>{history.goBack()}}
                            >                 
                            {t('GENERAL_back')}      
                        </AZButtonLink>
                    </div>
                </div>
                <div className="row my-3 justify-content-center">
                    <div className="col-md-6 col-12">
                        <h2>
                            {t('PAYMENTS_payment_methods_title')}
                        </h2>
                    </div>
                </div>              

                <div className="row justify-content-center my-2">
                    <div className="col-md-6 col-12">
                        <div className="az-card-container az-card-container--no-padding">
                            <PaymentsMethodsList
                                list={paymentsList}
                                noData={t('PAYMENTS_no_payments_found')}        
                                loading={paymentsLoading}
                                full
                                onItemClick={handleCardClick}
                                onAddMethod={()=>{setAddPaymentModal(true)}}
                                selectionMode={selectionMode}
                            >

                            </PaymentsMethodsList>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = (state) => {
    const {
        auth,
        pendingRates,
        
    } = state

    return {
        user:auth.user,
        currentRate:pendingRates.currentRate,
        pendingRatesList:pendingRates.list,
        ratePredata:pendingRates.predata,
    }
}

export default connect(mapStateToProps)(PaymentMethods);
