import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AZButtonIconLink from '../form/AZButtonIconLink';
import AZButton from '../form/AZButton';
import AZDesicionModal from '../modal/AZDesicionModal';
import AZModal from '../modal/AZModal';
import AZModalTitle from '../modal/AZModalTitle';

//Actions
import * as generalActions from '../../actions/general.actions'
import * as guruActions from '../../actions/guru.actions'
import * as appActions from '../../actions/app.actions'


//Components
import SelectCategory from './SelectCategory';
import SelectGuruLevel from './SelectGuruLevel';
import SelectGuruIndepent from './SelectGuruIndepent';
import SelectLanguages from './SelectLanguages';
import SelectPlaces from './SelectPlaces';

import AZLoading from '../modal/AZLoading';
import { useHistory, useLocation } from 'react-router-dom';


const BecomeGuruForm = (props) => {
    const {dispatch}=props

    const {t} = useTranslation()
    const history = useHistory()
    const location = useLocation()

    const [selectedComponent, setSelectedComponent] = useState("FORM");   
    const [showLangModal, setShowLangModal] = useState(false);
    const [showPlacesModal, setShowPlacesModal] = useState(false);

    const [categories, setCategories] = useState([]);
    
    const [guruData,setGuruData] = useState({})

    const [errors, setErrors] = useState({
        "selectCategory":false,
        "selectGuruIndepent":false,
        "selectGuruLevel":false,
        "selectLanguages":false,
        "selectPlaces":false,
    });

    const [showLoading, setShowLoading] = useState(false);
    

    const getCategories = () => {
        generalActions.getCategories().then(
            (data)=>{
                console.log(data)

                setCategories(data.list.map((c)=>(
                    {
                        "id":c.categoryId,
                        "value":c.name
                    }
                )))
            },
            (error)=>{
                console.log(error)
            }
        )
    }

    const showSelectedComponent = ()=>{
        switch (selectedComponent) {
            case "FORM":                                
                return showForm()
            case "CATEGORIES":
                return <div className='col-12'>
                    <SelectCategory
                        categories={categories}
                        backCategorySelect={guruData.categorySelect?guruData.categorySelect:undefined}
                        backTags={guruData.tags?guruData.tags:[]}
                        backSelectedTags={guruData.selectedTags?guruData.selectedTags:{}}
                        backTagsNumber={guruData.tagsNumber?guruData.tagsNumber:0}
                        onBack={()=>{setSelectedComponent("FORM")}}
                        onOk={(data)=>{setGuruData({...guruData,...data});setSelectedComponent("FORM");setErrors({...errors,"selectCategory":false})}}
                    >
                    </SelectCategory>
                </div>
            case "LEVEL":
                return <div className='col-10'>
                    <SelectGuruLevel                        
                        onOk={(data)=>{setGuruData({...guruData,...data});setSelectedComponent("FORM");setErrors({...errors,"selectGuruLevel":false})}}
                        backLevel={guruData.level!==undefined?guruData.level:-1}
                    >
                    </SelectGuruLevel>
                </div>
            case "INDEPENT":
                return <div className='col-10'>
                    <SelectGuruIndepent                        
                        onOk={(data)=>{setGuruData({...guruData,...data});setSelectedComponent("FORM");setErrors({...errors,"selectGuruIndepent":false})}}
                        backCompanyName={guruData.companyName?guruData.companyName:""}
                        backIsIndependent={guruData.isIndependent!==undefined?guruData.isIndependent:-1}
                    >
                    </SelectGuruIndepent>
                </div>
            default:
                break;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let valid = true
        const {
            address,
            tags,
            level,
            isIndependent,
            languages
        } = guruData
        console.log('guruData',guruData)
        console.log('tags',tags)

        console.log('the erros',errors)

        let formErrors = {
            "selectCategory":false,
            "selectGuruIndepent":false,
            "selectGuruLevel":false,
            "selectLanguages":false,
            "selectPlaces":false,
        }       

        //categories error        
        if(tags==undefined){
            console.log('error category')
            formErrors = {
                ...formErrors,
                'selectCategory':true
            }            
            valid=false
        }
        //gurulevel error
        if(level==undefined){
            formErrors = {
                ...formErrors,
                'selectGuruLevel':true
            }   
            valid=false
        }
        //guruindependent error
        if(isIndependent==undefined){
            formErrors = {
                ...formErrors,
                'selectGuruIndepent':true
            }   
            valid=false
        }
        //places error
        if(address==undefined){
            formErrors = {
                ...formErrors,
                'selectPlaces':true
            }   
            valid=false
        }
        //languages error
        if(languages==undefined){
            formErrors = {
                ...formErrors,
                'selectLanguages':true
            }   
            valid=false
        }
        if(valid){
            setShowLoading(true)
            let sendData = {
                "tags":Object.keys(guruData.selectedTags).filter((tag)=>(guruData.selectedTags[tag]!==false)),
                "languages":guruData.languages.value,
                "level":guruData.level,
                "country":guruData.country,
                "address":guruData.address,
                "lat":guruData.lat(),
                "lon":guruData.lon(),
                "googlePlaceId":guruData.googlePlaceId,
                "isIndependent":guruData.isIndependent,
                "companyName":guruData.companyName
            }
            console.log('send become guru data',sendData)
            dispatch(
                guruActions.createGuruProfile(sendData)
            ).then(
                ()=>{
                    dispatch(
                        appActions.setGlobalMessage(t('MSG_OK_become_guru_text'))
                    )
                },
                (error)=>{
                    if(error){
                        dispatch(
                            appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')
                        )
                    }
                }
            )
        }else{
            setErrors({
                ...formErrors
            })
        }
    }

    const showForm = ()=>{
        return <form onSubmit={handleSubmit}>
            <div className="az-form-container">
                <div className="row p-1">
                    <div className="col-12 my-1">
                        <h4 className='text-purple'>{t('BECOME_GURU_form_specialities_title')}</h4>                    
                    </div>
                    <div className="col-12">
                        <AZButtonIconLink
                            type="button"
                            style="gray"
                            fixMargin={guruData.tagsNames}
                            error={errors.selectCategory}
                            leftIcon={guruData.tagsNames?<i className="fa fa-check text-purple" aria-hidden="true"></i>:""}
                            onClick={()=>{ setSelectedComponent("CATEGORIES") }}
                            rightIcon={<i className="fa fa-angle-right text-purple" aria-hidden="true"></i>}
                        >
                            {guruData.tagsNames?guruData.tagsNames:t('BECOME_GURU_form_specialities_placeholder')}                        
                        </AZButtonIconLink>
                    </div>
                    <div className="col-12 my-1">
                        <div className="w-100 divider bg-gray-soft"></div>
                    </div>
                </div>  

                <div className="row p-1">
                    <div className="col-12 my-1">
                        <h4 className='text-purple'>{t('BECOME_GURU_form_level_title')}</h4>                    
                    </div>
                    <div className="col-12">
                        <AZButtonIconLink
                            type="button"
                            style="gray"
                            fixMargin={guruData.level!==undefined}   
                            error={errors.selectGuruLevel}
                            leftIcon={guruData.level!==undefined?<i className="fa fa-check text-purple" aria-hidden="true"></i>:""}
                            onClick={()=>{ setSelectedComponent("LEVEL") }}
                            rightIcon={<i className="fa fa-angle-right text-purple" aria-hidden="true"></i>}
                        >
                            {guruData.level!==undefined?t(`PROFILE_guru_level_${guruData.level}`):t('BECOME_GURU_form_level_placeholder')}                        
                        </AZButtonIconLink>
                    </div>
                    <div className="col-12 my-1">
                        <div className="w-100 divider bg-gray-soft"></div>
                    </div>
                </div>       

                <div className="row p-1">
                    <div className="col-12 my-1">
                        <h4 className='text-purple'>{t('BECOME_GURU_form_location_title')}</h4>                    
                    </div>
                    <div className="col-12">
                        <AZButtonIconLink
                            type="button"
                            style="gray"
                            fixMargin={guruData.address}
                            error={errors.selectPlaces}
                            onClick={()=>{setShowPlacesModal(true)}}
                            leftIcon={guruData.address?<i className="fa fa-check text-purple" aria-hidden="true"></i>:""}
                            rightIcon={<i className="fa fa-angle-right text-purple" aria-hidden="true"></i>}
                        >
                            {(guruData.address)?guruData.address:t('BECOME_GURU_form_location_placeholder')}                        
                        </AZButtonIconLink>
                    </div>
                    <div className="col-12 my-1">
                        <div className="w-100 divider bg-gray-soft"></div>
                    </div>
                </div> 

                <div className="row p-1">
                    <div className="col-12 my-1">
                        <h4 className='text-purple'>{t('BECOME_GURU_form_languagues_title')}</h4>                    
                    </div>
                    <div className="col-12">
                        <AZButtonIconLink
                            type="button"
                            style="gray"
                            fixMargin={guruData.langListSelected!==undefined}
                            error={errors.selectLanguages}
                            leftIcon={guruData.langListSelected!==undefined?<i className="fa fa-check text-purple" aria-hidden="true"></i>:""}
                            onClick={()=>{ setShowLangModal(true) }}
                            rightIcon={<i className="fa fa-angle-right text-purple" aria-hidden="true"></i>}
                        >
                            {guruData.langListSelected?guruData.langListSelected:t('BECOME_GURU_form_languagues_placeholder')}                        
                        </AZButtonIconLink>
                    </div>
                    <div className="col-12 my-1">
                        <div className="w-100 divider bg-gray-soft"></div>
                    </div>
                </div> 
            
                <div className="row p-1">
                    <div className="col-12 my-1">
                        <h4 className='text-purple'>{t('BECOME_GURU_form_indepent_guru')}</h4>                    
                    </div>
                    <div className="col-12">
                        <AZButtonIconLink
                            type="button"
                            style="gray"
                            fixMargin={guruData.isIndependent!==undefined}
                            error={errors.selectGuruIndepent}
                            leftIcon={guruData.isIndependent!==undefined?<i className="fa fa-check text-purple" aria-hidden="true"></i>:""}
                            onClick={()=>{ setSelectedComponent("INDEPENT") }}
                            rightIcon={<i className="fa fa-angle-right text-purple" aria-hidden="true"></i>}
                        >
                            {guruData.isIndependent!==undefined?(guruData.isIndependent?t('PROFILE_update_TYPE_option_is_independent_title'):`${t('PROFILE_update_TYPE_option_not_independent_title')}(${guruData.companyName})`):t('BECOME_GURU_form_indepent_guru')}                        
                        </AZButtonIconLink>
                    </div>
                    <div className="col-12 my-1 d-none">
                        <div className="w-100 divider bg-gray-soft"></div>
                    </div>
                </div> 
            </div>
            

            <div className="row my-4 justify-content-center">
                <div className="col-md-auto">
                    <AZButton
                        type="submit"
                        onClick={()=>{}}
                    >         
                        {t('BECOME_GURU_form_button_save')}               
                    </AZButton>
                </div>
            </div>
        </form>
    }

    useEffect(() => {
        getCategories()       
    }, []);

    useEffect(() => {
        const unblock = history.block((location, action) => {
            if(action == "POP"){
                if (selectedComponent!=='FORM') {
                    return false
                 }
                 return false;
            }
            return true;
        });

        return history.listen(locationR => { 
            if(selectedComponent!=='FORM'){                
                setSelectedComponent('FORM')                    
            }  
            unblock();                                  
        });
    }, [selectedComponent]);
    

    return (
        <div>
            <AZLoading
                show={showLoading}
            >                
            </AZLoading>
            <AZModalTitle 
                show={showLangModal}
                title={t('BECOME_GURU_form_languagues_title')}
                onClose={()=>{setShowLangModal(false)}}
            >               
                {showLangModal?<SelectLanguages
                    onOk={(data)=>{setGuruData({...guruData,...data});setShowLangModal(false);setErrors({...errors,"selectLanguages":false})}}
                    backLanguages={guruData.languages?guruData.languages:[]}
                    backLangList={guruData.langList?guruData.langList:[]}
                    backLangListSelected={guruData.langListSelected?guruData.langListSelected:[]}
                >                    
                </SelectLanguages>:<div></div>}
            </AZModalTitle>
            <AZModal
                show={showPlacesModal}
                onClose={()=>{setShowPlacesModal(false)}}                
            >
                {showPlacesModal?<SelectPlaces                    
                    onOk={(data)=>{setGuruData({...guruData,...data});setShowPlacesModal(false);setErrors({...errors,"selectPlaces":false})}}
                    onSelectPlace={(data)=>{}}
                >                    
                </SelectPlaces>: <div></div> } 
            </AZModal>
            <div className="row justify-content-center">
                {showSelectedComponent()}
            </div>
        </div>
    );
}

const mapStateToProps = (state)=>{
    
    return {
        ...state
    }
}

export default connect(mapStateToProps)(BecomeGuruForm);
