import React from 'react';
import SeekerDashboard from '../../components/seeker/SeekerDashboard';

const RequestList = () => {
    return (
        <div>
            <div className="container-lg py-1 bg-transparent">
                <div className="row justify-content-center">                    
                    <SeekerDashboard />
                </div>
            </div>   
        </div>
    );
}

export default RequestList;
