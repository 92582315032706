import {
    SET_MESSAGE,
    CLEAN_MESSAGE,
    LOGOUT_WITH_MESSAGE
} from "../actions/types"

const initialState = {
    "message":"",
    "type":"normal",//normal,error,succes
    "translate":false
}

export default (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    switch (type) {     
        case SET_MESSAGE:
        return {
            "message":payload.message,
            "type":payload.type,
            "translate":false
        }
        case CLEAN_MESSAGE:
        return initialState    
        case LOGOUT_WITH_MESSAGE:
        return {
            ...state,
            "message":"SESSION_expired",
            "type":"normal",//normal,error,succes
            "translate":true
        }    
        default:
        return state;
    }
}