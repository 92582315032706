import {
    SET_PENDING_RATES,
    POP_CURRENT_RATE,
    UPDATE_CURRENT_RATE
} from "../actions/types"

const predataInit={
    selectedButton:'none',
    tip:0,
    lastPay:{
        dateLastTip:null,
        currencyLastTip:null,
        amountLastTip:null,
        loading:true 
    },
    paymentMethodForTip:{
        id:-1
    },
    slideValue:[0],
    review:""
}

const initialState = {
    'currentRate':null,
    'predata':predataInit,
    'list':[]
}

export default (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    let {list,currentRate,predata} = state
    switch (type) {     
        case SET_PENDING_RATES:
            list  = payload;
            currentRate = null
            if(list.length>0){
                currentRate = list[0]
            }

            return {
                list,
                currentRate,
                predata:predataInit
            }
        case POP_CURRENT_RATE:
            if(currentRate){                
                const[
                    first,
                    ...newList
                ] = list
                list = newList
                if(list.length>0){
                    currentRate = list[0]
                }else{
                    currentRate = null
                }
            }                 

            return {
                ...state,
                list,
                currentRate,
                'predata':predataInit
            }
        case UPDATE_CURRENT_RATE:
            predata = {
                ...predata,
                ...payload
            }    

            return {
                ...state,
                predata
            }
        default:
        return state;
    }
}