import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import RP from '../../helpers/routePath';

import useWindowScrollApiList from '../../hooks/useWindowScrollApiList';

import AZModalTitle from '../modal/AZModalTitle';
import AZModal from '../modal/AZModal';
import AZDesicionModal from '../modal/AZDesicionModal';
import AZButtonLink from '../form/AZButtonLink';

import SeekerRequestList from './SeekerRequestList';
import ArchivedRequestList from './ArchivedRequestList'
import ExploreGurusView from './ExploreGurusView';

import {merge2ArraysById,mergeSeekerRequest} from '../../helpers/arrays';

import * as seekerActions from '../../actions/seeker.actions'
import * as appActions from '../../actions/app.actions'
import * as generalActions from '../../actions/general.actions'
import * as navActions from '../../actions/navbar.actions'

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const SeekerDashboard = (props) => {
    const {
        dispatch,
        currentRequest,
        user,
        seekerNewConversations,
        notifSeekers
    } = props   

    const {
        requestStep,
        requestId,
        matchedGurus,        
    } = currentRequest

    const {
        t
    } = useTranslation()
    const history = useHistory()
    const location = useLocation()

    const abortRef = useRef(null)
    const lastElementSeekerRequestRef = useRef(null)

    const rows = 10
   

    const [
        requestList,
        requestListLoading,
        isScrolling,
        setRequestList
    ] = useWindowScrollApiList({ 
        apiCall:seekerActions.getRequests,
        'rows':rows,    
        onSuccess:(response)=>{
            console.log("response after hook",response)            
            //<Redirect to={RP.getPath(t,RP.NEW_SEARCH)} />
        },
        onError:(error)=>{
            console.log("error after hook",error)
        },
        testing:false,
        dispatch
    })    

    const [closeRequestModal, setCloseRequestModal] = useState({
        show:false,
        loading:false,
        error:"",
        success:"",
        loading:false
    });

    const [requestModal, setRequestModal] = useState({
        show:false,
        title:"",
        description:""
    });

    const [firstCall, setfirstCall] = useState(true);


    const closeRequest = (requestId) =>{
        handleCloseRequestModal({"loading":true,"error":""})
        if(requestId!==undefined){
            generalActions.closeRequest(requestId).then(
                (response)=>{
                    handleCloseRequestModal({
                        "loading":false,
                        "show":false                        
                    })
                    setRequestList(
                        requestList.filter((request)=>{
                            return request.requestId !==requestId
                        })
                    )  
                    dispatch(generalActions.getPendingRates())                  
                },
                (error)=>{
                    if(error){                        
                        handleCloseRequestModal({
                            "loading":false,
                            "error":t("ERROR_"+error.data.codeNumber)                        
                        })
                    }
                }
            )            
        }else{

        }
    }

    const handleItemView = (title,description,address=null)=>{
        setRequestModal({
            "show":true,
            title,
            description,
            address
        })
    }

    const handleItemCloseClick  = (requestId,title)=>{
        handleCloseRequestModal({
            "show":true,
            requestId,
            title
        })
    }

    const handleConversationClick = (conversationData)=>{  
        console.log('conversation click')      
        dispatch(
            appActions.openConversation(
                conversationData
            )
        )        
    }

    const handleExploreGurusClick=(requestId,title,description)=>{
        dispatch(
            appActions.setCurrentRequest({
                ...currentRequest,
                requestId,
                title,
                description
            })
        )
    }

    const handleCloseRequestModal = (payload) => {
        setCloseRequestModal({
            ...closeRequestModal,
            ...payload
        })
    }

    const handleViewArchivedRequests = () =>{
        history.push(RP.getPath(t,RP.ARCHIVED_REQUESTS))
    }
    

    useEffect(() => {    
        console.log('check?',requestList)
        if(firstCall){
            setfirstCall(false)
        }else{
            if(requestList.length == 0){
                history.replace(RP.getPath(t,RP.NEW_SEARCH))
            }
        }
    }, [requestList]);
    
    //Check if reload the new notifications
    useEffect(() => {
        dispatch(appActions.addNotifications({
            'notifSeekers':0,   
            'seekerNewConversations':0
        }))
        return ()=>{
            dispatch(appActions.addNotifications({
                'notifSeekers':0,
                'seekerNewConversations':0                   
            }))
        }
    }, []);

    useEffect(() => {
        dispatch(
            navActions.switchToSeeker()
        )
        if(notifSeekers>0){
            dispatch(appActions.addNotifications({
                'notifSeekers':0,                        
            }))
        }        
    }, [notifSeekers]);

    useEffect(() => {
        //console.log('seekerNewConversations init',seekerNewConversations);
        if(seekerNewConversations>0&&requestList.length>0&&!requestListLoading){
            //console.log('seekerNewConversations ok')
            dispatch(seekerActions.getRequests(0,5)).then(
                (response) => {                    
                    const newList = response.requests
                    console.log('seekerNewConversations newList',newList)
                    console.log('seekerNewConversations oldList',requestList)
                    //requestId
                    if(newList.length > 0){
                        const mergedList = mergeSeekerRequest(requestList,newList)                        
                        console.log('seekerNewConversations merged',mergedList)
                        setRequestList([
                            ...mergedList
                        ])
                    } 
                    dispatch(appActions.addNotifications({
                        'seekerNewConversations':0,
                    }))
                },
                (response) => {
                    return 
                }
            )
        }
    }, [seekerNewConversations]);

    useEffect(() => {        
        const unblock = history.block((locationR, action) => {            
            if (requestId!==null && locationR.pathname!==location.pathname) {
               return false
            }
            return true;
        });

        return history.listen(locationR => {             
            console.log('dataBack r',locationR)
            console.log('dataBack cr',currentRequest) 
            // if(requestStep==0 ){
            //     dispatch(appActions.cleanCurrentRequest())
            //     return    
            // } 
            if(requestId!==null && locationR.pathname == location.pathname){                  
                dispatch(
                    appActions.cleanCurrentRequest()
                )                                                                
            }else{
                
            }    
            unblock();                                          
        });
    }, [requestId,matchedGurus]);


    return (
        <div className='col-12 position-relative'>
            {
                closeRequestModal.show&&<AZDesicionModal 
                    title={`${t('SEEKER_close_request_title')} ${closeRequestModal.title}`}
                    show={closeRequestModal.show}
                    onClose={()=>{handleCloseRequestModal({"show":false})}}  
                    noOptionText={t('SEEKER_close_request_cancel')}
                    yesOptionText={t('SEEKER_close_request_ok')}
                    onYesOption={()=>{closeRequest(closeRequestModal.requestId)}}
                    onNoOption={()=>{handleCloseRequestModal({"show":false})}}
                    loading={closeRequestModal.loading}
                    loadingText={""}              
                >
                    {   
                        <div>
                            <div className="p-3">                            
                                <p className="">
                                    {t('SEEKER_close_request_description')}
                                </p>
                            </div>
                            <div className="p-5">
                                
                                {
                                    closeRequestModal.success&&<p className="text-purple my-2">{closeRequestModal.success}</p>
                                }
                                {
                                    closeRequestModal.error&&<p className="text-danger my-2">{closeRequestModal.error}</p>
                                }
                            </div>
                        </div>                                                       
                    }  
                </AZDesicionModal>
            } 

            {
                requestModal.show&&<AZDesicionModal 
                    title={requestModal.title}
                    show={requestModal.show}
                    onClose={()=>{
                        setRequestModal({
                            "show":false,
                            "title":"",
                            "description":"",
                        })
                    }}                  
                    yesOptionText={t('GENERAL_ok')}
                    onYesOption={()=>{
                        setRequestModal({
                            "show":false,
                            "title":"",
                            "description":"",
                        })
                    }}                
                    loading={false}
                    loadingText={""}              
                >
                    {   
                        <div className='p-2'>
                            <p>
                                {requestModal.description}
                            </p>
                            {requestModal.address&&<p className='text-center mt-3'>
                                {requestModal.address}
                            </p>}
                        </div>                                                       
                    }  
                </AZDesicionModal>
            }           
            {/* 
            This is the scrolling header to show when user scroll down.
            <div className="position-relative w-100">
                <div className={`row w-100 row-hover bg-gray-soft ${isScrolling?"position-fixed":"position-relative"}`}>
                    <div className={`row align-items-center justify-content-between w-100 ${isScrolling&&"pr-4"}`}>
                        <div className="col-12 col-sm-auto pt-2 text-start">
                            <h3><b>{t('SEEKER_all_you_request')}</b></h3>
                        </div>
                        <div className="col-12 col-sm-auto text-start">
                            <AZButtonLink
                                underline
                                style="color"
                                onClick={handleViewArchivedRequests}
                            >
                                {t('SEEKER_see_archived')}
                            </AZButtonLink>
                        </div>
                    </div>
                </div>                        
            </div>*/}
            
            <div className={`row align-items-center justify-content-between w-100`}>
                <div className="col-12 col-sm-auto pt-2 text-start">
                    <h3><b>{t('SEEKER_all_you_request')}</b></h3>
                </div>
                <div className="col-12 col-sm-auto text-start">
                    <AZButtonLink
                        underline
                        style="color"
                        onClick={handleViewArchivedRequests}
                    >
                        {t('SEEKER_see_archived')}
                    </AZButtonLink>
                </div>
            </div>

            <div className="row justify-content-center mt-3">
                <div className="col-12">
                    <div className="az-card-container az-card-container--no-padding">
                        {
                            requestId?
                            <div className="p-2">
                                <ExploreGurusView
                                    dispatch={dispatch}
                                    currentRequest={currentRequest}
                                    user={user}
                                    sendById={true}
                                    onSend={(model)=>{                                       
                                        if(model){
                                            let requestOList = requestList.filter((req)=>{
                                                return req.requestId == model.idRequest
                                            })                                            
                                            if(requestList.length>0){
                                                let theRequest = requestOList[0]                                                
                                                let conversations = theRequest.conversations!==undefined?theRequest.conversations:[]
                                                let theNewRequest = {
                                                    ...theRequest,
                                                    'conversations':[
                                                        ...conversations,
                                                        model
                                                    ]
                                                }                                                
                                                setRequestList([
                                                    ...requestList.map((req)=>{
                                                        if(req.requestId == theNewRequest.requestId){
                                                            return theNewRequest
                                                        }else{
                                                            return req
                                                        }
                                                    })
                                                ])
                                            }
                                        }
                                    }}
                                    onBack={(change)=>{
                                        dispatch(
                                            appActions.cleanCurrentRequest()
                                        )
                                        //getSeekerRequests(change)
                                    }}
                                >
                                </ExploreGurusView>
                            </div>
                            :
                            <SeekerRequestList
                                lastItemRef={lastElementSeekerRequestRef}
                                list={requestList}
                                itemsLoaded={0}
                                loading={requestListLoading}
                                onScroll={()=>{}}
                                noData={t('SEEKER_no_requests_found')}
                                onItemView={handleItemView}
                                onItemViewReviews={()=>{}}
                                onItemClose={handleItemCloseClick}
                                onItemConversationClick={handleConversationClick}
                                onExploreGurusClick={handleExploreGurusClick}
                            ></SeekerRequestList>  
                        }
                    </div>                        
                </div>
            </div>   

        </div>
    );
}

const mapStateToProps = (state)=>{
    const {
        currentRequest,
        auth,
        notifications
    } = state
    return {
        currentRequest,
        user:auth.user,
        seekerNewConversations:notifications.seekerNewConversations,
        notifSeekers:notifications.notifSeekers
    }

}

export default connect(mapStateToProps)(SeekerDashboard);
