import React from 'react';
import GoogleMapReact from 'google-map-react';


const MarkerComponent = ({text}) => {
    return <div >
        <h5 className='text-purple'>
            {text}<i className="fa fa-map-marker"></i>
        </h5>
    </div>
}

const AZChatMessageMap = (props) => {
    const {
        lat,
        lng
    } = props

    const mapOptions = {
        center: {
            lat,
            lng
        },
        zoom: 11
    }    

    return (
        <div style={{height:'200px',width:'100%'}}>
            {console.log()}
            <GoogleMapReact            
                bootstrapURLKeys={{ key: "AIzaSyCmLVYw6TSuvlso6ILBgLfcmWxH5u5a0JY" }}
                defaultCenter={mapOptions.center}
                defaultZoom={mapOptions.zoom}
                >
                <MarkerComponent
                    lat={lat}
                    lng={lng}
                    text=""
                />
            </GoogleMapReact>
        </div>
    );
}

export default AZChatMessageMap;
