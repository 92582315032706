import React,{useState,useEffect} from 'react';
import moment from 'moment'

const AZTimer = (props) => {
    const { 
        style="gray", 
        isRecording 
    } = props;
    
    const timerStyles = {
        "gray":"text-muted",
        "purple":"text-purple",
        "danger":"text-danger",
    }

    const [progress, setProgress] = useState(0);
    var _progressInterval = null;
    var _recordStartTime = null;
    
    const getProgress = function () {
        return progress;
    }

    const formatTime  =  (seconds) => {
        return seconds > 3600
        ?
        [
            parseInt(seconds / 60 / 60),
            parseInt(seconds / 60 % 60),
            parseInt(seconds % 60)
        ].join(":").replace(/\b(\d)\b/g, "0$1")
        :
        [
            parseInt(seconds / 60 % 60),
            parseInt(seconds % 60)
        ].join(":").replace(/\b(\d)\b/g, "0$1")
    }

    useEffect(() => {
        if (props.onRef != null) {
            props.onRef(this)
        }

        return () => { };
    }, []);

    useEffect(() => {
        if (isRecording) {
          setProgress(0);
          _recordStartTime = Date.now();
          _progressInterval = setInterval(() => {
            console.log("recorder", Date.now() - _recordStartTime);
            setProgress((Date.now() - _recordStartTime));
          }, 500);
    
        }
        return () => {
          if (_progressInterval != null) {
            console.log("clear 2");
            clearInterval(_progressInterval);
          }
        };
      }, [isRecording]);
    


    return (
        <span className={`${timerStyles[style]} text-start`} >
            <h6>
                { formatTime(progress/1000) }
            </h6>
        </span>        
    );
}

export default AZTimer;
