import React,{useEffect,useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import RP from '../../helpers/routePath'


import c from '../../constants'

import * as generalActions from '../../actions/general.actions'



const ConfirmAccount = (props) => {

    const {
        match,
        dispatch
    }=props

    const {
        t
    } = useTranslation()

    const handleConfirm = ()=>{      
        console.log('checkMatch',match)  
        if(match.params.id!==undefined && match.params.code !== undefined){
            setLoading(true)
            dispatch(
                generalActions.validateProfile(
                    match.params.id,
                    match.params.code,                    
                    c.AZ_KEY,                    
                )
            ).then(
                (response) => {
                    setResponseMessage('AUTH_confirm_account_success')
                    setLoading(false)
                },
                (response) => {
                    setResponseMessage('ERROR_'+response.codeNumber)
                    setLoading(false) 
                }
            )
        }else{
            setLoading(false)
            setResponseMessage("AUTH_confirm_account_error")
        }

    }

    const [loading, setLoading] = useState(true);
    const [responseMessage, setResponseMessage] = useState("");    

    useEffect(() => {
        handleConfirm()
    }, []);

    return (
        <div>
            <div className="container bg-transparent">
                <div className="row justify-content-center justify-content-center">
                    <div className='col-md-6 col-lg-4'>
                        <div className="az-container bg-white p-5 my-3">
                            <div className="row justify-content-center ">
                                <div className="col-auto">
                                    <div className="az-logo">
                                        <img src={`${c.resources.webpage}logoHide.svg`} alt="" />
                                    </div>                    
                                </div>
                                <div className="col-12 my-2">

                                </div>
                                <div className="col-12">
                                    {
                                        loading?
                                        <h1 className="text-center"><i className="fa fa-spinner fa-spin"></i></h1>
                                        :<h2 className="text-purple text-center">{responseMessage!==""?t(responseMessage):t('ERROR_500')}</h2>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>                      
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const {

    }= state

    return {

    }
}

export default connect(mapStateToProps)(ConfirmAccount);
