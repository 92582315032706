import {
    SET_MESSAGE,
    CLEAN_MESSAGE,
    SET_REQUEST,
    CLEAN_REQUEST,
    SET_LOADING,
    OPEN_CONVERSATION,
    CLOSE_CONVERSATION,
    CLEAN_CONVERSATIONS,
    SET_SEARCHED_GURUS,
    CLEAN_SEARCHED_GURUS,    
    POP_CURRENT_RATE,
    UPDATE_CURRENT_RATE,
    NOTIFICATIONS_ADD,
    NOTIFICATIONS_CLEAN,
    GURU_CONVERSATIONS_ADD,
    GURU_CONVERSATIONS_SET,
    GURU_CONVERSATIONS_UPDATE_MARK,    
    GURU_CONVERSATIONS_UPDATE,    
    GURU_CONVERSATIONS_CLEAN, 
    DELETE_SESSION,
} from "./types";

export const setGlobalMessage = (message,type="") => (dispatch) =>{
    dispatch({
        type:SET_MESSAGE,
        payload:{
            message,
            type
        }
    })
}

export const cleanGlobalMessage = () => (dispatch) =>{
    dispatch({
        type:CLEAN_MESSAGE,
        payload:{}
    })
}
export const setLoading = (loading,loadingText="") => (dispatch) =>{
    console.log("loading?",{
        loading,
        loadingText
    })
    dispatch({
        type:SET_LOADING,
        payload:{
            loading,
            loadingText
        }
    })
}

export const setCurrentRequest = (request) => (dispatch) =>{
    dispatch({
        type:SET_REQUEST,
        payload:request
    })

}

export const cleanCurrentRequest = () => (dispatch) =>{
    dispatch({
        type:CLEAN_REQUEST,
        payload:{}
    })
}

export const setSearchedGurus = (payload) => (dispatch) =>{
    dispatch({
        type:SET_SEARCHED_GURUS,
        payload
    })

}

export const cleanSearchedGurus = () => (dispatch) =>{
    dispatch({
        type:CLEAN_SEARCHED_GURUS,
        payload:{}
    })
}

//Conversations
export const openConversation = (conversationData) => (dispatch)=>{
    dispatch({
        type:OPEN_CONVERSATION,
        payload:{
            conversationData
        }
    })
}
export const closeConversation = (idConversation) => (dispatch)=>{
    dispatch({
        type:CLOSE_CONVERSATION,
        payload:{
            idConversation
        }
    })    
}

export const cleanConversations = () => (dispatch)=>{
    dispatch({
        type:CLEAN_CONVERSATIONS,
        payload:{            
        }
    })
}
//PendingRates
export const popCurrentRate = (id) => (dispatch) =>{
    dispatch({
        type:POP_CURRENT_RATE,       
    })
}

export const updateCurrentRate = (payload) => (dispatch) =>{
    dispatch({
        type:UPDATE_CURRENT_RATE,
        payload       
    })
}

//Notifications

export const addNotifications = (payload) => (dispatch) => {
    dispatch({
        type:NOTIFICATIONS_ADD,
        payload
    })
}
export const cleanNotifications = () => (dispatch) => {
    dispatch({
        type:NOTIFICATIONS_CLEAN        
    })
}
//GuruConversations

export const addGuruConversations = (payload) => (dispatch) => {
    dispatch({
        type:GURU_CONVERSATIONS_ADD,
        payload
    })
}
export const setGuruConversations = (payload) => (dispatch) => {
    dispatch({
        type:GURU_CONVERSATIONS_SET,
        payload
    })
}
export const updateGuruConversationsMark = (conversationId,isMarked) => (dispatch) => {
    dispatch({
        type:GURU_CONVERSATIONS_UPDATE_MARK,
        payload:{
            conversationId,
            isMarked
        }
    })
}
export const updateGuruConversations = (conversationId,conversation) => (dispatch) => {
    dispatch({
        type:GURU_CONVERSATIONS_UPDATE_MARK,
        payload:{
            conversationId,
            conversation
        }
    })
}
export const cleanGuruConversations = () => (dispatch) => {
    dispatch({
        type:GURU_CONVERSATIONS_CLEAN        
    })
}
export const deleteSession = () => (dispatch) => {
    dispatch({
        type:DELETE_SESSION        
    })
}

