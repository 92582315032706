import React from 'react';
import AZAreaBubbleItem from './AZAreaBubbleItem';

const AZAreaBubble = (props) => {
    const {
        list=[],
        onItemClick,
        onItemRemove,
        bordered = false
    } = props

    return (
        <div>
            <div className={`az-area-bubble ${(bordered&&list.length>0)&&"az-area-bubble--bordered"}`}>
                <div className="az-area-bubble__content">
                    {
                        list.map(
                            item=>
                            <AZAreaBubbleItem
                                key={item.id==undefined?item:item.id}
                                id={item.id==undefined?item:item.id}
                                model={item}
                                disabled={item.disabled!==undefined}
                                value={item.id==undefined?item:item.value}                                      
                                onItemClick={onItemClick}
                                onItemRemove={onItemRemove}
                            >                                
                            </AZAreaBubbleItem>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default AZAreaBubble;
