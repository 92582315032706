import {

} from "./types";

import {
    apiErrorHandler
} from '../helpers/helpers'
  
import Service from "../services/chat.service";

export const checkConversationForRating = (
        idConversation,
        checked
    ) => (dispatch) =>{

    return Service.checkConversationForRating(
            idConversation,
            checked
        ).then(
        (response)=>{
            return Promise.resolve(response)
        },
        (response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response)
        }
    )
}

export const getPresignedUrl = (idConversation,messageType) => (dispatch) =>{
    return Service.getPresignedUrl(idConversation,messageType).then(
        (response)=>{           
            return Promise.resolve(response)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response)            
        }
    )
}

export const markConversationAsRead = (idConversation) => (dispatch) =>{
    return Service.markConversationAsRead(idConversation).then((response)=>{
            return Promise.resolve(response)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response)
        }
    )
} 

export const markNotificationAsRead = (type) => (dispatch) =>{
    return Service.markNotificationAsRead(type).then(
        (response)=>{
            return Promise.resolve(response)
        },
        (response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response)
        }
    )
}

export const sendAMessage = (theMessage) => (dispatch) => {
    const {
        idConversation,
        idMessage,
        type,
        message,
        duration,
        amount
    } = theMessage
    return Service.sendAMessage(
        idConversation,
        idMessage,
        type,
        message,
        duration,
        amount
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response)
        }
    )
}