import React from 'react';

import c from "../../constants"

import AZButton from "../../components/form/AZButton"

import { useTranslation } from 'react-i18next';

import * as actions from "../../actions/sign-ui.actions"

const LandingNav = (props) => {    
    //Use translations
    const { t, i18n } = useTranslation();

    const {
        onJoinClick
    }  = props

    return (
        <nav className=" col-12 navbar navbar-expand-lg navbar-dark  bgmenuHide navbar-custom  fixed-top">
            <a className="navbar-brand" href="#">
                <img src={`${c.resources.webpage}logoHide.svg`} className="img-fluid w-50 logoHide"/>
                <img src={`${c.resources.webpage}logo7.svg`} className="img-fluid logo "/>
            </a>
            <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse navigationHide" id="main_nav">
                <button type="button" className="close d-lg-none"  aria-label="Close">
                <span aria-hidden="true">X</span>
                </button>
                <ul className="navbar-nav">
                <li className="nav-item active"><a className="nav-link mt-2" href="#">{t('NAVIGATION_HOME')}</a></li>
                <li className="nav-item"><a className="nav-link mt-2" href="#sectionSeeker"> {t('NAVIGATION_SEEKERS')} </a></li>
                <li className="nav-item"><a className="nav-link mt-2" href="#sectionGuru"> {t('NAVIGATION_GURUS')} </a></li>
                <li className="nav-item"><a className="nav-link mt-2" href="#sectionSecurity"> {t('NAVIGATION_SECURITY')} </a></li>
                <li className="nav-item"><a className="nav-link mt-2" href="#sectionContact"> {t('NAVIGATION_CONTACT')} </a></li>
                <li className="nav-item text-center">
                    <AZButton
                        type="button"
                        onClick={onJoinClick}                    
                    >   
                        {t('LANDING_button_join')}               
                    </AZButton>
                </li>
                </ul>
            </div> 
        </nav>
    );
}

export default LandingNav;
