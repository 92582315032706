import React,{useState,useEffect} from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { connect } from 'react-redux';

import * as appActions from '../../actions/app.actions'
import * as seekerActions from '../../actions/seeker.actions'
import * as navActions from '../../actions/navbar.actions'

import RP from '../../helpers/routePath';

import NewRequestForm from '../../components/seeker/NewRequestForm';
import NewRequestHints from '../../components/seeker/NewRequestHints';

const NewSearch = (props) => {
    const {
        dispatch,
        currentRequest
    } = props

    const {        
        requestId,   
        requestStep     
    } = currentRequest

    const {t} = useTranslation()
    const history = useHistory()    
    
    const [requestData, setRequestData] = useState(currentRequest);    
    const [shouldMoveStep, setShouldStep] = useState(false);

    /*useEffect(() => {
        console.log('dataBack request step',requestStep)         
        const unblock = history.block((locationR, action) => {
            console.log('dataBack b r',locationR)
            console.log('dataBack b action',action)
            console.log('dataBack b cr',currentRequest) 
            if(action == "POP"){
                console.log(`dataBack local: ${location.pathname}  R:${locationR.pathname}`)                
                if(requestStep!==0){
                    console.log('dataBack what is happending here?') 
                    return false
                }                                             
            }

            return true                      
        });

        const unlisten = history.listen((locationR,action) => {             
            console.log('dataBack r',locationR)
            console.log('dataBack r a',action)            
            console.log('dataBack cr',currentRequest) 
            // if(requestStep==0 ){
            //     dispatch(appActions.cleanCurrentRequest())
            //     return    
            // } 
            if(action == 'POP' || (action == 'PUSH' && locationR.pathname == location.pathname)){
                if(requestStep!==0){
                    console.log('dataBack why not')
                    handleFinish()
                }
            }
            // if(locationR.pathname == location.pathname){
                
            // }

            unblock();       
        });

        
        return unlisten
    }, [requestStep]);*/

    useEffect(() => {
        dispatch(navActions.switchToSeeker())        
    }, []);    

    useEffect(() => {
        console.log('checkrequestStep',requestStep)
        console.log('checkrequestStep id',requestId)
        if(requestStep == 1 && shouldMoveStep ){
            history.push(RP.getPath(t,RP.NEW_SEARCH_GURUS))
        }
        if(requestId !== null){
            dispatch(navActions.switchToSeeker())  
            history.push(RP.getPath(t,RP.REQUEST_LIST))
        }
    }, [requestStep,shouldMoveStep]);

    const handleSubmit = (data,submitType,onError)=>{
        setRequestData({
            ...data
        })
        if(submitType=="SEND_REQUEST"){
            dispatch(appActions.setLoading(true,t("NEW_REQUEST_creating_request")))
            dispatch(seekerActions.createNewRequest(
                data
            )).then(
                (response)=>{
                    console.log(response)
                    const {
                        idElement
                    } = response
                    dispatch(appActions.setLoading(true,t("NEW_REQUEST_sending_request")))
                    seekerActions.notifyGurusNewRequest(idElement).then(
                        ()=>{
                            dispatch(appActions.setLoading(false))
                            dispatch(
                                appActions.cleanCurrentRequest()
                            )
                            dispatch(
                                appActions.setGlobalMessage(t('MSG_OK_new_request_text'))
                            )
                            dispatch(
                                navActions.switchToSeeker()
                            )
                            history.push(RP.getPath(t,RP.REQUEST_LIST))
                        },
                        (error)=>{
                            console.log(error)
                            dispatch(appActions.setLoading(false))
                            if(error){
                                dispatch(
                                    appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')
                                )
                            }
                        }
                    )
                },
                (error)=>{
                    console.log(error)
                    dispatch(appActions.setLoading(false))
                    if(error){
                        dispatch(
                            appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')                                          
                        )
                    }
                }
            )
            //call to service
        }else{
            console.log('preparing the request to gurus',data)
            setShouldStep(true)
            dispatch(appActions.setCurrentRequest({                
                ...data,
                requestId,
                requestStep:1,
                matchedGurus:{
                    gurusList:[],
                    loadedElements:0,
                    gurusStatus:{}
                }           
            }))                        
        }
    }
 
    return (        
        <div>
            <div className="container-lg py-1 bg-transparent">
                <div className="row justify-content-center">
                    <div className="col-12 col-container">
                        <div className="row my-3">                           
                            <div className="col-12">
                                {                                    
                                    <h2>
                                        {t('NEW_REQUEST_step2_title')}
                                    </h2>
                                }
                            </div>
                        </div>
                        <div className="row h-100">
                            <div className="col-md-8">
                                {/* New search form */}
                                <div className='az-card-container h-100'>
                                    <NewRequestForm
                                        dispatch={dispatch}
                                        backData={requestData}
                                        onSubmit={handleSubmit}
                                    ></NewRequestForm>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='az-card-container h-100'>
                                    {
                                        <NewRequestHints />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    );
}

const mapStateToProps = (state)=>{
    const {currentRequest} = state
    console.log('currentRequestStatus...',currentRequest)
    return {       
        currentRequest
    }
}

export default connect(mapStateToProps)(NewSearch);