import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import RP from '../../helpers/routePath';

import * as generalActions from "../../actions/general.actions"
import ReviewsList from '../../components/profile/ReviewsList';
import useWindowScrollApiList from '../../hooks/useWindowScrollApiList';

import ReviewTabPanel from '../../components/profile/ReviewTabPanel';
import AZButtonLink from '../../components/form/AZButtonLink';


const UserReviews = (props) => {
    const{
        user,
        location,
        dispatch
    }=props

    const {t,i18next} = useTranslation()
    const history = useHistory()

    const [viewReviewAs, setViewReviewAs] = useState("SEEKER");  

    const [
        list,
        listLoading,
        hasScrolled,
    ] = useWindowScrollApiList({ 
        apiCall:generalActions.getReviews,
        'params':[user.idUser,viewReviewAs],        
        onSuccess:(response)=>{
            console.log("response after hook",response)
        },
        onError:(error)=>{
            console.log("error after hook",error)
        },
        testing:true
    })     

    const handleItemCheckReview=(idUser)=>{
        history.push(RP.getPath(t,RP.PROFILE_USER,idUser),{'reviewAs':'SEEKER'})
    }

    return (
        <div className="container-lg py-1 bg-transparent">
            <div className='position-relative'>
                <div className={`row row-hover w-100 pt-3 bg-gray-soft ${hasScrolled?"position-fixed":"position-relative"}`} >
                    <div className="col-auto">
                        <AZButtonLink
                                style="color"
                                type="button"
                                underline
                                onClick={()=>{history.goBack()}}
                            >                 
                            {t('GENERAL_back')}      
                        </AZButtonLink>
                    </div>
                </div>
            </div>
            {
                user&&user.isAGuru&&
                <div className="row justify-content-center">
                    <div className="col-12 d-none d-sm-block mt-1 text-center">
                        <h5>
                            {t('PROFILE_reviews_as')}
                        </h5>
                    </div>
                    <div className="col-12">
                        <ReviewTabPanel 
                            disabled={listLoading}
                            reviewAs={viewReviewAs}
                            ratesAsGuru={user.ratesAsGuru}
                            ratesAsSeeker={user.ratesAsSeeker}
                            ratingAsGuru={user.ratingAsGuru}
                            ratingAsSeeker={user.ratingAsSeeker}
                            onItemClick={ (as)=>{ setViewReviewAs(as); console.log('Change to:',as) } }
                        />
                    </div>                               
                </div>
            }             
            <div className="row justify-content-center">
                <div className="col-12 col-container">
                    <div className="row d-none">
                        <div className="col-12 text-start">
                            <h3><b>{t('GENERAL_reviews')}</b></h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="az-card-container az-card-container--no-padding">
                                <ReviewsList
                                    full={true}
                                    list={list}
                                    itemsLoaded={0}
                                    loading={listLoading}
                                    onScroll={()=>{}}
                                    noData={t('GENERAL_no_reviews_found')}
                                    onItemClick={()=>{}}
                                    onItemViewReviews={handleItemCheckReview}
                                ></ReviewsList>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state)=>{
    const {
        auth
    } = state
    const {
        user
    } = auth
    return {
        user
    }
}

export default connect(mapStateToProps)(UserReviews);

