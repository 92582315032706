import React,{ useState } from 'react';
import AZButtonLink from '../form/AZButtonLink';
import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';

import C from '../../constants'

import AZProfileImage from '../profile/AZProfileImage'

import moment from 'moment';

import FormatedDate from '../format/FormatedDate';


const TipsReceivedItem = (props) => {
    const {
        model,        
        onItemClick,
        onItemReview
    } = props
    const {        
        id,	
        idHelpRequest,
        title,
        date, 	
        amount,
        currency,
        status,
        idUser,
        name,
        imageSufix,
        level,
        isIndependent,
        rates,
        rating
    } = model

    const {t,i18n} = useTranslation()


    const handleItemClick = (e) => { 
        if(onItemClick){
            onItemClick(model)    
        }
    }
    const handleItemReview = (e)=>{
        if(onItemReview){
            onItemReview(idUser)
        }
    }

    return (  
        <div>                        
            <div className="az-list__content__item az-list__content__item--guru-request align-items-center justify-content-between cursor-pointer" onClick={handleItemClick}>
                <div className="col">
                    <div className="row col-12">
                        <h5>{title != null ? (title) : (t('PAYMENTS_RECEIVED_direct_tip'))}</h5>
                        <p>
                            <FormatedDate>
                                {date}
                            </FormatedDate>                            
                        </p>                              
                    </div>
                    <div className="row col-12">
                        <div className="col-auto">
                            <AZProfileImage
                                user={{
                                    idUser,
                                    imageSufix,
                                    isAGuru:true,
                                    guruLevel:level,
                                    isIndependent
                                }}
                                bordered={false}
                                size="sm"
                            >                    
                            </AZProfileImage>
                        </div>
                        <div className="col text-start">
                            <h5>{name}</h5>
                            <div className="mt-1">
                                <AZButtonLink
                                    style="color"
                                    underline
                                    onClick={handleItemReview}                        
                                >
                                    {t('GURU_request_label_check_reviews')}
                                </AZButtonLink>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-auto text-end">
                    <h5 className={`${status==1?"text-success":status==0 || status==2?"text-gray":"text-danger"}`}>
                        {status >= 0 && status <= 4 && t('PAYMENTS_RECEIVED_status_' + status)}
                    </h5>
                    <h5>                        
                        {amount.toLocaleString(i18n.language, { currency: currency, currencyDisplay: "symbol", style: "decimal", })}
                    </h5>
                    <h5>
                        {currency.toUpperCase()}
                    </h5>                    
                </div>                                            
            </div>  

        </div>                       
    );
}

export default TipsReceivedItem;
