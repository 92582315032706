import React from 'react';
import './index.css'

const AZLoading = (props) => {
    const {
        show=false,
        loadingText=""
    } = props
    return (
        <div className={`az-loading ${!show&&"az-loading--hide"}`}> 
            <div className="az-loading__body">
                <div>
                    <h1>
                        <i className="fa fa-spinner fa-spin"></i>
                    </h1>
                    {loadingText!==""&& <h3>
                        {loadingText}
                    </h3> }
                </div>                
            </div>
        </div>
    );
}

export default AZLoading;
