import "./index.css"
const AZModalTitle = (props) => {
    const {
        title,
        children,
        onClose,
        show=false        
    } = props
    
    const setShowClass = (show)=>(
        show?"az-modal az-modal--title":"az-modal az-modal--hide"
    )

    return (
        <div className={setShowClass(show)} onClick={onClose}>
            <div className="az-modal__body" onClick={(e)=>(e.stopPropagation())} >
                <button 
                    className="az-modal__body__close"
                    onClick={onClose}>
                    <i className="fa fa-times text-white"></i>
                </button>
                <div className="az-modal__body__header">
                    <div className="az-modal__body__header__title">
                        {title}
                    </div>
                </div>
                <div className="az-modal__body__content">
                    {children}
                </div>
            </div>  
        </div>
    );
}

export default AZModalTitle;
