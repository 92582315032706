import React from 'react';
import AZField from "../form/AZField"
import AZImgInput from "../form/AZImgInput"
import AZSelectMultiple from "../form/AZSelectMultiple"
import AZButton from "../form/AZButton"
import AZTextArea from "../form/AZTextArea"

import C from "../../constants"

import { useTranslation } from 'react-i18next';

import { useState,useEffect } from 'react';
import useFocus from '../../hooks/useFocus';

const EditProfileForm = (props) => {
    const { t, i18n } = useTranslation();
    const {
        profile,
        onSubmit,
        onNicknameChange,
        loading
    } = props   
    
    let nicknameTimeout = null

    const [form, setForm] = useState({
        "image":{
            name:"image",
            value:`${C.resources.profile}${profile.idUser}-${profile.imageSufix<0?0:profile.imageSufix}.jpg`,
            error:false,
            img:[],
            hasImage:false,
            errorText:""
        },
        "name":{
            name:"name",
            value:profile.name,
            error:false,
            errorText:""
        },
        "lastName":{
            name:"lastName",
            value:profile.lastName,
            error:false,
            errorText:""
        },
        "nickname":{
            name:"nickname",
            value:profile.nickname,
            error:false,
            success:false,
            successText:"",
            errorText:""
        },
        "description":{
            name:"description",
            value:profile.description,
            error:false,
            errorText:""
        },
    });
    
    const [nicknameLoading, setNicknameLoading] = useState(false);
    const [nicknameAvaiable, setNicknameAvaiable] = useState(true);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [firstCall, setFirstCall] = useState(true);
    const [nicknameRef,setNicknameFocus] = useFocus()
    const [prepareSend, setprepareSend] = useState(false);
    

    const handleSubmit = (e)=>{
        e.preventDefault()
        setMessage("")
        setErrorMessage("")
        Object.keys(form).map(i=>form[i]={
            ...form[i],
            error:false,
            errorText:""
        })
        onSubmit(
            form,
            (data)=>{
                setMessage('')
            },
            (response,errors)=>{
                if(response){
                    setErrorMessage("ERROR_"+response.data.codeNumber)
                }
                if(errors!==null){
                    setForm({
                        ...form,
                        ...errors
                    })
                }
            }
        )
    }

    const handleChange = (e)=>{
        const{
            value,
            name
        } = e.target
        
        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })
        
    }
    useEffect(() => {
        console.log('setFocus check')
        if(!nicknameLoading){
            console.log('setFocus')
            setNicknameFocus()
        }
    }, [nicknameLoading]);

    useEffect(() => {
        if(firstCall){
            setFirstCall(false)
        }else{              
            setForm({
                ...form,
                "nickname":{
                    ...form['nickname'],
                    error:false,
                    errorText:'',
                    success:false,
                    successText:''
                }
            }) 
            if(form.nickname.value == ""){
                return
            }     
            setprepareSend(true)              
            const timeout = setTimeout(() => {      
                setNicknameLoading(true)            
                onNicknameChange(
                    form.nickname.value
                ).then((response)=>{
                    console.log('nickname response',response)
                    setNicknameLoading(false)   
                    setprepareSend(false)
                    if(!response.data.response){
                        setForm({
                            ...form,
                            "nickname":{
                                ...form['nickname'],
                                error:true,
                                errorText:t('UPDATE_PROFILE_nickname_unavailable'),
                                success:false,
                                successText:''
                            }
                        })
                        setNicknameLoading(false)
                        setNicknameAvaiable(false)                        
                    }else{
                        setForm({
                            ...form,
                            "nickname":{
                                ...form['nickname'],
                                error:false,
                                errorText:'',
                                success:true,
                                successText:t('UPDATE_PROFILE_nickname_available')
                            }
                        })
                        setNicknameLoading(false)
                        setNicknameAvaiable(true)
                    }                                     
                },(error,errors)=>{
                    if(errors!==null){
                        setForm({
                            ...form,
                            ...errors
                        })
                    }
                    setNicknameLoading(false)
                    setNicknameAvaiable(false)
                    setprepareSend(false)
                })   
            }, 1500)
            return () => clearTimeout(timeout)
        }                
      }, [form.nickname.value])

    return (
        <form  onSubmit={handleSubmit}>
            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <AZImgInput
                        name={form.image.name}
                        src={form.image.value}
                        square
                        onChange={(image,file)=>{
                            setForm({
                                ...form,
                                "image":{
                                    ...form['image'],
                                    "value":image,
                                    "img":file,
                                    "hasImage":file?true:false,
                                }
                            })
                        }}
                    >                                            
                    </AZImgInput>
                </div>
                <div className="col-12 my-2">
                    <h5 className="text-center">
                        {profile.email}
                    </h5>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZField
                        {...form.name}
                        onChange={handleChange}
                    >
                        {t('UPDATE_PROFILE_placeholder_input_name')} 
                    </AZField>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZField
                        name={form.lastName.name}
                        value={form.lastName.value}
                        error={form.lastName.error}
                        errorText={form.lastName.errorText}
                        onChange={handleChange}
                    >
                        {t('UPDATE_PROFILE_placeholder_input_last_name')} 
                    </AZField>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZField
                        {...form.nickname}
                        onChange={handleChange}
                        customRef={nicknameRef}
                        disabled={nicknameLoading}
                        loading={nicknameLoading}
                    >
                        {t('UPDATE_PROFILE_placeholder_input_nickname')} 
                    </AZField>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZTextArea 
                        name={form.description.name}
                        value={form.description.value}
                        error={form.description.error}
                        errorText={form.description.errorText}
                        onChange={handleChange}
                    >   
                        {t('UPDATE_PROFILE_placeholder_input_description')}                     
                    </AZTextArea>
                </div>
            </div>

                <div className="row my-2">
                    <div className="col-12">
                        <p>{t(message)}</p>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-12">
                        <p className='text-danger'>{t(errorMessage)}</p>
                    </div>
                </div>
            
                       
            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <AZButton
                        loading={loading}
                        onClick={()=>{}}
                        type="submit"
                        disabled={nicknameLoading||(!nicknameAvaiable)||(prepareSend)}
                        onLoadText={t('LOADING')}
                    >
                        {t('UPDATE_PROFILE_button_save')}
                    </AZButton>
                </div>
            </div>
        </form>
    );
}

export default EditProfileForm;
