import React from 'react';

import c from "../../constants";

import { useTranslation } from 'react-i18next';


import LandingNav from './LandingNav';
import LandingFooter from './LandingFooter';

const LandingPage = (props) => {
    const { t, i18n } = useTranslation();    

    const {
        signPage,
        onJoinClick
    } = props

    return (
        <div className="container2">
            <div className="container" id="contenido">
                {/* NAV */}
                <LandingNav 
                    onJoinClick={onJoinClick}
                />
                <div className="row sectionInicio">
                    <div className="col-12 col-lg-7  text-center columnaIzq">
                        <p className="titleInicio">{t('HOME_TITLE')}</p>
                        <p className="subtitleInicio">{t('HOME_DESCRIPTION')}</p>
                        <div className="row justify-content-center pt-2">
                            <div className="col-6 col-sm-4"><a href="#"><img className="img-fluid appstore" src={`${c.resources.webpage}buttons/${t('HOME_APP_STORE_BUTTON')}`} /></a></div>
                            <div className="col-6 col-sm-4"><a href="#"><img className="img-fluid googleplay" src={`${c.resources.webpage}buttons/${t('HOME_GOOGLE_PLAY_BUTTON')}`} /></a></div>
                        </div>
                    </div>
                    
                    <div className="d-none d-md-none d-lg-block col-5">
                        <img src={`${c.resources.webpage}world.svg`} className="img-fluid float-right world" />
                    </div>
                </div>

                <div className="row text-center justify-content-center pt-3 mt-5 bgslide">
                    <div className="col-12 mt-5">
                        <p className="title">{t('HOME_HOW_IT_WORKS_TITLE')}</p>
                    </div>
                    <div className="col-10 pt-sm-5 mt-5 col-sm-12 justify-content-center">
                        <div id="carouselExampleIndicators" className="carousel slide" data-interval="false">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img className="d-none d-md-block  d-lg-block img-fluid  m-auto slide1" src={`${c.resources.webpage}slider/${t('HOME_SLIDE_1_BIG')}`} alt="First slide"/>
                                    <img className="d-block d-sm-block d-md-none  img-fluid  m-auto slidec" src={`${c.resources.webpage}slider/${t('HOME_SLIDE_1_SMALL')}`} alt="First slide"/>
                                </div>
                                <div className="carousel-item ">
                                    <img className="d-none d-md-block d-lg-block img-fluid  m-auto slide1" src={`${c.resources.webpage}slider/${t('HOME_SLIDE_4_BIG')}`} alt="Second slide"/>
                                    <img className="d-block d-sm-block d-md-none  img-fluid  m-auto slidec" src={`${c.resources.webpage}slider/${t('HOME_SLIDE_4_SMALL')}`} alt="Second slide"/>
                                </div>
                                <div className="carousel-item">
                                    <img className="d-none d-md-block d-lg-block img-fluid  m-auto slide1" src={`${c.resources.webpage}slider/${t('HOME_SLIDE_5_BIG')}`} alt="Third slide"/>
                                    <img className="d-block d-sm-block d-md-none  img-fluid  m-auto slidec" src={`${c.resources.webpage}slider/${t('HOME_SLIDE_5_SMALL')}`} alt="Third slide"/>
                                </div>
                            </div>
                            <a className="carousel-control-prev " href="#carouselExampleIndicators" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">{t('HOME_SLIDE_PREV')}</span>
                            </a>
                            <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">{t('HOME_SLIDE_NEXT')}</span>
                            </a>
                        </div>
                    </div>
                </div>



                {/* <!--CONTENIDO SEEKER--> */}
                <div id="tab2" className="col-12 p-0">
                    <div className="row justify-content-center sectionSeeker" id="sectionSeeker">
                        <div className="col-10 text-center parrafo1 text-white pt-5">
                            <p>{t('HOME_SEEKER_TITLE')} </p>
                            <div className="row  align-items-center mt-5 mb-5 infoSeeker">
                                <div className="col-12 col-md-6 col-lg-4 columna1">
                                    <div className="card">
                                        <img src={`${c.resources.webpage}tabs/solucionesrapidas.svg`} className="img-fluid solucionesrapidas"  alt={t('HOME_SEEKER_OPTION_1_TITLE')} />
                                        <div className="card-body">
                                            <div className="my-card-title">{t('HOME_SEEKER_OPTION_1_TITLE')}</div>
                                            <div className="my-card-subtitle2">{t('HOME_SEEKER_OPTION_1_DESCRIPTION')}</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <img src={`${c.resources.webpage}tabs/contactoseguro.svg`} className="img-fluid contactoseguro"  alt={t('HOME_SEEKER_OPTION_2_TITLE')} />
                                        <div className="card-body">
                                            <div className="my-card-title">{t('HOME_SEEKER_OPTION_2_TITLE')}</div>
                                            <div className="my-card-subtitle2">{t('HOME_SEEKER_OPTION_2_DESCRIPTION')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-none d-lg-block columna2 text-center">
                                    <img src={`${c.resources.webpage}+"celular.svg`} className="img-fluid cellphone" />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4  columna3">
                                    <div className="card">
                                        <img src={`${c.resources.webpage}tabs/tratodirecto.svg`} className="img-fluid tratodirecto"  alt={t('HOME_SEEKER_OPTION_3_TITLE')} />
                                        <div className="card-body">
                                            <div className="my-card-title">{t('HOME_SEEKER_OPTION_3_TITLE')}</div>
                                            <div className="my-card-subtitle2">{t('HOME_SEEKER_OPTION_3_DESCRIPTION')}</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <img src={`${c.resources.webpage}tabs/reputacion.svg`} className="img-fluid reputacion"  alt={t('HOME_SEEKER_OPTION_4_TITLE')} />
                                        <div className="card-body">
                                            <div className="my-card-title">{t('HOME_SEEKER_OPTION_4_TITLE')}</div>
                                            <div className="my-card-subtitle2">{t('HOME_SEEKER_OPTION_4_DESCRIPTION')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row sectionSteps">
                        <div className="col-12 ">
                            <p className="titleSteps">{t('HOME_SEEKER_HOW_TO_BECOME_SEEKER')}</p>
                        </div>
                        <div className="col-12 stepsSeekers" data-v-32f968e2>
                            <div className="step" data-v-32f968e2>
                                <span data-v-32f968e2>1</span>
                                <div className="desc" data-v-32f968e2>{t('HOME_SEEKER_HOW_TO_BECOME_SEEKER_1')}</div>
                            </div>
                            <div className="arrow" data-v-32f968e2></div>
                            <div className="step" data-v-32f968e2>
                                <span data-v-32f968e2>2</span>
                                <div className="desc" data-v-32f968e2>{t('HOME_SEEKER_HOW_TO_BECOME_SEEKER_2')}</div>
                            </div>
                            <div className="arrow" data-v-32f968e2></div>
                            <div className="step" data-v-32f968e2>
                                <span data-v-32f968e2>5</span>
                                <div className="desc" data-v-32f968e2>{t('HOME_SEEKER_HOW_TO_BECOME_SEEKER_3')}</div>
                            </div>
                        </div>

                    </div>
                </div>


                <div id="tab1" className=" col-12 p-0">
                    <div className="row justify-content-center sectionGuru" id="sectionGuru">
                        <div className="col-10 text-center parrafo1 text-white pt-5">
                            <p>{t('HOME_GURU_TITLE')} </p>

                            <div className="row align-items-center mt-5 mb-5 infoGuru">
                                <div className="col-12 col-md-6 col-lg-4 columna1">
                                    <div className="card">
                                        <img src={`${c.resources.webpage}tabs/flexibilidad.svg`} className="img-fluid flexibilidad"  alt={t('HOME_GURU_OPTION_1_TITLE')}/>
                                        <div className="card-body">
                                            <div className="my-card-title">{t('HOME_GURU_OPTION_1_TITLE')}</div>
                                            <div className="my-card-subtitle2">{t('HOME_GURU_OPTION_1_DESCRIPTION')}</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <img src={`${c.resources.webpage}tabs/ganancias.svg`} className="img-fluid ganancias"  alt={t('HOME_GURU_OPTION_2_TITLE')}/>
                                        <div className="card-body">
                                            <div className="my-card-title">{t('HOME_GURU_OPTION_2_TITLE')}</div>
                                            <div className="my-card-subtitle2">{t('HOME_GURU_OPTION_2_DESCRIPTION')}</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <img src={`${c.resources.webpage}tabs/aprendizaje.svg`} className="img-fluid aprendizaje"  alt={t('HOME_GURU_OPTION_3_TITLE')}/>
                                        <div className="card-body">
                                            <div className="my-card-title">{t('HOME_GURU_OPTION_3_TITLE')}</div>
                                            <div className="my-card-subtitle2">{t('HOME_GURU_OPTION_3_DESCRIPTION')}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-none d-lg-block columna2 text-center">
                                    <img src={`${c.resources.webpage}+"celular.svg`} className="img-fluid cellphone" />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4  columna3">
                                    <div className="card">
                                        <img src={`${c.resources.webpage}tabs/elige.svg`} className="img-fluid elige"  alt={t('HOME_GURU_OPTION_4_TITLE')}/>
                                        <div className="card-body">
                                            <div className="my-card-title">{t('HOME_GURU_OPTION_4_TITLE')}</div>
                                            <div className="my-card-subtitle2">{t('HOME_GURU_OPTION_4_DESCRIPTION')}</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <img src={`${c.resources.webpage}tabs/tratodirecto.svg`} className="img-fluid tratodirecto"  alt={t('HOME_GURU_OPTION_5_TITLE')}/>
                                        <div className="card-body">
                                            <div className="my-card-title">{t('HOME_GURU_OPTION_5_TITLE')}</div>
                                            <div className="my-card-subtitle2">{t('HOME_GURU_OPTION_5_DESCRIPTION')}</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <img src={`${c.resources.webpage}tabs/reputacion.svg`} className="img-fluid reputacion"  alt={t('HOME_GURU_OPTION_1_TITLE')}/>
                                        <div className="card-body">
                                            <div className="my-card-title">{t('HOME_GURU_OPTION_1_TITLE')}</div>
                                            <div className="my-card-subtitle2">{t('HOME_GURU_OPTION_1_DESCRIPTION')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!--SECTION STEPS GURU--> */}
                    <div className="row sectionSteps ">
                        <div className="col-12">
                            <p className="titleSteps">{t('HOME_GURU_HOW_TO_BECOME_GURU')}</p>
                        </div>
                    
                        <div className="col-12 stepsGurus" data-v-32f968e2>
                            <div className="step" data-v-32f968e2>
                                <span data-v-32f968e2>1</span>
                                <div className="desc" data-v-32f968e2>{t('HOME_GURU_HOW_TO_BECOME_GURU_1')}</div>
                            </div>
                            <div className="arrow" data-v-32f968e2></div>
                            <div className="step" data-v-32f968e2>
                                <span data-v-32f968e2>2</span>
                                <div className="desc" data-v-32f968e2>{t('HOME_GURU_HOW_TO_BECOME_GURU_2')}</div>
                            </div>
                            <div className="arrow" data-v-32f968e2></div>
                            <div className="step" data-v-32f968e2>
                                <span data-v-32f968e2>3</span>
                                <div className="desc" data-v-32f968e2>{t('HOME_GURU_HOW_TO_BECOME_GURU_3')}</div>
                            </div>
                            <div className="arrow" data-v-32f968e2></div>
                            <div className="step" data-v-32f968e2>
                                <span data-v-32f968e2>4</span>
                                <div className="desc" data-v-32f968e2>{t('HOME_GURU_HOW_TO_BECOME_GURU_4')}</div>
                            </div>
                            <div className="arrow" data-v-32f968e2></div>
                            <div className="step" data-v-32f968e2>
                                <span data-v-32f968e2>5</span>
                                <div className="desc" data-v-32f968e2>{t('HOME_GURU_HOW_TO_BECOME_GURU_5')}</div>
                            </div>
                        </div>

                    </div>
                </div> 

                <div className="row text-center">
                    <div className="col-12">
                        <p className="titleFunciona">{t('HOME_GURU_LEVELS_TITLE')} </p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-10 text-center">
                        <p className="parrafo1 ">{t('HOME_GURU_LEVELS_DESCRIPTION')}</p>
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-6 pt-5 colPrincipiante">
                                <div className="col-12 my-card">
                                    <div className="card">
                                        <img src={`${c.resources.webpage}niveles/principiante.svg`} className="img-fluid imgCard"  alt="" />
                                        <div className="card-body">
                                            <div className="my-card-titleNivel text-center">{t('HOME_GURU_LEVELS_BEGINNER_TITLE')}</div>
                                            <div className="my-card-subtitleNivel text-justify">{t('HOME_GURU_LEVELS_BEGINNER_DESCRIPTION')} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 pt-5 colAprendiz">
                                <div className="col-12 my-card">
                                    <div className="card">
                                        <img src={`${c.resources.webpage}niveles/aprendiz.svg`} className="img-fluid imgCard"  alt="" />
                                        <div className="card-body ">
                                            <div className="my-card-titleNivel text-center">{t('HOME_GURU_LEVELS_APPRENTICE_TITLE')}</div>
                                            <div className="my-card-subtitleNivel text-justify">{t('HOME_GURU_LEVELS_APPRENTICE_DESCRIPTION')} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mb-5">
                            <div className="col-12 col-lg-6 pt-5 colProfesional">
                                <div className="col-12 my-card">
                                    <div className="card">
                                        <img src={`${c.resources.webpage}niveles/profesional.svg`} className="img-fluid imgCard"  alt="" />
                                        <div className="card-body">
                                            <div className="my-card-titleNivel text-center">{t('HOME_GURU_LEVELS_PRO_TITLE')}</div>
                                            <div className="my-card-subtitleNivel text-justify">{t('HOME_GURU_LEVELS_PRO_DESCRIPTION')} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 pt-5 colSamaritano">
                                <div className="col-12 my-card">
                                    <div className="card">
                                        <img src={`${c.resources.webpage}niveles/samaritano.svg`} className="img-fluid imgCard"  alt="" />
                                        <div className="card-body">
                                            <div className="my-card-titleNivel text-center">{t('HOME_GURU_LEVELS_SAMARITAN_TITLE')}</div>
                                            <div className="my-card-subtitleNivel text-justify">{t('HOME_GURU_LEVELS_SAMARITAN_DESCRIPTION')} </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                

                <div className="row text-center justify-content-center" id="sectionSecurity">
                    <div className="col-12 pl-5 pr-5 pb-5">
                        <p className="titleFunciona">{t('HOME_SECURITY_TITLE')}</p>
                    </div>
                </div>
                <div className="row justify-content-center mb-5 pb-5">
                    <div className="col-10">
                        <div className="row text-center">
                            <div className="col-12 mb-5 text-center col-md-12 col-lg-4 float-left">
                                <div className="infoSecurity">
                                    <div className="infohide1">
                                        <p className="titleSecurity">{t('HOME_SECURITY_OPTION_1_TITLE')}</p>
                                        <p className="subtitleSecurity pl-2 pr-2">{t('HOME_SECURITY_OPTION_1_DESCRIPTION')}</p>
                                    </div>
                                    <div className="security1">{t('HOME_SECURITY_OPTION_1_TITLE')}</div>
                                </div>
                            </div>
                            
                            <div className="col-12 mb-5 text-center col-md-12 col-lg-4">
                                <div className="infoSecurity">
                                    <div className="infohide1">
                                        <p className="titleSecurity">{t('HOME_SECURITY_OPTION_2_TITLE')}</p>
                                        <p className="subtitleSecurity pl-2 pr-2">{t('HOME_SECURITY_OPTION_2_DESCRIPTION')}</p>
                                    </div>
                                    <div className="security1">{t('HOME_SECURITY_OPTION_2_TITLE')}</div>                            
                                </div>
                            </div>

                            <div className="col-12 text-center col-md-12 col-lg-4 float-right">
                                <div className="infoSecurity">
                                    <div className="infohide1">
                                        <p className="titleSecurity">{t('HOME_SECURITY_OPTION_3_TITLE')}</p>
                                        <p className="subtitleSecurity pl-2 pr-2">{t('HOME_SECURITY_OPTION_3_DESCRIPTION')}</p>
                                    </div>
                                    <div className="security1">{t('HOME_SECURITY_OPTION_3_TITLE')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="row text-center justify-content-center pt-3 sectionContact" id="sectionContact">
                    <div className="col-12 pb-5">
                        <p className="titleFunciona">{t('HOME_CONTACT_US_TITLE')}</p>
                    </div>
                    <div className="col-12">
                        <ul className="list-inline">
                        <li className="list-inline-item"><a className="text-xs-center"><img className="img-fluid iconDatos" src={`${c.resources.webpage}+"email.svg`} alt="" /></a></li>
                        <li className="list-inline-item"><a className="text-xs-center"><p className="titleDatos"><strong>{t('HOME_CONTACT_US_EMAIL')}:</strong> info@askzhao.com</p></a></li>
                        </ul>
                    </div>
                    <div className="col-12">
                        <ul className="list-inline">
                        <li className="list-inline-item"><a className="text-xs-center"><img className="img-fluid iconDatos" src={`${c.resources.webpage}+"phone.svg`} alt="" /></a></li>
                        <li className="list-inline-item"><a className="text-xs-center"><p className="titleDatos"><strong>{t('HOME_CONTACT_US_PHONE')}:</strong> 3314 01 33 24</p></a></li>
                        </ul>
                    </div>
                    <div className="col-10 pt-5">
                        <p className="titleFunciona">{t('HOME_DOWNLOAD_ASKZHAO')}</p>
                    </div>
                    <div className="col-12 text-center mt-5 mb-5 pb-5">
                        <a href="#"><img className="img-fluid btnDownload" src={`${c.resources.webpage}buttons/${t('HOME_APP_STORE_BUTTON_2')}`} /></a>
                        <a href="#"><img className="img-fluid btnDownload" src={`${c.resources.webpage}buttons/${t('HOME_APP_STORE_BUTTON_2')}`} /></a>
                    </div>
                </div>

                {/* FOOTER */}
                <LandingFooter />
                
            </div> 
        </div>
    );
}

export default LandingPage;
