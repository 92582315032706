import React,{useEffect,useState,useRef} from 'react';
import "./index.css"

import ConversationHolder from './ConversationHolder';
import ConversationsList from './ConversationsList';
import ChatHolder from './ChatHolder';

import AZButtonIconLink from '../form/AZButtonIconLink';

import { useTranslation } from 'react-i18next';

import CircledLabel from '../ui/CircledLabel';

import * as generalActions from '../../actions/general.actions'
import * as guruActions from '../../actions/guru.actions'
import * as appActions from '../../actions/app.actions'

import { merge2ArraysById } from '../../helpers/arrays';


const ConversationsView = (props) => {
    const {
        activeConversations,
        activeConversationsId,
        guruConversations=[],
        guruNewConversations,
        notificationsNumber,
        notifications,       
        dispatch,
        isGuru,
        userAs,
    } = props

    const headerRef = useRef(null)
    const guruConversationsRef = useRef(guruNewConversations)
    const guruConversationsSizeRef = useRef(guruConversations.length)
    
    const [showConversations, setShowConversations] = useState(false);    

    const [conversationsLoading, setConversationsLoading] = useState(false);
    const [conversationsList, setConversationsList] = useState(guruConversations);    
    const [loadedElements, setLoadedElements] = useState(guruConversations.length);    
   
    const [showOptions, setShowOptions] = useState(false);
    const [conScrollTop, setConScrollTop] = useState(0);


    const {t} = useTranslation()
    

    const showActiveConversations = () =>{
        
        return <div className="w-100" style={{textAlign:"end",overflowX:"auto"}}>
            <div style={{whiteSpace:"nowrap"}}>
                {activeConversationsId.map((v)=>{
                    return <div key={v} className='d-inline-block' style={{verticalAlign:"bottom",pointerEvents:"all"}}>
                        <ChatHolder 
                            onCloseChat={closeConversation}
                            chatData={activeConversations[v]}
                            isShown={activeConversations[v]?activeConversations[v].isOpen:-1}
                            isScrollDown={activeConversations[v]?activeConversations[v].isScrollDown:0}
                        />
                    </div>
                })}
            </div>
        </div>
    }

    const closeConversation = (idConversation) => {
        dispatch(
            appActions.closeConversation(idConversation)
        )
        console.log("conversations ids",activeConversationsId)
    }

    const handleConversationClick = (conversationData) => {
        console.log(activeConversations)
        console.log('conversations add',conversationData)
        dispatch(
            appActions.openConversation(
                {
                    ...conversationData,
                    enableRateRequest:true            
                }
            )
        )
        console.log('conversations',activeConversations)
    }

    const getConversations = (fromStart)=>{
        console.log('on get Conversations service')
        if(conversationsLoading){
            return
        }
        setConversationsLoading(true)      
        let loadedItems = guruConversationsSizeRef.current
        if(fromStart){
            dispatch(appActions.cleanGuruConversations())
            setLoadedElements(0)
            loadedItems = 0
        }        
        guruActions.getConversations(loadedItems).then((response)=>{
            const {
                list
            } = response.data
            console.log('request response',response)
            if(list.length>0){ 
                let actualList = fromStart?[]:guruConversationsRef.current
                dispatch(appActions.setGuruConversations([...actualList,...list]))
                setConversationsList([...actualList,...list])                 
                setLoadedElements(loadedElements+list.length)         
            }
            setConversationsLoading(false)               
        },(error)=>{
            console.log(error)
            setConversationsLoading(false)
        })
    }

    const handleReorganizeConversations = (conversation,reorganize=true) => {
        //TODO LEGR clean this code
        //use reducer conversations list: guruConversations
        if(!reorganize){
            dispatch(appActions.updateGuruConversations(conversation.idConversation,conversation))
        }
        console.log('reorganize:reo the conversations',guruConversationsRef.current)
        const filteredConversationList = guruConversationsRef.current.filter((con)=>{
            return conversation.idConversation !== con.idConversation
        })            
        console.log('reorganize:filter the conversations',filteredConversationList)
        let newFilteredConversationList = [
            conversation,
            ...filteredConversationList,
        ]
        console.log('reorganize:add new the conversations',newFilteredConversationList)
        dispatch(appActions.setGuruConversations(newFilteredConversationList))
        setConversationsList(newFilteredConversationList)
        setConScrollTop(conScrollTop+1)
    }

    useEffect(() => {      
        //dispatch(appActions.cleanGuruConversations())  
        setConversationsList([])
        if(isGuru){
            getConversations(true)
        }        
    }, []);

    useEffect(() => {
        console.log('conversations is a guru?',isGuru)
        if(isGuru){
            console.log('get conversations')
            getConversations(true)
        }
    }, [isGuru]);

    useEffect(() => {
        console.log('chelUserAs',userAs)
        if(userAs == "GURU"&&showConversations){
            dispatch(appActions.addNotifications({
                'notifConversations':0,
            }))
        }
    }, [userAs]);

    useEffect(() => {
        console.log('change conversations',guruConversations)
        guruConversationsRef.current = guruConversations
        guruConversationsSizeRef.current = guruConversations.length
        //setLoadedElements(guruConversations.length)
    }, [guruConversations]);

    useEffect(() => {
        var headerTimeout = null;
        if(showConversations && isGuru && notificationsNumber > 0){
            dispatch(appActions.addNotifications({
                'notifConversations':0,
            }))
            if(headerRef && headerRef.current){
                headerRef.current.classList.add("conversation-holder__header--animate");
                headerTimeout = setTimeout(() => {
                    console.log('clear animation')
                    headerRef.current.classList.remove("conversation-holder__header--animate");
                    console.log(headerRef.current.classList)
                },1500);                
            }
        }
        
        return ()=>{
            if(headerTimeout!==null){
                console.log('timeout',headerTimeout)
                clearTimeout(headerTimeout)
            }
        }

    }, [notificationsNumber]);

    useEffect(() => {
        if(guruNewConversations>0&&!conversationsLoading){
            //console.log('guruNewConversations ok')
            //dispatch(appActions.cleanGuruConversations())                       
            guruActions.getConversations(0,5).then(
                (response) => {                    
                    console.log('guruNewConversations response',response)
                    const newList = response.data.list
                    //requestId                    
                    if(newList.length > 0){
                        const mergedList = merge2ArraysById(newList,guruConversations,'idConversation')                        
                        dispatch(appActions.setGuruConversations(mergedList))
                        setConversationsList([
                            ...mergedList
                        ])                        
                    }                    
                },
                (response) => {
                    return 
                }
            )
        }
    }, [guruNewConversations]);

    return (
        <div className='conversations-view-wrapper'>            
            <div className={`${isGuru?'col-8':'col-12'} align-self-end`}>
                {showActiveConversations()} 
            </div>
            {
                isGuru&&<div className="conversations-view mr-2 ms-1">                                                           
                    <ConversationHolder
                        headerRef={headerRef}
                        header={
                            <AZButtonIconLink
                                style="white"
                                full
                                type="button"                    
                                onClick={()=>{ 
                                    
                                    if(showConversations==false){
                                        console.log(`notifications.notifRequests - guruNewConversations ${notifications.notifRequests} - ${guruNewConversations}`)
                                        dispatch(appActions.addNotifications({
                                            'notifConversations':0,
                                            'guruNewConversations':0,
                                            //'notifRequests':notifications.notifRequests - notificationsNumber,
                                        })) 
                                    }

                                    setShowConversations(!showConversations) 
                                }}
                                rightIcon={showConversations?<h5><i className="fa fa-chevron-down" aria-hidden="true"></i></h5>:<h5><i className="fa fa-chevron-up" aria-hidden="true"></i></h5>}
                            >
                                {t('GURU_title_tab_conversations')} {notificationsNumber>0&&!showConversations&&<CircledLabel>{notificationsNumber>99?"99":notificationsNumber}</CircledLabel>}
                                
                            </AZButtonIconLink>    
                        }
                        show={showConversations}
                        loading={conversationsLoading}
                        onScroll={getConversations}
                        scrollTopBack={conScrollTop}
                    >
                    <ConversationsList
                            list={[
                                ...guruConversations,
                                //...conversationsList
                            ]}
                            itemsLoaded={conversationsLoading}
                            loading={conversationsLoading}
                            onScroll={()=>{}}
                            noData={t('GENERAL_no_conversations_found')}
                            onItemClick={handleConversationClick}
                            onReorganizeConversations={handleReorganizeConversations}
                        >                        
                        </ConversationsList>
                        
                    </ConversationHolder>
                </div>
            }
        </div>
    );
}

export default ConversationsView;
