import React,{useState} from 'react';
import RP from '../../helpers/routePath';
import { Link } from 'react-router-dom';
import AZButton from '../form/AZButton';
import AZButtonIcon from '../form/AZButtonIcon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as generalActions from '../../actions/general.actions';
import * as appActions from '../../actions/app.actions';
import * as navActions from '../../actions/navbar.actions';

import './index.css';

const MenuList = (props) => {
    const {
        nav="SEEKER",
        dispatch,
        isGuru
    }= props
    const history = useHistory()
    const { t, i18n } = useTranslation()
    const [newRequestLoading, setNewRequestLoading] = useState(false);

    const handleNewRequest = () =>{
        setNewRequestLoading(true)
        dispatch(generalActions.getRemainingRequests()).then(
            (response)=>{
                console.log('response',response)
                const requestRemaining = response.response
                if(requestRemaining>0){
                    history.push(RP.getPath(t,RP.NEW_SEARCH))
                }else{
                    dispatch(
                        appActions.setGlobalMessage(t('ERROR_0225'))
                    )
                }
                setNewRequestLoading(false)
            },
            (error)=>{
                console.log('error',error)
                setNewRequestLoading(false)
            }
        )
    }

    const seekerMenu = ()=>{
        return <div className='row text-start justify-content-start'>                        
            <div className="col-12 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.BASE)}
                >
                    <p>
                    {t('NAVIGATION_HOME')}
                    </p>
                </Link>
            </div> 
            <div className="col-12 my-1 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.ARCHIVED_REQUESTS)}
                >
                    <p>
                        {t('NAVBAR_SEEKER_archived_request_list')}
                    </p>
                </Link>
            </div>
            <div className="col-12 my-1 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.SEARCH_GURUS)}
                >
                    <p>
                        {t('NAVBAR_SEEKER_search_for_gurus')}
                    </p>
                </Link>
            </div>
            <div className="col-12 my-1 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.ADD_PAYMENT_METHOD)}
                >
                    <p>
                        {t('NAVBAR_SEEKER_payments_methods')}
                    </p>
                </Link>
            </div>            
            <div className="col-12 my-1 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.TIPS_GIVEN)}
                >
                    <p>
                        {t('NAVBAR_SEEKER_tip_given')}
                    </p>
                </Link>
            </div>
            <div className="col-12 my-1 az-navbar-menu-link az-navbar-menu-link--last">
                <Link
                    to={RP.getPath(t,RP.SEEKER_TUTORIAL)}
                >
                    <p>
                        {t('NAVBAR_SEEKER_seeker_tutorial')}
                    </p>
                </Link>
            </div>
        </div>        
    }
    const guruMenu = ()=>{
       return  <div className="row justify-content-start text-start">
            <div className="col-12 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.BASE)}
                >
                    <p>
                    {t('NAVIGATION_HOME')}
                    </p>
                </Link>
            </div> 
            <div className="col-10 my-1 az-navbar-menu-link d-none">
                <AZButton                
                    onClick={()=>(history.push(RP.getPath(t,RP.CONFIG_STRIPE)))}
                >
                    {t('NAVBAR_GURU_stripe_payments')}
                </AZButton>           
            </div>
            <div className="col-12 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.CONFIG_STRIPE)}
                >
                    <p>
                    {t('NAVBAR_GURU_stripe_payments')}
                    </p>
                </Link>
            </div>
            <div className="col-12 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.TIPS_RECEIVED)}
                >
                    <p>
                    {t('NAVBAR_GURU_tips_received')}
                    </p>
                </Link>
            </div>
            <div className="col-12 az-navbar-menu-link az-navbar-menu-link--last">
                <Link
                    to={RP.getPath(t,RP.GURU_TUTORIAL)}
                >
                    <p>
                    {t('NAVBAR_GURU_guru_tutorial')}
                    </p>
                </Link>
            </div>           
        </div>
    }
    const noneMenu = ()=>{
       return  <div className="row justify-content-start text-start">           
            <div className="col-12 az-navbar-menu-link">
                <a href=""
                    onClick={(e)=>{e.preventDefault();dispatch(navActions.switchToSeeker());history.push(RP.getPath(t,RP.BASE));}}
                >
                    <p>
                    {t('NAVIGATION_HOME')}
                    </p>    
                </a>                
            </div> 
            <div className="col-12 my-1 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.ARCHIVED_REQUESTS)}
                >
                    <p>
                        {t('NAVBAR_SEEKER_archived_request_list')}
                    </p>
                </Link>
            </div>
            <div className="col-12 my-1 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.SEARCH_GURUS)}
                >
                    <p>
                        {t('NAVBAR_SEEKER_search_for_gurus')}
                    </p>
                </Link>
            </div>
            <div className="col-12 my-1 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.ADD_PAYMENT_METHOD)}
                >
                    <p>
                        {t('NAVBAR_SEEKER_payments_methods')}
                    </p>
                </Link>
            </div>            
            <div className="col-12 my-1 az-navbar-menu-link">
                <Link
                    to={RP.getPath(t,RP.TIPS_GIVEN)}
                >
                    <p>
                        {t('NAVBAR_SEEKER_tip_given')}
                    </p>
                </Link>
            </div>
            <div className={`col-12 my-1 az-navbar-menu-link ${!isGuru&&"az-navbar-menu-link--last"}`}>
                <Link
                    to={RP.getPath(t,RP.SEEKER_TUTORIAL)}
                >
                    <p>
                        {t('NAVBAR_SEEKER_seeker_tutorial')}
                    </p>
                </Link>
            </div>   
            {
                isGuru&&
                <div>
                    <div className="col-10 my-1 az-navbar-menu-link d-none">
                        <AZButton                
                            onClick={()=>(history.push(RP.getPath(t,RP.CONFIG_STRIPE)))}
                        >
                            {t('NAVBAR_GURU_stripe_payments')}
                        </AZButton>           
                    </div>
                    <div className="col-12 az-navbar-menu-link">
                        <Link
                            to={RP.getPath(t,RP.CONFIG_STRIPE)}
                        >
                            <p>
                            {t('NAVBAR_GURU_stripe_payments')}
                            </p>
                        </Link>
                    </div>
                    <div className="col-12 az-navbar-menu-link">
                        <Link
                            to={RP.getPath(t,RP.TIPS_RECEIVED)}
                        >
                            <p>
                            {t('NAVBAR_GURU_tips_received')}
                            </p>
                        </Link>
                    </div>
                    <div className="col-12 az-navbar-menu-link az-navbar-menu-link--last">
                        <Link
                            to={RP.getPath(t,RP.GURU_TUTORIAL)}
                        >
                            <p>
                            {t('NAVBAR_GURU_guru_tutorial')}
                            </p>
                        </Link>
                    </div>  
                </div>
            }
        </div>
    }


    return (
        <div>
            {               
                noneMenu()
            }
        </div>
    );
}

export default MenuList;
