import { useTranslation } from "react-i18next";


const AZField = (props) => {
    const {
        customRef,
        name,
        error=false,
        errorText,
        success=false,
        successText,
        focus,
        children,
        value,
        onChange,
        loading=false,
        disabled=false,
        leftIcon = false,
        rightIcon = false,
        ref        
    } = props

    const { t, i18n } = useTranslation(); 

    const setInputClass = (error,focus) =>{
        let inputClassName = `az-inputfield ${success&&"az-inputfield--success"} ${error&&"az-inputfield--error"} ${focus?"az-inputfield--focus":""} ${disabled&&"az-inputfield--disabled"} ${leftIcon&&"az-inputfield--left-icon"} ${rightIcon&&"az-inputfield--right-icon"}`        
        return inputClassName
    }

    return (
        <div className={setInputClass(error,focus)}>
            <div className="az-inputfield__textfield">
                {
                    leftIcon&&<div className="az-inputfield__textfield__icon">
                        {leftIcon}
                    </div>
                }
                {
                    console.log(customRef)
                }
                <input      
                    name={name}           
                    value={value} 
                    placeholder={children}
                    disabled={disabled}
                    onChange={onChange!==undefined?onChange:()=>{}}
                    ref={customRef}
                />
                {loading&&<div className="az-inputfield__textfield__icon az-inputfield__textfield__icon--loading">                    
                    <i className="fa fa-spinner fa-spin"></i>                        
                </div>}
                {
                    rightIcon&&(!loading)&&<div className="az-inputfield__textfield__icon">
                        {rightIcon}
                    </div>
                }
            </div>
            {
                error&&
                <div className="az-inputfield__error">
                    <p>{t(errorText)}</p>
                </div>
            }
            {
                success&&
                <div className="az-inputfield__error">
                    <p className="text-success">{t(successText)}</p>
                </div>
            }

        </div>
    );
}

export default AZField;
