import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    UPDATE_PROFILE,
    CLEAN_PROFILE
} from "./types";

import {
    apiErrorHandler
} from '../helpers/helpers'
  
import Service from "../services/guru.service";

export const createGuruProfile = (guruData) => (dispatch) =>{
    return Service.createGuruProfile(guruData).then(
        (response)=>{
            let auth = JSON.parse(localStorage.getItem("auth"));
            console.log(auth)
            if(auth && auth.user){
                localStorage.setItem("auth", JSON.stringify({
                    ...auth,
                    user:{
                        ...auth.user,
                        ...guruData,
                        isAGuru:true
                    }
                }));
                dispatch({
                    type:UPDATE_PROFILE,
                    payload:{
                        user:{
                            isAGuru:true
                        }
                    }
                })             
            }else{
                return Promise.reject()
            }            
            return Promise.resolve(guruData)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const getConversations = (loadedElements,numberOfRows=10) => {
    return Service.getConversations(loadedElements,numberOfRows).then(
        (response)=>{ 
            console.log("get conversations",response)
            return Promise.resolve(response)
        },(response)=>{

            return Promise.reject(response.response)
        }
    )
}

export const getRequests = (idLastRequest,numberOfRows=5)=> (dispatch) =>{
    return Service.getRequests(idLastRequest,numberOfRows).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            apiErrorHandler(response.response,dispatch)
            return Promise.reject(response.response)
        }
    )
}

export const sendInitialMessage = (
    requestId,
    idGuru,
    message
) => {
    return Service.sendInitialMessage(
        requestId,
        idGuru,
        message
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            return Promise.reject(response.response)
        }
    )
}

export const sendProposalToSeeker = (
    requestId,
    message,
    amount,
) => {
    return Service.sendProposalToSeeker(
        requestId,
        message,
        amount,
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            return Promise.reject(response.response)
        }
    )
}