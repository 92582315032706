import React from 'react';
import { useTranslation } from 'react-i18next';
import AZButton from '../form/AZButton';
import AZButtonLink from '../form/AZButtonLink';


const RequestOptions = (props) => {
    const {
        onReport,
        onView,
        onAnswer,
        onCancel
    } = props
    
    const {t} = useTranslation()
    

    return (
        <div>
            <div className="p-3">
                <div className="my-2 text-center">
                    <AZButtonLink
                        style="color"
                        onClick={onReport}
                    >
                        {t('GURU_request_options_report')}
                    </AZButtonLink>    
                </div>
                <div className="my-2 d-md-none text-center">
                    <AZButtonLink
                        style="color"
                        onClick={onView}
                    >
                        {t('GURU_request_label_check_reviews')}
                    </AZButtonLink>    
                </div>
                <div className="my-2 d-md-none text-center">
                    <AZButtonLink
                        style="color"
                        onClick={onAnswer}
                    >
                        {t('GURU_button_i_can_help')}
                    </AZButtonLink>    
                </div>
                <div className="my-2 text-center">
                    <AZButtonLink
                        style="gray"
                        onClick={onCancel}
                    >
                        {t('GENERAL_cancel')}
                    </AZButtonLink>    
                </div>
            </div>
        </div>
    );
}

export default RequestOptions;
