import React from 'react';
import './index.css'

const AZTutorialDots = (props) => {
    const {
        step,
        elements,
        onItemClick
    }= props

    const handleItemClick = (index)=>{
        console.log("dot click",index)
        onItemClick(index)
    }

    return (                    
            <div className="az-dots">               
                {                    
                    elements.map((e,index)=>{
                        return <div key={index} onClick={()=>{handleItemClick(index)}} className={`az-dots__dot ${step==index?"az-dots__dot--active":""}`}></div>
                    })
                }
            </div>        
    );
}

export default AZTutorialDots;
