import { useState } from "react";
import { useTranslation } from "react-i18next";
import AZSelectMultipleItem from "./AZSelectMultipleItem";


const AZSelectMultiple = (props) => {
    const{
        title,
        list,
        hideHeader=false,
        isListOpen,
        onToggle,
        onItemClick,
        error,
        errorText,
        loading,
        loadingText="",
        disabled=false,
        style="",
        closeDivIcon=undefined,
        selectedValues=0
    }=props    

    const {t,i18n} = useTranslation()

    return (
        <div>
            <div className={`az-select-multiple ${disabled&&"az-select-multiple--disabled"}`}>
                <button 
                    className={`az-select-multiple__header ${hideHeader&&"d-none"}`}
                    onClick={onToggle}
                    type="button"
                > 
                    <div className="az-select-multiple__header__title">
                        {
                            loading?
                                <span>
                                    {loadingText} <i className="fa fa-spinner fa-spin"></i>
                                </span>
                            :
                            title
                        }
                    </div>               
                    <div className="az-select-multiple__header__icon">
                        {isListOpen?
                            closeDivIcon&&selectedValues>0
                                ?
                                closeDivIcon
                                :
                                <span>
                                    <i className={isListOpen?"fas fa-chevron-up":"fas fa-chevron-down"}></i>                     
                                </span>
                            :
                            <span>
                                <i className={"fas fa-chevron-down"}></i>                     
                            </span>
                        }
                    </div>               
                </button>
                <div className="az-select-multiple__list">
                    { isListOpen&&
                        <div 
                            className="az-select-multiple__list__content"
                            role="list">
                            {!hideHeader&&<div className="az-select-multiple__list__content__blank"></div>}
                            {
                                list.length>1&&list.map(
                                    item=>
                                    <AZSelectMultipleItem
                                        key={item.id==undefined?item:item.id}
                                        id={item.id==undefined?item:item.id}
                                        value={item.id==undefined?item:item.value} 
                                        disabled={item.disabled!==undefined}                                   
                                        onItemClick={onItemClick}
                                        selected={item.selected!==undefined?item.selected:false}
                                    >                                
                                    </AZSelectMultipleItem>
                                )
                            }
                        </div>
                    }
                </div>              
            </div>
            {error&&<div className="text-danger">
                <p>{t(errorText)}</p>
            </div>}
        </div>
    );
}


export default AZSelectMultiple;