export const formValidateField = (field,type) => {    
    switch (type) {
        case 'required':
        
            if(field == "" || field.length == 0){
                
                return false             
            } 
        break;
        case 'email':            
            if(field == ""){
                return false    
            }else{
                return String(field)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    );
            }         
        case 'password':            
            if(field == ""){
                return false    
            }else{
                return field.length>6||field.length==6
            }         
        default:
            
            return true
    }  
    return true;     
}
