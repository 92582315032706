import React from 'react';
import c from '../../constants'

import * as generalActions from '../../actions/general.actions'
import { useState,useEffect } from 'react';

import AZProfileGuru from '../profile/AZProfileGuru';

import "./index.css"

const ChatImage = (props) => {
    const {
        user=false,
        bordered=true,        
        size='',        
        conversation=false,//If the image is a conversation chat image
        conversationReadIndicator=-1,//0=notRead,1=Read,2=arrow
        type,
        onClick,
        children
    }= props
    const {
        isAGuru=false,
        isIndependent=true,
        guruLevel=1
    } = user;

    const [loading, setLoading] = useState(true);
    const [src, setSrc] = useState(`${c.resources.webpage}app/avatar.png`);
    const [presenting, setPresenting] = useState(true);


    useEffect(() => {
        if(user.idUser&&user.imageSufix>0){
            getImage(3)
        }else{
            setLoading(false)
        }
        return ()=>{
            setPresenting(false)
        }
    }, []);

    useEffect(() => {
        if(user){
            setLoading(true)
            getImage(3)
        }
    }, [user.imageSufix]);

    const getImage = (tries)=>{
        if(presenting){
            generalActions.getImage(`${c.resources.profile}${user.idUser}-${user.imageSufix}.jpg`).then(
                (response)=>{                
                    setSrc(response)
                    setLoading(false)
                },
                (error)=>{                
                    if(tries>0){
                        setTimeout(() => {
                            getImage(--tries)
                        }, 3000);
                    }else{
                        setLoading(false)
                    }                 
                }
            ) 
        }
    }   

    const showReadIndicator = (indicator)=>{
        switch (indicator) {
            case 0:
                return <div className="chat-image__read-status__0">
                </div>  
            case 1:
                return <div className="chat-image__read-status__1">
                </div>        
            case 2: 
                return <div className="chat-image__read-status__2">
                    <i className="fa fa-reply"></i>
                </div>
            default:
                return <div>

                </div>                
        }
    }


    return (
        <div className={`chat-image ${bordered?"chat-image--bordered chat-image--bordered__"+conversationReadIndicator:""} ${size!==""?"chat-image--"+size:""} mx-auto`} onClick={(e)=>{onClick()}}>
            <div className="chat-image__read-status">
                {conversation&&showReadIndicator(conversationReadIndicator)}
            </div>
            <div className="chat-image__image">
                {
                    loading?<div className="chat-image__image__loading">
                        <h1 className="text-center"><i className="fa fa-spinner fa-spin"></i></h1>
                    </div>
                    :<img src={src} alt="profileimage" />
                }
            </div>
            {isAGuru&&
                <AZProfileGuru 
                    guruLevel={guruLevel}
                    isIndependent={isIndependent}
                    size={size}
                />
            }         
        </div>
    );
}

export default ChatImage;