import "bootstrap/dist/css/bootstrap.min.css";

import { connect } from "react-redux";
import { useEffect,useState,useRef } from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useSound from "use-sound";

import popSfx from "../audios/pop.mp3";

import { BrowserRouter as Router,Switch,Route,Link,Redirect} from "react-router-dom";

import { format } from "react-string-format";

import RP from "../helpers/routePath";

import AZButton from "../components/form/AZButton";
import AZLoading from "../components/modal/AZLoading";

import DashboardNav from "../components/navbar/DashboardNav";
import Dashboard from "../pages/Dashboard"
import MyProfile from "../pages/MyProfile"
import EditProfile from "../pages/EditProfile"
import BloquedUsers from "../pages/BloquedUsers";
import UserReviews from "../pages/UserReviews";
import UserProfile from "../pages/UserProfile";
import GuruTutorial from "../pages/GuruTutorial";
import SeekerTutorial from "../pages/SeekerTutorial";
import NewSearch from "../pages/NewSearch";
import NewSearchGurus from "../pages/NewSearchGurus";
import ArchivedRequests from "../pages/ArchivedRequests";
import SearchForGurus from "../pages/SearchForGurus";
import ConfigureStripe from "../pages/ConfigureStripe";
import PaymentMethods from "../pages/PaymentMethods";
import TipsGiven from "../pages/TipsGiven";
import TipsReceived from "../pages/TipsReceived";
import ConfirmAccount from "../pages/ConfirmAccount";
import RequestList from "../pages/RequestList";

import ConversationsView from "../components/chat/ConversationsView";
import RateUserForm from "../components/payments/RateUserForm";


import * as appActions from "../actions/app.actions"
import * as authActions from "../actions/auth.actions"
import * as generalActions from "../actions/general.actions"
import * as navActions from "../actions/navbar.actions"

import C from "../constants"


import "../pages/index.css"

import AZModal from "../components/modal/AZModal";
import AZModalTitle from "../components/modal/AZModalTitle";

import AZButtonLink from "../components/form/AZButtonLink";

import {loadStripe} from '@stripe/stripe-js';

import {    
    ref,
    onValue,
    query,
    onChildChanged,
    off,
    orderByChild,
    startAt,
    onChildAdded,
    getDatabase, 
    endAt,
    limitToLast   
} from "firebase/database"



const AppRouter = (props) => {
    const {
        dispatch,
        auth,
        user,
        userAs,
        globalMessage,
        globalLoading,
        conversations,
        currentRate,
        ratePredata,
        pendingRatesList,
        notifications,
        guruConversations
    } = props

    let history = useHistory()
    const location = useLocation()
    const {t,i18n} = useTranslation()

    const notificationsRef = useRef(notifications)

    const rootRef = ref(getDatabase(),'/alerts/'+user.idUser)
    const guru_received_a_new_conversation_ref = ref(getDatabase(),'/alerts/' + user.idUser + '/PUSH_TYPE_GURU_RECEIVED_A_NEW_CONVERSATION')
    const guru_has_requests_avaiables_ref = ref(getDatabase(),'/alerts/' + user.idUser + '/PUSH_TYPE_GURU_HAS_REQUESTS_AVAILABLES')

    const somebody_tried_to_pay_you_ref = ref(getDatabase(),'/alerts/' + user.idUser + '/PUSH_TYPE_SOMEBODY_TRIED_TO_PAY_YOU')
    const somebody_pay_you_ref = ref(getDatabase(),'/alerts/' + user.idUser + '/PUSH_TYPE_SOMEBODY_PAY_YOU')

    const seeker_received_a_new_conversation_ref = ref(getDatabase(),'/alerts/' + user.idUser + '/PUSH_TYPE_SEEKER_RECEIVED_A_NEW_CONVERSATION')

    const seeker_received_a_new_message_ref = ref(getDatabase(),'/alerts/' + user.idUser + '/PUSH_TYPE_SEEKER_RECEIVED_A_NEW_MESSAGE')
    const guru_received_a_new_message_ref = ref(getDatabase(),'/alerts/' + user.idUser + '/PUSH_TYPE_GURU_RECEIVED_A_NEW_MESSAGE')

    const rate_seeker_ref = ref(getDatabase(),'/alerts/' + user.idUser + '/PUSH_TYPE_YOU_HAVE_TO_RATE_SEEKER')

    const [rateModal, setRateModal] = useState({
        show:true,
        loading:false,
        errors:"",
        success:"",             
    });

    const [rerenderRate,setRerenderRate] = useState(true)    
    const [goToTipModal, setGoToTipModal] = useState(false);

    const [notificationSound] = useSound(`${C.resources.webpage}audios/pop.mp3`);
    const soundRef = useRef(notificationSound)

    const renderPendingRates = () => {
        const onClose = () => {
            setRerenderRate(
                false
            )
            setRateModal({
                ...rateModal,
                //show:false,
                success:'',
                errors:''
            })            
            dispatch(appActions.popCurrentRate())
            setTimeout(()=>{
                setRerenderRate(
                    true
                )
            },1000)
            // setTimeout(() => {
            //     setRateModal({
            //         ...rateModal,
            //         show:true
            //     })
            // }, 500);
        }
        const onSubmit = (data)=>{
            const{
                idConversation,
                review,
                rating,
                amount,
                paymentMethodId,
                success
            } = data
            console.log('submit rate data',data)

            if(success){
                setRateModal({
                    ...rateModal,                                
                    errors:'',                    
                })  
                onClose()
                return
            }

            setRateModal({
                ...rateModal,                                
                errors:'',
                loading:true
            })            

            if(amount>0 && (paymentMethodId!==-1&&paymentMethodId!==0)){
                dispatch(
                    generalActions.rateAndTipUser(
                        idConversation,
                        review,
                        rating,
                        paymentMethodId,
                        amount,
                    )
                ).then(
                    async (response) => {
                        const {
                            clientSecret,
                            account
                        }= response
                        const newStripe = await loadStripe(C.stripe.publishableKey, {
                            stripeAccount:account,
                            clientSecret:clientSecret
                        });                       
                        if(newStripe){
                            newStripe.confirmCardPayment(clientSecret).then(
                                (response) => {
                                    if(response.paymentIntent){
                                        setRateModal({
                                            ...rateModal,
                                            'loading':false,
                                            'success':format(
                                                t('RATE_success_paying_message'),
                                                currentRate.name
                                            ),                                            
                                        })                                        
                                    }else{
                                        setRateModal({
                                            ...rateModal,
                                            'loading':false,
                                            'errors':t("RATE_error_confirm_paying_message")
                                        })                                                                         
                                    }                                                                
                                }
                            )
                        }else{
                            setRateModal({
                                ...rateModal,
                                'loading':false,
                                'errors':t("RATE_error_confirm_paying_message")
                            })   
                        }
        
                        console.log('on send tip',response)
                        return 
                    },
                    (error) => {
                        let responseError=t('ERROR_500')                       
                        if(error){
                            responseError = t(`ERROR_${error.codeNumber}`)                            
                        }
                        setRateModal({
                            ...rateModal,
                            'loading':false,
                            'errors':responseError
                        })   
                    }
                )
            }else{
                dispatch(
                    generalActions.rateUser(
                        idConversation,
                        review,
                        rating,
                    )
                ).then(
                    (response) => {
                        console.log('rate success',response)                            
                        setRateModal({
                            ...rateModal,
                            'loading':false,
                            'success':format(t('RATE_success_rating_message'),currentRate.name),                                
                        })
                    },
                    (response) => {
                        if(response){
                            setRateModal({
                                ...rateModal,
                                'loading':false,
                                'errors':t(`ERROR_${response.codeNumber}`)
                            })
                        }else{
                            setRateModal({
                                ...rateModal,
                                'loading':false,
                                'errors':t(`ERROR_500`)
                            })
                        }                            
                    }
                )
            }
            console.log('rate data',data)
        }

        if(currentRate && rerenderRate){
            return <AZModal
                show={rateModal.show}                
                onClose={onClose}
                noClose={true}
            >
                <div className="p-2">
                    <RateUserForm 
                        onCancel={onClose}
                        onSubmit={onSubmit}
                        errors={rateModal.errors}
                        loading={rateModal.loading}
                        success={rateModal.success}
                        dispatch={dispatch}
                        preData={ratePredata}
                        rateInfo={currentRate}
                    />
                </div>
            </AZModal>
        }
    }
    const handleNotifications = (key)=>{        
        switch (key) {
            case "PUSH_TYPE_GURU_RECEIVED_A_NEW_CONVERSATION":
                //dispatch(appActions.cleanGuruConversations())
                dispatch(appActions.addNotifications({
                    'notifConversations':notificationsRef.current.notifConversations+1, 
                    //'notifRequests':notificationsRef.current.notifRequests+1,
                    'guruNewConversations':notificationsRef.current.guruNewConversations+1,
                    //'guruRequestsAvaiables':notificationsRef.current.guruRequestsAvaiables+1,
                }))                                
                break;
            case "PUSH_TYPE_GURU_HAS_REQUESTS_AVAILABLES":
                dispatch(appActions.addNotifications({                                
                    'notifRequests':notificationsRef.current.notifRequests+1,
                    'guruRequestsAvaiables':notificationsRef.current.guruRequestsAvaiables+1,
                }))                                
                break;
            case "PUSH_TYPE_SOMEBODY_TRIED_TO_PAY_YOU":
                dispatch(
                    appActions.setGlobalMessage(t('TIP_someone_tried_to_pay_you'))
                )                                         
                break;
            case "PUSH_TYPE_SOMEBODY_PAY_YOU":
                setGoToTipModal(true)                
                break;
            case "PUSH_TYPE_SEEKER_RECEIVED_A_NEW_CONVERSATION":
                dispatch(appActions.addNotifications({
                    //'notifSeekers':notificationsRef.current.notifSeekers+1,  
                    'seekerNewConversations':notificationsRef.current.seekerNewConversations+1,
                }))                               
                break;
            case "PUSH_TYPE_SEEKER_RECEIVED_A_NEW_MESSAGE":
                dispatch(appActions.addNotifications({
                    'notifSeekers':notificationsRef.current.notifSeekers+1,                   
                }))                                
                break;
            case "PUSH_TYPE_GURU_RECEIVED_A_NEW_MESSAGE":
                //dispatch(appActions.cleanConversations())
                dispatch(appActions.addNotifications({
                    'notifConversations':notificationsRef.current.notifConversations+1, 
                    //'notifRequests':notificationsRef.current.notifRequests+1                 
                }))                               
                break;
            case "PUSH_TYPE_YOU_HAVE_TO_RATE_SEEKER":
                dispatch(
                    generalActions.getPendingRates()
                ).then(
                    (response) => {
                        notificationSound()
                        console.log('get pending rates',pendingRatesList)
                        console.log('current',currentRate)                        
                    },
                    (error) => {
                        appActions.setGlobalMessage(t('ERROR_'+error.codeNumber))
                        return 
                    }
                )                 
                break;
            default:
                //do nothing
                break;
        }        
    }
    //Use effect to observe change on notifications
    useEffect(() => {                         
        onChildAdded(   
            query(rootRef,orderByChild('timestamp'),startAt(Date.now())),         
            (snapshot) => {
                if(snapshot.exists()){
                    console.log('snapshot',snapshot.key) 
                    console.log('notificaciones',notifications)
                    console.log('notificaciones ref',notificationsRef.current)

                    const {
                        key
                    } = snapshot

                    handleNotifications(key)

                }               
            }
        ) 
        onChildChanged(
            rootRef,            
            (snapshot) => {
                if(snapshot.exists()){
                    console.log('snapshot',snapshot.key) 
                    console.log('notificaciones',notifications)
                    console.log('notificaciones ref',notificationsRef.current)
                    const {
                        key
                    } = snapshot

                    handleNotifications(key)

                }
            }
        )             

        return ()=>{
            console.log('off observer')
            off(rootRef)
        }
    },[]);
    
    /* Check if this useEffect creates the issue*/
    useEffect(() => {
        //history.push(RP.getPath(t,RP.BASE))        
    },[auth.isLoggedIn]);

    useEffect(() => {     
        console.log('WAYBM',location.pathname)       
        if(location.pathname == RP.getPath(t,RP.ADD_PAYMENT_METHOD)){
            setRateModal({
                ...rateModal,
                show:false
            })            
        }else{
            setRateModal({
                ...rateModal,
                show:true
            })
        }
    }, [location.pathname]);

    useEffect(() => {
        console.log('change notifications')        
        notificationsRef.current = notifications 
        if(notifications.notifSeekers > 0 ||
            notifications.notifRequests > 0 ||
            notifications.notifConversations > 0|| 
            notifications.seekerNewConversations > 0){
            notificationSound()
            document.title = `(${notifications.notifSeekers+notifications.notifRequests+notifications.notifConversations+notifications.seekerNewConversations}) AskZhao`
        }else{
            document.title = `AskZhao`
        }    
    }, [notifications]);
    
    useEffect(() => {       
        //notificationSound()
        //history.push(RP.getPath(t,RP.BASE))                     
        return ()=>{            
        }        
        

        //remove this        
        let lang = i18n.language.substr(0,2);
        let device = auth.user.device;
        let so = "WEB"         
                         
    },[userAs]);

    useEffect(() => {        
        let lang = i18n.language.substr(0,2);
        //let device = auth.user.device;
        let so = "WEB"
        dispatch(
            authActions.updateSession(so,null,lang)
        ).then(
            (response) => {
                const {
                    notifSeekers,
                    notifRequests,
                    notifConversations,
                    notifGeneral
                }= response  
                dispatch(appActions.addNotifications({
                    notifSeekers,
                    notifRequests,
                    notifConversations,
                    notifGeneral
                }))              
            },
            (error) => { 

                appActions.setGlobalMessage(t('ERROR_'+error.data.codeNumber))         
            }
        )
        dispatch(
            generalActions.getPendingRates()
        ).then(
            (response) => {
                console.log('get pending rates',pendingRatesList)
                console.log('current',currentRate)
            },
            (error) => {
                appActions.setGlobalMessage(t('ERROR_'+error.codeNumber))
                return 
            }
        )
        
    }, []);

    return (
        
        <div  onScroll={()=>{}}>
            <AZModal
                show={globalMessage.message!==""}
                onClose={()=>{dispatch(appActions.cleanGlobalMessage())}}
            >
                <div className="p-2">
                    {globalMessage.message}
                </div>
            </AZModal>
            {
              goToTipModal&&<AZModal
                    show={goToTipModal}
                    onClose={()=>{setGoToTipModal(false)}}
                >
                    <div className="p-2 text-center">
                        <h4 className="my-2">{t('TIP_someone_pay_you')}</h4>
                        <AZButtonLink
                            onClick={()=>{
                                setGoToTipModal(false)
                                dispatch(
                                    navActions.switchToGuru(1)
                                )
                                history.push(RP.getPath(t,RP.TIPS_RECEIVED))
                            }}
                            underline
                            style='color'
                        >
                            {t('TIP_check_tips_received')}
                        </AZButtonLink>   
                        {/*
                        <AZButtonLink
                            style='color'
                            onClick={()=>{
                                dispatch(appActions.addNotifications({
                                    'notifSeekers':notifications.notifSeekers+1,
                                    'seekerNewConversations':notifications.seekerNewConversations+1
                                })) 
                            }}
                        >
                            more conversations...                    
                        </AZButtonLink>                     
                        <AZButtonLink
                            style='color'
                            onClick={()=>{
                                dispatch(appActions.addNotifications({
                                    'notifRequests':notifications.notifRequests+1,
                                    'guruRequestsAvaiables':notifications.guruRequestsAvaiables+1
                                })) 
                            }}
                        >
                            more requests...                    
                        </AZButtonLink>                     
                        <AZButtonLink
                            style='color'
                            onClick={()=>{
                                dispatch(appActions.addNotifications({
                                    'notifConversations':notifications.notifConversations+1,
                                    'guruNewConversations':notifications.guruRequestsAvaiables+1
                                })) 
                            }}
                        >
                            more conversations gurus...                    
                        </AZButtonLink> 
                        */}                    
                    </div>
                </AZModal>
            }
            <AZLoading
                show={globalLoading.loading}
                loadingText={globalLoading.loadingText}
            >                
            </AZLoading>

            {renderPendingRates()}

            {/* <AZModalTitle>
            </AZModalTitle> */}

            <DashboardNav 
                user={auth.user}
                notifications={notifications}
            >                
            </DashboardNav>
            
            {/* <AZButton onClick={()=>{dispatch(appActions.setGlobalMessage("Hola, este es un mensaje de prueba..."))}}>
                <h4>"Clickme"</h4>
            </AZButton> */}

            {/* The conversations view */}
            { console.log(`is a guru ? ${userAs=="GURU"&&user.isAGuru}`)}
            <ConversationsView 
                activeConversations={conversations.list}
                activeConversationsId={conversations.ids}
                dispatch={dispatch}
                userAs={userAs}
                guruNewConversations={notifications.guruNewConversations}
                isGuru={userAs=="GURU"&&user.isAGuru}
                notificationsNumber={notifications.notifConversations}
                notifications={notifications}
                guruConversations={guruConversations}
            />
            <div>
            {/* <AZButton style="color" onClick={()=>{dispatch(appActions.deleteSession())}}>borrar sesión</AZButton> */}
            </div>
            {/* Content */}
            <div>                
                    {/* <Switch>
                        <Route exact path={["/"]} component={Dashboard} />        
                        <Route exact path={["/archived"]} component={ArchivedRequests} />        
                        <Route exact path={["/search-gurus"]} component={SearchForGurus} />        
                        <Route exact path={["/new-search"]} component={NewSearch} />        
                        <Route exact path={[`/${t('PATH_profile')}`]} component={MyProfile} />    
                        <Route exact path={["/profile/edit"]} component={EditProfile} />        
                        <Route exact path={["/profile/:id"]} component={UserProfile} />    
                        <Route exact path={["/bloqued-users"]} component={BloquedUsers} />        
                        <Route exact path={["/reviews"]} component={UserReviews} />        
                        <Route exact path={["/guru/tutorial"]} component={GuruTutorial} />        
                        <Route exact path={["/seeker/tutorial"]} component={SeekerTutorial} />                                
                    </Switch>  */}
                <Switch>                    
                    <Route exact strict path={[RP.getPath(t,RP.BASE)]} component={Dashboard} />
                    <Route exact strict path={[RP.getPath(t,RP.NEW_SEARCH)]} component={NewSearch} />
                    <Route exact strict path={[RP.getPath(t,RP.REQUEST_LIST)]} component={RequestList} />
                    <Route path={[RP.getPath(t,RP.ARCHIVED_REQUESTS)]} component={ArchivedRequests} />
                    <Route path={[RP.getPath(t,RP.SEARCH_GURUS)]} component={SearchForGurus} />                    
                    <Route exact path={[RP.getPath(t,RP.NEW_SEARCH_GURUS)]} component={NewSearchGurus} />
                    <Route path={[RP.getPath(t,RP.PROFILE)]} component={MyProfile} />
                    <Route path={[RP.getPath(t,RP.PROFILE_EDIT)]} component={EditProfile} />
                    <Route path={[RP.getPath(t,RP.PROFILE_ID)]} component={UserProfile} />
                    <Route path={[RP.getPath(t,RP.BLOQUED_USERS)]} component={BloquedUsers} />
                    <Route path={[RP.getPath(t,RP.REVIEWS)]} component={UserReviews} />
                    <Route path={[RP.getPath(t,RP.GURU_TUTORIAL)]} component={GuruTutorial} />
                    <Route path={[RP.getPath(t,RP.SEEKER_TUTORIAL)]} component={SeekerTutorial} />
                    <Route path={[RP.getPath(t,RP.CONFIG_STRIPE)]} component={ConfigureStripe} />
                    <Route path={[RP.getPath(t,RP.ADD_PAYMENT_METHOD)]} component={PaymentMethods} />
                    <Route path={[RP.getPath(t,RP.TIPS_GIVEN)]} component={TipsGiven} />
                    <Route path={[RP.getPath(t,RP.TIPS_RECEIVED)]} component={TipsReceived} />
                    <Route path={[RP.getPath(t,RP.CONFIRM_ACCOUNT_ID)]} component={ConfirmAccount} />
                    <Redirect to="/" />
                </Switch> 
            </div>
        </div>                   
    );
}

const mapStateToProps = (state)=>{
    console.log('routerstate',state)
    const {
        auth,
        navbar,
        globalMessage,
        globalLoading,
        activeConversations,
        pendingRates,
        notifications,
        guruConversations
    } = state

    console.log('the user?',auth.user) 
    return {
        auth,
        user:auth.user?auth.user:{},
        userAs:navbar.userAs,
        globalMessage,
        globalLoading,
        conversations:activeConversations,
        currentRate:pendingRates.currentRate,
        pendingRatesList:pendingRates.list,
        ratePredata:pendingRates.predata,
        notifications,
        guruConversations
    }
}

export default connect(mapStateToProps)(AppRouter);
