import AZField from "../form/AZField"
import AZPassword from "../form/AZPassword"
import AZSelect from "../form/AZSelect"
import AZButton from "../form/AZButton"

import { useTranslation } from 'react-i18next';
import { useState,useEffect } from "react";

import { Link } from "react-router-dom";

import C from "../../constants"

import * as authActions from '../../actions/auth.actions'

import { RecaptchaVerifier,getAuth } from "@firebase/auth";

const CreateAccountForm = (props) => {
    const { t, i18n } = useTranslation();

    const {
        onSubmit,
        onChange,
        onChangePhoneData,
        phoneData,
        loading,
        profile,
        dispatch
    } = props    

    const [codeList, setCodeList] = useState();
    const [codeListSelected, setCodeListSelected] = useState(phoneData.phoneCode!==""?phoneData.iso+" "+phoneData.phoneCode:"");
    const [codeListOpen, setCodeListOpen] = useState(false);
    const [codeListLoading, setCodeListLoading] = useState(false);
    const [originalCodeList, setOriginalCodeList] = useState([]);
     

    const [form, setForm] = useState({        
        "name":{
            name:"name",
            value:profile.name?profile.name:"",
            error:false,
            errorText:""
        },
        "lastName":{
            name:"lastName",
            value:profile.lastName?profile.lastName:"",
            error:false,
            errorText:""
        },
        "password":{
            name:"password",
            value:profile.password?profile.password:"",
            error:false,
            errorText:""
        },
        "email":{
            name:"email",
            value:profile.email?profile.email:"",
            error:false,
            errorText:""
        },
        "codeNumberId":{
            name:"codeNumberId",
            value:profile.codeNumberId?profile.phoneCodeId:"",
            error:false,
            errorText:""
        },
        "phoneNumber":{
            name:"phoneNumber",
            value:profile.phoneNumber?profile.phoneNumber:"",
            error:false,
            errorText:""
        },
    });

    const handleCodeListClick = () =>{
        if(codeListOpen){
            setCodeListOpen(false)
        }else{
            setCodeListLoading(true)   
            setForm({  
                ...form,                      
                'codeNumberId':{
                    ...form['codeNumberId'],
                    error:false,
                    errorText:''
                }                               
            })         

            authActions.getPhoneCodes().then(
                (list)=>{
                    console.log(list)
                    setOriginalCodeList(list)
                    console.log(originalCodeList)
                    setCodeList(
                        list.map(v=>(
                            {                                
                                id:v.phoneCodeId,
                                value:`${v.iso} ${v.phoneCode}`
                            }                           
                        ))
                    )
                    setCodeListLoading(false) 
                    setCodeListOpen(true)
                },
                (error)=>{
                    console.log(error)
                }
            )
        }        
    }

    const handleCodeSelect = (id,value) =>{
        console.log('checkPhone value',value)
        console.log('checkPhone value',id)
        setCodeListSelected(
            value
        )
        setForm({  
            ...form,                      
            'codeNumberId':{
                ...form['codeNumberId'],
                value:id
            }                               
        })
        console.log("lista verdadera")
        console.log(originalCodeList)
        let dataP;
        //let data = originalCodeList.find((element) =>{ console.log("elements"); console.log(element); return element.phoneCodeId == id?element:{}})
        originalCodeList.map((element)=>{
            if(element.phoneCodeId === id ){
                dataP = element
            }
        })
        
        //originalCodeList.filter((element) =>{ console.log("elements"); console.log(element); return element.phoneCodeId === id?element:{}})
        console.log("The data",dataP)        
        onChangePhoneData(dataP)

        setCodeListOpen(false)
    }   

    const handleChange = (e) => {        
        let formName =  e.target.name
        let value = e.target.type === 'checkbox' ? e.target.checked: e.target.value
        console.log(`${formName} ${value}`)
        setForm({  
            ...form,                      
            [formName]:{
                ...form[formName],
                value
            }                               
        })
    }

    const handleSubmit = (e) => {
        console.log('prevent from submit')
        e.preventDefault()
        // reset the errors
        Object.keys(form).map(i=>form[i]={
            ...form[i],
            error:false,
            errorText:""
        })

        onSubmit(
            form,
            window.applicationVerifier,
            (errors)=>{
                console.log('errors')
                console.log(errors)
                setForm({
                    ...form,
                    ...errors
                })
                console.log(form)
            }
        )        
    }

    useEffect(() => {
        window.applicationVerifier = new RecaptchaVerifier('recaptcha-container',{
            size: 'invisible'
        },getAuth());
    }, []);

    

    return (
        <form onSubmit={handleSubmit}>
            <div className="row my-3">
                <div className="col-12 text-center">
                    <h1 className="az-title">
                        {t('CREATE_ACCOUNT_title')}                
                    </h1>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-md-6 my-2">
                    <AZField
                        name={form.name.name}
                        value={form.name.value}
                        error={form.name.error}
                        errorText={form.name.errorText}
                        onChange={handleChange}
                    >
                        {t('CREATE_ACCOUNT_placeholder_input_name')} 
                    </AZField>
                </div>
                <div className="col-md-6 my-2">
                    <AZField
                         name={form.lastName.name}
                         value={form.lastName.value}
                         error={form.lastName.error}
                         errorText={form.lastName.errorText}
                         onChange={handleChange}
                    >
                        {t('CREATE_ACCOUNT_placeholder_input_last_name')} 
                    </AZField>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZField
                        name={form.email.name}
                        value={form.email.value}
                        error={form.email.error}
                        errorText={form.email.errorText}
                        onChange={handleChange}
                    >
                        {t('CREATE_ACCOUNT_placeholder_input_email')} 
                    </AZField>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZPassword
                         name={form.password.name}
                         value={form.password.value}
                         error={form.password.error}
                         errorText={form.password.errorText}
                         onChange={handleChange}
                    >
                        {t('CREATE_ACCOUNT_placeholder_input_password')} 
                    </AZPassword>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 my-2">
                    <AZSelect
                        name={form.codeNumberId.name}                        
                        title={codeListSelected==""?t('CREATE_ACCOUNT_placeholder_input_phone_code'):codeListSelected}
                        list={codeList}
                        error={form.codeNumberId.error}
                        errorText={form.codeNumberId.errorText}
                        isListOpen={codeListOpen}
                        onToggle={handleCodeListClick}
                        onItemClick={handleCodeSelect}
                        loading={codeListLoading}
                    >
                        
                    </AZSelect>
                </div>
                <div className="col-md-8 my-2">
                    <AZField
                        name={form.phoneNumber.name}
                        value={form.phoneNumber.value}
                        error={form.phoneNumber.error}
                        errorText={form.phoneNumber.errorText}
                        onChange={handleChange}
                    >
                        {t('CREATE_ACCOUNT_placeholder_input_phone_number')}
                    </AZField>
                </div>
            </div>
            <div className="row justify-content-center my-2">
                <div className="col-8">
                    <AZButton
                        onClick={()=>{}}
                        customClass="w-100"
                        type="submit"
                        loading={loading}
                        onLoadText={t('LOADING')}
                    >
                        {t('CREATE_ACCOUNT_button_create_account')} 
                    </AZButton>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <div id="recaptcha-container">
                    
                    </div>                    
                </div>
            </div>
            <div className="row my-2 mb-3">
                <div className="col-12 text-center">
                    <h5>
                        <span className="ms-1">
                            {t('CREATE_ACCOUNT_term_text')}
                        </span>
                        <span className="ms-1">
                            <a target="_blank" className="text-purple ms-1" href={`${C.resources.legal}terms`}>{t('START_terms_term')} </a>
                        </span>
                        <span className="ms-1">
                            {t('START_terms_know_how')}
                        </span>
                        <span className="ms-1">
                            <a target="_blank" className="text-purple ms-1" href={`${C.resources.legal}privacy`}>{t('START_terms_privacy')} </a>
                        </span>
                        <span className="ms-1">
                            {t('START_terms_and')}
                        </span>
                        <span className="ms-1">
                            <a target="_blank" className="text-purple ms-1" href={`${C.resources.legal}dataprotection`}>{t('START_terms_dpa')} </a>
                        </span>                                                  
                    </h5>
                    
                </div>
            </div>
        </form>
    );
    
}

export default CreateAccountForm;
