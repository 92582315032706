import { useState } from "react";

const AZSelectMultipleItem = (props) => {
    const {
        onItemClick,
        id,
        value,
        error,
        errorText,
        selected,
        disabled
    } = props    
    
    return (
        <div>
            <div className={`az-select-multiple__list__content__item ${disabled&&"az-select-multiple__list__content__item--disabled"} az-select-multiple__list__content__item--${id}`}
                onClick={()=>onItemClick(
                    id,
                    !selected,
                    value
                )}>
                <div className="az-select-multiple__list__content__item__text">
                    {value}
                </div>
                {
                    selected&&<div className="az-select-multiple__list__content__item__icon">
                        <i className="fas fa-check"></i>
                    </div>
                }            
            </div>                   
        </div>
    );
}

export default AZSelectMultipleItem;
