import React from 'react';

import { useEffect,useState } from 'react';

import NewRequestMatchedGuruList from './NewRequestMatchedGurusList';
import AZButtonLink from '../form/AZButtonLink';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import RP from '../../helpers/routePath';

import useWindowScrollApiList from '../../hooks/useWindowScrollApiList';

import * as appActions from '../../actions/app.actions'
import * as guruActions from '../../actions/guru.actions'
import * as seekerActions from '../../actions/seeker.actions'
import { set } from 'firebase/database';

const ExploreGurusView = (props) => {
    const {
        dispatch,
        currentRequest, 
        user,
        onSend,       
        onBack=null,
        sendById=false
    }=props

    const rows = 10

    const {t} = useTranslation()
    const history = useHistory()

    const [requestData, setRequestData] = useState(currentRequest);
    const [requestId, setRequestId] = useState(currentRequest.requestId!==null?currentRequest.requestId:null);

    const [atLeastOneSend, setAtLeastOneSend] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const [gurusStatus, setGurusStatus] = useState(currentRequest.matchedGurus.gurusStatus);

    // const [gurusList, setGurusList] = useState(currentRequest.matchedGurus.gurusList);
    // const [gurusListLoading, setGurusListLoading] = useState(false);
    // const [loadedElements, setLoadedElements] = useState(currentRequest.matchedGurus?currentRequest.matchedGurus.loadedElements:0);

    const [
        gurusList,
        gurusListLoading,
        isScrolling,
        setGurusList,
        loadedElements,
    ] = useWindowScrollApiList({ 
        apiCall:requestId?seekerActions.getGurusMatchingUsingRequestId:seekerActions.getGurusMatchingUsingParams,
        'rows':rows,    
        //params:requestId?[requestId]:(requestData.step3Value==3?[{...currentRequest}]:[{'tags':currentRequest.tags}]),
        params:sendById?[requestId]:[{...currentRequest}],
        onSuccess:(response)=>{
            console.log("response after hook",response)
        },
        onError:(error)=>{
            console.log("error after hook",error)
        },
        testing:true,
        block:sendingRequest,
        preList:currentRequest.matchedGurus.gurusList,
        preLoaded:currentRequest.matchedGurus.loadedElements,
        "shouldObserveParams":false,
        mapListFunction:(element)=>{return  {...element,'status':element.status?element.status:'send'}},
        fromStartFunction:(theList)=>{console.log("checkTheList",theList);console.log("checkTheList",theList.length == 0);return theList.length == 0}
    })

    const [guardProfile, setGuardProfile] = useState(-1);

    // const getMatchedGurus = (fromStart=false,theParams=null)=>{
    //     const successFunction = (response)=>{
    //         console.log('matching response...',response)
    //         const {
    //             list
    //         } = response
    //         if(list.length>0){      
    //             let actualList = fromStart?[]:gurusList
    //             setGurusList([...actualList,...list.map((element)=>{
    //                 return {
    //                     ...element,
    //                     'status':element.status?element.status:'send'
    //                 }
    //             })])                 
    //             setLoadedElements(loadedElements+list.length)         
    //         }
    //         setGurusListLoading(false)           
    //     }

    //     const errorFunction = (error)=>{
    //         if(error){
    //             dispatch(
    //                 appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')                                          
    //             )
    //         }
    //         setGurusListLoading(false)
    //     }

    //     if(theParams===null){
    //         theParams=requestData
    //     }
    //     console.log('the matching data',theParams)
    //     if(gurusListLoading){
    //         return
    //     }
    //     setGurusListLoading(true)      
    //     let loadedItems = loadedElements
    //     if(fromStart){
    //         setLoadedElements(0)
    //         loadedItems = 0
    //     }

    //     if(requestId){
    //         seekerActions.getGurusMatchingUsingRequestId(
    //             requestId,
    //             loadedItems,
    //             rows
    //         ).then(
    //             successFunction,
    //             errorFunction
    //         )
    //     }else{
    //         seekerActions.getGurusMatchingUsingParams(
    //             (theParams.step3Value==3?{...theParams}:{'tags':theParams.tags}),
    //             loadedItems,
    //             rows
    //         ).then(
    //             successFunction,
    //             errorFunction
    //         )
    //     }

    // }

    const sendMessageToGuru = (requestId,guruId,guruModel)=>{
        let newGurusList = gurusList.map((element)=>{
            if(element.idUser === guruId){
                return {
                    ...element,
                    'status':"sending"
                }
            }else{
                return {
                    ...element,                    
                }
            }
        })     

        setGurusList(newGurusList)
        setSendingRequest(true)
        guruActions.sendInitialMessage(
            requestId,
            guruId,
            requestData.description
        ).then(
            (response)=>{
                console.log('send initial',response)
                const {
                    codeNumber="",
                    codeDescription="",
                    ...initialMessage
                } = response
                

                newGurusList = gurusList.map((element)=>{
                    console.log('')
                    if(element.idUser === guruId){
                        return {
                            ...element,
                            'status':"sent"
                        }
                    }else{
                        return {
                            ...element,                    
                        }
                    }
                })       
                if(onSend){
                    onSend({
                        ...guruModel,
                        ...initialMessage,
                        idRequest:requestId,
                        idGuru:guruId,
                        'lastMessageIdUser':user.idUser,                                                
                        notReadByGuru: 1,
                        notReadBySeeker: 0
                    })
                }         
                setGurusList(newGurusList)
                setAtLeastOneSend(true)
                setSendingRequest(false)
            },
            (error)=>{
                console.log(error)
                newGurusList = gurusList.map((element)=>{
                    if(element.idUser === guruId){
                        return {
                            ...element,
                            'status':"send"
                        }
                    }else{
                        return {
                            ...element,                    
                        }
                    }
                })
                if(error){
                    dispatch(
                        appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')                                          
                    )
                }
                setGurusList(newGurusList)
                setSendingRequest(false)
            }
        )
    }

    const handleItemSend = (guruId,guruModel) =>{
        if(requestId){
            sendMessageToGuru(requestId,guruId,guruModel)
        }else{
            dispatch(appActions.setLoading(true,t("NEW_REQUEST_creating_request")))
            dispatch(
                seekerActions.createNewRequest(
                    requestData
                )
            ).then(
                (response)=>{
                    dispatch(appActions.setLoading(false))
                    console.log("create request and send",response)
                    const { idElement } = response
                    dispatch(appActions.setCurrentRequest({
                        ...requestData,
                        atLeastOneSend:true,
                        requestId:idElement,
                    }))
                    setRequestId(idElement)
                    sendMessageToGuru(idElement,guruId,guruModel)
                },
                (error)=>{
                    dispatch(appActions.setLoading(false))
                    if(error){
                        dispatch(
                            appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')
                        )
                    }
                }
            )
        }
    }    

    const handleItemViewReviews = (guruId)=>{
        console.log('request before reviews',requestData)        
        setGuardProfile(guruId)
        //push history
    }

    useEffect(() => {  
        // console.log('request',currentRequest)       
        // console.log('first',gurusList)       
        // let fromStartVar = currentRequest.matchedGurus.gurusList.length == 0
        // getMatchedGurus(
        //     fromStartVar
        // )        
    }, []);

    useEffect(() => {
        const unblock = sendById?history.block((location, action) => {
            if (guardProfile<1) {
               return false
            }
            return true;
        }):()=>{ return true};

        if(guardProfile>0){
            dispatch(
                appActions.setCurrentRequest(
                    {
                        ...requestData,
                        requestId,
                        atLeastOneSend,
                        matchedGurus:{
                            gurusList,
                            loadedElements,
                            gurusStatus
                        },
                        requestStep:1
                    }
                )
            )
            console.log('guarded profile',guardProfile)
            history.push(RP.getPath(t,RP.PROFILE_USER,guardProfile),{'reviewsAs':'GURU'})
        }
        
        return ()=>{
            console.log('guarded escape?',guardProfile)            
            if(guardProfile<1 ){
                console.log("why are you clenaing?")  
                if(sendById){
                    dispatch(
                        appActions.cleanCurrentRequest()
                    )
                }              
            }else{
                console.log('cant be cleaned')
            }
            unblock();
        }
    }, [guardProfile]);

    useEffect(() => {
        if(gurusList.length > 0){
            dispatch(appActions.setCurrentRequest({
                //...currentRequest,
                ...currentRequest,
                matchedGurus:{
                    gurusList,
                    loadedElements,
                    gurusStatus
                },                           
            })) 
        }
    }, [gurusList]);
    // useEffect(() => {
    //     const unblock = history.block((location, action) => {
    //         if (requestId!==null) {
    //            return false
    //         }
    //         return true;
    //     });

    //     return () => {
    //         unblock()
    //     };

    // }, [requestId]);


    return (
        <div>
            {
                onBack&&<div className="row justify-content-start my-2">
                    <div className=" col-md-auto">
                        <AZButtonLink
                            style="color"
                            type="button"
                            onClick={()=>{onBack(atLeastOneSend)}}
                            underline
                        >
                            {t('GENERAL_back')}
                        </AZButtonLink>
                    </div>
                </div>
                
            }
            <div className="row">
                <div className="col-12 my-2">
                    <h4>{requestData.title}</h4>
                    <p className='my-2'>{requestData.description}</p>
                    <div className="divider w-100 bg-gray"></div>
                </div>
                <div className="col-12 my-2">
                    <NewRequestMatchedGuruList
                        list={gurusList}
                        full={true}
                        blocked={sendingRequest}
                        itemsLoaded={loadedElements}
                        loading={gurusListLoading}
                        onScroll={()=>{}}
                        noData={t('SEEKER_no_matching_guru_found')}
                        onItemClick={()=>{}}
                        onItemViewReviews={handleItemViewReviews}
                        onItemSend={handleItemSend}
                    ></NewRequestMatchedGuruList>
                </div>
            </div>
        </div>
    );
}

export default ExploreGurusView;
