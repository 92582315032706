const AZButtonLink = (props) => {
    const {        
        style,
        type,
        underline,
        children,    
        onClick,
        disabled,
        loading,
        size="md"
    } = props
    
    const buttonStyle = {
        color:"btn-az-link btn-az-link--color",
        gray:"btn-az-link btn-az-link--gray",
        danger:"btn-az-link btn-az-link--danger"
    }

    const handleClick = (e)=>{
        if(e !== undefined){
            e.stopPropagation()
        }
        onClick()
    }

    return (
        <button 
        type={type}
        className={`${buttonStyle[style]} ${underline?"btn-az-link--underline":""} btn-az-link--${size} ${disabled&&"btn-az-link--disabled"}`}                 
        onClick={handleClick}
        >
            {
                loading?
                <i className="fa fa-spin fa-spinner"></i>
                :
                children
            }            
        </button>
    );
}

export default AZButtonLink;
