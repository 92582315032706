import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    UPDATE_PROFILE
} from "./types";
  
import Service from "../services/userAuth.service";
import {
    apiErrorHandler
} from "../helpers/helpers"
import { profile } from "../reducers/profile";
import { useHistory } from "react-router";


export const login = (params) => (dispatch) => {
    const {
        email,
        device
    } = params
    return Service.loginUsingMailCredentials(params).then(
        (response) => {   
            console.log("login response...",response);
            const {
                data
            } = response

            const {
                codeNumber,
                codeDescription,
                ...userData
            } = data
            userData.email = email
            userData.device = device
            console.log(userData)
            if (userData.idUser && userData.idSession) {
                localStorage.setItem("auth", JSON.stringify({
                    user:{
                        ...userData,
                        id:userData.idUser
                    },
                    session:userData.idSession
                }));
            }         
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { 
                    user:userData,
                    session:userData.idSession                    
                },
            }); 
            return Promise.resolve();
        },
        (error) => {        
            console.log('error on action',error)
            console.log('error on action',error.response)
            dispatch({
                type: LOGIN_FAIL
            });                  
            return Promise.reject(error);
        }
    );
};

export const logout = () => (dispatch) => {
    return Service.logout().then(
        (data)=>{            
            dispatch({
                type:LOGOUT
            })
            localStorage.removeItem("auth");
            return Promise().resolve()
        },
        (error) => {            
            dispatch({
                type:LOGOUT
            })
            localStorage.removeItem("auth");
            return Promise().reject()
        }  
    )      
};

export const getPhoneCodes = () => {
     return Service.getPhoneCodes().then(
        (data)=>{             
            return Promise.resolve(data.data.list);                     
        },
        (error)=>{
            return Promise.reject(error);
        }
    )
}
export const uploadProfilePhoto = (url,profileImage) => {
     return Service.uploadProfilePhoto(url,profileImage).then(
        (data)=>{        
            console.log('photo data')     
            return Promise.resolve(data.response.presignedURL);                     
        },
        (error)=>{
            return Promise.reject(error);
        }
    )
}

export const checkEmailAvaiable = (email) => {    
     return Service.checkEmailAvaiable(email).then(
        (data)=>{             
            return Promise.resolve(data);                     
        },
        (error)=>{
            return Promise.reject(error.response);
        }
    )
}

export const createUser = (profile) => (dispatch) => {
    const {        
        email,        
        image,
        device
    } = profile
    
    return Service.createNewUserProfile(
        profile
    ).then(
        (response)=>{
            console.log("login response...",response);
            const {
                data
            } = response

            const {
                codeNumber,
                codeDescription,
                ...userData
            } = data
            userData.email = email
            userData.device = device
           
            if (userData.idUser && userData.idSession) {
                localStorage.setItem("auth", JSON.stringify({
                    user:{
                        ...userData,
                        id:userData.idUser
                    },
                    session:userData.idSession
                }));
            }

            Service.uploadProfilePhoto(
                userData.presignedURL,
                image
            ).then(
                (response)=>{
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: { 
                            user:userData,
                            session:userData.idSession                    
                        },
                    });                       
                    return Promise.resolve({
                        user:userData,
                        session:userData.session 
                    });
                },(error)=>{
                    console.log('imageError',error)
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: { 
                            user:userData,
                            session:userData.idSession                    
                        },
                    });    
                    return Promise.reject({
                        response:{
                            data:{
                                'codeNumber':"IMAGE",
                                'codeDescription':'cant update the image'
                            }
                        },
                        data:{
                        'codeNumber':"IMAGE",
                        'codeDescription':'cant update the image'
                        }
                    });
                }                
            ).catch((response)=>{
                console.log('error',response)
            })
           
            
        },
        (error,xhr,lol)=>{
            console.log('onCreateError',error)
            console.log('onCreateError',xhr)
            console.log('onCreateError',lol)
            return Promise.reject(error);
        }
    )
}

export const isEmailAvailable = (email) => (dispatch)=>{
    Service.checkEmailAvaiable(email).then(
        (data)=>{
            return Promise.resolve(data);
        },
        (error)=>{
            return Promise.reject(error);
        }
    )
}

export const passwordRecovery = (email,lang) => {
    return Service.passwordRecovery(email,lang).then(
        (data)=>{
            return Promise.resolve(data);
        },
        (error)=>{
            console.log('password response',error.response)
            return Promise.reject(error.response);
        }
    )
}

export const updateSession = (so,device,lang) => (dispatch) => {    
    return Service.updateSession(so,device,lang).then(
        (response) => {   
            console.log("update session response...",response);
            const {
                data
            } = response
            const auth = JSON.parse(localStorage.getItem('auth'))
            
            const {
                user
            } = auth

            const {
                codeNumber,
                codeDescription,
                ...userData
            } = data            
            if (userData.idUser && userData.idSession) {
                localStorage.setItem("auth", JSON.stringify({
                    user:{
                        ...user,
                        ...userData,
                        id:userData.idUser
                    },
                    session:userData.idSession,
                    isLoggedIn:true
                }));
            }         
            dispatch({
                type: UPDATE_PROFILE,
                payload: { 
                    user:{
                        ...user,
                        ...userData,
                        id:userData.idUser
                    },
                    session:userData.idSession,
                    isLoggedIn:true
                },
            }); 
            return Promise.resolve(userData);
        },
        (error) => {        
            apiErrorHandler(error.response,dispatch)
                return Promise.reject(error.response);
        }
    );
};