import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import RP from '../../helpers/routePath';

import * as navActions from '../../actions/navbar.actions'

import C from "../../constants"

import AZTutorial from '../../components/secuence/AZTutorial';

const GuruTutorial = (props) => {
    const {
        user,
        dispatch
    } = props
    const history = useHistory()
    const {t} = useTranslation()

    const tutorialElements = [
        {
            image:`${C.resources.webpage}app/guru/iconScreen1.png`,
            title:t('BECOME_GURU_slide1_title'),
            description:t('BECOME_GURU_slide1_text')
        },        
        {
            image:`${C.resources.webpage}app/guru/iconScreen2.png`,
            title:t('BECOME_GURU_slide2_title'),
            description:t('BECOME_GURU_slide2_text')
        },        
        {
            image:`${C.resources.webpage}app/guru/iconScreen3.png`,
            title:t('BECOME_GURU_slide3_title'),
            description:t('BECOME_GURU_slide3_text')
        },        
        {
            image:`${C.resources.webpage}app/guru/iconScreen4.png`,
            title:t('BECOME_GURU_slide4_title'),
            description:t('BECOME_GURU_slide4_text')
        },        
        {
            image:`${C.resources.webpage}app/guru/iconScreen5.png`,
            title:t('BECOME_GURU_slide5_title'),
            description:t('BECOME_GURU_slide5_text')
        },        
        {
            image:`${C.resources.webpage}app/guru/iconScreen6.png`,
            title:t('BECOME_GURU_slide6_title'),
            description:t('BECOME_GURU_slide6_text')
        },        
    ]

    const [tutorialStep, setTutorialStep] = useState(0);
    const [tutorialFinish, setTutorialFinish] = useState(true);
    
    useEffect(() => {
        dispatch(
            navActions.switchToGuru(2)
        )
    }, []);
    
    return (
        <div>  
            <div className="container-lg py-1 bg-transparent">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-8 col-md-6 col-container">             
                        <AZTutorial
                            elements={tutorialElements}
                            step={tutorialStep}
                            onBack={()=>{setTutorialStep(tutorialStep-1)}}
                            onNext={()=>{setTutorialStep(tutorialStep+1)}}
                            onFinish={()=>{history.push(RP.getPath(t,RP.BASE))}}
                            nextText={t('BECOME_GURU_button_continue_slide')}
                            finishText={user.isAGuru?`${t('GURU_title1')} ${t('GURU_title2')}`:`${t('BECOME_GURU_button_become')}`}
                            onDotClick={(index)=>{console.log('dot');setTutorialStep(index)}}
                        >
                        </AZTutorial>       
                    </div>
                </div>
            </div>                              
        </div>
    );
}

const mapStateToProps = (state) =>{
    const {
        auth
    } = state

    return {
        user:auth.user
    }
}

export default connect(mapStateToProps)(GuruTutorial);
