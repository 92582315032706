import React,{ useState } from 'react';
import AZProfileImage from './AZProfileImage';
import AZButtonLink from '../form/AZButtonLink';
import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';




const BloquedUserItem = (props) => {
    const {
        model,
        onUnblocked,
        onItemClick
    } = props

    const {t,i18n} = useTranslation()


    const handleItemClick = (e) => {    
        onItemClick()    
    }

    const handleUnblocked = () => {        
        onUnblocked(model.idUser)
    }

    return (  
        <div>
            <div className="az-list__content__item az-list__content__item--bloqued-user align-items-center" onClick={handleItemClick}>
                <div className="">
                    <AZProfileImage
                        user={model}
                        bordered={true}
                        size="sm"
                    >                    
                    </AZProfileImage>
                </div>

                <div className="az-list__content__item__info">
                    <h5 className="ms-4">
                        <b>                        
                            {model.name}                    
                        </b>
                    </h5>
                </div>
                
                <div className="col-sm-auto col-12 text-end">
                    <div className="">
                        <AZButton 
                            onClick={handleUnblocked}
                            type="button"
                            style="color"     
                            size="sm"               
                        > 
                            {t('PROFILE_blocked_users_unblock_button')}                 
                        </AZButton>  
                    </div>
                </div>              
            </div>            
        </div>      
    );
}

export default BloquedUserItem;
