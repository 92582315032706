import {
    SET_REQUEST,
    CLEAN_REQUEST, 
} from "../actions/types"

const initialState = {
    atLeastOneSend:false,
    requestId:null,
    requestStep:0,
    categories:[],
    categorySelect:{
        isOpen:false,
        selectedId:-1,
        selectedValue:""
    },
    tagSelect:{
        isOpen:false,
        selectedId:-1,
        selectedValue:""
    },
    tags:[],
    selectedTags:[],
    bubbleTags:[],
    tagsNumber:0,
    title:"",    
    description:"",
    willingToPay:false,
    step3Value:0,
    matchedGurus:{
        gurusList:[],
        loadedElements:0,
        gurusStatus:{}
    }
}

export default (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    switch (type) {     
        case SET_REQUEST:
        return {
            ...state,
            ...payload
        }
        case CLEAN_REQUEST:
        return initialState    
        default:
        return state;
    }
}