import React,{useEffect,useState,useRef} from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as appActions from '../../actions/app.actions' 
import * as paymentsActions from '../../actions/payments.actions' 
import * as navActions from '../../actions/navbar.actions'

import AZButton from '../../components/form/AZButton';
import AZButtonLink from '../../components/form/AZButtonLink';

const ConfigureStripe = (props) => {
    const {
        dispatch
    } = props

    const getStripeAccountACRef = useRef()
    const getOnboardingACRef = useRef()

    const {t} = useTranslation()
    const history = useHistory()

    const alertType = {
        ONBOARDING_SUCCESS: "onboarding_success",
        SHOW_NOT_READY: "not_ready",
        SHOW_NOT_STARTED:"not_started",
    }
    
    const [viewState, setViewState] = useState({
        viewTitle:"",
        viewType:false,
        viewDescription:"",
        sendButtonTitle:"",
        sendButtonDisabled:false,
    });
    const [loading, setLoading] = useState(false);
    const [loadingOnBoarding, setLoadingOnBoarding] = useState(false);
    const {
        viewTitle,
        viewType,
        viewDescription,
        sendButtonTitle,
        sendButtonDisabled
    } = viewState

    const getAccountStatus = () => {
        setLoading(true)       
        dispatch(paymentsActions.getStripeAccount({
            abortController:getStripeAccountACRef.current
        }))
        .then(
            (response) => {
                console.log('onSuccess',response)
                //the logic here
                const {
                    codeNumber="",
                    codeDescription="",
                    ...account
                } = response                
                if(account.payoutsEnabled==true && account.chargesEnabled==true && account.detailsSubmitted==true){
                    setViewState({
                        ...viewState,
                        viewTitle:t('CONFIGURE_STRIPE_onboarding_success_title'), 
                        viewDescription:t('CONFIGURE_STRIPE_onboarding_success_description'), 
                        sendButtonTitle:t('CONFIGURE_STRIPE_onboarding_success_title_button'), 
                        viewType:alertType.ONBOARDING_SUCCESS, 
                        sendButtonDisabled:false
                    })
                    //setState(previousState => ({ ...previousState, title: t('CONFIGURE_STRIPE_onboarding_success_title'), description:t('CONFIGURE_STRIPE_onboarding_success_description'), buttonSendTitle:t('CONFIGURE_STRIPE_onboarding_success_title_button'), type:alertType.ONBOARDING_SUCCESS, buttonSendDisabled: -1, }));
                }else if(account.disabledReason!=null){
                    let sendButtonTitle = "";
                    let viewTitle = "";
                    let viewDescription = "";                    
                    if(account.disabledReason==='requirements.past_due'){
                        if(account.errors!=null && account.errors.length>0 && account.errors[0] === 'verification_failed_keyed_identity'){
                            sendButtonTitle = t('CONFIGURE_STRIPE_onboarding_verification_failed_key_identity_title_button');
                            viewTitle = t('CONFIGURE_STRIPE_onboarding_verification_failed_key_identity_title');
                            viewDescription = t('CONFIGURE_STRIPE_onboarding_verification_failed_key_identity_description');
                        }else{
                            sendButtonTitle = t('CONFIGURE_STRIPE_onboarding_disabled_reason_past_due_button');
                            viewTitle = t('CONFIGURE_STRIPE_onboarding_disabled_reason_title_past_due');
                            viewDescription = t('CONFIGURE_STRIPE_onboarding_disabled_reason_description_past_due');
                        }
                    }else if(account.disabledReason==='requirements.pending_verification'){
                        sendButtonTitle = t('CONFIGURE_STRIPE_onboarding_disabled_reason_title_pending_verification_button');
                        viewTitle = t('CONFIGURE_STRIPE_onboarding_disabled_reason_title_pending_verification');
                        viewDescription = t('CONFIGURE_STRIPE_onboarding_disabled_reason_description_pending_verification');
                    }else if(account.disabledReason==='listed' || account.disabledReason==='rejected.fraud' || account.disabledReason==='rejected.terms_of_service' || account.disabledReason==='rejected.listed' || account.disabledReason==='rejected.other'){
                        sendButtonTitle = t('CONFIGURE_STRIPE_onboarding_disabled_reason_button_rejected');
                        viewDescription = t('CONFIGURE_STRIPE_onboarding_disabled_reason_description_rejected');
                        viewTitle = t('CONFIGURE_STRIPE_onboarding_disabled_reason_title_rejected');
                    }else if( account.disabledReason==='under_review'){
                        sendButtonTitle = t('CONFIGURE_STRIPE_onboarding_disabled_reason_button_under_review');
                        viewDescription = t('CONFIGURE_STRIPE_onboarding_disabled_reason_description_under_review');
                        viewTitle = t('CONFIGURE_STRIPE_onboarding_disabled_reason_title_under_review');
                    }else{                        
                        sendButtonTitle = t('CONFIGURE_STRIPE_onboarding_disabled_reason_button_general');
                        viewDescription = t('CONFIGURE_STRIPE_onboarding_disabled_reason_description_general');
                        viewTitle = t('CONFIGURE_STRIPE_onboarding_disabled_reason_title_general');
                    }
                    setViewState({
                        ...viewState,
                        sendButtonTitle,
                        viewTitle,
                        viewDescription,
                        viewType:alertType.SHOW_NOT_READY,
                        sendButtonDisabled:false
                    })
                    //setState(previousState => ({ ...previousState, title:title, description:description, buttonSendTitle:buttonTitle, type:alertType.SHOW_NOT_READY, buttonSendDisabled: -1, }));

                }else{
                    setViewState({
                        ...viewState,
                        viewTitle: t('CONFIGURE_STRIPE_onboarding_no_success_title'),
                        viewDescription:t('CONFIGURE_STRIPE_onboarding_no_success_description'),
                        sendButtonTitle: t('CONFIGURE_STRIPE_onboarding_no_success_title_button'), 
                        viewType:alertType.SHOW_NOT_READY,
                        buttonSendDisabled:false
                    })
                    //setState(previousState => ({ ...previousState, title: t('CONFIGURE_STRIPE_onboarding_no_success_title'), description:t('CONFIGURE_STRIPE_onboarding_no_success_description'), buttonSendTitle: t('CONFIGURE_STRIPE_onboarding_no_success_title_button'), type:alertType.SHOW_NOT_READY, buttonSendDisabled: -1, }));
                }
                console.log('the account info',account)
                setLoading(false)
            },
            (error) => {
                console.log('onError',error)
                if(error.codeNumber==='0104'){
                    setViewState({
                        ...viewState,
                        'viewTitle':t('CONFIGURE_STRIPE_title'),
                        'viewDescription':t('CONFIGURE_STRIPE_description'),
                        'sendButtonTitle':t('CONFIGURE_STRIPE_ok_button'),
                        'sendButtonDisabled': false,
                        'viewType':alertType.SHOW_NOT_STARTED,
                    })       
                }
                setLoading(false)
            }
        )

    }

    const getOnboardingLink = () => {
        setLoadingOnBoarding(true)        
        dispatch(
            paymentsActions.getOnboardingLink({
                abortController:getOnboardingACRef.current
            })
        )
        .then(
            (response) => {
                console.log('get onbiardingresponse',response)
                const {
                    link
                } = response                
                window.location.href = link; 
            },
            (error) => {
                console.log('error',error)
                setLoadingOnBoarding(false)
            }
        )
    }

    const handleRefresh = () => {
        getAccountStatus()
    }

    const handleSend = () => {        
        getOnboardingLink()
    }

    useEffect(() => {     
        getStripeAccountACRef.current = new AbortController();           
        getOnboardingACRef.current = new AbortController(); 
        dispatch(
            navActions.switchToGuru()
        )                
        getAccountStatus()
        return () => {
            if(getStripeAccountACRef.current){                
                getStripeAccountACRef.current.abort()               
            }
            if(getOnboardingACRef.current){                
                getOnboardingACRef.current.abort()               
            }
        }
    }, []); 
 
    
    return (
        <div>
            <div className="container-lg my-2 py-2">
                <div className="row">
                    <div className="col-12"></div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="az-card-container  text-center">
                            {!loading?
                                <div className='text-center'>
                                    <h1 className='my-2'>
                                        {
                                            viewType == alertType.ONBOARDING_SUCCESS &&
                                            <i className="fa fa-check-circle text-purple"></i>
                                        }
                                        {
                                            viewType == alertType.SHOW_NOT_READY &&
                                            <i className="fa fa-exclamation-circle text-warning"></i>
                                        }                                        
                                    </h1>                                    
                                    <h3 className='my-2'>
                                        {viewTitle}
                                    </h3>
                                    <p className='w-75 mx-auto'>
                                        {viewDescription}
                                    </p>
                                    <div className="my-2">
                                        <AZButton
                                            style="color"
                                            onClick={handleSend}
                                            disabled={sendButtonDisabled}
                                            type="button"
                                            loading={loadingOnBoarding}
                                        >
                                            {sendButtonTitle}
                                        </AZButton>
                                    </div>
                                    {
                                        viewType != alertType.SHOW_NOT_STARTED && viewType != alertType.ONBOARDING_SUCCESS &&
                                        <div className="my-2">
                                            <AZButtonLink
                                                style="color"
                                                onClick={handleRefresh}
                                                disabled={sendButtonDisabled}
                                                type="button"
                                            >
                                                <i className="fa fa-refresh">                                                
                                                </i>
                                            </AZButtonLink>
                                        </div>
                                    }
                                </div>
                                :
                                <div className="p-3 text-center text-purple">
                                    <h4 className=''>
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </h4>
                                    <h5>{t('GENERAL_loading')}</h5>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    );
        
}

const mapStateToProps = (state) => {
    const {

    } = state

    return {}
}

export default connect(mapStateToProps)(ConfigureStripe);
