import {
    NAV_GURU,
    NAV_SEEKER,
    NAV_SELECT_INDEX,
    NAV_NONE
} from "./types"

export const switchToSeeker = (option=-1) => (dispatch) =>{
    dispatch({
        type:NAV_SEEKER,
        payload:option        
    })
    return Promise.resolve
}
export const switchToGuru= (option=-1) => (dispatch) =>{
    dispatch({
        type:NAV_GURU,   
        payload:option     
    })
    return Promise.resolve
}

export const switchToNone= (option=-1) => (dispatch) =>{
    dispatch({
        type:NAV_NONE,   
        payload:option     
    })
    return Promise.resolve
}

export const selectIndex= (index) => (dispatch) =>{
    dispatch({
        type:NAV_SELECT_INDEX,
        payload:index        
    })
    return Promise.resolve
}
