import React from 'react';

const NavTypeItem = (props) => {
    const {
        onClick,
        active=false,
        notifications=0,
        children
    } = props



    return (        
        <div className={`az-navbar__type-item ${active?"az-navbar__type-item--active":""}`} onClick={()=>{ onClick() }}>
            {notifications>0&&<div className="az-navbar__type-item__notifications">{notifications}</div>}
            <p>{children}</p>
        </div>
    );
}

export default NavTypeItem;
