import { useRef } from "react";

const AZCheckbox = (props) => {
    const {
        checked,        
        onChange,
        children,
        name
    }= props
    
    const checkboxRef = useRef(null)

    const setCheckClass = (checked) =>(
        checked?"az-checkbox__box az-checkbox__box--checked":"az-checkbox__box"
    )

    return (
        <label className="az-checkbox" 
        >
            <input 
                type="checkbox" 
                className="d-none" 
                ref={checkboxRef}                
                onChange={onChange}
                checked={checked}                
                name={name}
            />
            <div className={setCheckClass(checked)}>
            </div>
            <span  
                className={"az-checkbox__text"}>
                {children}
            </span>
        </label>
    );
}

export default AZCheckbox;
