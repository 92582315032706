import React from 'react';

const AZVerticalCheck = (props) => {
    const {
        isOn,
        onClick,
        value,
        children,
        disabled
    } = props
    return (
        <div>
            <div className={`az-vertical-check ${isOn&&"az-vertical-check--on"} ${disabled&&"az-vertical-check--disabled"}`} onClick={()=>{
                onClick(value,isOn)
            }}>
                <div className="az-vertical-check__check">
                    <div className="az-vertical-check__check__icon">
                        {isOn&&<i className="fa fa-check"></i> }
                    </div>
                </div>  

                {
                    children&&
                    <div className="az-vertical-check__label mt-2">
                        {children}
                    </div>   
                }                                              
            </div>
        </div>
    );
}

export default AZVerticalCheck;
