import React,{useState,useEffect,useRef} from 'react';
import { useTranslation } from 'react-i18next';

import AZButton from '../form/AZButton';
import AZButtonIcon from '../form/AZButtonIcon';
import AZButtonIconLink from '../form/AZButtonIconLink';
import AZSelect from '../form/AZSelect';
import AZField from '../form/AZField';
import AZSelectMultiple from '../form/AZSelectMultiple';
import AZAreaBubble from '../form/AZAreaBubble';

import { formValidateField } from '../../helpers/formvalidation';

import AZModal from '../modal/AZModal';
import AZDesicionModal from '../modal/AZDesicionModal';

import * as generalActions from '../../actions/general.actions'

import ReactTooltip from 'react-tooltip';


const SelectCategory = (props) => {
    const {
        onBack,
        onOk,
        categories,
        backCategorySelect={
            isOpen:false,
            selectedId:-1,
            selectedValue:""
        },
        backTags=[],
        backSelectedTags={},
        backTagsNumber=0
    } = props

    const tooltipRef = useRef()

    const {t} = useTranslation()

    const [tags, setTags] = useState(backTags);
    const [bubbleTags, setBubbleTags] = useState([]);
    const [categorySelect, setCategorySelect] = useState(backCategorySelect);
    const [selectedTags, setSelectedTags] = useState(backSelectedTags);
    const [tagsNumber, setTagsNumber] = useState(backTagsNumber);

    const [tagsLoading, setTagsLoading] = useState(false);

    const [messageModal, setMessageModal] = useState("");

    const [showSuggestModal, setShowSuggestModal] = useState({
        show:false,
        type:"category",
        successMessage:false,
        errorMessage:false
    });
    const [sendSuggestionLoading, setSendSuggestionLoading] = useState(false);
    const [showToolTip, setshowToolTip] = useState(true);

    const [form, setForm] = useState({
        'suggestion':{
            'name':'suggestion',
            'value':'',
            'error':false
        }
    });

    const categoriesListMenu=[
        {
            id:"tip",
            value:t('NEW_REQUEST_make_suggestion_soon_available'),
            disabled:true
        },
        {
            id:"suggestion",
            value:t('NEW_REQUEST_make_suggestion')
        }
    ]    
    const subCategoriesListMenu=[
        {
            id:"tip",
            value:t('NEW_REQUEST_make_suggestion_subcategories_soon_available'),
            disabled:true
        },
        {
            id:"suggestion",
            value:t('NEW_REQUEST_make_suggestion_subcategory')
        }
    ]    

    const getTags = ()=>{
        console.log('get the tags...')  
        setTagsLoading(true)
        generalActions.getTagsCategory(categorySelect.selectedId).then(
            (response)=>{
                console.log("get tags...",response)
                const theTags = response.list.map((t)=>(
                    {
                        "id":t.tagId,
                        "value":t.name,
                        "selected":selectedTags[t.tagId]?true:false 
                    }
                ));           
                
                if(theTags.length>0){
                    setTags([...theTags,...subCategoriesListMenu])
                }else{
                    setTags(theTags)
                }
                console.log('show tooltip')                            
                setTagsLoading(false)
            },
            (error)=>{
                console.log(error)
                setTagsLoading(false)
            }
        )
    }

    const handleChange = (e)=>{
        const {
            name,
            value
        } = e.target

        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })
    }

    const handleSuggestionModalClose=()=>{
        const {
            suggestion
        } = form
        setForm({
            'suggestion':{
                ...suggestion,
                value:"",
                error:false
            }
        })
        setShowSuggestModal({type:"category",show:false,successMessage:false,errorMessage:false})
    }

    const handleSendSuggestion=(type)=>{
        const {
            suggestion
        } = form

        setForm({
            'suggestion':{
                ...suggestion,
                error:false
            }
        })
        setShowSuggestModal({
            ...showSuggestModal,
            successMessage:false,
            errorMessage:false
        })
        setSendSuggestionLoading(true)
                
                

        if(!formValidateField(suggestion.value,'required')){
            setForm({
                'suggestion':{
                    ...suggestion,
                    error:true
                }
            })
            setSendSuggestionLoading(false)
            return false
        }

        if(type=="category"){
            generalActions.sendSuggestion(form.suggestion.value).then(
                (response)=>{
                    setShowSuggestModal({
                        ...showSuggestModal,
                        show:false,
                        successMessage:false,
                        errorMessage:false
                    })
                    setForm({
                        'suggestion':{
                            ...suggestion,
                            'value':"",
                            error:false
                        }
                    })
                    setMessageModal(t('SUGGESTION_MODAL_success'))
                    setSendSuggestionLoading(false)
                },
                (response)=>{
                    if(response){
                        setShowSuggestModal({
                            ...showSuggestModal,
                            successMessage:false,
                            errorMessage:t("ERROR_"+response.data.codeNumber)
                        })                        
                    }
                    setSendSuggestionLoading(false)
                }
            )
        }else{
            generalActions.sendSubcategorySuggestion(form.suggestion.value,categorySelect.selectedId).then(
                (response)=>{
                    setShowSuggestModal({
                        ...showSuggestModal,
                        show:false,
                        successMessage:false,
                        errorMessage:false
                    })
                    setForm({
                        'suggestion':{
                            ...suggestion,
                            'value':"",
                            error:false
                        }
                    })
                    setMessageModal(t('SUGGESTION_MODAL_success'))
                    setSendSuggestionLoading(false)
                },
                (response)=>{
                    if(response){
                        setShowSuggestModal({
                            ...showSuggestModal,
                            successMessage:false,
                            errorMessage:t("ERROR_"+response.data.codeNumber)
                        })                        
                    }
                    setSendSuggestionLoading(false)
                }
            )
        }

    }

    const handleCategoryClick = (id,value)=>{
        console.log('category click?',id)
        if(id=="suggestion"){
            console.log('why?')
            setShowSuggestModal({
                ...showSuggestModal,
                show:true,
                type:"category"
            })
            return false
        }

        setCategorySelect({
            "isOpen":false,
            "selectedId":id,
            "selectedValue":value
        })

    }

    const handleTagClick = (id,selected,value)=>{
        console.log(`${id} ${selected} ${value}`)
        if(id=="suggestion"){
            console.log('why?')
            setShowSuggestModal({
                ...showSuggestModal,
                show:true,
                type:"subcategory"
            })
            return false
        }
        if(selected && tagsNumber<5){
            setSelectedTags({
                ...selectedTags,
                [id]:value
            })
            setTagsNumber(tagsNumber+1)
        }else{
            console.log('the id',id)                               
            setSelectedTags({
                ...selectedTags,
                [id]:false
            })
            setTagsNumber(tagsNumber-1)
        }
    }

    const handleOk =()=>{
        onOk({
            "tags":Object.keys(selectedTags).filter(
                (v)=>(selectedTags[v])
            ),
            "tagsNames":Object.keys(selectedTags).filter(
                (v)=>(selectedTags[v])
            ).map((v)=>{return selectedTags[v]}).join(', '),
            tagsNumber,
            selectedTags,
            tags,
            categorySelect:{
                ...categorySelect,
                isOpen:false
            }
        })
    }

    // useEffect(() => {
    //     console.log('use effect??',categories)
    //     if(categories&&categories[1]){
    //         getTags()
    //     }
    // },[]);    
    
    useEffect(() => {
        getTags()
        ReactTooltip.hide(tooltipRef.current)
    },[categorySelect.selectedId]);  

    useEffect(() => {
        if(showToolTip){
            if(tooltipRef.current){
                ReactTooltip.show(tooltipRef.current)                
            }   
            setshowToolTip(false)
        }else{
            ReactTooltip.hide(tooltipRef.current)
        }        
        setTags([
            ...tags.map((t)=>({                
                "id":t.id,
                "value":t.value,
                "disabled":t.disabled,
                "selected":selectedTags[t.id]?true:false              
            })),            
        ])        
        setBubbleTags([...Object.keys(selectedTags).filter((tag)=>{
            return selectedTags[tag]!==false
        }).map((newTag)=>{
            return {
                id:newTag,
                value:selectedTags[newTag]
            }
        })])

        console.log([...Object.keys(selectedTags).filter((tag)=>{
            return selectedTags[tag]!==false
        }).map((newTag)=>{
            return {
                id:newTag,
                value:selectedTags[newTag]
            }
        })])

    },[selectedTags]);  

    return (
        <div>
            {/* messages modal */}
            <AZModal
                show={messageModal!==""}
                onClose={()=>{setMessageModal("")}}
            >
                <div className="p-5">
                    <h5 className="text-center text-purple">
                        {messageModal}
                    </h5>
                </div>                
            </AZModal>
            <AZDesicionModal 
                title={showSuggestModal.type=="category"?t('SUGGESTION_MODAL_category_title'):t('SUGGESTION_MODAL_subcategory_title')}
                show={showSuggestModal.show}
                onClose={handleSuggestionModalClose}  
                noOptionText={t('GENERAL_cancel')}
                yesOptionText={t('SUGGESTION_MODAL_send')}
                onYesOption={()=>{handleSendSuggestion(showSuggestModal.type)}}
                onNoOption={handleSuggestionModalClose}
                loading={sendSuggestionLoading}
                loadingText={t('SUGGESTION_MODAL_sending')}              
            >
                {showSuggestModal.show?
                    showSuggestModal.type=="category"?
                    <div className="p-5">
                        <AZField
                            {...form.suggestion}
                            onChange={handleChange}
                        >
                            {t('SUGGESTION_MODAL_category_placeholder_input_message')}
                        </AZField>
                        {
                            showSuggestModal.successMessage&&<p className="text-purple my-2">{showSuggestModal.successMessage}</p>
                        }
                        {
                            showSuggestModal.errorMessage&&<p className="text-danger my-2">{showSuggestModal.errorMessage}</p>
                        }
                    </div>:
                    <div className="p-5">
                        <AZField
                            {...form.suggestion}
                            onChange={handleChange}
                        >
                            {t('SUGGESTION_MODAL_subcategory_placeholder_input_message')}
                        </AZField>
                        {
                            showSuggestModal.successMessage&&<p className="text-purple my-2">{showSuggestModal.successMessage}</p>
                        }
                        {
                            showSuggestModal.errorMessage&&<p className="text-danger my-2">{showSuggestModal.errorMessage}</p>
                        }
                    </div>
                    :<div></div>
                }                
            </AZDesicionModal>
            <div className="row justify-content-between my-4">
                <div className="col-auto">
                    <AZButtonIconLink
                        leftIcon={<i className="fa fa-chevron-left" aria-hidden="true"></i>}
                        style="color"
                        onClick={()=>{onBack()}}
                    >
                        {t('GENERAL_back')}
                    </AZButtonIconLink>    
                </div>                
            </div>
            <div className="row my-2 justify-content-center">
                <div className="col-10 my-1">                    
                    <h2 className='text-center'>{t('BG_SPECIALITIES_title')}</h2>
                </div>

                <div className={`col-md-6 mt-4 ${categories.length == 0?"d-none":""}`}>
                    <AZSelect
                        title={categorySelect.selectedValue!==""?categorySelect.selectedValue:t('BG_SPECIALITIES_select_placeholder')}
                        list={[...categories,...categoriesListMenu]}
                        isListOpen={true}
                        selectedItem={categorySelect.selectedValue}
                        style="color"
                        hideHeader={true}
                        onToggle={()=>{setCategorySelect({...categorySelect,isOpen:!categorySelect.isOpen})}}
                        onItemClick={handleCategoryClick}
                    >
                    </AZSelect>      
                    <p ref={(ref)=>{tooltipRef.current = ref}} data-background-color="#7e42b8" data-border-color="#7e42b8" data-arrow-color="#7e42b8" data-offset="{'top': 50, 'right': 180}" data-place="top" data-tip={t('BG_SPECIALITIES_select_tooltip')}></p>
                    <ReactTooltip />                                    
                </div>  

                <div className="col-md-6">
                    {
                        tagsLoading&&<div className="row justify-content-center my-2">
                            <div className="col-auto text-center">
                                <h3><i className="fa fa-spinner fa-spin"></i></h3>
                            </div>
                        </div>
                    }   
                    <div className="row justify-content-center my-1">
                        <div className={`col-10 my-1 ${tags.length == 0 || tagsLoading ?"d-none":""}`}>
                            <p className='text-center'>{t('NEW_REQUEST_choose_at_least_categories')}</p>
                            <AZSelectMultiple
                                list={tags}
                                hideHeader
                                isListOpen
                                onItemClick={handleTagClick}
                                disabled={tagsLoading}
                            >                        
                            </AZSelectMultiple>
                        </div>
                    </div>
                </div>

                <div className="col-md-auto col-12 my-2">
                    <AZAreaBubble
                        list={bubbleTags}
                        onItemClick={()=>{}}
                        onItemRemove={(id)=>{handleTagClick(id,false,false)}}
                    >                        
                    </AZAreaBubble>
                </div>
                

            </div>                                         

            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <AZButton
                        size="md"
                        disabled={tagsNumber==0}
                        onClick={handleOk}
                    >  
                        {t('BG_SPECIALITIES_button_done')} 
                    </AZButton>
                </div>
            </div>

        </div>
    );
}

export default SelectCategory;
