import React from 'react';
import ArchivedRequestItem from './ArchivedRequestItem';
import "../../pages/index.css"

const ArchivedRequestList = (props) => {
    const {
        list,
        noData,
        itemsLoaded,
        loading,
        onScroll,
        onItemConversationClick,
        hideResponseIcon=false
    } = props
    
    const handlePageScroll = (e) =>{
        console.log("page scroll")
        const element = e.target
        const childHeight =element.offsetHeight;
        const {scrollTop,scrollHeight,offsetHeight} = element        
        if (
            scrollHeight - scrollTop < (offsetHeight+2)            
            && !loading
            ) {
                console.log('on scroll!!');
                onScroll()
        }
    }


    return (
        <div className="az-list az-list--full">
            {loading&&<div className="az-list__loading">
                <i className="fa fa-spinner fa-spin"></i>
            </div>}
            <div className="az-list__content" onScroll={handlePageScroll}>
                {list&&list.length>0?
                    list.map(v=>(
                        <ArchivedRequestItem
                            key={v.requestId}
                            model={v}   
                            hideResponseIcon={hideResponseIcon}
                            onConversationClick={onItemConversationClick}                         
                        >
                        </ArchivedRequestItem>
                    ))
                :<div>
                    {!loading?<h4 className="p-3">{noData}</h4>: <h4></h4>  }                    
                </div>    
                }
            </div>
        </div>
    );
}

export default ArchivedRequestList;

