import React from 'react';

const AZAreaBubbleItem = (props) => {
    const {
        model,
        id,
        value,
        onItemClick,
        onItemRemove
    }= props

    const handleItemRemove=()=>{
        onItemRemove(id)
    }
    const handleItemClick=()=>{

    }

    return (
        <div className='az-area-bubble__content__item'>
            <div className="az-area-bubble__content__item__title"> 
                {value}               
            </div>
            <div className="az-area-bubble__content__item__button" onClick={handleItemRemove}>                
                <i className="fa fa-times"></i>
            </div>
        </div>
    );
}

export default AZAreaBubbleItem;
