import {
    NAV_GURU,
    NAV_SEEKER,
    NAV_SELECT_INDEX,
    NAV_NONE
} from "../actions/types"

const initialState = {
    userAs:"SEEKER",
    selected_index:-1
}

export default (state = initialState, action) => {
const {
    type,
    payload
} = action
//console.log('dispatch logout',type)
switch (type) {     
    case NAV_GURU:
    return {
        ...initialState,
        userAs:"GURU",
        selected_index:payload
    }
    case NAV_SEEKER:
    return {
        ...initialState,
        userAs:"SEEKER",
        selected_index:payload
    }
    case NAV_NONE:
    return {
        ...initialState,
        userAs:"NONE",
        selected_index:payload
    }
    case NAV_SELECT_INDEX:
    return {
        ...state,
        selected_index:payload
    }
    default:
    return state;
}
}