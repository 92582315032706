import C from "../constants"
import axios from "axios"
import authHeader from "./auth-header"

const API_URL = `${C.ws}payments/`

class PaymentsService {
    constructor(abortController=null){    
        if(abortController){
            this.abortController = abortController
        }else{
            this.abortController = new AbortController()
        }
    }

    attachPaymentMethod = (paymentMethodId)=>{ 
        console.log('on service',paymentMethodId)       
        return axios
        .post(`${API_URL}attach-payment-method`,
        {
            paymentMethodId
        },
        {
            signal:this.abortController.signal,
            headers:{
                ...authHeader()
            }
        })
        .then((response) => {
            return response
        })
    }
    detachPaymentMethod = (paymentMethodId) => {
        return axios
        .post(`${API_URL}detach-payment-method`,
        {
            paymentMethodId
        },{
            signal:this.abortController.signal,
            headers:{
                ...authHeader()
            }
        })
        .then((response) => {
            return response
        })        
    }

    getLastPayment = (idGuru) => {
        return axios
        .get(`${API_URL}get-last-payment-to-user`,
        {
            params:{
                idGuru
            },
            signal:this.abortController.signal,
            headers:authHeader() 
        })
        .then((response) => {
            return response
        })
    }

    getStripeAccount = () => {
        return axios
        .get(`${API_URL}get-stripe-account`,
        {            
            signal:this.abortController.signal,
            headers:authHeader() 
        })
        .then((response) => {
            return response
        })
    }

    getPaymentMethods = () => {
        return axios
        .get(`${API_URL}get-payment-methods`,
        {            
            signal:this.abortController.signal,
            headers:authHeader() 
        })
        .then((response) => {
            return response
        })
    }
    
    getTipsGiven = (loadedItems,numberOfRows) => {
        return axios
        .get(`${API_URL}get-tips-given`,
        {        
            params:{
                loadedItems,
                numberOfRows
            }, 
            signal:this.abortController.signal,
            headers:authHeader() 
        })
        .then((response) => {
            return response
        })
    }
    
    getTipsReceived = (loadedItems,numberOfRows) => {
        return axios
        .get(`${API_URL}get-tips-received`,
        {        
            params:{
                loadedItems,
                numberOfRows
            }, 
            signal:this.abortController.signal,
            headers:authHeader() 
        })
        .then((response) => {
            return response
        })
    }
    
    getOnboardingLink = () => {
        return axios
        .get(`${API_URL}get-onboarding-link`,
        {                   
            signal:this.abortController.signal,
            headers:authHeader() 
        })
        .then((response) => {
            return response
        })
    }

    sendPayment = (
        paymentMethodId,
        amount,
        idGuru,
        idHelpRequest=undefined,
        idMessageToPay=undefined,
        idConversation=undefined
    )=>{        
        return axios
        .post(`${API_URL}send-payment`,
        {
            paymentMethodId,
            amount,
            idGuru,
            idHelpRequest,
            idMessageToPay,
            idConversation
        },
        {
            signal:this.abortController.signal,
            headers:{
                ...authHeader()
            }
        })
        .then((response) => {
            return response
        })
    }

    setDefaultPaymentMethod = (paymentMethodId) => {
        return axios
        .post(`${API_URL}set-default-payment-method`,
        {
            paymentMethodId
        },
        {
            signal:this.abortController.signal,
            headers:{
                ...authHeader()
            }
        }).then(
            (response) => {
                return response
            }
        )
    }
}

export default PaymentsService