import React from 'react';

import AZGuruBadge from './AZGuruBadge';

const AZProfileGuru = (props) => {
    const {
        guruLevel,
        isIndependent,
        size
    } = props

    const renderBadges = (guruLevel,isIndependent)=>{
        switch (guruLevel) {
            case 0:
                return <div className="az-profile-guru__content">                    
                    <AZGuruBadge  
                         guruLevel={guruLevel}
                         isIndependent={isIndependent}
                         size={size}                         
                    />
                </div>                    
            case 1:
               return <div className="az-profile-guru__content">
                    <AZGuruBadge  
                         guruLevel={guruLevel}
                         isIndependent={isIndependent}
                         size={size}                         
                    />
                </div>                    
            case 2:
               return <div className="az-profile-guru__content">
                   <AZGuruBadge  
                         guruLevel={guruLevel}
                         isIndependent={isIndependent}
                         size={size}                         
                    />
                   <AZGuruBadge  
                         guruLevel={guruLevel}
                         isIndependent={isIndependent}
                         size={size}                         
                    />
                </div>                    
            case 3:
               return <div className="az-profile-guru__content">
                   <AZGuruBadge  
                         guruLevel={guruLevel}
                         isIndependent={isIndependent}
                         size={size}   
                         top                      
                    />
                    <AZGuruBadge  
                         guruLevel={guruLevel}
                         isIndependent={isIndependent}
                         size={size}                         
                    />
                    <AZGuruBadge  
                         guruLevel={guruLevel}
                         isIndependent={isIndependent}
                         size={size}  
                         top                       
                    />
                </div>                    
            default:
                break;
        }
    }

    return (
        <div className='az-profile-guru'>
            {renderBadges(guruLevel,isIndependent)}
        </div>
    );
};



export default AZProfileGuru;
