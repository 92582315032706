import CreateAccountForm from "../sign/CreateAccountForm"
import VerificateForm from "../sign/VerificateForm"
import ProfileSimpleForm from "../sign/ProfileSimpleForm"
import AZModal from "../modal/AZModal"
import AZModalTitle from "../modal/AZModalTitle"

import { connect } from "react-redux"

import { getAuth, PhoneAuthProvider,signInWithCredential } from "@firebase/auth";

import { getMessaging,getToken } from "@firebase/messaging";
import { getApp } from "@firebase/app";

import * as generalActions from "../../actions/general.actions"
import * as authActions from "../../actions/auth.actions"


import {formValidateField} from "../../helpers/formvalidation"

import { useEffect,useState } from "react"
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom"

import RP from "../../helpers/routePath"
import { checkNotificationPromise, isNotificationsSupported, apiHasNetworkError} from '../../helpers/helpers'


const RegisterSecuence = (props) => {
    const {
        dispatch,        
        loading,
        show,
        onClose,
        auth
    } = props

    const history = useHistory()

    const { t, i18n } = useTranslation();

    const [step,setStep] = useState(0)
    const [secuenceLoading,setSecuenceLoading] = useState(false)
    const [profile,setProfile] = useState({
        "so": "W"
    })
    const [phoneData, setPhoneData] = useState({
        iso:"",
        phoneCode:"",
        phoneCodeId:""
    });
    const [formErrors, setFormErrors] = useState({});
    const [verificationId, setVerificationId] = useState("");
    const [device, setDevice] = useState("");
    const [lang, setLang] = useState("");

    const handleClose = (step)=>{
        console.log('handleClose')
        if(step==0){
            onClose()
        }else{
            setStep(step - 1)
        }
    }    
    const handleVerifyPhoneNumber = (phoneNumber,appVerifier)=>{
        const provider = new PhoneAuthProvider(getAuth());
        return provider.verifyPhoneNumber(phoneNumber, appVerifier);
    }
    const handleSubmit = (step) =>{
        switch (step) {
            case 0:
            return (params,verifier,onError)=>{    
                const {
                    name,
                    lastName,
                    email,
                    password,
                    codeNumberId,
                    phoneNumber
                }= params         
                setSecuenceLoading(true)  
                console.log(params) 
                let errors = {}
                let validForm = true;

                if(!formValidateField(name.value,'required')){
                    validForm = false
                    errors['name'] = {
                        ...name,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_name"
                    }
                }             
                if(!formValidateField(lastName.value,'required')){
                    validForm = false
                    errors['lastName'] = {
                        ...lastName,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_lastname"
                    }
                }                
                if(!formValidateField(email.value,'required')){
                    validForm = false
                    errors['email'] = {
                        ...email,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_email"
                    }
                }                
                if(!formValidateField(email.value,'email')){
                    validForm = false
                    errors['email'] = {
                        ...email,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_email"
                    }
                }  
                if(!formValidateField(password.value,'password')){
                    validForm = false
                    errors['password'] = {
                        ...password,
                        error:true,
                        errorText:"FORM_ERROR_PASSWORD_password"
                    }
                }  

                if(!formValidateField(password.value,'required')){
                    validForm = false
                    errors['password'] = {
                        ...password,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_password"
                    }
                }                
                              
                if(!formValidateField(codeNumberId.value,'required')){
                    validForm = false
                    errors['codeNumberId'] = {
                        ...codeNumberId,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_phone_code"
                    }
                }                
                if(!formValidateField(phoneNumber.value,'required')){
                    validForm = false
                    errors['phoneNumber'] = {
                        ...phoneNumber,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_phone_number"
                    }
                }
                                 
                if(validForm){  
                    authActions.checkEmailAvaiable(email.value).then(()=>{
                            let phoneNumberToVerify = `+${phoneData.phoneCode}${phoneNumber.value}`
                            
                            setProfile({
                                ...profile,
                                name:name.value,
                                lastName:lastName.value,
                                email:email.value,
                                password:password.value,
                                phoneCodeId:codeNumberId.value,
                                phoneNumber:phoneNumber.value,
                                phoneNumberToVerify
                            })                                
                            
                            console.log(`verify ${phoneNumberToVerify}`)
                            handleVerifyPhoneNumber(phoneNumberToVerify,verifier).then((verification)=>{
                                console.log("Verification")
                                console.log(verification)
                                setVerificationId(verification)
                                setSecuenceLoading(false)
                                setStep(step + 1)
                            },(error)=>{
                                console.log("errors",error)
                                console.log("errors code",error.code)
                                let errormessage = "AUTH_too_many_tries"
                                if(error.code == "auth/invalid-phone-number"){
                                    errormessage = "AUTH_invalid_phone"
                                }
                                errors['phoneNumber']={
                                    ...phoneNumber,                                
                                    error:true,
                                    errorText:errormessage                               
                                }
                                setSecuenceLoading(false)
                                onError(errors)
                            })
                            
                        },()=>{
                            errors['email']={
                                ...email,                                
                                error:true,
                                errorText:"ERROR_0222"                                
                            }
                            setSecuenceLoading(false)
                            onError(errors)
                        }
                    ) 
                }else{
                    setSecuenceLoading(false)  
                    onError(errors)
                }    
                // const handleVerifyPhoneNumber = (phoneNumber,appVerifier)=>{
                //     const provider = new PhoneAuthProvider(getAuth());
                //     return provider.verifyPhoneNumber(phoneNumber, appVerifier);
                // }
                
            }        
            case 1:
            return (params,onError)=>{
                const validateCode = async (verificationId,verificationCode) => {                    
                    const credential = PhoneAuthProvider.credential(
                        verificationId,
                        verificationCode
                    );
                    return signInWithCredential(getAuth(),credential)
                }

                setSecuenceLoading(true)
                let errors = {}
                let validForm = true;

                const {
                    verificationCode
                } = params
                console.log(verificationCode)
              
                if(!formValidateField(verificationCode.value,'required')){
                    validForm = false
                    errors['verificationCode'] = {
                        ...verificationCode,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_verification_code"
                    }
                }
                if(validForm){
                    validateCode(verificationId,verificationCode.value).then(()=>{
                        setSecuenceLoading(false)                              
                        setStep(step + 1)
                    },(error)=>{         
                        console.log('verification error')
                        console.log(error)

                        if (error.code == 'auth/invalid-verification-code') {
                            errors['verificationCode']={
                                ...verificationCode,                                
                                error:true,
                                errorText:"AUTH_invalid_code"                                
                            }
                            onError(errors)                            
                        }else if (error.code == 'auth/code-expired' || error.code == 'auth/invalid-session-cookie-duration') {
                            errors['verificationCode']={
                                ...verificationCode,                                
                                error:true,
                                errorText:"AUTH_invalid_code_expired"                                
                            }
                            onError(errors)
                        } else {
                            errors['verificationCode']={
                                ...verificationCode,                                
                                error:true,
                                errorText:"AUTH_error_sending"                                
                            }
                            onError(errors)
                        }     
                        setSecuenceLoading(false)                     
                    });                    
                }else{
                    onError(errors)
                    setSecuenceLoading(false)  
                }
                                
            }        
            case 2:
            return async (params,onError)=>{
                const {                   
                    image,
                    profileDescription,
                    languages,
                }= params  
                console.log(dispatch)              
                setSecuenceLoading(true)
                let validForm = true;
                let errors = {}

                if(!formValidateField(image.value,'required')){
                    validForm = false
                    errors['image'] = {
                        ...image,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_image"
                    }
                }
                if(!formValidateField(profileDescription.value,'required')){
                    validForm = false
                    errors['profileDescription'] = {
                        ...profileDescription,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_profile_description"
                    }
                }
                if(!formValidateField(languages.value,'required')){
                    validForm = false
                    errors['languages'] = {
                        ...languages,
                        error:true,
                        errorText:"FORM_ERROR_REQUIRED_languages"
                    }
                }

                if(validForm){   
                    // setProfile({
                    //     ...profile,
                    //     languages:languages.value,
                    //     profileDescription:profileDescription.value,
                    //     image:image.img
                    // })
                    const handleNotificationRequest = async (permission)=>{
                        var sendProfile = {
                            ...profile,
                            "languages":languages.value,
                            "profileDescription":profileDescription.value,                            
                            "image":image.img
                        }
                        //let permission =  await Notification.requestPermission();
                        if (permission !== "granted") {
                            // onError({
                            //     data:{
                            //         codeNumber:"notifications"
                            //     }
                            // },errors)
                            // setSecuenceLoading(false)
                            // Notification.requestPermission()                            
                        }else{
                            //TODO:LEGR this code must have a better testing disabled for now until receive push notifications
                            //const token = await getToken(getMessaging(getApp()))                        
                            // sendProfile = {
                            //     ...sendProfile,
                            //     "device":token
                            // }                            

                        }
                        
                        setProfile({
                            ...sendProfile
                        })                      

                        dispatch(authActions.createUser({
                            ...sendProfile,                                                                                  
                        })).then(
                            (data)=>{                                    
                                console.log("createSuccess")
                                console.log(data)
                                history.push(RP.getPath(t,RP.BASE))
                                //setStep(0)
                                //setSecuenceLoading(false)
                                //Go to dashboard
                            },
                            (error)=>{
                                console.log(error)
                                setSecuenceLoading(false)
                                if(apiHasNetworkError(error)){
                                    errors['profileDescription'] = {
                                        ...profileDescription,
                                        error:true,
                                        errorText:"ERROR_internet"
                                    }
                                    onError(null,errors)
                                }else{
                                    onError(error,errors)
                                }                                          
                                
                            }                               
                        )
                    }
                    
                    try {
                        //const hasRequestPromise = checkNotificationPromise()

                        /*if(hasRequestPromise){
                            let permission =  await Notification.requestPermission();
                            handleNotificationRequest(permission)
                        }else{
                            if(isNotificationsSupported()){
                                Notification.requestPermission((permission)=>{
                                    handleNotificationRequest(permission)
                                })
                            }else{
                                handleNotificationRequest('')
                            }
                        }*/
                        var sendProfile = {
                            ...profile,
                            "languages":languages.value,
                            "profileDescription":profileDescription.value,                            
                            "image":image.img
                        }
                        //let permission =  await Notification.requestPermission();
                        /*
                        if (permission !== "granted") {
                            // onError({
                            //     data:{
                            //         codeNumber:"notifications"
                            //     }
                            // },errors)
                            // setSecuenceLoading(false)
                            // Notification.requestPermission()                            
                        }else{
                            //TODO:LEGR this code must have a better testing disabled for now until receive push notifications
                            //const token = await getToken(getMessaging(getApp()))                        
                            // sendProfile = {
                            //     ...sendProfile,
                            //     "device":token
                            // }                            

                        }
                        */
                        
                        setProfile({
                            ...sendProfile
                        })                      

                        dispatch(authActions.createUser({
                            ...sendProfile,                                                                                  
                        })).then(
                            (data)=>{                                    
                                console.log("createSuccess")
                                console.log(data)
                                history.push(RP.getPath(t,RP.BASE))
                                //setStep(0)
                                //setSecuenceLoading(false)
                                //Go to dashboard
                            },
                            (error)=>{
                                console.log(error)
                                setSecuenceLoading(false)
                                if(apiHasNetworkError(error)){
                                    errors['profileDescription'] = {
                                        ...profileDescription,
                                        error:true,
                                        errorText:"ERROR_internet"
                                    }
                                    onError(null,errors)
                                }else{
                                    onError(error,errors)
                                }                                          
                                
                            }                               
                        )
                        

                    } catch (error) {
                        setSecuenceLoading(false)
                        console.log(error)
                        onError(null,errors)
                    }                      
                }else{
                    setSecuenceLoading(false)
                    onError(null,errors)
                }
                
            }                            
            default:
                break;
        }
    }

    const handleChange = (name,value)=>{        
        setProfile((state)=>({
            ...state,
            [name]:value
        }))
    }

    const componentSecuence = (step) =>{
        console.log("parse:"+parseInt(step))
        switch (parseInt(step)) {
            case 0:
                return <AZModal 
                        onClose={()=>{handleClose(step)}}                        
                        show={show}
                    >
                        <CreateAccountForm 
                            onSubmit={handleSubmit(step)} 
                            onChangePhoneData={(pData)=>{console.log("cb",pData);console.log(phoneData); setPhoneData(pData)}}
                            onChange={handleChange}
                            loading={secuenceLoading}
                            profile={profile}
                            phoneData={phoneData}
                            errors = {formErrors}
                        >
                        </CreateAccountForm>   
                    </AZModal>      
                            
            case 1:
                return <AZModalTitle 
                            onClose={()=>{handleClose(step)}} 
                            show={show}                                                      
                            title={t('CREATE_ACCOUNT_title_confirmation_phone_modal')}
                        >
                            <VerificateForm
                                onSubmit={handleSubmit(step)} 
                                phoneData={phoneData}
                                profile={profile}
                                onResendCode={(phone,verifier,onFinish)=>{
                                    handleVerifyPhoneNumber(profile.phoneNumberToVerify,verifier).then((verification)=>{
                                        console.log("Verification")
                                        console.log(verification)
                                        setVerificationId(verification)  
                                        onFinish(true)                                                                       
                                    },(error)=>{
                                        console.log(error)                                        
                                        onFinish(false,error)
                                    })                                    
                                }}
                                loading={secuenceLoading}
                                verificationId={verificationId}
                            >
                            </VerificateForm>                
                        </AZModalTitle>
                break;                         
            case 2:                
                return  <AZModalTitle 
                            onClose={()=>{handleClose(step)}} 
                            show={show}
                            phoneData={phoneData}
                            profile={profile}                            
                            title={t('CREATE_ACCOUNT_title_profile_settings_modal')}                            
                        >
                            <ProfileSimpleForm
                                onSubmit={handleSubmit(step)} 
                                profile={profile}
                                loading={secuenceLoading}
                                errors = {formErrors}                                
                            >
                            </ProfileSimpleForm>                        
                        </AZModalTitle>
                
            default:
                (
                    <div>
                        {step}
                    </div>   
                )                                
        }
    }

    useEffect(() => {   
        setFormErrors({})        
    }, [step]);

    
    useEffect(() => {             
        setLang(i18n.language.substr(0,2))
        setProfile({
            ...profile,
            lang:i18n.language.substr(0,2)
        })
        console.log('auth',auth)
    }, []);

    return (
        <div>   
            {
                console.log("the step is"+step)
            }                   
            {
                
                show?                    
                componentSecuence(step):
                <div></div>
            }            
        </div>
    );
}
function mapStateToProps(state) {
    console.log(state)
    const {auth} = state
    return {   
        auth
    };
}

export default connect(mapStateToProps)(RegisterSecuence)

