import React,{ useState } from 'react';
import AZButtonLink from '../form/AZButtonLink';
import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';

import C from '../../constants'


const PaymentsMethodsItems = (props) => {
    const {
        model,        
        onItemClick,
        isIddle=false,
        selectionMode,
        error
    } = props

    const {
        id,
        brand,
        expMonth,
        expYear,
        last4,
        isDefault,
        status,
    } = isIddle?{}:model

    const {t,i18n} = useTranslation()


    const handleItemClick = (e) => { 
        if(onItemClick){
            onItemClick(model)    
        }
    }

    const renderCardSrc = (brand) => {
        let editedBrand = brand.replace(/\s/g, '').toLowerCase();
        return `${C.resources.webpage}app/cards/${editedBrand}.png`;        
    }
   
    return (  
        
        <div className={`az-list__content__item az-list__content__item--payment-method ${isIddle&&"az-list__content__item--payment-method-add"} ${error&&"az-list__content__item--payment-method-add--error"} align-items-center`} onClick={handleItemClick}>
            {
                isIddle
                ?
                <div className='col-12 row justify-content-center align-items-center'>                    
                    <div className="col-auto">
                        <h3 className="text-purple">
                            <i className="fa fa-plus"></i>
                        </h3>
                    </div>
                    <div className="col text-start">
                        <h4 className='text-purple'>{t('PAYMENTS_add_payment_method')}</h4>
                        <h4 className='text-gray'>{t('PAYMENTS_credit_debit_card')}</h4>
                    </div>
                </div>
                :
                <div className='col-12 row justify-content-between'>
                    <div className="col-auto row">
                        <div className="col-auto">
                            <div className="payment-method__card ms-4">
                                <img src={renderCardSrc(brand)} alt="card brand image" />
                            </div>
                        </div>
                        <div className="col-auto">
                            <h4>
                                • • • • {last4}
                            </h4>
                        </div>
                        {
                            status > 1&&
                            <div className="col-auto">
                                <h5 className='ms-3 text-danger'>
                                    {
                                        status==2?
                                        t('PAYMENTS_card_expired')
                                        :
                                        t('PAYMENTS_card_not_valid')                                
                                    }
                                </h5>                        
                            </div>
                        }
                    </div>
                    <div className="col-auto row justify-content-center text-end">
                        {selectionMode ? (
                                isDefault && (
                                    <h4 className="me-3 text-purple">
                                        <i className="fa fa-check">
                                        </i>
                                    </h4>
                                )
                            ) : (
                                <h4 className="me-3 text-muted">
                                    <i className="fa fa-chevron-right">
                                    </i>
                                </h4>                               
                            )}
                    </div>
                </div>
            }
        </div>                          
    );
}

export default PaymentsMethodsItems;
