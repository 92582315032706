import {
    GURU_CONVERSATIONS_ADD,
    GURU_CONVERSATIONS_SET,
    GURU_CONVERSATIONS_UPDATE_MARK,    
    GURU_CONVERSATIONS_UPDATE,
    GURU_CONVERSATIONS_CLEAN,    
} from "../actions/types"

const initialState = []

export default (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    
    //console.log('dispatch logout',type)
    switch (type) {     
        case GURU_CONVERSATIONS_SET:
        return payload
        case GURU_CONVERSATIONS_ADD:
        return [
            payload,
            ...state,
        ]
        case GURU_CONVERSATIONS_UPDATE:
            let {                
                conversation
            }= payload
            //TODO LEGR: remove this logs
            let updatedList = []
            console.log('GURU_CONVERSATIONS_UPDATE state',state)
            console.log('GURU_CONVERSATIONS_UPDATE payload',payload)
            state.map(c=>{
                if(c.idConversation == payload.conversationId || c.conversationId == payload.conversationId){
                    updatedList.push({
                        ...c,
                        ...conversation
                    })
                }else{
                    updatedList.push(c)
                }
            })
            console.log('GURU_CONVERSATIONS_UPDATE result',updatedList)
            return [...updatedList]
        case GURU_CONVERSATIONS_UPDATE_MARK:
            let {
                conversationId,
                isMarked
            }= payload
            //TODO LEGR: remove this logs
            let newList = []
            console.log('GURU_CONVERSATIONS_UPDATE_MARK state',state)
            console.log('GURU_CONVERSATIONS_UPDATE_MARK payload',payload)
            state.map(c=>{
                if(c.idConversation == conversationId || c.conversationId == conversationId){
                    newList.push({
                        ...c,
                        'markedForRating':isMarked
                    })
                }else{
                    newList.push(c)
                }
            })
            console.log('GURU_CONVERSATIONS_UPDATE_MARK result',newList)
            return [...newList]
        case GURU_CONVERSATIONS_CLEAN:            
        return initialState    
        default:
        return state;
    }
}