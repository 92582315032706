import React,{useEffect,useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import RP from '../../helpers/routePath'

import ChangePasswordWebForm from '../../components/profile/ChangePasswordWebForm';

import c from '../../constants'

import { useHistory } from 'react-router-dom';

import * as generalActions from '../../actions/general.actions'
import AZButtonLink from '../../components/form/AZButtonLink';



const RecoverPassword = (props) => {

    const {
        match,
        dispatch
    }=props

    const {
        t
    } = useTranslation()
    const history = useHistory()

    const [loading, setLoading] = useState(true);
    const [validToken, setValidToken] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showHomeButton, setShowHomeButton] = useState(false); 

    const handleValidateToken = ()=>{        
        if(match.params.id!==undefined && match.params.code !== undefined){
            setLoading(true)
            dispatch(
                generalActions.validateTokenForRecovery(
                    match.params.id,
                    match.params.code,  
                    c.AZ_KEY                  
                )
            ).then(
                (response) => {
                    setValidToken(true)
                    setLoading(false)
                    console.log(response)
                },
                (response) => {
                    console.log(response)
                    setValidToken(false)
                    setLoading(false)
                }
            )
        }else{
            setLoading(false)            
        }

    }      

    const handleLoginSubmit = (form, onSuccess, onError)=>{
        const {
            password            
        } = form

        setSubmitLoading(true)

        dispatch(
            generalActions.updatePasswordWeb(
                match.params.id,
                match.params.code,
                c.AZ_KEY,
                password.value
            )
        ).then(
            (response) => {
                onSuccess()
                setSubmitLoading(false)
                setShowHomeButton(true)
            },
            (response) => {
                onError(response)
                setSubmitLoading(false)
                setShowHomeButton(false)
            }
        )
    }

    useEffect(() => {
        handleValidateToken()
    }, []);

    return (
        <div>
            <div className="container bg-transparent">
                <div className="row justify-content-center justify-content-center">
                    <div className="col-md-6 col-lg-4">
                        <div className="az-container bg-white p-5 my-3">
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <div className="az-logo">
                                        <img src={`${c.resources.webpage}logoHide.svg`} alt="" />
                                    </div>                    
                                </div>
                                <div className="col-12 my-2">

                                </div>
                                <div className="col-12">
                                    {
                                        loading?
                                            <h1 className="text-center"><i className="fa fa-spinner fa-spin"></i></h1>
                                        : validToken?
                                        <div>
                                            <h3 className="text-center">
                                                {t('AUTH_recovery_password_title')}
                                            </h3>
                                            <ChangePasswordWebForm
                                                loading={submitLoading}
                                                onSubmit={handleLoginSubmit}
                                                
                                            >                                            
                                            </ChangePasswordWebForm> 
                                            {
                                                showHomeButton&&
                                                <div className="row justify-content-center">
                                                    <div className="col-auto">
                                                        <AZButtonLink
                                                            onClick={()=>{history.push(RP.getPath(t,RP.BASE))}}
                                                            style="color"
                                                            type="button"
                                                            underline
                                                        >
                                                            {t('NAVIGATION_HOME')}
                                                        </AZButtonLink>
                                                    </div>
                                                </div>
                                            }
                                        </div> 
                                        :<h2 className="text-purple text-center">{t('AUTH_recovery_password_token_fail')}</h2>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    const {

    }= state

    return {

    }
}

export default connect(mapStateToProps)(RecoverPassword);
