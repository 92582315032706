import { createStore,applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/index";

//This middleware works to make the actions return functions avaiables
const middleware = [thunk];

const store = createStore(
  rootReducer, 
  applyMiddleware(...middleware)
);

export default store;