import React from 'react';
import ConversationsItem from './ConversationsItem';
import "../../pages/index.css"

const ConversationsList = (props) => {
    const {
        list,
        noData,
        itemsLoaded,
        loading,
        onScroll,        
        onOptionsClick,
        onSendMessage,
        onViewReviews,
        onItemClick,
        onReorganizeConversations
    } = props
    
    const handlePageScroll = (e) =>{
        console.log("page scroll")
        const element = e.target
        const childHeight =element.offsetHeight;
        const {scrollTop,scrollHeight,offsetHeight} = element        
        if (
            scrollHeight - scrollTop < (offsetHeight+2)            
            && !loading
            ) {
                console.log('on scroll!!');
                onScroll()
        }
    }


    return (
        <div className="az-list az-list--inner">
            {loading&&<div className="az-list__loading">
                <i className="fa fa-spinner fa-spin"></i>
            </div>}
            <div className="az-list__content" onScroll={handlePageScroll}>
                {list&&list.length>0?
                    list.map(v=>(
                        <ConversationsItem
                            key={`${v.idConversation} ${v.idRequest}`}
                            model={v}
                            onViewReviews = {onViewReviews}
                            onItemClick = {onItemClick}
                            onOptionsClick = {onOptionsClick}    
                            onReorganizeConversations={onReorganizeConversations}                        
                        >
                        </ConversationsItem>
                    ))
                :<div>
                    {!loading?<h4 className="p-3">{noData}</h4>: <h4></h4>  }                    
                </div>    
                }
            </div>
        </div>
    );
}

export default ConversationsList;

