import React from 'react';
import c from '../../constants'

import * as generalActions from '../../actions/general.actions'
import { useState,useEffect,useRef } from 'react';

import AZProfileGuru from './AZProfileGuru';

import "./index.css"

const AZProfileImage = (props) => {
    const {
        user=false,
        bordered=false,
        size='sm',
        type,
        onClick,
        children
    }= props

    const {
        isAGuru,
        guruLevel=0,
        isIndependent=false
    } = user

    const abortC = useRef(null)

    const [loading, setLoading] = useState(true);
    const [src, setSrc] = useState(`${c.resources.webpage}app/avatar.png`);
    useEffect(() => {
        if(user.imageSufix>0){                        
            getImage(3)
        }else{
            setLoading(false)
        }
        return ()=>{
            if(abortC.current){
                abortC.current.abort()
            }
        }
    }, []);

    useEffect(() => {
        setLoading(true)
        getImage(3)
    }, [user.imageSufix]);

    const getImage = (tries)=>{
        console.log('image ac?',abortC.current)
        if(abortC.current == null){
            abortC.current = new AbortController()
        }
        console.log('image ac?',abortC.current)
        generalActions.getImage(`${c.resources.profile}${user.idUser}-${user.imageSufix>0?user.imageSufix:0}.jpg`,abortC.current).then(
            (response)=>{                
                setSrc(response)
                setLoading(false)
            },
            (error)=>{   
                console.log('imageError',error)             
                if(tries>0){
                    setTimeout(() => {
                        getImage(--tries)
                    }, 5000);
                }else{
                    setLoading(false)
                }                 
            }
        )
    }   
    return (
        <div className={`az-profile-image ${bordered?"az-profile-image--bordered":""} ${size!==""?"az-profile-image--"+size:""}`} onClick={(e)=>{onClick()}}>
            <div className="az-profile-image__image">
                {
                    loading?<div className="az-profile-image__image__loading">
                        <h1 className="text-center"><i className="fa fa-spinner fa-spin"></i></h1>
                    </div>
                    :<img src={src} alt="profileimage" />
                }
            </div>            
            {isAGuru&&
                <AZProfileGuru 
                    guruLevel={guruLevel}
                    isIndependent={isIndependent}
                    size={size}
                />
            }
        </div>
    );
}

export default AZProfileImage;
