import React from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import RP from '../../helpers/routePath';

import C from "../../constants"

import AZTutorial from '../../components/secuence/AZTutorial';

import * as navActions from '../../actions/navbar.actions'

const SeekerTutorial = (props) => {
    const {
        dispatch
    } = props
    const history = useHistory()
    const {t} = useTranslation()

    const tutorialElements = [
        {
            image:`${C.resources.webpage}app/tuto/${t('INTRO_slide_image_prefix')}/image0.jpg`,
            title:t('INTRO_slide0_title'),
            description:t('INTRO_slide0_text')
        },        
        {
            image:`${C.resources.webpage}app/tuto/${t('INTRO_slide_image_prefix')}/image1.jpg`,
            title:t('INTRO_slide1_title'),
            description:t('INTRO_slide1_text')
        },        
        {
            image:`${C.resources.webpage}app/tuto/${t('INTRO_slide_image_prefix')}/image2.jpg`,
            title:t('INTRO_slide2_title'),
            description:t('INTRO_slide2_text')
        },        
        {
            image:`${C.resources.webpage}app/tuto/${t('INTRO_slide_image_prefix')}/image3.jpg`,
            title:t('INTRO_slide3_title'),
            description:t('INTRO_slide3_text')
        },        
        {
            image:`${C.resources.webpage}app/tuto/${t('INTRO_slide_image_prefix')}/image4.jpg`,
            title:t('INTRO_slide4_title'),
            description:t('INTRO_slide4_text')
        },        
        {
            image:`${C.resources.webpage}app/tuto/${t('INTRO_slide_image_prefix')}/image5.jpg`,
            title:t('INTRO_slide5_title'),
            description:t('INTRO_slide5_text')
        },    
    ]

    const [tutorialStep, setTutorialStep] = useState(0);
    const [tutorialFinish, setTutorialFinish] = useState(true);

    useEffect(() => {
        dispatch(navActions.selectIndex(4))
    }, []);

    return (
        <div>  
            <div className="container-lg py-1 bg-transparent">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-8 col-md-6 col-container">             
                        <AZTutorial
                            elements={tutorialElements}
                            step={tutorialStep}
                            onBack={()=>{setTutorialStep(tutorialStep-1)}}
                            onNext={()=>{console.log('next');setTutorialStep(tutorialStep+1)}}
                            onFinish={()=>{history.push(RP.getPath(t,RP.BASE))}}
                            nextText={tutorialStep==0?t('INTRO_button_start'):t('INTRO_button_continue_slide')}
                            finishText={`${t('INTRO_button_ok')}`}
                            onDotClick={(index)=>{console.log('dot');setTutorialStep(index)}}
                        >
                        </AZTutorial>       
                    </div>
                </div>
            </div>                              
        </div>
    );
}

const mapStatetoProps = (state) => {
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStatetoProps)(SeekerTutorial);
