import {
    LOGOUT_WITH_MESSAGE
} from "../actions/types"

export const showFormattedTags=(tags)=>{
    let newTags = []
    tags.map((tag)=>{
        if(newTags[tag.category]){
            newTags[tag.category]=[
                ...newTags[tag.category],
                tag.tag
            ]
        }else{
            newTags[tag.category]=[
                tag.tag
            ]
        }
    });

    let returnString = Object.keys(newTags).map((tag)=>{
        return `${tag} : ${newTags[tag].join(' , ')}`
    }).join(' • ')                             

    return returnString;
}

export const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch(e) {
      return false;
    }
    return true;
}
export const isNotificationsSupported = ()=>{
   return 'Notification' in window &&
   'serviceWorker' in navigator &&
   'PushManager' in window 
}

export const apiHasNetworkError = (error) =>{
    console.log('call error',error)
    console.log(error === "Error: Network Error")
    return error.toJSON().message === 'Network Error'||error === "Error: Network Error"
}

export const apiErrorHandler = (error,dispatch) =>{
    const {
        status=409
    } = error
    console.log('handleError',error)
    switch (status) {
        case 401:
            dispatch({
                type:LOGOUT_WITH_MESSAGE,                
            })
            return false            
        default:
            return true
    }
}

export const linkify=(inputText) => {
    var replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    //Check for breaks lines
    //replacedText.replace(/(?:\r\n|\r|\n)/g, '<br>')

    return replacedText.trim();
}