import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18n from './helpers/i18n'
import reportWebVitals from './reportWebVitals';
import store from './helpers/store'
import C from './constants'
import { initializeApp } from "@firebase/app";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js'

initializeApp(C.firebase);
const stripePromise = loadStripe(C.stripe.publishableKey)

ReactDOM.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
            <Router>
              <Elements stripe={stripePromise}>
                <App />
              </Elements>
            </Router>        
      </I18nextProvider>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
