import C from "../constants"
import axios from "axios";
import authHeader from './auth-header';

const API_URL = C.ws + "guru/";
const API_URL_SEEKER = C.ws + "seeker/";

class GuruService{

    createGuruProfile(guruData){
        return axios
        .post(API_URL + "create-or-update-profile",
        {
            ...guruData
        }
        ,{
            headers:authHeader()
        })
        .then((response) =>{          
          return response;
        })
    }

    getConversations(loadedElements,numberOfRows){
        return axios
        .get(API_URL + "get-conversations",{
            headers:authHeader(),
            params:{
                loadedElements,
                numberOfRows
            }
        })
        .then((response) =>{                      
          return response;
        })
    }
    
    getRequests(idLastRequest,numberOfRows){
        return axios
        .get(API_URL + "get-requests",{
            headers:authHeader(),
            params:{
                idLastRequest,
                numberOfRows
            }
        })
        .then((response) =>{                      
          return response;
        })
    }

    getArchivedRequests(loadedElements,numberOfRows){
        return axios
        .get(API_URL + "get-archived-requests",{
            headers:authHeader(),
            params:{
                loadedElements,
                numberOfRows
            }
        })
        .then((response) =>{                      
          return response;
        })
    }

    sendInitialMessage(
        requestId,
        idGuru,
        message
    ){
        return axios
        .post(API_URL_SEEKER + "send-initial-message",
        {
            requestId,
            idGuru,
            message
        }
        ,{
            headers:authHeader(),            
        })
        .then((response) =>{                      
          return response;
        })
    }  

    sendProposalToSeeker(
        requestId,        
        message,
        amount
    ){
        return axios
        .post(API_URL + "send-a-proposal",
        {
            requestId,            
            message,
            amount
        }
        ,{
            headers:authHeader(),            
        })
        .then((response) =>{                      
          return response;
        })
    }   
    
}

export default new GuruService()