import React from 'react';

import AZChatMessageMap from './AZChatMessageMap';

import moment from 'moment';

import { linkify } from '../../../helpers/helpers';

import "./index.css"

const AZChatMessage = (props) => {
    const {
        message,
        myMessage=false
    } = props

    const {
        _id,
        text=undefined,
        image=undefined,
        audio=undefined,
        duration=undefined,
        map=undefined,        
        sent=undefined,
        pending=undefined,
        createdAt
    } = message

    const renderMesaggeType = ()=>{
        if(text){
            console.log('converted text',linkify(text))
            return <div className='az-chat-message__bubble__text'>
                <p  style={{'white-space':'pre-line'}} dangerouslySetInnerHTML={{__html: linkify(text)}}>                                        
                </p>
            </div>
        }
        if(image){
            return <div className='az-chat-message__bubble__img'>
                <img src={image} alt="" />
            </div>
        }
        if(audio){
            return <div className='az-chat-message__bubble__audio'>
                <audio src={audio} controls></audio>
            </div>
        }
        if(map){
            const mapObject = JSON.parse(map)
            return <div className='az-chat-message__bubble__map'>
                <AZChatMessageMap 
                    lat={mapObject.latitude}
                    lng={mapObject.longitude}
                />
            </div>
        }        
    }    
    const renderTime = ()=>{
        const time  = new Date(createdAt)
        const todayTime = new Date()

        let stringTime = moment(time).format('LT');
        if(moment(time).isBefore(todayTime, 'day')){
            stringTime = moment(time).calendar()
        }

        return <div className={`${myMessage?"text-end text-muted":"text-start text-white"} mt-2`}>            
            {myMessage&&pending==true?
                <h6>
                    <i className='fa fa-clock-o'></i>
                </h6>
                :
                <h6>
                    {stringTime} {myMessage&&<i className='fa fa-check'></i>}
                </h6>                
            }                       
        </div>
    }

    return (
        <div>
            <div className="az-chat-message">
                <div className={`az-chat-message__bubble ${myMessage&&"az-chat-message__bubble--right"}`}>
                    {renderMesaggeType()}
                    {renderTime()}
                </div>
            </div>
        </div>
    );
}

export default AZChatMessage;
