import {
    SET_DEFAULT_PAYMENT_METHOD,
    CLEAN_DEFAULT_PAYMENT_METHOD, 
} from "../actions/types"

const initialState = {
    id: "pm_1KRlZoHTTWxjz5aiXGloDmhT",
    last4: "4242"
}

export default (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    switch (type) {     
        case SET_DEFAULT_PAYMENT_METHOD:
        return {
            ...state,
            ...payload
        }
        case CLEAN_DEFAULT_PAYMENT_METHOD:
        return initialState    
        default:
        return state;
    }
}