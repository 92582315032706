import React, { useState,useEffect,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import * as generalActions from "../../actions/general.actions"

import ProfileHeader from '../../components/profile/ProfileHeader';
import ReviewTabPanel from '../../components/profile/ReviewTabPanel';

import useWindowScrollApiList from '../../hooks/useWindowScrollApiList';

import AZButton from '../../components/form/AZButton';
import AZLoading from '../../components/modal/AZLoading';
import AZProfileImage from '../../components/profile/AZProfileImage';
import ReviewsList from '../../components/profile/ReviewsList';
import AZModal from '../../components/modal/AZModal';

import RP from '../../helpers/routePath';

import {format} from 'react-string-format'

import * as appActions from '../../actions/app.actions'
import * as paymentActions from '../../actions/payments.actions'

import { profile } from '../../reducers/profile';
import AZButtonLink from '../../components/form/AZButtonLink';
import AZModalTitle from '../../components/modal/AZModalTitle';
import UserOptions from '../../components/profile/UserOptions';
import UserReportForm from '../../components/profile/UserReportForm';
import UserBlockForm from '../../components/profile/UserBlockForm';
import GiveTipForm from '../../components/payments/GiveTipForm';

import { useStripe } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import C from '../../constants'

const UserProfile = (props) => {
    const{
        auth,
        match,
        dispatch
    }=props

    const history = useHistory()
    const location = useLocation()
    const{
        reviewsAs,
        giveTipData={
            selectedButton:'none',
            tip:0,
            lastPay:{
                dateLastTip:null,
                currencyLastTip:null,
                amountLastTip:null,
                loading:true 
            },
            paymentMethodForTip:{
                id:-1
            }
        }
    } = location.state

    const stripe = useStripe()
    const {t,i18next} = useTranslation()

    const rows = 10;

    const stripeRef = useRef(null)

    const [userProfile, setUserProfile] = useState({idUser:-1});
    const [userLoading, setUserLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);    

    const [viewReviewAs, setViewReviewAs] = useState(reviewsAs?reviewsAs:"SEEKER");   

    const [showReportForm, setShowReportForm] = useState(false);
    const [sendReportLoading, setSendReportLoading] = useState(false);        
    const [showBlockForm, setShowBlockForm] = useState(false);    
    const [showOptions, setShowOptions] = useState(false);

    const [tipModal, setTipModal] = useState(giveTipData.paymentMethodForTip.id!==-1);
    const [sendTipLoading, setSendTipLoading] = useState(false);
    const [tipErrors, setTipErrors] = useState("");

    const [loadedReviews, setLoadedReviews] = useState(0);

    const [
        reviewsList,
        usersLoading,
        isScrolling,
    ] = useWindowScrollApiList({ 
        apiCall:generalActions.getReviews,
        'rows':rows,
        'params':[match.params.id,viewReviewAs],        
        onSuccess:(response)=>{
            console.log("response after hook",response)
        },
        onError:(error)=>{
            console.log("error after hook",error)
        },
        testing:true
    }) 
      

    const getProfile = () => {
        setUserLoading(true)
        setPageLoading(true)
        generalActions.getUserProfile(match.params.id).then(
            (response)=>{
                console.log("okResponse",response)
                setUserLoading(false)
                setPageLoading(false)
                const {
                    codeNumber="",
                    codeResponse="",
                    ...theProfile
                }=response
                setUserProfile({...userProfile,...theProfile})
            },
            (errors)=>{
                console.log("user error",errors)
                if(errors.status == 409){
                    setUserProfile({
                        idUser:0
                    })
                }else{
                    dispatch(
                        appActions.setGlobalMessage(
                            t(`ERROR_${errors.data.codeNumber}`)
                        )
                    )
                }
                setUserLoading(false)
                setPageLoading(false)
            }
        )                       
    }

    const handleBack=()=>{
        history.goBack();
    }

    const handleItemCheckReview=(idUser)=>{
        console.log('push?')
        history.push(RP.getPath(t,RP.PROFILE_USER,idUser),{'reviewAs':'SEEKER'})
    }
    
    const handleReportUserClick = ()=>{
        setShowReportForm(true)
    }
    const handleBlockUserClick = ()=>{
        setShowBlockForm(true)
    }
    const handleOpenMenu = ()=>{
        setShowOptions(true)
    }

    const handleUserReport = (data, onSuccess,onError)=>{
        const {
            message,
            option
        }=data
        console.log("the message",message)
        console.log("the user",userProfile.idUser)
        setSendReportLoading(true)

        generalActions.reportUser(userProfile.idUser,option,message).then(
            (response)=>{
                setShowReportForm(false)
                dispatch(
                    appActions.setGlobalMessage(t('PROFILE_report_sent_success'))
                )
                //onSuccess(response)
                setSendReportLoading(false)
            },(response)=>{
                onError(response)
                setSendReportLoading(false)
            }
        )
    }
    const handleUserBlock = (data,onSuccess,onError)=>{
        const {
            message,
            option
        }=data
        console.log("the message",message)
        console.log("the user",userProfile.idUser)
        setSendReportLoading(true)

        generalActions.blockUser(userProfile.idUser,option,message).then(
            (response)=>{
                setShowBlockForm(false)
                dispatch(
                    appActions.setGlobalMessage(t('PROFILE_block_sent_success'))
                )
                //onSuccess(response)
                setSendReportLoading(false)
            },(response)=>{
                console.log("block on service",response)
                onError(response)
                setSendReportLoading(false)
            }
        )
    }

    const handleGiveProps = (data) => {
        setSendTipLoading(true)
        setTipErrors("")
        console.log('send tip param',data)
        dispatch(
            paymentActions.sendPayment(data)
        ).then(
            async (response) => {
                const {
                    clientSecret,
                    account
                }= response
                const newStripe = await loadStripe(C.stripe.publishableKey, {
                    stripeAccount:account,
                    clientSecret:clientSecret
                });
                console.log('payment method',newStripe)
                console.log('newStripe',newStripe)
                if(newStripe){
                    newStripe.confirmCardPayment(clientSecret).then(
                        (response) => {
                            if(response.paymentIntent){
                                setTipModal(false)                                
                                dispatch(
                                    appActions.setGlobalMessage(format(
                                        t('TIP_success_paying_message'),
                                        `${userProfile.idUser>0?`${userProfile.name}`:""}`
                                    ))
                                )
                            }else{
                                setTipErrors(t("TIP_error_confirm_paying_message"))
                            }                            
                            console.log('stripe response',response)
                            setSendTipLoading(false)
                        }
                    )
                }else{
                    setSendTipLoading(false)
                    setTipErrors(t("TIP_error_confirm_paying_message"))
                }

                console.log('on send tip',response)
                return 
            },
            (error) => {
                setSendTipLoading(false)
                console.log('send error',error)
                if(error){
                    setTipErrors(t(`ERROR_${error.codeNumber}`))
                }
            }
        )
    }

    useEffect(() => {  
        console.log('sant',appActions)   
        setUserProfile({idUser:-1})
        getProfile();               
        console.log('getReviews and location',reviewsAs)        
    },[location.pathname]);
    

    return (
        <div>
            <AZLoading
                show={pageLoading}
            >
            </AZLoading>
            <AZModalTitle
                title={format(
                    t('PROFILE_options_title'),
                    `${userProfile.idUser>0?`${userProfile.name} ${userProfile.lastName}`:""}`
                )}
                show={showOptions}
                onClose={()=>{setShowOptions(false)}}
            >
                {showOptions?<UserOptions
                    onReport={()=>{setShowReportForm(true);setShowOptions(false);}}
                    onBlock={()=>{setShowBlockForm(true);setShowOptions(false);}}
                    onCancel={()=>{setShowOptions(false)}}
                >                    
                </UserOptions>:<div></div>}
            </AZModalTitle>

            <AZModalTitle
                title={format(
                    t('PROFILE_report_title'),
                    `${userProfile.idUser>0?`${userProfile.name} ${userProfile.lastName}`:""}`
                )}
                show={showReportForm}
                onClose={()=>{setShowReportForm(false);setSendReportLoading(false);}}
            >
                {showReportForm?<UserReportForm
                    loading={sendReportLoading}
                    username={`${userProfile.idUser>0?`${userProfile.name} ${userProfile.lastName}`:""}`}                                        
                    onSubmit={handleUserReport}
                    onCancel={()=>{setShowReportForm(false);setSendReportLoading(false);}}
                >                    
                </UserReportForm>:<div></div>}
            </AZModalTitle>
            
            <AZModalTitle
                title={format(
                    t('PROFILE_block_title'),
                    `${userProfile.idUser>0?`${userProfile.name} ${userProfile.lastName}`:""}`
                )}
                show={showBlockForm}
                onClose={()=>{setShowBlockForm(false);setSendReportLoading(false);}}
            >
                {showBlockForm?<UserBlockForm
                    loading={sendReportLoading}
                    username={`${userProfile.idUser>0?`${userProfile.name} ${userProfile.lastName}`:""}`}                                        
                    onSubmit={handleUserBlock}
                    onCancel={()=>{setShowBlockForm(false);setSendReportLoading(false);}}
                >                    
                </UserBlockForm>:<div></div>}
            </AZModalTitle>
            {tipModal&&<AZModal
                header={
                    <div className="row w-100 align-items-center">
                        <div className="col-auto">
                            <AZProfileImage
                                user={userProfile}
                                size="md"
                                bordered
                                onClick={()=>{}}
                            >
                            </AZProfileImage>
                        </div>
                        <div className="col">
                            <h3>
                                {format(t('RATE_tip_service_title'),userProfile.name)}
                            </h3>
                        </div>
                    </div>
                }
                show={tipModal}  
                onClose={()=>{ setTipModal(false) }}              
            >
                <GiveTipForm 
                    user={userProfile}
                    onCancel={()=>{setTipModal(false)}}
                    dispatch={dispatch}                    
                    onGiveProps={handleGiveProps}
                    loading={sendTipLoading}
                    errors={tipErrors}
                    preData={giveTipData}
                />
            </AZModal>}            

            <div className="container-lg py-1 bg-transparent">
                <div className='position-relative'>
                    <div className={`row row-hover w-100 pt-3 bg-gray-soft ${isScrolling?"position-fixed":"position-relative"}`} >
                        <div className="col-auto">
                            <AZButtonLink
                                    style="color"
                                    type="button"
                                    underline
                                    onClick={()=>{history.goBack()}}
                                >                 
                                {t('GENERAL_back')}      
                            </AZButtonLink>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-container">                        
                        {
                            userProfile.idUser==0&&<div className="row my-2">
                                <div className="col-12">
                                    <div className="az-card-container text-center">
                                        <h5>
                                            {t('ERROR_0201')}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                        userProfile.idUser>0&&<div className="row my-2">
                            <div className="col-12">
                                {/* USER */}                                   
                                <div className="az-card-container">
                                    <ProfileHeader 
                                        userProfile={userProfile}
                                        onReportUser={handleReportUserClick}
                                        onBlockUser={handleBlockUserClick}
                                        onOptionsClick={handleOpenMenu}  
                                        onTipUser={()=>{setTipModal(true)}}                 
                                    />
                                </div>                                
                            </div>
                        </div>
                        }                       
                        {
                            userProfile.idUser>0&&userProfile.isAGuru&&
                            <div className="row justify-content-center">
                                <div className="col-12 d-none d-sm-block mt-1 text-center">
                                    <h5>
                                        {t('PROFILE_reviews_as')}
                                    </h5>
                                </div>
                                <div className="col-12">
                                    <ReviewTabPanel 
                                        disabled={usersLoading}
                                        reviewAs={viewReviewAs}
                                        ratesAsGuru={userProfile.ratesAsGuru}
                                        ratesAsSeeker={userProfile.ratesAsSeeker}
                                        ratingAsGuru={userProfile.ratingAsGuru}
                                        ratingAsSeeker={userProfile.ratingAsSeeker}
                                        onItemClick={ (as)=>{ setViewReviewAs(as) } }
                                    />
                                </div>                               
                            </div>
                        }           
                        {
                            userProfile.idUser>0&&<div className="row justify-content-center">                        
                            <div className="col-12">
                                <div className="az-card-container az-card-container--no-padding">                                    
                                    <ReviewsList
                                        list={reviewsList}
                                        itemsLoaded={loadedReviews}
                                        full={true}
                                        loading={usersLoading}
                                        onScroll={()=>{}}
                                        noData={t('GENERAL_no_reviews_found')}
                                        onItemClick={()=>{}}
                                        onItemViewReviews={handleItemCheckReview}
                                    ></ReviewsList>
                                </div>                        
                            </div>
                        </div>
                        }                                                      
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state)=>{
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps)(UserProfile);

