import React from 'react';
import { useTranslation } from 'react-i18next';

import AZButton from '../form/AZButton';
import AZButtonLink from '../form/AZButtonLink';
import AZProfileImage from './AZProfileImage';

import { format } from 'react-string-format';

import * as helpers from '../../helpers/helpers'

const ProfileHeader = (props) => {
    const {
        userProfile,
        onReportUser=undefined,
        onBlockUser=undefined,
        onOptionsClick=undefined,
        onTipUser=undefined
    } = props

    const {t} = useTranslation()

    const {
        name,
        lastName,
        nickname,
        description,
        languages,
        isAGuru,
        tags
    } = userProfile

    const handleReportUser=()=>{
        if(onReportUser){
            onReportUser()
        }
    }
    const handleBlockUser=()=>{
        if(onBlockUser){
            onBlockUser()
        }
    }   
    const handleTipUser = (arg) => {
        if(onTipUser){
            onTipUser()
        }
    }

    const handleOptionsClick=()=>{
        if(onOptionsClick){
            onOptionsClick()
        }
    }   

    return (
        <div className='row p-2'>
            {console.log('profile...',userProfile)}
            <div className="col-12">
                <div className="row justify-content-md-between align-items-center">
                    <div className="col-auto text-center my-1">                        
                        <AZProfileImage
                            user={userProfile}
                            size="md"
                            bordered
                            onClick={()=>{}}
                        >
                        </AZProfileImage>
                    </div>
                    <div className="col my-1 text-start">
                        <h5>{name} {lastName}</h5>
                        <p className="text-muted my-1">
                            {`@${nickname}`}
                        </p>
                        {
                            isAGuru&&<AZButton
                                size="sm"
                                type="button"
                                onClick={handleTipUser}
                            >
                                {t('CHAT_button_tip')}
                            </AZButton>                 
                        }
                    </div>
                    <div className="d-none d-md-block col-auto my-1 mx-auto mx-md-1 align-self-start mt-3">
                        <div className="d-inline-block-middle ms-2">
                            <AZButton
                                style="color"
                                type="button"
                                onClick={handleReportUser}
                                size="sm"
                            >
                                {t('PROFILE_report_user')}
                            </AZButton>
                        </div>
                        <div className="d-inline-block-middle ms-2">
                            <AZButton
                                    style="color"
                                    type="button"
                                    onClick={handleBlockUser}
                                    size="sm"
                            >
                                {t('PROFILE_block_user')}
                            </AZButton>
                        </div>
                    </div>
                    <div className="d-md-none col-auto align-self-start mt-3">
                        <AZButtonLink
                                style="gray"
                                type="button"
                                onClick={handleOptionsClick}
                                size="sm"
                        >
                            <i className="fa fa-ellipsis-v"></i>
                        </AZButtonLink>
                    </div>
                </div>
                <div className="row my-1">
                    <div className="col-12 text-start">
                        <h5>{description}</h5>
                        <h6 className='mt-1'>
                            {languages.map((lang)=>{
                                return t(`languagues.${lang}`)
                            }).join(' • ')}
                        </h6>
                        {
                            isAGuru&&
                            <div className='mt-2'>
                                <h4>{t(format(
                                    t('PROFILE_my_expertise_areas'),
                                    tags.length
                                ))}</h4>
                                <h6 className='mt-1'>
                                    {helpers.showFormattedTags(tags)}
                                </h6>
                            </div>
                        }
                        {/* The languages goes here */}
                    </div>
                </div>
            </div>            
        </div>
    );
}

export default ProfileHeader;
