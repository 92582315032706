import React from 'react';
import { useTranslation } from 'react-i18next';
import AZButtonLink from '../form/AZButtonLink';

const ProfileMenu = (props) => {
    const { t, i18n } = useTranslation();
    const {
        isAGuru=false,
        onEditProfile,
        onUpdatePassword,
        onAcceptStripe,
        onViewBloquedUsers,
        onSendABug=()=>{},
        onLogout
    } = props

    return (
        <div>
            <div className="">
                <AZButtonLink
                    style="color"
                    customClases=""
                    onClick={onEditProfile}
                >
                    {t('PROFILE_my_options_edit_profile')}
                </AZButtonLink>
            </div>
            <div className="my-2">
                <AZButtonLink
                    style="color"
                    customClases=""
                    onClick={onUpdatePassword}
                >
                    {t('PROFILE_my_options_update_password')}
                </AZButtonLink>
            </div>           
            {isAGuru&&
                <div className="my-2">
                    <AZButtonLink
                                style="color"
                                customClases=""
                                onClick={onAcceptStripe}
                            >
                        {t('PROFILE_my_options_setup_stripe')}
                    </AZButtonLink>
                </div> 
            }   
            <div className="my-2">
                <AZButtonLink
                        style="color"
                        customClases=""
                        onClick={onViewBloquedUsers}
                    >
                    {t('PROFILE_my_options_see_blocked_users')}
                </AZButtonLink>
            </div>                                
            <div className="my-2">
                <AZButtonLink
                    style="color"
                    customClases="text-left"
                    onClick={onSendABug}                    
                >
                    {t('MORE_OPTIONS_report_a_problem')}
                </AZButtonLink>
            </div>  
            <div className="my-2">
                <AZButtonLink
                    style="color"
                    customClases="text-left"
                    onClick={onLogout}                    
                >
                    {t('PROFILE_my_options_logout')}
                </AZButtonLink>
            </div>            
        </div>
    );
}

export default ProfileMenu;
