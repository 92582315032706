import "./index.css"

const AZButton = (props) => {
    const {
        type="button",
        style="color",
        size="md",
        children,
        onLoadText,
        disabled,
        customClass="",
        loading=false,
        onClick
    } = props
    
    const buttonStyle = {
        color:"btn-az btn-az--color",
        gray:"btn-az btn-az--gray",
        graySoft:"btn-az btn-az--gray-soft",

    }

    const handleClick = (e)=>{
        if(e !== undefined){
            e.stopPropagation()
        }
        console.log("click button")
        onClick()
    }

    return (
        <button className={`${buttonStyle[style]} ${customClass} btn-az--${size} ${disabled&&"btn-az--disabled"}`} 
                type={type}
                disabled={loading||disabled}
                onClick={(e)=>{handleClick(e)}} 
                >
            {
                !loading?children:
                <span>
                    <i className="fa fa-spinner fa-spin text-white mr-3"></i>
                    {onLoadText}
                </span>
            }            
        </button>
    );
}

export default AZButton;
