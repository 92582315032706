import Service from "../services/seeker.service";

import {
    apiErrorHandler
} from "../helpers/helpers"

export const createNewRequest = (requestData) => (dispatch) => {
    return Service.createNewRequest(requestData).then(
        (response)=>{ 
            console.log("create request",response)
            return Promise.resolve(response.data)
        },(response)=>{
            if(apiErrorHandler(response.response),dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const getConversations = (numberOfRequests,numberOfConversations) =>{
    return Service.getConversations(numberOfRequests,numberOfConversations).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{

            return Promise.reject(response.response)
        }
    )
}

export const getRequests = (loadedRequest,numberOfRequests) => (dispatch) =>{
    return Service.getRequests(loadedRequest,numberOfRequests).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const getArchivedRequests = (loadedRequest,numberOfRequests) => (dispatch) =>{
    return Service.getArchivedRequests(loadedRequest,numberOfRequests).then(
        (response)=>{
            console.log('actions response?',response)
            return Promise.resolve(response.data)
        },
        (response)=>{
            console.log('actions  error response?',response)
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response)
        }
    )
}

export const getGurusMatchingUsingParams = (params,loadedElements,numberOfRows) =>{
    return Service.getGurusMatchingUsingParams(params,loadedElements,numberOfRows).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{

            return Promise.reject(response.response)
        }
    )
}
export const getGurusMatchingUsingRequestId = (requestId,loadedElements,numberOfRows) =>{
    return Service.getGurusMatchingUsingRequestId(requestId,loadedElements,numberOfRows).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{

            return Promise.reject(response.response)
        }
    )
}

export const getGurusMatchingText = (text,loadedElements,numberOfRows) =>{
    return Service.getGurusMatchingText(text,loadedElements,numberOfRows).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{

            return Promise.reject(response.response)
        }
    )
}

export const notifyGurusNewRequest = (
    idRequest
) => {
    return Service.notifyGurusNewRequest(
        idRequest
    ).then(
        (response)=>{
            return Promise.resolve(response.data)
        },
        (response)=>{
            return Promise.reject(response.response)
        }
    )
}

export const abort = ()=>{
    console.log('Abort requests')
    Service.abort()
}

export const setAbortController = ()=>{
    console.log('Abort requests')
    const theNewAbortController = new AbortController()
    return Service.setAbortController(theNewAbortController)
} 