import {
    SET_SEARCHED_GURUS,
    CLEAN_SEARCHED_GURUS,
} from "../actions/types"

const initialState = {
    "text":"",
    "list":[],
    "profile":false
}

export default (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    console.log(action)
    switch (type) {     
        case SET_SEARCHED_GURUS:
            return {
                ...state,
                ...payload
            }        
        case CLEAN_SEARCHED_GURUS:
            return initialState      
        default:
        return state;
    }
}