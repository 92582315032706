import React from 'react';

const AZSwitch = (props) => {
    const {
        children,
        onClick,
        color="purple",
        error,
        isOn=true
    } = props

    return (
        <div>
            <div className={`az-switch az-switch--${color} ${error&&"az-switch--error"} `} onClick={onClick}>
                {children&&<div className="az-switch__label">
                    {children}
                </div>}
                <div className="az-switch__icon">
                    {
                        isOn?
                        <i className="fa fa-toggle-on" aria-hidden="true"></i>
                        :<i className="fa fa-toggle-off" aria-hidden="true"></i>
                    }
                </div>
            </div>
        </div>
    );
}

export default AZSwitch;
