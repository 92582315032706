import C from "../constants"
import axios from "axios";
import authHeader from './auth-header';

const API_URL = C.ws + "general/";

class GeneralService{
    constructor(abortcontroller=null){
        this.getImageTries = 0
        this.abortcontroller = abortcontroller?abortcontroller:new AbortController()
    }

    blockUser(idUser,typeOfReport,comment=""){        
        return axios
        .post(API_URL + "block-user",{            
            idUser,
            typeOfReport,
            comment
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    checkNicknameValid(nickname){
        return axios
        .get(API_URL + "nickname-is-valid",{
            params:{
                nickname
            },
            headers:{
                ...authHeader()
            }
        })
        .then((response) =>{          
          return response;
        })
    }

    closeRequest(idRequest){
        
        return axios
        .post(API_URL + "close-request",{            
            idRequest
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })

    }

    deleteAccount(){
        return axios
        .post(API_URL + "delete-account",{            
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    getBloquedUsers(loadedElements,numberOfRows){
        return axios
        .get(API_URL + "get-blocked-users",{
            params:{
                loadedElements,
                numberOfRows
            },
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{     
            console.log("bloqued users response",response)     
          return response;
        })
    }

    getCategories(){
        return axios
        .get(API_URL + "get-categories",{           
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{                    
          return response;
        })
    }
    //TODO LEGR: resolve this(abort controller signal) on the entire class
    getImage(src,abortController=null){
        // let ac;
        // if(abortController == null){
        //     ac = new AbortController()
        // }else{
        //     ac = abortController
        // }
        // console.log(this)
        // const service = this
        // return axios
        // .get(src,{
        //     signal:ac.signal,
        // })
        // .then((response) =>{   
        //     service.getImageTries = 0
        //     return response;
        // })
        return fetch(`${src}`, {
            method: 'GET',
            //headers:{'Content-Type': 'image/jpg'},                      
        }).then((response)=>{
            console.log("get",response)
            return response
        }).then((response)=>{
            return response
        })
    }
    getMyProfile(idUser){
        return axios
        .get(API_URL + "get-my-profile",{
            signal:this.abortController,
            headers:authHeader(),
            idUser 
        })
        .then((response) =>{          
          return response;
        })
    }

    getPendingRates(){
        return axios
        .get(API_URL + "get-my-pending-ratings",{           
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{                    
          return response;
        })
    }

    getUserProfile(idUser){
        return axios
        .get(API_URL + "get-user-profile",{
            params:{
                idUser
            },
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }
    

    getRemainingRequests(){
        return axios
        .get(API_URL + "get-remaining-requests",{            
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    getReviews(idUser,type,loadedElements,numberOfRows){
        return axios
        .get(API_URL + "get-reviews",{
            params:{
                idUser,
                type,
                loadedElements,
                numberOfRows
            },
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    getTagsCategory(idCategory){
        return axios
        .get(API_URL + "get-tags",{   
            params:{
                idCategory
            },     
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{                    
          return response;
        })
    }

    reportRequest(idRequest,typeOfReport,comment){
        return axios
        .post(API_URL + "report-request",{
            idRequest,
            typeOfReport,
            comment
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }   
    
    reportUser(idUser,typeOfReport,comment=""){        
        return axios
        .post(API_URL + "report-user",{            
            idUser,
            typeOfReport,
            comment
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    sendABugReport(description,image){
        return axios
        .post(API_URL + "send-bug-report",{
            description,
            returnURL:image?true:false
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    rateUser(idConversation,review,rating){
        return axios
        .post(API_URL + "rate-user",{
            idConversation,
            review,
            rating
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    rateAndTipUser(
        idConversation,
        review,
        rating,
        paymentMethodId,
        amount
    ){
        return axios
        .post(API_URL + "rate-and-tip-user",{
            idConversation,
            review,
            rating,
            paymentMethodId,
            amount
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    sendSubcategorySuggestion(suggestion,idCategory,userType){
        return axios
        .post(API_URL + "send-subcategory-suggestion",{
            suggestion,
            idCategory,
            userType
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    sendSuggestion(suggestion,userType){
        return axios
        .post(API_URL + "send-category-suggestion",{
            suggestion,
            userType
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    updateGuruLevel(guruLevel){
        return axios
        .post(API_URL + "update-guru-level",{
            guruLevel,            
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    updateIsIndependent(isIndependent,companyName){
        console.log("service call",{
            isIndependent,
            companyName
        })

        return axios
        .post(API_URL + "update-is-independent",{
            isIndependent,
            companyName
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    updateLanguages(languages){
        return axios
        .post(API_URL + "update-languages",{
            languages
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    updateLocation(locationData){
        return axios
        .post(API_URL + "update-location",{
            ...locationData
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    updatePassword(currentPassword,newPassword){
        return axios
        .post(API_URL + "update-password",{
            currentPassword,
            newPassword
        },{
            headers:{
                ...authHeader(),
                "Content-Type":"application/json"
            } 
        })
        .then((response) =>{          
          return response;
        })
    }
    updatePasswordWeb(idUser,token,auth,password){
        return axios
        .post(API_URL + "update-password-web",{
            idUser,
            token,auth,password
        },{
            headers:{                
                "Content-Type":"application/json"
            } 
        })
        .then((response) =>{          
          return response;
        })
    }

    updateProfile(
        params
    ){
        const {
            name,
            lastName,
            nickname,
            description,
            returnURL
        } = params
        return axios
        .post(API_URL + "update-profile-info",{
            name,
            lastName,
            nickname,
            description,
            returnURL
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    updateSpecialities(
        specialities
    ){        
        return axios
        .post(API_URL + "update-specialities",{
            specialities
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    updateUserIsIndependent(isIndependent){
        return axios
        .post(API_URL + "update-is-independent",{
            isIndependent
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    uploadAudio(uploadUrl,audioFile){
        console.log('the audiofile',audioFile)
        const formData = new FormData()
        const file = new File([audioFile],"voice_note.m4a",{
            type:"audio/mp4"
        })
        formData.append('file',file)
        console.log("the file:",file)
        
        return fetch(`${uploadUrl}`, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': 'audio/mp4',
            },
        }).then((response)=>{
            console.log("put",response)
            return response
        })
    }

    unblockUser(idUser){
        return axios
        .post(API_URL + "unblock-user",{
            idUser
        },{
            signal:this.abortController,
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }

    validateProfile(idUser,token,auth){
        return axios
        .post(API_URL + "validate-profile",{
            idUser,
            token,
            auth
        },{
            signal:this.abortController,            
        })
        .then((response) =>{          
          return response;
        })
    }
    validateTokenForRecovery(idUser,token,auth){
        return axios
        .post(API_URL + "validate-token-for-recovery",{
            idUser,
            token,
            auth
        },{
            signal:this.abortController,            
        })
        .then((response) =>{          
          return response;
        })
    }

    
}

export default GeneralService