import React,{ useState } from 'react';
import AZProfileImage from '../profile/AZProfileImage';
import AZButtonLink from '../form/AZButtonLink';
import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';
import FormatedDate from '../format/FormatedDate';
import FormatRating from '../format/FormatRating';

const SearchGurusItem = (props) => {
    const {
        model,        
        onViewReviews,
    } = props

    const {t,i18n} = useTranslation()

    const {
        idUser,
        name,
        description,
        imageSufix,
        level,
        isIndependent,
        rates,
        rating,
        status
    } = model

    const handleViewReviews = (e) =>{
        onViewReviews(idUser)
    }
   

    return (  
        <div>
            <div className="az-list__content__item justify-content-between az-list__content__item--search-gurus align-items-center" >                
                <div className="col-sm col-12">
                    <div className="d-inline-block-middle">
                        <AZProfileImage
                            user={{
                                ...model,
                                isAGuru:true,
                                isIndependent,
                                guruLevel:level
                            }}
                            bordered={true}
                            size="sm"
                            onClick={()=>{}}
                        >                    
                        </AZProfileImage>
                    </div>
                    <div className="d-inline-block-middle">
                        <h5>
                            {name}
                        </h5>                        
                        <h6 className="">
                            <i className="fa fa-star ms-1 me-1"></i>
                            {rating==0?t('GENERAL_no_reviews'):
                                <span>
                                    <FormatRating>
                                        {rating}
                                    </FormatRating>
                                    {` · ${rates} ${t('GENERAL_reviews')}`}
                                </span>
                            }                        
                        </h6>
                    </div> 
                    <div className="w-100 my-1">
                        <p>
                            {description}
                        </p>
                    </div>                                       
                </div>
                <div className="col-sm-auto mx-sm-2 text-center col-6">
                    <AZButtonLink
                                style="color"
                                underline
                                onClick={handleViewReviews}                        
                            >
                        {t('GURU_request_label_check_reviews')}
                    </AZButtonLink>
                </div>                                                            
            </div>            
        </div>      
    );
}

export default SearchGurusItem;

