import React from 'react';

const AZButtonLevel = (props) => {
    const {
        icon="",
        selected=false,
        title,
        description,
        onClick
    } = props

    return (
        <div className={`btn-az-level ${selected?"btn-az-level--selected":""}`}
            onClick={()=>{onClick()}}
        >
            {
                icon!==""&&<div className="btn-az-level__icon">
                    {icon}
                </div>
            }
            <div className="btn-az-level__body">
                <h5 className="btn-az-level__body__title">    
                    {title}                
                </h5>
                <p className="btn-az-level__body__description"> 
                    {description}
                </p>
            </div>
        </div>
    );
}

export default AZButtonLevel;
