import React,{useState,useEffect,useLayoutEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import RP from '../../helpers/routePath';

import AZField from '../../components/form/AZField';

import * as appActions from '../../actions/app.actions'
import * as seekerActions from '../../actions/seeker.actions'
import * as navActions from '../../actions/navbar.actions'

import SearchGurusList from '../../components/seeker/SearchGurusList';

const SearchForGurus = (props) => {   
    const {
        searchedGurus,
        dispatch
    } = props

    const {
        text,
        list,
        profile
    } = searchedGurus

    const {t} = useTranslation()
    const history = useHistory()   
    
    const rows = 10

    const [searchText, setSearchText] = useState(text);
    const [firstSearchCall, setFirstSearchCall] = useState(true);
    
    const [searchTextLoading, setSearchTextLoading] = useState(false);
    const [gurusList, setGurusList] = useState(list);    
    const [loadedGurus, setLoadedGurus] = useState(0);
    const [searching, setSearching] = useState(false);

    const [guardGuru, setGuardGuru] = useState(-1);
    

    const handleChangeText = (e)=>{
        const {
            name,
            value
        } = e.target
        setSearchText(value)
    }

    const handleViewReviews = (idGuru)=>{        
        //Set the dispatch on useEffect to prevent it runs twice
        setGuardGuru(idGuru)        
    }

    useEffect(() => {
        setGurusList(list)
        setSearchText(text)  
       
        dispatch(
            navActions.selectIndex(1)
        )             
    },[]);

    useLayoutEffect(() => {       
        if(guardGuru>0){
            dispatch(
                appActions.setSearchedGurus({
                    ...searchedGurus,
                    "text":searchText,
                    "list":gurusList,
                    "profile":true
                })
            )            
            history.push(RP.getPath(t,RP.PROFILE_USER,guardGuru),{                
                "reviewsAs":"GURU"                
            })
        }        
        return ()=>{        
            if(guardGuru<0){     
                dispatch(
                    appActions.cleanSearchedGurus()
                )
            }
        }
    }, [guardGuru]);

    useEffect(() => {
        if(firstSearchCall){
            setFirstSearchCall(false)
        }else{  
            setSearching(true)                             
            const timeout = setTimeout(() => {    
                setGurusList([])            
                getGurus(true)
            }, 600)
            return () => clearTimeout(timeout)
        }                
    }, [searchText])

    const getGurus = (fromStart)=>{
        console.log('gey gurus')
        if(searchTextLoading){
            return
        }
        setSearchTextLoading(true)
        let loadedItems = loadedGurus
        if(fromStart){
            setLoadedGurus(0)
            loadedItems = 0
        }
        console.log()
        seekerActions.getGurusMatchingText(searchText,loadedItems,rows).then((response)=>{
            const {
                list
            } = response
            console.log("loaded items",loadedItems+list.length)
            if(list.length>0){     
                let actualList = fromStart?[]:gurusList
                setGurusList([...actualList,...list])                  
                setLoadedGurus(loadedItems+list.length)         
            }
            setSearchTextLoading(false)  
            setSearching(false)      
        },(error)=>{
            console.log(error)
            setSearchTextLoading(false)
            setSearching(false)
        })
    }   

    return (
        <div className="container-lg py-1 bg-transparent">
            <div className="row justify-content-center">
                <div className="col-12 col-container">
                    <div className="row justify-content-start align-items-center my-3">
                        <div className="col-12 col-md-auto mr-md-2 my-1 text-start">
                            <h2><b>{t('SEARCH_GURU_header')}</b></h2>
                        </div>
                        <div className="col-12 col-md-4 mr-md-2 my-1 text-start">
                            <AZField                                 
                                rightIcon={
                                    <i className="fa fa-search text-purple"></i>
                                } 
                                loading={searchTextLoading} 
                                name="text"      
                                value={searchText}
                                onChange={handleChangeText}                                                     
                            >                                
                                {t('SEARCH_GURU_type_here')}
                            </AZField>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-12 my-1">
                            <div className="az-card-container az-card-container--no-padding">                                                               
                                <SearchGurusList
                                    full={true}
                                    list={gurusList}
                                    itemsLoaded={loadedGurus}
                                    loading={searchTextLoading}
                                    onScroll={()=>{}}
                                    noData={searchText==""||searching?t('SEARCH_GURU_data_will_appear_here'):t('SEARCH_GURU_data_not_found')}
                                    onItemClick={()=>{}}
                                    onItemViewReviews={handleViewReviews}
                                ></SearchGurusList>                                
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    );
}

const mapStateToProps = (state)=>{
    const {
        searchedGurus
    } = state
    console.log("guardedGurus",searchedGurus)

    return {
        searchedGurus
    }
}

export default connect(mapStateToProps)(SearchForGurus);
