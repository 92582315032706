// const constants = {    
//     ws:"https://kwa7669s2h.execute-api.us-east-1.amazonaws.com/Prod/",
//     name:"AZWEB",
//     resources:{
//         profile:"https://askzhao-test-small.s3.amazonaws.com/users/",
//         profileThumb:"https://askzhao-test-small.s3.amazonaws.com/users/",
//         //profile:"https://askzhao-dev-a.s3.amazonaws.com/users/",
//         //profileThumb:"https://askzhao-dev-a-resized.s3.amazonaws.com/users/",
//         webpage:"http://localhost:3000/img/",
//         page:"https://test.askzhao.com/"    
//     },
//     firebase:{        
//         apiKey: "AIzaSyAxzrsqcpAXrf5X9HvNvbUM_2m3NwHtOCg",
//         authDomain: "askzhao-test.firebaseapp.com",
//         databaseURL: "https://askzhao-test-default-rtdb.firebaseio.com",
//         projectId: "askzhao-test",
//         storageBucket: "askzhao-test.appspot.com",
//         messagingSenderId: "606221375580",
//         appId: "1:606221375580:web:256bcc450fb458eb8dee99",
//         measurementId: "G-JPG6RGF27Z"          
//     },
//     stripe:{
//         publishableKey:'pk_test_51JVGN0HTTWxjz5aiF8yn90JS7I8XqktgyXJspM1hqReukoovgVcvuizLVY6yBPuO16FvyOW4ZCWI6qqZvLJKLgLE006U7h7Uzy'
//     }
// }
const constants = {    
    ws:"https://kwa7669s2h.execute-api.us-east-1.amazonaws.com/Prod/",
    name:"AZWEB",
    AZ_KEY:"78Rt!0h%75/1!2uE4dk49nDj85)5nf",
    resources:{
        profile:"https://askzhao-test-small.s3.amazonaws.com/users/",
        profileThumb:"https://askzhao-test-small.s3.amazonaws.com/users/",
        //profile:"https://askzhao-dev-a.s3.amazonaws.com/users/",
        //profileThumb:"https://askzhao-dev-a-resized.s3.amazonaws.com/users/",
        webpage:"https://test.askzhao.com/img/",
        //webpage:"http://localhost:3000/img/",
        page:"https://test.askzhao.com/",    
        legal:"https://askzhao.com/",    
    },
    firebase:{         
        apiKey: "AIzaSyAxzrsqcpAXrf5X9HvNvbUM_2m3NwHtOCg",
        authDomain: "askzhao-test.firebaseapp.com",
        databaseURL: "https://askzhao-test-default-rtdb.firebaseio.com",
        projectId: "askzhao-test",
        storageBucket: "askzhao-test.appspot.com",
        messagingSenderId: "606221375580",
        appId: "1:606221375580:web:256bcc450fb458eb8dee99",
        measurementId: "G-JPG6RGF27Z"          
    },
    stripe:{
        publishableKey:'pk_test_51JVGN0HTTWxjz5aiF8yn90JS7I8XqktgyXJspM1hqReukoovgVcvuizLVY6yBPuO16FvyOW4ZCWI6qqZvLJKLgLE006U7h7Uzy'
    }
}

export default constants