import React from 'react';
import { useTranslation } from 'react-i18next';
import AZButtonIcon from '../form/AZButtonIcon'

const PaymentMethodInfo = (props)=> {
    const {
        paymentMethod,
        onDetach,
        errors,
        loading     
    } = props

    const {
        id,
        brand,
        expYear,
        expMonth,
        last4,
        isDefault,
        status, //1=Active, 2=Expired, 3=Not available anymore
        isForAdding,
    }= paymentMethod
    
    const {t} = useTranslation()   
    
    const handleDetach = () => {
        if(onDetach){
            onDetach(id)
        }
    }

    return (
        <div className='p-2'>
            <div className="row justify-content-end">
                <div className="col-12 my-2">
                    <h4 className='text-gray-strong'>
                        {t('PAYMENTS_card_ending')}
                    </h4>
                    <h5>• • • • {last4}</h5>
                    <h4 className='text-gray-strong'>
                        {t('PAYMENTS_card_expiry_date')}
                    </h4>
                    <h5>
                        {expMonth<10?'0':''}{expMonth}/{expYear}
                    </h5>                
                </div>  
                
                {errors!==""&&<div className="col-12 my-1 text-danger">
                    <p>
                        {errors}
                    </p>
                </div> }
                
                <div className="col-auto my-2">
                    <AZButtonIcon
                        onClick={handleDetach}
                        style="danger"
                        rightIcon={  
                            <h5>
                                <i className="fa fa-times"></i>
                            </h5>
                        }
                        type="button"
                        loading={loading}
                    >                        
                        {t('PAYMENTS_card_remove_method')}
                    </AZButtonIcon>
                </div>
            </div>
        </div>
    );
}

export default PaymentMethodInfo;
