import C from "../constants"
import axios from "axios";
import authHeader from './auth-header';

const API_URL = C.ws + "seeker/";

class SeekerService{
    
    constructor(){
        this.abortController = new AbortController()
    }

    setAbortController(abortController){
        this.abortController = abortController;
        return this.abortController
    }

    abort(){
        console.log("thesignal?",this.abortController.signal)
        this.abortController.abort()
    }

    createNewRequest(request){
        const {
            tags,
            title,
            description,
            country,
            isCountry,
            address,
            lat,
            lon,
            Longitude,
            searchLat,
            searchLon,
            googlePlaceId,
            willingToPay
        } = request
        return axios
        .post(API_URL + "create-request",
        {
            tags,
            title,
            description,
            country,
            isCountry,
            address,
            lat,
            lon,
            Longitude,
            searchLat,
            searchLon,
            googlePlaceId,
            willingToPay
        }
        ,{
            signal:this.abortController.signal,
            headers:authHeader()
        })
        .then((response) =>{          
          return response;
        })
    }

    getConversations(loadedElements,numberOfRows){
        return axios
        .get(API_URL + "get-conversations",{
            signal:this.abortController.signal,
            headers:authHeader(),
            params:{
                loadedElements,
                numberOfRows
            }
        })
        .then((response) =>{                      
          return response;
        })
    }
    getArchivedRequests(loadedRequests,numberOfRequests){
        return axios
        .get(API_URL + "get-archived-requests",{
            signal:this.abortController.signal,
            headers:authHeader(),
            params:{
                loadedRequests,
                numberOfRequests
            }
        })
        .then((response) =>{ 
            console.log("service response?",response)                     
            return response;
        })
    }

    getRequests(
        loadedRequests,
        numberOfRequests,
        numberOfConversations=5
    ){
        return axios
        .get(API_URL + "get-requests",{
            signal:this.abortController.signal,
            headers:authHeader(),
            params:{
                loadedRequests,
                numberOfRequests,
                numberOfConversations
            }
        })
        .then((response) =>{                      
          return response;
        })
    }

    getGurusMatchingUsingRequestId(
        requestId,
        loadedElements,
        numberOfRows
    ){
        return axios
        .get(API_URL + "get-gurus-matching-using-request-id",{
            signal:this.abortController.signal,
            headers:authHeader(),
            params:{
                requestId,
                loadedElements,
                numberOfRows
            }
        })
        .then((response) =>{                      
          return response;
        })
    }

    getGurusMatchingText(
        text,
        loadedElements,
        numberOfRows
    ){
        return axios
        .get(API_URL + "get-gurus-matching-text",{
            //signal:this.abortController.signal,
            headers:authHeader(),
            params:{
                text,
                loadedElements,
                numberOfRows
            }
        })
        .then((response) =>{      
            console.log("the response?",response)                
          return response;
        })
    }

    getGurusMatchingUsingParams(
        theParams,
        loadedElements,
        numberOfRows 
    ){
        console.log("params on service",theParams)
        const {
            tags,
            country,
            isCountry,
            lat,
            lon,
            googlePlaceId 
        } = theParams
        const tagsForQuery = tags.map((item) => {
            return "tags="+item;
        });
        return axios
        .get(API_URL + `get-gurus-matching-using-params?${tagsForQuery.join('&')}&country=${country}&isCountry=${isCountry}&googlePlaceId=${googlePlaceId}&lat=${lat==null?"":lat}&lon=${lon==null?"":lon}&loadedElements=${loadedElements}&numberOfRows=${numberOfRows}`,{
            signal:this.abortController.signal,
            headers:authHeader(),
            // params:{                
            //     country,
            //     isCountry,
            //     lat,
            //     lon,
            //     googlePlaceId,
            //     loadedElements,
            //     numberOfRows
            // }
        })
        .then((response) =>{                      
          return response;
        })
    }

    notifyGurusNewRequest(
        requestId,
    ){
        return axios
        .post(API_URL + "notify-gurus-about-new-request",
        {
            requestId
        },
        {
            signal:this.abortController.signal,
            headers:authHeader(),            
        })
        .then((response) =>{                      
          return response;
        })
    }
    
}

export default new SeekerService()