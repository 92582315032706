import AZButton from "../form/AZButton"
import "./index.css"

const AZDesicionModal = (props) => {
    const {
        title,
        titleHeader,
        children,
        onClose,
        show=false,
        noOptionText,
        yesOptionText,
        onYesOption,
        onNoOption,
        loading,
        loadingText=""   
    } = props
    
    const setShowClass = (show)=>(
        show?"az-modal az-modal--desicion":"az-modal az-modal--hide"
    )

    return (
        <div className={setShowClass(show)} onClick={onClose}>
            <div className="az-modal__body" onClick={(e)=>(e.stopPropagation())} >
                <button 
                    className="az-modal__body__close"
                    onClick={onClose}>
                    <i className="fa fa-times text-white"></i>
                </button>
                {
                    title&&
                    <div className="az-modal__body__header">
                        <div className="az-modal__body__header__title">
                            {title}
                        </div>
                    </div>
                }
                {
                    !title&&titleHeader&&
                    <div className="az-modal__body__header">
                        {titleHeader}
                    </div>
                }
                <div className="az-modal__body__content">
                    {children}
                    {loading&&<div className="my-2 text-center">
                        <h5 className="text-muted">
                            <i className="fa fa-spinner fa-spin me-3"></i>{loadingText}
                        </h5>
                    </div>}
                </div>
                <div className="az-modal__body__footer">
                    <div className="row justify-content-center">
                        {
                            onNoOption&&
                            <div className="col-md-auto col-6 text-center">
                                <AZButton
                                    style="gray"
                                    onClick={onNoOption}
                                    disabled={loading}
                                >
                                    {noOptionText}
                                </AZButton>
                            </div>
                        }
                        <div className="col-md-auto col-6 text-center">
                            <AZButton
                                style="color"
                                onClick={onYesOption}
                                disabled={loading}
                            >
                                {yesOptionText}
                            </AZButton>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
}

export default AZDesicionModal;
