import React,{useEffect,useState} from 'react';

import ChatImage from '../chat/ChatImage';
//firebase
import { getDatabase,ref,onChildAdded,off,onChildChanged } from "firebase/database";

const HorizontalConversationItem = (props) => {
    const {
        model,
        onClick, 
        idRequest,
        hideResponseIcon
    } = props

    const [conversationData, setConversationData] = useState(model);

    const {
        conversationStatus,
        date,
        idConversation,
        idGuru,
        imageSufix,
        isIndependent,
        lastMessageIdUser,
        level,
        name,
        notReadByGuru,
        notReadBySeeker,
        rates,
        rating,
    } = conversationData

    const REF = ref(getDatabase(),`/chats/${idRequest}/info/${idConversation}`)

    const handleClick = ()=>{
        console.log('conversation click',idConversation)
        onClick({
            ...model,
            idUser:idGuru
        })
    }
    
    const setConversationStatus= (lastMessageIdUser,idGuru,notReadBySeeker)=>{
        return (lastMessageIdUser == idGuru?(notReadBySeeker>0?0:1):2)
    } 

    useEffect(() => {        
        onChildChanged(REF,(data) => {                    
            if (data.key == "data") {
                let newConversationData = {
                    ...conversationData,
                    lastMessageIdUser:data.val().lastMessageIdUser,
                    notReadBySeeker:data.val().notReadBySeeker,
                    notReadByGuru:data.val().notReadByGuru,   
                }
                setConversationData(newConversationData)                                                          
            }
        })
        return () => {
            off(REF)
        };
    }, []);

    return (        
        <div className="az-horizontal-list__content__item az-horizontal-list__content__item--seeker-conversation" onClick={handleClick}>
            
            <ChatImage
                user={{
                    'idUser':idGuru,
                    imageSufix,
                    isAGuru:true,
                    guruLevel:level,
                    isIndependent
                }}
                onClick={()=>{}}
                conversation={!hideResponseIcon}
                conversationReadIndicator={setConversationStatus(lastMessageIdUser,idGuru,notReadBySeeker)}
            >
            </ChatImage>
            
            <h5>
                {name}
            </h5>
        </div>        
    );
}

export default HorizontalConversationItem;
