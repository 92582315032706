import {
    ADD_PAYMENT_METHODS,
    REMOVE_PAYMENT_METHODS,
    SET_PAYMENT_METHODS,
    CLEAN_PAYMENT_METHODS, 
} from "../actions/types"

const initialState = []

export default (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    switch (type) {     
        case SET_PAYMENT_METHODS:
        return payload
        case ADD_PAYMENT_METHODS:
        return [
            ...state,
            payload
        ]
        case REMOVE_PAYMENT_METHODS:
        return state.filter((card)=>{
            return card.id !== payload
        })
        case CLEAN_PAYMENT_METHODS:
        return initialState    
        default:
        return state;
    }
}