import React,{useState,useEffect} from 'react';

import ConversationHolder from './ConversationHolder';
import Chat from './Chat/Chat';

import AZButtonLink from '../form/AZButtonLink';
import AZButtonIconLink from '../form/AZButtonIconLink'

import AZChat from './AZChat';

const ChatHolder = (props) => {
    const {
        onCloseChat,
        chatData,    
        isShown=-1                 
    } = props

    const {
        title,
        idConversation
    } = chatData

    const [show, setShow] = useState(true);

    useEffect(() => {
        
        console.log("open the holder",isShown>-1)
        setShow(isShown>-1)
    }, [isShown]);

    const renderHeader = ()=>{
        return <div className='d-flex w-100 justify-content-between text-start'>
            <div className='col-10' style={{overflowX:"hidden"}}>
                <AZButtonLink
                    style="gray"                    
                    type="button"
                    onClick={()=>{ setShow(!show) }}
                >
                    { title }
                </AZButtonLink>
            </div>
            <div className='col-1'>
                <AZButtonLink
                     style="gray"                     
                     type="button"
                     onClick={()=>{ onCloseChat(idConversation) }}
                >
                    <h5><i className="fa fa-times" aria-hidden="true"></i></h5>
                </AZButtonLink>
            </div>
        </div>
                
    }

    return (
        <div className=''>
            <ConversationHolder
                    header={
                        renderHeader()                          
                    }
                    show={show}
                    loading={false}
                    onScroll={()=>{}}
                    chat
                >
                <AZChat 
                    chatData={chatData}
                    loading={false}
                    onClickMessage={()=>{}}
                    onSend={()=>{}}
                />
                
            </ConversationHolder>
        </div>
    );
}

export default ChatHolder;
