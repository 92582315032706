import React,{useState,useEffect,useRef} from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'react-string-format';

import * as paymentActions from '../../actions/payments.actions'

import AZButton from '../form/AZButton';
import AZButtonIcon from '../form/AZButtonIcon';
import AZField from '../form/AZField';
import AZProfileImage from '../profile/AZProfileImage';
import AZTextArea from '../form/AZTextArea';
import { Range,getTrackBackground} from 'react-range';

import { formValidateField } from '../../helpers/formvalidation';

import CardItem from './CardItem';

import RP from '../../helpers/routePath';

import C from '../../constants'

import * as appActions from '../../actions/app.actions'

import PaymentsMethodsItems from './PaymentsMethodsItem';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



const RateUserForm = (props) => {
    const {                        
        onCancel=()=>{},
        onSubmit,
        errors,
        loading,
        dispatch,
        preData,
        rateInfo,
        success
    } = props   

    const {
        idUser,        
        idConversation,
        idRequest,        
        requestTitle,        
        name,
        lastName,
        imageSufix,
        guruInfo
    } = rateInfo

    const paymentStatus = {
        NOT_STARTED: 'NOT_STARTED',
        IN_PROGRESS: 'IN_PROGRESS',
        DONE: 'DONE',
    }; 
    

    const focusRef = useRef(null)
    const history = useHistory()
    const location = useLocation()   

    //payments
    const [selectedButton, setSelectedButton] = useState(preData.selectedButton);
    const [tip, setTip] = useState(preData.tip);
    const [lastPay, setLastPay] = useState(preData.lastPay);
    const [paymentMethod, setPaymentMethod] = useState(preData.paymentMethodForTip);
    
    const [userAlreadyInteractedWithSlider, setUserAlreadyInteractedWithSlider] = useState(false);
    const [slideValue, setSlideValue] = useState(preData.slideValue);    
    const [payingStatus, setPayingStatus] = useState(paymentStatus.NOT_STARTED);
    const [paymentMethodsVisible, setPaymentMethodsVisible] = useState(guruInfo!==undefined&&guruInfo!==null?true:false);
    

    const [form, setForm] = useState({
        "review":{
            'name':'review',
            'value':preData.review,
            'error':false,
            'errorText':''
        }
    });



    const {t,i18n} = useTranslation()

    const handleGiveProps = ()=>{        
        if(onSubmit){
            setForm({
                ...form,
                "review":{
                    ...form.review,
                    "error":false,
                    "errorText":""
                }
            })
            let errorForm = {}
            let isValid = true  
            
            // if(!formValidateField(form.review.value,'required')){
            //     isValid = false
            //     errorForm={
            //         ...errorForm,
            //         review:{
            //             ...form.review,
            //             "error":true,
            //             "errorText":t('RATE_review_placeholder')
            //         }
            //     }
            // }

            if(isValid){
                onSubmit({
                    idConversation,
                    'review':form.review.value,
                    'rating':parseInt(slideValue),
                    'paymentMethodId':paymentMethod.id,
                    'amount':tip,
                    'success':success!==""
                })
            }else{                
                setForm({
                    ...form,
                    ...errorForm
                })
            }
            
        }        
    }

    const handleRange = (value) => {
        console.log('okay')
        if(!userAlreadyInteractedWithSlider)
            setUserAlreadyInteractedWithSlider(true)

        setSlideValue([value])
    }
    const handleOnType = (e)=>{
        const {
            value,
            name
        } = e.target
        if(name == "tip"){
            if(value.match(/^\d+$/) || value=="" ){
                if (parseInt(value)>100){
                    setTip(100)
                }else{
                    setTip(value)
                }                
            }
        }
        if(name == "slider"){
            if(!userAlreadyInteractedWithSlider)
                setUserAlreadyInteractedWithSlider(true)

            setSlideValue([value])
        }
        if(name == "review"){  
            setForm({
                ...form,
                'review':{
                    ...form.review,
                    'value':value
                }
            })
        }
    }    
    const handleSelectPaymentMethod = ()=>{
        history.push(RP.getPath(t,RP.ADD_PAYMENT_METHOD),{
            'selectionMode':true,
            'backpath':location.pathname,
            'giveTipData':{
                selectedButton,
                lastPay,
                tip,
                slideValue:parseInt(slideValue),
                'review':form.review.value
            }
        })
    }

    const getPaymentMethods = () =>{        
        dispatch(
            paymentActions.getPaymentMethods()
        ).then(
            (response) => {
                const {
                    list
                } = response
                let pm = list.find((m)=>(m.isDefault))
                console.log('pm-q',pm)
                if(pm){
                    setPaymentMethod(pm)
                }else{
                    setPaymentMethod({
                        id:0
                    })                    
                }                
                console.log('get payments list response',response)               
                return 
            },
            (error) => {
                console.log('get payments list error',error)                
                return 
            }
        )
    } 
    const getLastPay = ()=>{
        dispatch(
            paymentActions.getLastPayment(idUser)
        ).then(
            (response) => {
                const {
                    codeNumber="",
                    codeDescription="",
                    ...restResponse
                } = response
                setLastPay({                    
                    ...restResponse,
                    'loading':false
                })
                console.log('get last',restResponse)
                return 
            },
            (error) => {
                setLastPay({  
                    ...lastPay,                
                    'loading':false
                })
                console.log('get last error',error)
                return 
            }
        )
    }

    const renderLastPayDate = (date)=>{
        moment.locale(i18n.language.substr(0,2))
        let newDate=moment.utc(date);
        if(newDate.isValid()){
            return newDate.local().fromNow()            
        }
        return " "
    }
   
    useEffect(() => {
        console.log('cached payment method',paymentMethod)
        if(lastPay.dateLastTip==null)
            getLastPay()
        if(paymentMethod.id == -1){
            getPaymentMethods()
        }        
    },[]);
    useEffect(() => {
        setPaymentMethod(preData.paymentMethodForTip)        
    },[preData.paymentMethodForTip]);

    return (
        <div className=''>
            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <AZProfileImage
                        user={guruInfo?{
                            idUser,
                            imageSufix,
                            isAGuru:true,
                            guruLevel:guruInfo.guruLevel,
                            isIndependent:guruInfo.guruIsIndependent
                        }:{
                            idUser,
                            imageSufix,
                            isAGuru:false
                        }}
                        onClick={()=>{}}
                        size="md"
                    >                        
                    </AZProfileImage>
                </div>
            </div>
            <div className={success!==""?"d-none":""}>
                <div className="row">
                    <div className="col-12">                        
                        {requestTitle&&
                            <h5 className='text-gray-strong'>
                                {requestTitle}
                            </h5>
                        }
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-6 text-center">
                        <h3 className='my-2'>{slideValue}<i className="fa fa-star ms-2"></i></h3>
                        {/* <input 
                        className='my-2'
                        type="range" 
                        name="slider" 
                        id="slider" 
                        min={0} 
                        max={10} 
                        step={1} 
                        value={slideValue}
                        onChange={handleOnType} />  */}
                        <Range
                            name="slider"
                            step={1}
                            min={0}
                            max={10}
                            values={[slideValue]}
                            onChange={handleRange}
                            renderTrack={({ props, children }) => (
                                <div
                                  onMouseDown={props.onMouseDown}
                                  onTouchStart={props.onTouchStart}
                                  style={{
                                    ...props.style,
                                    height: "36px",
                                    display: "flex",
                                    width: "100%"
                                  }}
                                >
                                  <div
                                    ref={props.ref}
                                    style={{
                                      height: "8px",
                                      width: "100%",
                                      borderRadius: "4px",
                                      background: getTrackBackground({
                                        values: [slideValue],
                                        colors: ["purple", "gray"],
                                        min: 0,
                                        max: 10
                                      }),
                                      alignSelf: "center"
                                    }}
                                  >
                                    {children}
                                  </div>
                                </div>
                              )}
                            renderThumb={({ props }) => (
                                <div
                                    {...props}
                                    style={{
                                    ...props.style,
                                    height: '20px',
                                    width: '20px',
                                    borderRadius:'10px',
                                    backgroundColor: 'white',
                                    border:'1px solid gray'
                                    }}
                                />
                            )}
                        />                        
                    </div>
                    <div className="col-12 text-center">
                        <h5 className='text-gray-strong'>
                            {guruInfo !== null
                                ? t('RATE_rate_and_tip_title')
                                : t('RATE_rate_title')}{' '}
                            {name}
                        </h5>
                    </div>
                    <div className="col-12 text-center">                                       
                        <AZTextArea
                            {...form.review}
                            onChange={handleOnType}
                        >
                            {t('RATE_review_placeholder')}
                        </AZTextArea>
                    </div>                
                </div>
                {
                    paymentMethodsVisible&&selectedButton!=='CUSTOM'&&
                    <div className="row my-2">
                        <h3 className='text-center'>
                            
                        </h3>
                    </div>
                }
                <div className={!paymentMethodsVisible&&"d-none"}>
                    <div className="row my-2">
                        <div className="col-12 text-center">
                            <h5 className='text-gray-strong'>
                                {format(t('RATE_tip_service_title'),name)}
                            </h5>
                            {
                                lastPay.loading&&name?
                                <h5 className='text-center'><i className="fa fa-spinner fa-spin"></i></h5>
                                :guruInfo==null?<div></div>
                                :lastPay.dateLastTip!==null&&<h5 className='text-purple my-1'>                                
                                    {format(t('RATE_tip_service_already_tipped'),lastPay.amountLastTip,lastPay.currencyLastTip,name,renderLastPayDate(lastPay.dateLastTip))}
                                </h5>
                            }
                        </div>
                    </div>
                    <div className="row my-2">
                        <div className="col-12 my-1">
                            <AZButton
                                    style={selectedButton=='FIRST'?"color":"graySoft"}
                                    onClick={()=>{setTip(2);setSelectedButton('FIRST')}}
                                    type="button"
                                    size='sm'
                                    customClass={`w-100 ${selectedButton!=='FIRST'&&"text-purple"}`}
                                >
                                $2.00 USD
                            </AZButton>
                        </div>                    
                        <div className="col-12 my-1">
                            <AZButton
                                style={selectedButton=='SECOND'?"color":"graySoft"}
                                onClick={()=>{setTip(5);setSelectedButton('SECOND')}}
                                type="button"
                                size='sm'
                                customClass={`w-100 ${selectedButton!=='SECOND'&&"text-purple"}`}
                            >
                                $5.00 USD
                            </AZButton>
                        </div>
                        <div className="col-12 my-1">
                            <AZButton
                                style={selectedButton=='CUSTOM'?"color":"graySoft"}
                                onClick={()=>{setSelectedButton('CUSTOM');setTip("");}}
                                type="button"
                                size='sm'
                                customClass={`w-100 ${selectedButton!=='CUSTOM'&&"text-purple"}`}
                            >
                                {t('RATE_custom')}
                            </AZButton>
                        </div>
                        <div className="col-12 my-1">
                            <AZButton
                                style={selectedButton=='NO_TIP'?"color":"graySoft"}
                                onClick={()=>{setSelectedButton('NO_TIP');setTip(0);}}
                                type="button"
                                size='sm'
                                customClass={`w-100 ${selectedButton!=='NO_TIP'&&"text-purple"}`}
                            >
                                {t('RATE_no_thanks')}
                            </AZButton>
                        </div>
                    </div>
                    {
                        selectedButton=='CUSTOM'&&
                        <div className="row my-2">
                            <div className="col-12 my-1">
                                <p className='text-center'>
                                    {t('RATE_custom_description')}
                                </p>
                            </div>
                            <div className="col-12">
                                <AZField
                                    onChange={handleOnType}
                                    name="tip"
                                    value={tip}
                                    type="number"                                
                                >
                                    {t('RATE_custom_placeholder')}
                                </AZField>
                            </div>
                        </div>
                    }
                    {
                        paymentMethod.id!==0&&paymentMethod.id!==-1&&selectedButton!=="NO_TIP"?
                        <div className='row my-2 cursor-pointer' onClick={handleSelectPaymentMethod}>
                            <div className="col-12">
                                <CardItem 
                                    {...paymentMethod}
                                    selectionMode={false}
                                />

                            </div>
                            <div className="col-12">
                                <div className="divider bg-gray"></div>
                            </div>                    
                        </div>:paymentMethod.id==0? 
                        <div className="col-12">
                            {console.log(`check these: ${paymentMethodsVisible}&&${selectedButton!=='NO_TIP'}`)}
                            <PaymentsMethodsItems
                                key={-1}
                                isIddle    
                                selectioMode={true}                       
                                onItemClick = {handleSelectPaymentMethod}
                                error={paymentMethodsVisible&&selectedButton!=='NO_TIP'&&selectedButton!=='none'}
                            >
                            </PaymentsMethodsItems>
                        </div>                           
                        :selectedButton!=="NO_TIP"&&
                        <div className="row">
                            <div className="col-12 text-center">
                                <i className="fa fa-spinner fa-spin"></i>
                            </div>
                        </div>            
                    }
                </div>
            </div>

            {
                errors!==""&&success==""&&
                <div className="row my-2">
                    <div className="col-12">
                        <p className='text-danger'>{errors}</p>
                    </div>
                </div>
            }
            {
                success!==""&&
                <div className="row my-2">
                    <div className="col-12">
                        <p className='text-purple'>{success}</p>
                    </div>
                </div>
            }

            <div className="row my-2 justify-content-center">
                {
                    success==""&&<div className="col-md-auto col-4 d-none">
                        <AZButton
                            style="gray"
                            onClick={onCancel}
                            type="button"
                            customClass="w-100"
                        >
                            {t('GENERAL_cancel')}
                        </AZButton>
                    </div>
                }
                <div className="col-auto col-8">
                    <AZButton
                        style="color"
                        onClick={handleGiveProps}
                        type="button"
                        customClass="w-100"
                        loading={loading}
                        onLoadText={t('RATE_in_progress_button')}
                        disabled={!userAlreadyInteractedWithSlider||(paymentMethodsVisible&&selectedButton!=="NO_TIP")&&(tip==0||(paymentMethod.id==-1||paymentMethod.id==0))}
                    >
                        {t(`${success!==''?'RATE_done_button':'RATE_ok_button'}`)}
                    </AZButton>
                </div>
            </div>
        </div>
    );
}

export default RateUserForm;
