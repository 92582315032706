import { connect } from 'react-redux';

import '../index.css';
import c from '../../constants'; 

import * as signActions from '../../actions/sign-ui.actions'
import * as authActions from '../../actions/auth.actions'
import * as navActions from '../../actions/navbar.actions'

import { useHistory } from 'react-router-dom';

import { getMessaging,getToken } from "@firebase/messaging";
import { getApp } from "@firebase/app";

import AZButton from '../../components/form/AZButton';

import RP from '../../helpers/routePath'
import { checkNotificationPromise, isNotificationsSupported, apiHasNetworkError} from '../../helpers/helpers'

import { useTranslation } from 'react-i18next';

import { useState,useEffect } from 'react';

import RegisterSecuence from '../../components/secuence/RegisterSecuence'
import AZModal from '../../components/modal/AZModal'
import AZModalTitle from '../../components/modal/AZModalTitle'
import SignInForm from '../../components/sign/SignInForm'
import PasswordRecoveryForm from '../../components/sign/PasswordRecoveryForm'

import {formValidateField} from "../../helpers/formvalidation"

const Home = (props) => {
    const {
        dispatch,
        signPage,
        cache,
        isLoggedIn
    } = props

    const history = useHistory();

    const { t, i18n } = useTranslation();

    const [recoveryLoading, setRecoveryLoading] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [finishPassword, setFinishPassword] = useState(false);
    const [loginCredentials, setloginCredentials] = useState({
        so:"WEB"
    });

    const handleLoginModal = (show) => {
        console.log(`JOIN ${signPage.showLogin}`)        
        dispatch(
            signActions.showLoginForm(show)
        )
    }
    const handleRegisterModal = (show) =>{
        console.log("show secuence")
        dispatch(
            signActions.showRegisterForm(show)
        )
    }
    const handlePasswordRecoveryModal = (show) =>{
        console.log("show password")
        dispatch(
            signActions.showPasswordRecoveryForm(show)
        )
    }

    const handlePasswordRecoverySubmit = (form,onSuccess,onError) =>{
        const lang = i18n.language;
        const {
            email
        }=form;
        let errors = {}
        let validForm = true;
        setRecoveryLoading(true)       

         if(!formValidateField(email.value,'required')){
            validForm = false
            errors['email'] = {
                ...email,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_email"
            }
        }        
        
        if(!formValidateField(email.value,'email')){
            validForm = false
            errors['email'] = {
                ...email,
                error:true,
                errorText:"FORM_ERROR_EMAIL_email"
            }
        }

        if(validForm){
            authActions.passwordRecovery(email.value,lang).then(
                (data)=>{
                    setRecoveryLoading(false)
                    setFinishPassword(true)
                    onSuccess(data)
                },
                (error)=>{
                    setRecoveryLoading(false)
                    onError(error,errors)
                }
            )
        }else{
            setRecoveryLoading(false)
            onError(null,errors)
        }

    }
    const handleRegisterSubmit = () =>{
       
    }
    const handleLoginSubmit = async (form,onSuccess,onError) => {
        const lang = i18n.language;
        const {
            email,
            password,
            remember_me
        } = form;
        let errors = {}
        let validForm = true;
        console.log("click")
        setLoginLoading(true)

         if(!formValidateField(email.value,'required')){
            validForm = false
            errors['email'] = {
                ...email,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_email"
            }
        }
         if(!formValidateField(email.value,'email')){
            validForm = false
            errors['email'] = {
                ...email,
                error:true,
                errorText:"FORM_ERROR_EMAIL_email"
            }
        }
        if(!formValidateField(password.value,'required')){
            validForm = false
            errors['password'] = {
                ...password,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_password"
            }
        }

        if(validForm){
            const  handleNotificationRequest = async (permission)=>{
                var sendCredentials = {
                    ...loginCredentials,     
                    "email":email.value,                              
                    "password":password.value,                   
                }
                if (permission !== "granted") {
                    // onError({
                    //     data:{
                    //         codeNumber:"notifications"
                    //     }
                    // },errors)
                    // setLoginLoading(false)
                    // return                    
                }else{                    
                    //TODO:LEGR this code must have a better testing disabled for now until receive push notifications
                    // const token = await getToken(getMessaging(getApp()))
                    // console.log('is not waiting?',token)
                    // sendCredentials = {
                    //     ...sendCredentials,
                    //     "device":token
                    // }                    
                }           

                setloginCredentials({
                    ...sendCredentials,                                                                  
                })

                dispatch(authActions.login({
                    ...sendCredentials
                })).then(
                    (data)=>{                                    
                        console.log("loginSuccess")
                        console.log(data)
                        setLoginLoading(false)
                        //Go to dashboard
                    },
                    (error)=>{                        
                        if(apiHasNetworkError(error)){
                            errors['password'] = {
                                ...password,
                                error:true,
                                errorText:'ERROR_internet'
                            }
                        }else{
                            onError(error.response,errors)
                        }                      
                        setLoginLoading(false)
                    }                               
                )
            }

            try {
                if(remember_me.value){
                    dispatch(
                        signActions.cacheEmail(true,email.value)
                    )
                }else{
                    dispatch(
                        signActions.cacheEmail(false)
                    )
                }

                //const hasRequestPromise = checkNotificationPromise()
                /*
                if(hasRequestPromise){
                    let permission =  await Notification.requestPermission();
                    handleNotificationRequest(permission)
                }else{
                    if(isNotificationsSupported()){
                        Notification.requestPermission((permission)=>{
                            handleNotificationRequest('')
                        })
                    }else{
                        handleNotificationRequest('')
                    }
                }
                */

                var sendCredentials = {
                    ...loginCredentials,     
                    "email":email.value,                              
                    "password":password.value,                   
                }
                /*
                if (permission !== "granted") {
                    // onError({
                    //     data:{
                    //         codeNumber:"notifications"
                    //     }
                    // },errors)
                    // setLoginLoading(false)
                    // return                    
                }else{                    
                    //TODO:LEGR this code must have a better testing disabled for now until receive push notifications
                    // const token = await getToken(getMessaging(getApp()))
                    // console.log('is not waiting?',token)
                    // sendCredentials = {
                    //     ...sendCredentials,
                    //     "device":token
                    // }                    
                } */         

                setloginCredentials({
                    ...sendCredentials,                                                                  
                })

                dispatch(authActions.login({
                    ...sendCredentials
                })).then(
                    (data)=>{                                    
                        console.log("loginSuccess")
                        console.log(data)
                        setLoginLoading(false)
                        //Go to dashboard
                    },
                    (error)=>{                        
                        if(apiHasNetworkError(error)){
                            errors['password'] = {
                                ...password,
                                error:true,
                                errorText:'ERROR_internet'
                            }
                        }else{
                            onError(error.response,errors)
                        }                      
                        setLoginLoading(false)
                    }                               
                )
            } catch (error) {
                setLoginLoading(false)
               
                errors['password'] = {
                    ...password,
                    error:true,
                    errorText:error
                }                
                onError(null,errors)
            }
        }else{            
            setLoginLoading(false)
            onError(null,errors)
        }
        //authActions.login()
    }

    useEffect(() => {
        setloginCredentials({
            ...loginCredentials,
            "lang":i18n.language.substr(0,2)
        })
    },[]);
    useEffect(() => {
        console.log('check create account')
        if(isLoggedIn){
            dispatch(navActions.switchToSeeker())
            history.replace(RP.BASE)
            
        }
    },[isLoggedIn]);

    return (
        <div className=''>
            {/* <!-- Boton hacia arriba --> */}
            <a className="ir-arriba"  href="javascript:void(0)" title="Volver arriba">
                <i className=""><img src={c.resources.webpage+"up.svg"}/></i>
            </a>
            {/* Sign form */}
            <AZModal
                show={signPage.showLogin}
                onClose={()=>{handleLoginModal(false)}}
            >
                <SignInForm
                    onSubmit = {handleLoginSubmit}
                    onCreateAccountClick = {()=>{handleRegisterModal(true)}}
                    onForgetPasswordClick = {()=>{handlePasswordRecoveryModal(true)}}
                    loading={loginLoading}
                    cache={cache}
                >                    
                </SignInForm>
            </AZModal>
            {/* Password recovery form */}
            <AZModalTitle
                show={signPage.showPasswordRecovery}
                title={t('RECOVERY_PASS_title')}
                onClose={()=>{handlePasswordRecoveryModal(false);setFinishPassword(false)}}
            >           
                {
                    finishPassword?
                    <div className="p-3">
                        <div className="row col-12">
                            <p>
                                {t('AUTH_recovery_password_send_success')}
                            </p>
                        </div>
                        <div className="row justify-content-center my-2">
                            <div className="col-auto">
                                <AZButton                                    
                                    loading={false}
                                    onLoadText={t('LOADING')}
                                    onClick={()=>{handlePasswordRecoveryModal(false);setFinishPassword(false)}}
                                    type="button"
                                >
                                    {t('GENERAL_ok')}                      
                                </AZButton>  
                            </div>
                        </div>
                    </div>:
                    <PasswordRecoveryForm
                        onSubmit={handlePasswordRecoverySubmit}
                        loading={recoveryLoading}
                    >                    
                    </PasswordRecoveryForm>
                }                     
            </AZModalTitle>
            
            {/* Register secuence */}
            <RegisterSecuence
                show={signPage.showRegister}
                onClose={()=>{handleRegisterModal(false)}}
                onSubmit={handleRegisterSubmit}
            >                
            </RegisterSecuence>
            {/*}
            <LandingPage 
                {...props}
                onJoinClick={()=>{handleLoginModal(true)}}             
            />
            */}
            <div className="">
                <div className="container bg-transparent">
                    <div className="row justify-content-center align-content-center">
                        <div className="col-md-5 col-lg-4 col-12 py-5 my-5 az-container bg-white">
                            <SignInForm
                                onSubmit = {handleLoginSubmit}
                                onCreateAccountClick = {()=>{handleRegisterModal(true)}}
                                onForgetPasswordClick = {()=>{handlePasswordRecoveryModal(true)}}
                                loading={loginLoading}
                                cache={cache}
                            >                    
                            </SignInForm>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state){    
    console.log("the state",state)
    const {
        auth,
        signPage,
        cache
    } = state
    return {
        'isLoggedIn':auth.isLoggedIn,
        signPage,
        cache
    }
}

export default connect(mapStateToProps)(Home);

