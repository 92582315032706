import React,{useState,useEffect} from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory,useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import AZButton from '../../components/form/AZButton';

import * as appActions from '../../actions/app.actions'
import * as navActions from '../../actions/navbar.actions'

import RP from '../../helpers/routePath';

import NewRequestSendHints from '../../components/seeker/NewRequestSendHints';
import ExploreGurusView from '../../components/seeker/ExploreGurusView';

const NewSearchGurus = (props) => {
    const {
        dispatch,
        currentRequest
    } = props

    const {
        requestStep,
        requestId,
        matchedGurus
    } = currentRequest
    

    const {t} = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const rows = 10          

    useEffect(() => {
        dispatch(navActions.switchToSeeker())        
    }, []);

    useEffect(() => {
        console.log('requestStep',requestStep)
        if(requestStep == 0){            
            history.replace(RP.getPath(t,RP.NEW_SEARCH))
        }
    }, [requestStep]);

    const handleFinish = () =>{
        console.log('dataBack handlefinish:clean request?')        
        if(requestId){   
            dispatch(
                appActions.setGlobalMessage(t('MSG_OK_new_request_text'))
            )
           
            dispatch(
                appActions.cleanCurrentRequest()
            )         
            
            dispatch(
                navActions.switchToSeeker()
            )

            history.push(RP.getPath(t,RP.REQUEST_LIST))
        }else{
            dispatch(
                appActions.setCurrentRequest({
                    ...currentRequest,
                    requestStep:0,   
                    matchedGurus:{
                        gurusList:[],
                        loadedElements:0,
                        gurusStatus:{}
                    }                    
                })
            )            
        }
    }
 
    return (        
        <div>
            <div className="container-lg py-1 bg-transparent">
                <div className="row justify-content-center">
                    <div className="col-12 col-container">
                        <div className="row my-3">                           
                            <div className="col-12">
                                {                                    
                                    <AZButton
                                        style="color"
                                        type="button"
                                        onClick={handleFinish}
                                    >
                                        {requestId?t('BROWSE_GURU_finish'):t('BROWSE_GURU_edit_request')}
                                    </AZButton>
                                }
                            </div>
                        </div>

                        <div className="row h-100">
                            <div className="col-md-8">
                                {/* New search form */}
                                <div className='az-card-container h-100'>                            
                                    <ExploreGurusView 
                                        currentRequest={currentRequest}
                                        dispatch={dispatch}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='az-card-container h-100'>
                                    {
                                        <NewRequestSendHints/>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>   
        </div>
    );
}

const mapStateToProps = (state)=>{
    const {currentRequest} = state
    console.log('currentRequestStatus...',currentRequest)
    return {       
        currentRequest
    }
}

export default connect(mapStateToProps)(NewSearchGurus);