import React from 'react';
import c from "../../constants"

import AZButtonLink from '../form/AZButtonLink';

const AZBarMenu = (props) => {
    const {
        show,
        children,
        onClose
    } = props

    return (
        <div className={`az-bar-menu ${!show&&"az-bar-menu--hide"}`}>
            <div className="az-bar-menu__header align-items-center">
                <div className="col">
                    <div className="az-navbar__logo" style={{"cursor":"pointer"}}>
                        <img src={`${c.resources.webpage}app/askshao.png`} alt="" />
                    </div> 
                </div>
                <div className="col-2 text-right">
                    <AZButtonLink
                        style="color"
                        onClick={()=>{if(onClose){onClose()}}}
                    >
                        <h1 className="text-center text-dark">
                            <i className="fa fa-times"></i>
                        </h1>
                    </AZButtonLink>
                </div>
            </div>
            <div className="az-bar-menu__body text-center">
                {children}
            </div>
        </div>
    );
}

export default AZBarMenu;
