import React from 'react';
import TipsGivenItem from './TipsGivenItem';
import "../../pages/index.css"

import C from "../../constants"

const TipsGivenList = (props) => {
    const {
        list,
        noData,        
        loading,
        onItemClick,  
        onItemReview,             
        full=false
    } = props

    return (
        <div className={`az-list ${full&&"az-list--full"}`}>
            {loading&&<div className="az-list__loading">
                <i className="fa fa-spinner fa-spin"></i>
            </div>}
            <div className="az-list__content" >                
                {list&&list.length>0?
                    list.map(v=>(
                        <TipsGivenItem
                            key={v.id}
                            model={v}                                                      
                            onItemClick = {onItemClick}
                            onItemReview = {onItemReview}
                        >
                        </TipsGivenItem>
                    ))
                :<div>
                    {!loading&&list.length==0?
                     <div className='row justify-content-center'>
                        <div className="col-12 my-2">
                            <h4 className="p-2 text-center text-muted">{noData}</h4>
                        </div>
                        <div className="col-4 col-md-6 mt-4">
                            <img className='w-100 p-2' src={`${C.resources.webpage}app/sinpropinas.png`} alt="" />
                        </div>
                    </div>
                    : <h4></h4>  }                    
                </div>    
                }                
            </div>
        </div>
    );
}

export default TipsGivenList;
