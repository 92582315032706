import React from 'react';
import AZField from "../form/AZField"
import AZPassword from "../form/AZPassword"
import AZButton from "../form/AZButton"

import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const ChangePasswordWebForm = (props) => {
    const { t, i18n } = useTranslation();  
    
    const {
        onSubmit,
        onClose,
        loading
    } = props

    const [form, setForm] = useState({
        "password":{
            name:"password",
            value:"",
            error:false,
            errorText:""
        },
        "passwordConfirm":{
            name:"passwordConfirm",
            value:"",
            error:false,
            errorText:""
        }
    });
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [showForm, setShowForm] = useState(true);

    const handleSubmit = (e)=>{
        e.preventDefault()
        Object.keys(form).map(i=>form[i]={
            ...form[i],
            error:false,
            errorText:""
        })
        setMessage("")
        setErrorMessage("")
        if(form.password.value !== form.passwordConfirm.value){
            setForm({
                ...form,
                'passwordConfirm':{
                    ...form.passwordConfirm,
                    'error':true,
                    'errorText':t('FORM_ERROR_CONFIRM_password')
                }
            })

            return
        }
        onSubmit(
            form,
            (data)=>{
                setShowForm(false)
                setMessage(t('AUTH_recovery_password_success'))                
            },
            (response,errors)=>{
                if(response){
                    setErrorMessage("ERROR_"+response.codeNumber)
                }
                if(errors!==null){
                    setForm({
                        ...form,
                        ...errors
                    })
                }                
            }
        )
    }
    const handleChange = (e)=>{
        const {
            value,
            name
        }=e.target

        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })
    }

    return (
        <form  className="my-2" onSubmit={handleSubmit}>
            {showForm&&
                <div>
                    <div className="row ">
                        <div className="col-12 my-2">
                            <AZPassword
                                name={form.password.name}
                                value={form.password.value}
                                error={form.password.error}
                                errorText={form.password.errorText}
                                onChange={handleChange}
                            >
                                {t('AUTH_recovery_password_password_field_placeholder')}
                            </AZPassword>
                        </div>
                    </div>
                    <div className="row justify-content-center my-2">
                        <div className="col-12">
                            <AZPassword
                                name={form.passwordConfirm.name}
                                value={form.passwordConfirm.value}
                                error={form.passwordConfirm.error}
                                errorText={form.passwordConfirm.errorText}
                                onChange={handleChange}
                            >
                                {t('AUTH_recovery_password_password_confirm_field_placeholder')}
                            </AZPassword>
                        </div>
                    </div>
                </div>
            }
            
            <div className="row my-1">
                <div className="col-12">
                    <p className='text-center'>
                        {t(message)}
                    </p>
                </div>
            </div>
            <div className="row my-1">
                <div className="col-12 text-danger">
                    <p className='text-center'>                        
                        {t(errorMessage)}
                    </p>
                </div>
            </div>
            {showForm&&
            <div className="row justify-content-center">               
                <div className="col-md-6 my-2">
                    <AZButton
                        loading={loading}
                        onClick={()=>{}}
                        customClass="w-100"
                        type="submit"
                    >
                        {t('PROFILE_update_password_update_button')}
                    </AZButton>
                </div>
            </div>
            }
            
        </form>
    );
}

export default ChangePasswordWebForm;
