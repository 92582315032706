import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';
import AZButtonLevel from '../form/AZButtonLevel';
import AZButton from '../form/AZButton';
import AZDesicionModal from '../modal/AZDesicionModal';
import AZField from '../form/AZField';
import AZButtonIconLink from '../form/AZButtonIconLink';
import { formValidateField } from '../../helpers/formvalidation';


const SelectGuruIndepent = (props) => {

    const {
        onOk,
        backIsIndependent=-1,
        backCompanyName="",
        onBack,
        update
    } = props

    const {t} = useTranslation()

    const [isIndependent, setIsIndependent] = useState(backIsIndependent);
    const [showCompanyModal,setShowCompanyModal] = useState(false)
    const [companyName, setCompanyName] = useState(backCompanyName);

    const [form, setForm] = useState({
        companyName:{
            name:"companyName",
            value:"",
            error:false,
            errorText:""
        }
    });

    const handleFormChange = (e)=>{
        const {value} = e.target
        setForm({
            ...form,
            'companyName':{
                ...form['companyName'],
                value:value
            }
        })
    }

    const handleSaveCompany = ()=>{        
        let errors = {}
        let validForm = true;

        const {
            companyName
        }= form
               
        if(!formValidateField(companyName.value,'required')){
            validForm = false
            errors['companyName'] = {
                ...companyName,
                error:true,
                errorText:"FORM_ERROR_"
            }
        }
        if(validForm){
            setCompanyName(companyName.value)
            setShowCompanyModal(false)        
            setIsIndependent(0)          
        }else{
            if(errors!==null){
                setForm({
                    ...form,
                    ...errors
                })
            }
        }        
    }

    const handleOk = ()=>{
        onOk({
            isIndependent,
            companyName
        })
    }

    return (
        <div>
            <AZDesicionModal
                title={t('BG_TYPE_not_independent_prompt_title')}                
                onClose={()=>{ setShowCompanyModal(false)  }}
                show={showCompanyModal}
                noOptionText={t('GENERAL_cancel')}
                yesOptionText={t('GENERAL_ok')}
                onYesOption={handleSaveCompany}
                onNoOption={()=>{ setShowCompanyModal(false)  }}
                loading={false}
            >
                <div className="p-5">
                    <h4 className="my-1">
                        {t('BG_TYPE_not_independent_prompt_description')}
                    </h4>
                    <AZField 
                        {...form.companyName}
                        onChange={handleFormChange}
                    >       
                        {t('BG_TYPE_not_independent_prompt_placeholder')}
                    </AZField>
                </div>                
            </AZDesicionModal>
            {update&&<div className="row">
                <div className="col-auto">
                    <AZButtonIconLink
                        leftIcon={<i className="fa fa-chevron-left" aria-hidden="true"></i>}
                        style="color"
                        onClick={()=>{onBack()}}
                    >
                        {t('GENERAL_back')}
                    </AZButtonIconLink>    
                </div>
            </div>}
            <div className="row my-2">
                <div className="col-12">
                    <h2 className='text-center'>{t('BG_TYPE_title')}</h2>
                </div>
            </div>
            <div className="row justify-content-center my-4">
                <div className="col-md-10 my-1">
                    <AZButtonLevel                        
                        selected={isIndependent==1}
                        title={t('PROFILE_update_TYPE_option_is_independent_title')}
                        description={t('PROFILE_update_TYPE_option_is_independent_text')}
                        onClick={()=>{setIsIndependent(1);setCompanyName("")}}
                    >
                    </AZButtonLevel>
                </div>
                <div className="col-md-10 my-1">
                    <AZButtonLevel                        
                        selected={isIndependent==0}
                        title={t('PROFILE_update_TYPE_option_not_independent_title')}
                        description={`${companyName==""?t('PROFILE_update_TYPE_option_not_independent_text'):companyName}`}
                        onClick={()=>{setShowCompanyModal(true);}}
                    >                        
                    </AZButtonLevel>
                </div>
            </div>           
            <div className="row my-4 justify-content-center">
                <div className="col-auto">
                    <AZButton
                        style="color"
                        onClick={handleOk}
                        disabled={isIndependent==-1}
                    >
                        {update?t('PROFILE_update_is_independent_button'):t('BG_TYPE_button_save')}                        
                    </AZButton>
                </div>
            </div>
        </div>
    );
}

export default SelectGuruIndepent;
