import React,{useState,useEffect,useRef} from 'react';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import AZChat from '../AZChat';

import {v4 as uuidv4} from 'uuid';

import { 
    GiftedChat,
} from 'react-web-gifted-chat';

import {    
    ref,
    onValue,
    query,
    onChildChanged,
    off,
    orderByChild,
    startAt,
    onChildAdded,
    getDatabase
} from "firebase/database"

// import {
//     renderActions,
//     renderAudio,
//     renderBubble,
//     renderComposer,
//     renderSystemMessage,            
//     renderSend,
//     renderTime,    
//     renderTicks,    
// } from "./ChatComponents"



const Chat = (props) => {
    const {
      user,
      dispatch,
      chatData
    } = props    

    //export userVariables
    const {
      isAGuru,
      idUser
    } = user

    //export chat variables
    const {
      idConversation,
      idRequest,
      markedForRating
    } = chatData

    const {t} = useTranslation()
    const history = useHistory()
    const REF = ref(getDatabase(),'/chats/' + idRequest + '/messages/' + idConversation)    
    const recordedFileName = 'voice_note.m4a';
    const timerRef = useRef(null);

    const [messages, setMessages] = useState([]);

    const messagesKeys = useRef({});

    const [isRatingLoading, setRatingLoading] = useState(false);
    const [isMarkedForRating, setMarkedForRating] = useState(
        markedForRating
    );
    const [isRecording, setIsRecording] = useState(false);
    const [recorder, setRecorder] = useState(null);            

    const [chatState, setChatState] = useState({
        defaultCard: null,
        amountToPay: 0,
        currencyToPay: '',
        idMessageToPay: '',
        showDataAlertTip: false,
        showDataAlertPay: false,
        activityIndicator: false,
        showPopover: false,
    });

    const addLocalMessage = (payload) => {
        var newMessage = {
          _id: payload.idMessage,
          createdAt: new Date(),
          user: {_id: idUser},
        };
        if (payload.type == 0) { //plain text
          newMessage.text = payload.message;
        } else if (payload.type == 1) { //image
          newMessage.image = payload.message;
        } else if (payload.type == 2) {//audio
          newMessage.audio = payload.message;
          newMessage.duration = payload.duration;
        } else if (payload.type == 3) {//tip
          newMessage.currency = 'usd';
          newMessage.status = 1;
          newMessage.system = true;
          newMessage.type = 'ASKING_TIP';
        } else if (payload.type == 4) {//location
          newMessage.text = payload.message;
          newMessage.map = payload.map;
        }
    
        newMessage.pending = true;
        newMessage.sent = false;
    
        messagesKeys.current[newMessage._id] = true;
        setMessages(
          GiftedChat.append(messages, newMessage)
        );
    };

    const onSendMessage = (payload) => {
        // if (payload.map) delete payload.map;
        // await dispatch(
        //     SEND_A_MESSAGE({
        //     payload,
        //     successCall: function (response) {},
        //     errorCall: function (response) {
        //         onWSError(response);
        //     },
        //     }),
        // );
    };
    

    useEffect(() => {
      console.log("Chat start",REF)
        if (messages == null || messages.length == 0) {                    
            onValue(
                REF,
                (snapshot) => {
                    console.log("once")
                    //markConversationAsRead();
                    if (snapshot.exists()) {
                        var newMessages = [];
                        var newKeys = {};
                        snapshot.forEach((child) => {
                            let aMessage = child.val();
                            aMessage.key = child.key;
                            aMessage.sent = true;
                            aMessage.pending = false;
                            newMessages.push(aMessage);
                            messagesKeys.current[aMessage._id] = true;
                        });
                        let array = newMessages.reverse()
                        console.log('the array',array)
                        setMessages(
                            newMessages
                        );
                    }
                },
                true
            )
            
            onChildAdded(
                query(REF,orderByChild('createdAt'),startAt(Date.now())),
                (snapshot) => {
                    var newMessage = snapshot.val();
                    newMessage.key = snapshot.key;
                    if (newMessage.user._id !== idUser) {
                        //markConversationAsRead();
                    }
                    newMessage.sent = true;
                    newMessage.pending = false;
            
                    //If already in the messages list, UPDATE it
                    if (messagesKeys.current[newMessage._id] === true) {
                        setMessages((previousMessages) => {
                        const foundIndex = previousMessages.findIndex(
                            (aMessage) => aMessage._id == newMessage._id,
                        );
                        const newArr = [...previousMessages];
                        newArr[foundIndex] = newMessage;
                        return newArr;
                        });
                    } else {
                        //If it is not in the messages list, ADD it
                        messagesKeys.current[newMessage._id] = true;
                        setMessages((previousMessages) =>
                            GiftedChat.append(previousMessages, newMessage),
                        );
                    }
                }
            ) 
          
            onChildChanged(
                REF,
                (snapshot) => {
                    var message = snapshot.val();
                    message.key = snapshot.key;
                    if (message.system == true) {
                        if (messagesKeys.current[message._id] === true) {
                        setMessages((previousMessages) => {
                            const foundIndex = previousMessages.findIndex(
                            (aMessage) => aMessage._id == message._id,
                            );
                            const newArr = [...previousMessages];
                            newArr[foundIndex] = message;
                            return newArr;
                        });
                        }
                    } else {
                        //Do nothing
                    }
                }
            )              

            //   setRecorder(null);
            //   _reloadRecorder();

        }

        // if (isGuru) {
        //   loadPaymentMethods();
        // }

        // setTimeout(() => {
        //   showPopupAdviceIfNeeded();
        // }, 1000);
    
        return () => {
            off(REF)         
            // if (recorder && recorder.isRecording) {
            //     recorder.stop();
            // }
        };

    }, []);

    return (
        <div>
            <div>
              <h1>Chat :v</h1>
            </div>
            <div className='position-relative'>
                <AZChat 
                    messages={messages}
                    loading={false}
                    onClickMessage={()=>{}}
                    onSend={()=>{}}
                />
            </div>
            {/*<GiftedChat 
                messages={messages}
                onSend={(msgs) => console.log(msgs)/*onSendText(msgs)}
                user={{
                  _id: idUser,                 
                }}
                onLongPressAvatar={(user) => alert(JSON.stringify(user))}               
                keyboardShouldPersistTaps="never"
                renderAvatar={() => null}
                renderActions={renderActions}
                renderBubble={renderBubble}
                renderComposer={renderComposer}
                renderMessageAudio={renderAudio}
                renderSystemMessage={renderSystemMessage}                
                renderSend={renderSend}
                renderTime={renderTime}
                renderTicks={renderTicks}
                showAvatarForEveryMessage={true}
                isAnimated={true}
                maxComposerHeight={100}
            />*/}
        </div>
    );
}

const mapPropsToState = (state)=>{
    const {
        auth
    } = state

    return {
        user:auth.user
    }
}

export default connect(mapPropsToState)(Chat);
