import React from 'react';

const CircledLabel = (props) => {
    const {
        colorClasses="bg-success",  
        displayClasses="d-inline-block",
        children     
    } = props

    return (
        <div className={`circled-label mx-1 ${colorClasses} ${displayClasses}`} >
            <div className="circled-label__content">                
                <p>
                    {children}
                </p>                   
            </div>
        </div>
    );
}

export default CircledLabel;
