import React, { Children } from 'react';
import { useTranslation } from 'react-i18next';


const AZTextArea = (props) => {
    const {
        name,
        onChange,
        onKeyPress=()=>{},
        children,
        error,
        errorText,
        value,
        defaultValue,
        ref,
        rows
    }= props

    const {t,i18n} = useTranslation()

    return (
        <div>
            <div className={`az-textarea ${error&&"az-textarea--error"}`}>
                <div className="az-textarea__field">
                    <textarea 
                        name={name}
                        value={value?value:""}
                        onChange={onChange}
                        placeholder={children}
                        ref={ref}
                        rows={rows}
                        onKeyPress={onKeyPress}
                        >                    
                    </textarea>
                </div>                      
            </div>
            {
                error&&
                <div className="az-textarea__error">
                    <p>
                        {t(errorText)}
                    </p>
                </div>
            }  
        </div>
    );
}

export default AZTextArea;
