import {
    CLEAN_CACHE,
    UPDATE_CACHE
} from "../actions/types"

const cache = JSON.parse(localStorage.getItem("cache"));

const initialState = cache
? { cachedEmail:cache.cachedEmail,isEmailCached:cache.isEmailCached }
: { cachedEmail:"",isEmailCached:false };

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {                 
        case UPDATE_CACHE:
        return {
            ...state,
            ...payload,
            isEmailCached:true
        }
        case CLEAN_CACHE:   
        return {
            cachedEmail:"",
            isEmailCached:false
        }
        default:
        return state;
    }
}