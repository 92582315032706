import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import AZButton from '../form/AZButton';
import GooglePlacesAutocomplete,{geocodeByPlaceId} from 'react-google-places-autocomplete';

const SelectPlaces = (props) => {

    const {
        onOk,
        onSelectPlace
    } = props

    const {t,i18n} = useTranslation()
    
    const [place,setPlaces] = useState(null)
    const [placeData,setPlaceData] = useState(null)
    const [placesLoading, setPlacesLoading] = useState(false); 

    useEffect(() => {
        console.log("the place",place)    
        if(place !== null){
            setPlacesLoading(true)
            geocodeByPlaceId(place.value.place_id).then(
                results => {
                    const res = results[0]
                    setPlacesLoading(false)
                    console.log('the results',res)
                    var country = "";
                    res.address_components.forEach(function (component) {
                        if (component.types.includes('country')) {
                            country = component.short_name;
                            return;
                        }
                    });
        
                    const data = {
                        googlePlaceId: res.place_id,
                        isCountry: res.types.includes('country') ? true : false,
                        address: res.formatted_address,
                        country: country,
                        lat: res.geometry.location.lat,
                        lon: res.geometry.location.lng,
                    };
                    setPlaceData(data)
                    onSelectPlace(data)
                }
            )  
        }
    }, [place]);
    

 
    return (
        <div>                       
            <div className="row justify-content-center my-2">
                <div className="col-md-10 my-2">
                    <h4 className='text-center'>{t('BG_LOCATION_title')}</h4>
                </div>

                <div className="col-md-10 my-2">
                    <GooglePlacesAutocomplete                        
                        apiKey="AIzaSyCmLVYw6TSuvlso6ILBgLfcmWxH5u5a0JY"
                        apiOptions={
                            { language: i18n.language.substr(0,2), region: i18n.language.substr(0,2)=='es'?"MX":"US" }
                        }
                        selectProps={{
                            placeholder:t('NEW_REQUEST_placeholder_select_location'),
                            openMenuOnClick:true,
                            components:{ 
                                DropdownIndicator:() => null, 
                                IndicatorSeparator:() => null,
                                DownChevron:()=> null
                            },
                            styles:{
                                SingleValue:{
                                    borderRadius:'1rem',
                                    padding:'.4rem'
                                },                                
                            },
                            place,
                            onChange: setPlaces,                            
                        }}                        
                    >
                    </GooglePlacesAutocomplete>
                </div>
            </div>           
            <div className="row my-4 justify-content-center">
                <div className="col-auto">
                    <AZButton
                        style="color"
                        onClick={()=>{onOk(placeData)}}
                        loading={placesLoading}
                        onLoadText={t('LOADING')}
                    >
                        {t('BG_LANGS_button_done')}                        
                    </AZButton>
                </div>
            </div>
        </div>
    );
}

export default SelectPlaces;
