
const base_url="/"

class RoutePath{
    static base_url = base_url;
    static BASE="BASE";
    static REQUEST_LIST="REQUEST_LIST";
    static ARCHIVED_REQUESTS="ARCHIVED_REQUESTS";
    static SEARCH_GURUS="SEARCH_GURUS";
    static NEW_SEARCH="NEW_SEARCH";
    static NEW_SEARCH_GURUS="NEW_SEARCH_GURUS";
    static PROFILE="PROFILE";
    static PROFILE_EDIT="PROFILE_EDIT";
    static PROFILE_ID="PROFILE_ID";
    static PROFILE_USER="PROFILE_USER";
    static BLOQUED_USERS="BLOQUED_USERS";
    static REVIEWS="REVIEWS";
    static GURU_TUTORIAL="GURU_TUTORIAL";
    static SEEKER_TUTORIAL="SEEKER_TUTORIAL";
    static CONFIG_STRIPE="CONFIG_STRIPE";
    static ADD_PAYMENT_METHOD="ADD_PAYMENT_METHOD";
    static TIPS_GIVEN="TIPS_GIVEN";
    static TIPS_RECEIVED="TIPS_RECEIVED";
    static CONFIRM_ACCOUNT_ID="CONFIRM_ACCOUNT_ID";
    static RECOVER_PASSWORD_ID="RECOVER_PASSWORD_ID";

    /*
    @name routePath
    @description Get the localized path string    
    @param tf - translate function
    @param type - the path type
    @param payoad - param type 
    @return string - localized path
    */
    static getPath = (tf,type,payload=0)=>{
        try {
            switch (type) {
                case this.BASE:
                    return this.base_url;                    
                case this.ARCHIVED_REQUESTS:
                    return `${this.base_url}archived-request`                                                           
                case this.SEARCH_GURUS:
                    return `${this.base_url}search-gurus`                    
                case this.REQUEST_LIST:
                    return `${this.base_url}request-list`                    
                case this.NEW_SEARCH:
                    return `${this.base_url}new-search`                    
                case this.NEW_SEARCH_GURUS:
                    return `${this.base_url}send-request`                    
                case this.PROFILE:
                    return `${this.base_url}my-profile`                    
                case this.PROFILE_EDIT:
                    return `${this.base_url}profile-edit`                    
                case this.PROFILE_ID:
                    return `${this.base_url}profile/:id`                    
                case this.PROFILE_USER:
                    return `${this.base_url}profile/${payload}`                    
                case this.BLOQUED_USERS:
                    return `${this.base_url}blocked-users`                    
                case this.REVIEWS:
                    return `${this.base_url}reviews`                    
                case this.GURU_TUTORIAL:
                    return `${this.base_url}guru-tutorial`                    
                case this.SEEKER_TUTORIAL:
                    return `${this.base_url}seeker-tutorial`                    
                case this.CONFIG_STRIPE:                    
                    return `${this.base_url}config-stripe`                    
                case this.ADD_PAYMENT_METHOD:                    
                    return `${this.base_url}add-payment-method`                    
                case this.TIPS_GIVEN:                    
                    return `${this.base_url}tips-given`                    
                case this.TIPS_RECEIVED:                    
                    return `${this.base_url}tips-received`                    
                case this.CONFIRM_ACCOUNT_ID:                    
                    return `${this.base_url}validateEmail/:id/:code`                    
                case this.RECOVER_PASSWORD_ID:
                    return `${this.base_url}recoverPassword/:id/:code`                    
                default:
                    break;
            }
            
        } catch (error) {
            return this.base_url;
        }
    }
    static getPathT = (tf,type,payload=0)=>{
        try {
            switch (type) {
                case this.BASE:
                    return this.base_url;                    
                case this.ARCHIVED_REQUESTS:
                    return `${this.base_url}${tf('PATH_archived_requests')}`                                                           
                case this.SEARCH_GURUS:
                    return `${this.base_url}${tf('PATH_search_gurus')}`                    
                case this.NEW_SEARCH:
                    return `${this.base_url}${tf('PATH_new_search')}`                    
                case this.PROFILE:
                    return `${this.base_url}${tf('PATH_profile')}`                    
                case this.PROFILE_EDIT:
                    return `${this.base_url}${tf('PATH_profile_edit')}`                    
                case this.PROFILE_ID:
                    return `${this.base_url}${tf('PATH_profile')}/:id`                    
                case this.PROFILE_USER:
                    return `${this.base_url}${tf('PATH_profile')}/${payload}`                    
                case this.BLOQUED_USERS:
                    return `${this.base_url}${tf('PATH_bloqued_users')}`                    
                case this.REVIEWS:
                    return `${this.base_url}${tf('PATH_reviews')}`                    
                case this.GURU_TUTORIAL:
                    return `${this.base_url}${tf('PATH_guru_tutorial')}`                    
                case this.SEEKER_TUTORIAL:
                    return `${this.base_url}${tf('PATH_seeker_tutorial')}`                    
                case this.CONFIG_STRIPE:                    
                    return `${this.base_url}${tf('PATH_guru_payments')}`                    
                case this.ADD_PAYMENT_METHOD:                    
                    return `${this.base_url}${tf('PATH_payment_methods')}`                    
                case this.TIPS_GIVEN:                    
                    return `${this.base_url}${tf('PATH_tips_given')}`                    
                case this.TIPS_RECEIVED:                    
                    return `${this.base_url}${tf('PATH_tips_received')}`                    
                default:
                    break;
            }
            
        } catch (error) {
            return this.base_url;
        }
    }

}
export default RoutePath;
