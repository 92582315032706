import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import AZButtonLevel from '../form/AZButtonLevel';
import AZButton from '../form/AZButton';
import AZButtonIconLink from '../form/AZButtonIconLink';
import AZModal from '../modal/AZModal';
import { First } from 'react-bootstrap/esm/PageItem';

const SelectGuruLevel = (props) => {

    const {
        onOk,
        backLevel=-1,
        onBack,
        update
    } = props

    const {t} = useTranslation()

    const [level, setLevel] = useState(backLevel);
    const [warningLevel, setWarningLevel] = useState("");
    const [first, setFirst] = useState(false);

    const handleOk = ()=>{
        onOk({
            level
        })
    }

    useEffect(() => {
        if(update&&first){
            setWarningLevel(level!==0?t('PROFILE_edit_level_confirm_good_samaritan_beginner_title'):t('PROFILE_edit_level_confirm_other_good_samaritan'))
        }else{
            setFirst(true)
        }
    }, [level]);

    return (
        <div>
            <AZModal
                title={t('PROFILE_edit_level_asking_title')}
                onClose={()=>{setWarningLevel("")}}
                show={warningLevel!==""}
            >   
                <div className="p-3">
                    <p>
                        {warningLevel}
                    </p>
                </div>
            </AZModal>
             {update&&<div className="row">
                <div className="col-auto">
                    <AZButtonIconLink
                        leftIcon={<i className="fa fa-chevron-left" aria-hidden="true"></i>}
                        style="color"
                        onClick={()=>{onBack()}}
                    >
                        {t('GENERAL_back')}
                    </AZButtonIconLink>    
                </div>
            </div>}
            <div className="row my-2">
                <div className="col-12">
                    <h2 className='text-center'>{update?t('PROFILE_edit_level_title'):t('BG_TYPE_title')}</h2>
                </div>
            </div>
            <div className="row justify-content-center my-4">
                <div className="col-md-10 my-1">
                    <AZButtonLevel
                        icon={<i className='fa fa-star'></i>}
                        selected={level==1}
                        title={t('BG_TYPE_option_beginner_title')}
                        description={t('BG_TYPE_option_beginner_text')}
                        onClick={()=>{setLevel(1)}}
                    >
                    </AZButtonLevel>
                </div>
                <div className="col-md-10 my-1">
                    <AZButtonLevel
                        icon={<i className='fa fa-heart'></i>}
                        selected={level==0}
                        title={t('BG_TYPE_option_good_samaritan_title')}
                        description={t('BG_TYPE_option_good_samaritan_text')}
                        onClick={()=>{setLevel(0)}}
                    >                        
                    </AZButtonLevel>
                </div>
            </div>           
            <div className="row my-4 justify-content-center">
                <div className="col-auto">
                    <AZButton
                        style="color"
                        onClick={handleOk}
                        disabled={level==-1}
                    >
                        {update?t('PROFILE_edit_level_confirm_button'):t('BG_TYPE_button_save')}                        
                    </AZButton>
                </div>
            </div>
        </div>
    );
}

export default SelectGuruLevel;
