import React,{useEffect,useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import TipsGivenList from '../../components/payments/TipsGivenList';
import RP from '../../helpers/routePath';

import { connect } from 'react-redux';
import useWindowScrollApiList from '../../hooks/useWindowScrollApiList';

import * as generalActions from '../../actions/general.actions'
import * as paymentActions from '../../actions/payments.actions'
import * as navActions from '../../actions/navbar.actions'

import AZButtonLink from '../../components/form/AZButtonLink';

const TipsGiven = (props) => {    
    const {
        dispatch
    }= props
    const rows = 10;


    const {t,i18next} = useTranslation()
    const history = useHistory()
    

    const [
        list,
        listLoading,
        hasScrolled,
    ] = useWindowScrollApiList({ 
        apiCall:paymentActions.getTipsGiven,
        dispatch:dispatch,                
        onSuccess:(response)=>{
            console.log("response after hook",response)
        },
        //loadById:true,
        onError:(error)=>{
            console.log("error after hook",error)
        },
        testing:true
    })     

    const handleItemCheckReview=(idUser)=>{
        history.push(RP.getPath(t,RP.PROFILE_USER,idUser),{'reviewAs':'GURU'})
    }

    useEffect(() => {
        dispatch(
            navActions.selectIndex(3)
        )        
    }, []);

    return (
        <div className="container-lg py-1 bg-transparent">            
            <div className='position-relative'>
                <div className={`row row-hover w-100 pt-3 bg-gray-soft ${hasScrolled?"position-fixed":"position-relative"}`} >
                    <div className="col-auto">
                        <AZButtonLink
                                style="color"
                                type="button"
                                underline
                                onClick={()=>{history.goBack()}}
                            >                 
                            {t('GENERAL_back')}      
                        </AZButtonLink>
                    </div>
                </div>
            </div>                   
            <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-xl-4 col-container">                   
                    <h2 className='my-2'><b>{t('NAVBAR_SEEKER_tip_given')}</b></h2>
                    <div className="az-card-container az-card-container--no-padding p-2">
                        <TipsGivenList
                            full={true}
                            list={list}
                            itemsLoaded={0}
                            loading={listLoading}
                            onScroll={()=>{}}
                            noData={t('PAYMENTS_GIVEN_data_not_found')}
                            onItemClick={()=>{}}
                            onItemReview={handleItemCheckReview}
                        ></TipsGivenList>
                    </div>  
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state)=>{
    const {
        auth
    } = state
    const {
        user
    } = auth
    return {
        user
    }
}

export default connect(mapStateToProps)(TipsGiven);
