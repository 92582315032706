import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'react-string-format';

import AZField from '../form/AZField';
import AZButton from '../form/AZButton';
import { formValidateField } from '../../helpers/formvalidation';
import AZSelect from '../form/AZSelect';
import AZTextArea from '../form/AZTextArea';
import AZImgInput from '../form/AZImgInput';

import C from "../../constants"


const SendABugForm = (props) => {
    const {        
        onSubmit,
        onCancel,
        loading,        
    }=props

    const {t} = useTranslation();

    const [form, setForm] = useState({
        "message":{
            "name":"message",
            "value":"",
            "error":false,
            "errorText":""
        },
        "image":{
            "name":"image",
            "value":"",
            "error":false,
            "errorText":false
        }
    });

    const [showInput, setShowInput] = useState(true);

    const [image, setImage] = useState(null);

    const [selectControl, setSelectControl] = useState({
        "open":false,
        "selectedValue":"",
        "selectedId":-1,
        "error":false
    });

    const [messages, setMessages] = useState({
        "success":false,
        "error":false
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        const { 
            message
        } = form
        const {
            selectedId
        } = selectControl

        let valid = true

        setForm({
            ...form,
            "message":{
                ...form['message'],
                error:false
            }
        })
        setMessages({
            "success":false,
            "error":false
        })

        let errors = {}

        if(showInput){
            if(!formValidateField(message.value,'required')){
                valid = false
                errors["message"] = {
                    ...form["message"],
                    error:true,    
                }
            }
        }
       

        if(valid){
            onSubmit(
                {
                    "message":message.value,
                    "image":image
                },
                (response)=>{
                    console.log(response)
                    setMessages({
                        'success':t('REPORT_A_PROBLEM_success'),
                        'error':false
                    })
                },
                (error)=>{
                    console.log('error block',error)
                    if(error){
                        setMessages({
                            'success':false,
                            'error':t("ERROR_"+error.data.codeNumber)
                        })
                    }
                }
            )
        }else{
            setForm({
                ...form,
                ...errors
            })
        }
    }

    const handleChange = (e)=>{
        const{
            name,
            value
        } = e.target

        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })
    }

    const handleImageChange = (src,img)=>{
        console.log('handle image change..',img)
        setForm({
            ...form,
            "image":{
                ...form.image,
                "value":src
            }
        })
        console.log("uploaded image?",img)
        setImage(img)
    }

    return (
        <div className='p-3'>
            <form onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                    <AZImgInput
                        onChange={handleImageChange}
                        {...form.image}
                        src={form.image.value==""?`${C.resources.webpage}app/backgroundreport.png`:form.image.value}
                        placeholder={t('REPORT_A_PROBLEM_add_image')}
                        circled={false}
                    >
                    </AZImgInput>
                </div>
                <div className="row my-2">                    
                    {showInput&&<div className="col-12 my-2">
                        <AZTextArea 
                            {...form.message}
                            onChange={handleChange}
                        >
                            {t('REPORT_A_PROBLEM_placeholder')}
                        </AZTextArea>
                    </div>}
                    <div className="col-12 my-2">
                        {messages.success&&<p className='text-purple'>{messages.success}</p>}
                        {messages.error&&<p className='text-danger'>{messages.error}</p>}
                    </div>
                </div>
                <div className="row my-2 justify-content-between">
                    <div className="col-sm-auto">
                        <AZButton
                            style="gray"
                            onClick={onCancel}
                        >
                            {t('GENERAL_cancel')}
                        </AZButton>
                    </div>
                    <div className="col-sm-auto">
                        <AZButton
                            style="color"
                            type="submit"
                            onClick={()=>{}}
                            loading={loading}
                            onLoadText={t('GENERAL_sending')}
                        >
                            {t('REPORT_A_PROBLEM_button')}
                        </AZButton>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default SendABugForm;

