import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import * as generalActions from "../../actions/general.actions"
import * as appActions from "../../actions/app.actions"

import useWindowScrollApiList from '../../hooks/useWindowScrollApiList';

import BloquedUsersList from '../../components/profile/BloquedUsersList';
import AZDesicionModal from '../../components/modal/AZDesicionModal';

const BloquedUsers = (props) => {
    const{
        auth,
        dispatch
    }=props

    const {t,i18next} = useTranslation()

    const rows = 10;

    const [
        bloquedUsersList,
        usersLoading,
        isScrolling,
        setBloquedUsersList
    ] = useWindowScrollApiList({ 
        apiCall:generalActions.getBloquedUsers,
        'rows':rows,    
        onSuccess:(response)=>{
            console.log("response after hook",response)
        },
        onError:(error)=>{
            console.log("error after hook",error)
        },
        testing:true
    }) 

    const [showUnblockModal, setShowUnblockModal] = useState(false);
    const [unblockLoading, setUnblockLoading] = useState(false);
    const [unblockUserId, setUnblockUserId] = useState(-1);
    const [unbloquedMessages, setUnbloquedMessages] = useState({success:false,error:false});
   

    //bloqued users
    const handleUnblockedModalClose =()=>{
        setUnblockLoading(false)
        setShowUnblockModal(false)
        setUnbloquedMessages({success:false,error:false})
    }

    const handleItemUnblockedClick=(idUser)=>{
        setShowUnblockModal(true)        
        setUnblockUserId(idUser)
        console.log('the user id'+unblockUserId)
    }
    
    const handleUnblocked = () => {
        setUnbloquedMessages({success:false,error:false})
        setUnblockLoading(true)
        if(unblockUserId<0){
            setUnblockLoading(false)
            setUnbloquedMessages({...unbloquedMessages,error:t(`ERROR_undefined`)})
            return
        }

        generalActions.unblockUser(unblockUserId).then(
            (response)=>{                
                setUnblockLoading(false)
                dispatch(
                    appActions.setGlobalMessage(t('PROFILE_blocked_users_unblocked_ok'))
                )
                //setUnbloquedMessages({error:false,success:t('PROFILE_blocked_users_unblocked_ok')})              
                setBloquedUsersList([
                    ...bloquedUsersList.filter((u)=>(u.idUser!==unblockUserId))
                ])  
                setShowUnblockModal(false)
            },
            (error)=>{
                setUnblockLoading(false)
                if(error!==undefined){
                    setUnbloquedMessages({success:false,error:t(`ERROR_${error.data.codeNumber}`)})
                }else{
                    setUnbloquedMessages({success:false,error:t(`ERROR_undefined`)})
                }
            }
        )
    }

    return (
        <div>
            <AZDesicionModal
                show={showUnblockModal}
                onClose={handleUnblockedModalClose}
                noOptionText={false}
                onNoOption={false}   
                title={t('')}             
                yesOptionText={t('PROFILE_blocked_users_unblock_button')}
                onYesOption={handleUnblocked}
                loading={unblockLoading}
            >      
                <div className="p-5">
                    <p className="text-muted">
                        {t('PROFILE_blocked_users_unblocked_title')}
                    </p>
                    {unbloquedMessages.success&&<p className="text-success">{unbloquedMessages.success}</p>}
                    {unbloquedMessages.error&&<p className="text-danger">{unbloquedMessages.error}</p>}
                </div>          
            </AZDesicionModal>
            <div className="container-lg py-1 bg-transparent">
                <div className="row justify-content-center">
                    <div className="col-12 col-container">
                        <div className="row my-3">
                            <div className="col-12 text-start">
                                <h2><b>{t('PROFILE_blocked_users_title')}</b></h2>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-1">
                            <div className="col-12 my-2">
                                
                            </div>
                            <div className="col-12 my-2">
                                <div className="az-card-container az-card-container--no-padding">
                                    <BloquedUsersList
                                        list={bloquedUsersList}
                                        full={true}
                                        itemsLoaded={0}
                                        loading={usersLoading}
                                        onScroll={()=>{}}
                                        noData={t('GENERAL_no_users_blocked_found')}
                                        onItemClick={()=>{}}
                                        onItemUnblocked={handleItemUnblockedClick}
                                    ></BloquedUsersList>
                                </div>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    );
}

const mapStateToProps = (state)=>{
    const {
        auth
    } = state
    return {
        auth
    }
}

export default connect(mapStateToProps)(BloquedUsers);

