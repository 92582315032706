import Service from "../services/payments.service";

import {
    ADD_PAYMENT_METHODS,
    REMOVE_PAYMENT_METHODS,
    SET_PAYMENT_METHODS,
    CLEAN_PAYMENT_METHODS,
} from "./types"

import {
    apiErrorHandler
} from '../helpers/helpers'

export const attachPaymentMethod = (paymentMethodId,options={}) => (dispatch) => {
    const {
        abortController = null
    } = options

    return new Service(abortController)
    .attachPaymentMethod(paymentMethodId).then(
        (response)=>{             
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}

export const detachPaymentMethod = (paymentMethodId,options={}) => (dispatch) => {
    const {
        abortController = null
    } = options

    return new Service(abortController)
    .detachPaymentMethod(paymentMethodId).then(
        (response)=>{             
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}

export const getLastPayment = (idGuru,options={}) => (dispatch) => {
    const {
        abortController = null
    } = options
    
    return new Service(abortController)
    .getLastPayment(idGuru).then(
        (response)=>{             
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}

export const getStripeAccount = (options={}) => (dispatch) => {
    const {
        abortController = null
    } = options
    
    return new Service(abortController)
    .getStripeAccount().then(
        (response)=>{             
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}

export const getPaymentMethods = (options={}) => (dispatch) => {
    const {
        abortController = null
    } = options    
    
    return new Service(abortController)
    .getPaymentMethods().then(
        (response)=>{                 
            // dispatch({
            //     type:SET_PAYMENT_METHODS,
            //     payload:response.list        
            // })
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}

export const getTipsGiven = (loadedItems,numberOfRows,options={}) => (dispatch) => {
    const {
        abortController = null
    } = options
    
    return new Service(abortController)
    .getTipsGiven(loadedItems,numberOfRows).then(
        (response)=>{             
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}

export const getTipsReceived = (loadedItems,numberOfRows,options={}) => (dispatch) => {
    const {
        abortController = null
    } = options    
    
    return new Service(abortController)
    .getTipsReceived(loadedItems,numberOfRows).then(
        (response)=>{             
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}

export const getOnboardingLink = (options={}) => (dispatch) => {
    const {
        abortController = null
    } = options
    
    return new Service(abortController)
    .getOnboardingLink().then(
        (response)=>{             
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}

export const sendPayment = (params,options={}) => (dispatch) => {
    const {
        abortController = null
    } = options
    const {
        paymentMethodId,
        amount,
        idGuru,
        idHelpRequest,
        idMessageToPay,
        idConversation
    } = params
    
    return new Service(abortController)
    .sendPayment(
        paymentMethodId,
        amount,
        idGuru,
        idHelpRequest,
        idMessageToPay,
        idConversation
    ).then(
        (response)=>{             
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}

export const setDefaultPaymentMethod = (paymentMethodId,options={}) => (dispatch) => {
    const {
        abortController = null
    } = options

    return new Service(abortController)
    .setDefaultPaymentMethod(paymentMethodId).then(
        (response)=>{             
            return Promise.resolve(response.data)
        },(response)=>{
            apiErrorHandler(response.response,dispatch)
                return Promise.reject(response.response.data)
        }
    )
}