import React from 'react';
import { useTranslation } from 'react-i18next';
import AZButton from '../form/AZButton';
import AZButtonLink from '../form/AZButtonLink';


const UserOptions = (props) => {
    const {
        onReport,
        onBlock,
        onCancel
    } = props
    
    const {t} = useTranslation()
    

    return (
        <div>
            <div className="p-3">
                <div className="my-2 text-center">
                    <AZButtonLink
                        style="color"
                        onClick={onReport}
                    >
                        {t('PROFILE_report_user')}
                    </AZButtonLink>    
                </div>
                <div className="my-2 text-center">
                    <AZButtonLink
                        style="color"
                        onClick={onBlock}
                    >
                        {t('PROFILE_block_user')}
                    </AZButtonLink>    
                </div>
                <div className="my-2 text-center">
                    <AZButtonLink
                        style="gray"
                        onClick={onCancel}
                    >
                        {t('GENERAL_cancel')}
                    </AZButtonLink>    
                </div>
            </div>
        </div>
    );
}

export default UserOptions;
