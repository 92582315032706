import React from 'react';
import PaymentsMethodsItem from './PaymentsMethodsItem';
import "../../pages/index.css"

import C from "../../constants"

const PaymentsMethodsList = (props) => {
    const {
        list,
        noData,        
        loading,
        onItemClick,
        onAddMethod,
        selectionMode,
        full=false
    } = props

    return (
        <div className={`az-list ${full&&"az-list--full"}`}>
            {loading&&<div className="az-list__loading">
                <i className="fa fa-spinner fa-spin"></i>
            </div>}
            <div className="az-list__content" >                
                {list&&list.length>0?
                    list.map(v=>(
                        <PaymentsMethodsItem
                            key={v.id}
                            model={v}  
                            selectionMode={selectionMode}                        
                            onItemClick = {onItemClick}

                        >
                        </PaymentsMethodsItem>
                    ))
                :<div>
                    {!loading&&list.length==0?<h4 className="p-2 text-center my-3 text-muted">{noData}</h4>: <h4></h4>  }                    
                </div>    
                }
                {
                    !loading&&
                    <div className="row justify-content-center m-2">
                        <div className="col-12">
                                <PaymentsMethodsItem
                                key={-1}
                                isIddle    
                                selectionMode={false}                       
                                onItemClick = {onAddMethod}
                            >
                            </PaymentsMethodsItem>
                        </div>
                        <div className="col-12"></div>
                        {list.length==0&&<div className="col-sm-8 col-md-6 my-3">
                            <img src={`${C.resources.webpage}app/sinmetodopago.png`} alt="" className="w-100 p-2" />
                        </div>}
                    </div>
                }
            </div>
        </div>
    );
}

export default PaymentsMethodsList;
