import React,{useState,useEffect,useRef,useLayoutEffect} from 'react';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import ChatImage from '../ChatImage';

import FormatRating from '../../format/FormatRating';

import RP from '../../../helpers/routePath';

import AZButtonLink from '../../form/AZButtonLink';
import AZField from '../../form/AZField';
import AZTimer from './AZTimer';
import AZButtonIcon from '../../form/AZButtonIcon';
import AZTextArea from '../../form/AZTextArea';


import {v4 as uuidv4} from 'uuid';

import * as chatActions from '../../../actions/chat.actions'
import * as appActions from '../../../actions/app.actions'
import * as generalActions from '../../../actions/general.actions'

import {    
    ref,
    onValue,
    query,
    onChildChanged,
    off,
    orderByChild,
    startAt,
    onChildAdded,
    getDatabase,    
} from "firebase/database"

import AZChatMessage from './AZChatMessage';

import "./index.css"

const AZChat = (props) => {
    const {
        user,
        dispatch,        
        loading,
        onClickMessage,
        onSend,
        chatData,        
    } = props

    //export userVariables
    const {
        isAGuru,
        idUser
    } = user
  
    //export chat variables
    const {
        idConversation,
        name,        
        otherUser = chatData.idUser,
        otherGuruLevel =chatData.guruLevel,
        otherIsAGuru =chatData.isAGuru,
        otherIsIndependent =chatData.isIndependent,
        imageSufix,
        idRequest,
        markedForRating,
        rates,
        rating,
        isScrollDown,
        enableRateRequest
    } = chatData

    const {t} = useTranslation()
    const history = useHistory()
    const REF = ref(getDatabase(),'/chats/' + idRequest + '/messages/' + idConversation)    
    const recordedFileName = 'voice_note.m4a';
    
    const timerRef = useRef(null); //reference for the timer.
    
    const chatEnd = useRef(null) //reference to get the las empty item on the chat.

    const imgRef = useRef(null) //reference to the image file input

    const audioRef = useRef(null)

    const [messages, setMessages] = useState([]);

    const messagesKeys = useRef({});

    const [isRatingLoading, setRatingLoading] = useState(false);
    const [isMarkedForRating, setMarkedForRating] = useState(
        markedForRating?markedForRating:false
    );
    const [isRecording, setIsRecording] = useState(false);    
    const [recorder, setRecorder] = useState({
        minutes:0,
        seconds:0,
        mediaStream:null,
        mediaRecorder:null,
        audio:null,
        audioUrl:""
    });            

    const [chatState, setChatState] = useState({
        defaultCard: null,
        amountToPay: 0,
        currencyToPay: '',
        idMessageToPay: '',
        showDataAlertTip: false,
        showDataAlertPay: false,
        activityIndicator: false,
        showPopover: false,
    });

    const [chatText, setChatText] = useState("");
    const [chatImage,setChatImage] = useState({
        img:[],
        imgUrl:""
    });
    const [loadingMsg, setLoadingMsg] = useState(true);
    const [sendingChat, setSendingChat] = useState(false);
    const [scrollDown, setScrollDown] = useState(isScrollDown);

    const addLocalMessage = (payload) => {
        var newMessage = {
          _id: payload.idMessage,
          createdAt: new Date(),
          user: {_id: idUser},
        };
        if (payload.type == 0) { //plain text
          newMessage.text = payload.message;
        } else if (payload.type == 1) { //image
          newMessage.image = payload.message;
        } else if (payload.type == 2) {//audio
          newMessage.audio = payload.message;
          newMessage.duration = payload.duration;
        } else if (payload.type == 3) {//tip
          newMessage.currency = 'usd';
          newMessage.status = 1;
          newMessage.system = true;
          newMessage.type = 'ASKING_TIP';
        } else if (payload.type == 4) {//location
          newMessage.text = payload.message;
          newMessage.map = payload.map;
        }
    
        newMessage.pending = true;
        newMessage.sent = false;
    
        messagesKeys.current[newMessage._id] = true;
        setMessages([
            ...messages,
            newMessage
        ]);
    };

    const sendMessage = (theMessage) => {
        
        setSendingChat(false)
        dispatch(
            chatActions.sendAMessage(
                theMessage
            )
        ).then((response)=>{            
        },(error)=>{
            if(error){
                dispatch(
                    appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')
                )
            }
        })
    };

    //HANDLERS

    const handleSendImage = (imageFile,imageUrl)=>{
        const messageBody = { 
            idMessage: uuidv4(), 
            message: imageUrl, 
            idConversation: idConversation, 
            type: 1 
        };

        dispatch(
            chatActions.getPresignedUrl(
                idConversation,
                1
            )
        ).then(
            (response)=>{
                console.log("get presignedUrl",response)
                const {
                    media,
                    presignedURL
                } = response.data

                dispatch(
                    generalActions.uploadMedia(
                        presignedURL,
                        imageFile
                    )
                ).then(
                    (response)=>{
                        console.log("upload image",response)
                        messageBody.message = media
                        sendMessage(messageBody);
                    },
                    (error)=>{
                        console.log("error upload image",error)                        
                        dispatch(
                            appActions.setGlobalMessage(t("ERROR_500"),'error')
                        )                        
                    }
                )
            },
            (error)=>{
                console.log("error presign url",error)
                if(error){
                    dispatch(
                        appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')
                    )
                }
            }
        )
        setSendingChat(true)        
        addLocalMessage(messageBody);        
    }

    const handleSendAudio = ()=>{
        const audioFile = recorder.audio
        console.log('audioblob',audioFile)
        const audioUrl = recorder.audioUrl
        const messageBody = { 
            idMessage: uuidv4(), 
            message:audioUrl, 
            idConversation: idConversation, 
            duration:audioRef.current?audioRef.current.duration:0,
            type: 2 
        };

        if(audioFile==undefined){
            return
        }

        dispatch(
            chatActions.getPresignedUrl(
                idConversation,
                2
            )
        ).then(
            (response)=>{
                console.log("get presignedUrl",response)
                const {
                    media,
                    presignedURL
                } = response.data

                dispatch(
                    generalActions.uploadAudio(
                        presignedURL,
                        audioFile
                    )
                ).then(
                    (response)=>{
                        console.log("upload image",response)
                        messageBody.message = media
                        setRecorder({
                            ...recorder,
                            audio:null,
                            audioUrl:""
                        })
                        sendMessage(messageBody);
                    },
                    (error)=>{
                        console.log("error upload image",error)     
                        setSendingChat(false)
                        dispatch(
                            appActions.setGlobalMessage(t("ERROR_500"),'error')
                        )                        
                    }
                )
            },
            (error)=>{
                console.log("error presign url",error)
                setSendingChat(false)
                if(error){
                    dispatch(
                        appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')
                    )
                }
            }
        )
        setSendingChat(true)        
        addLocalMessage(messageBody);        
    }

    const handleSendText = (e)=>{
        e.preventDefault()
        if(chatText==""){
            return
        }
        const messageBody = { 
            idMessage: uuidv4(), 
            message: chatText, 
            idConversation: idConversation, 
            type: 0 
        };
        setSendingChat(true)
        setChatText("")
        addLocalMessage(messageBody);
        sendMessage(messageBody);
    }

    const handleCheckReviews = (idUser)=>{
        history.push(RP.getPath(t,RP.PROFILE_USER,idUser),{'reviewAs':'SEEKER'})
        //RP.getPath(t,RP.PROFILE_USER,idUser)
    }

    const handleImageInputChange = (e)=>{
        if (e.target.files && e.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (readerEvent) {
                var image = new Image();
                image.onload = function (imageEvent) {                        
                    // Resize the image
                    var canvas = document.createElement('canvas'),
                        max_size = 300,
                        imgSize = Math.min(image.width, image.height),
                        left = (image.width - imgSize) / 2,
                        top = (image.height - imgSize) / 2;
                    
                    canvas.width = image.width;
                    canvas.height = image.height;                            
                    canvas.getContext('2d').fillStyle = 'white'
                    canvas.getContext('2d').fillRect(0,0,max_size,max_size)
                    canvas.getContext('2d').drawImage(image,0, 0, image.width, image.height,0,0,image.width,image.height);                        
                    var dataUrl = canvas.toDataURL('image/jpeg'),
                        resizedImage = dataURLToBlob(dataUrl);
                    handleSendImage(resizedImage,dataUrl)                        
                    //document.querySelector('#canvas').appendChild(canvas);
                }
                image.src = readerEvent.target.result;
            }
            reader.readAsDataURL(e.target.files[0]);
            // see this         
            /*let image = e.target.files[0]
            let imageUrl = URL.createObjectURL(e.target.files[0])                
            handleSendImage(image,imageUrl)*/
        }else{
            
        }
    }
    var dataURLToBlob = (dataURL)=>{
        var BASE64_MARKER = ';base64,';
        if (dataURL.indexOf(BASE64_MARKER) == -1) {
            var parts = dataURL.split(',');
            var contentType = parts[0].split(':')[1];
            var raw = parts[1];
    
            return new Blob([raw], {type: contentType});
        }
    
        var parts = dataURL.split(BASE64_MARKER);
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;
    
        var uInt8Array = new Uint8Array(rawLength);
    
        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
    
        return new Blob([uInt8Array], {type: contentType});
    }
    
    const handleSendImageClick = () =>{
        imgRef.current.click()
    }
    //Start recorder
    const startRecording = async () =>{
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio:true})
            setRecorder({
                ...recorder,
                mediaStream:stream
            })
        } catch (error) {
            console.log('error on recording',recorder)
        }
    }

    //recording controls
    const handleStartRecording = ()=> {
        startRecording()
        setIsRecording(true)         
    }

    const handleStopRecording = ()=>{        
        const _recorder = recorder.mediaRecorder
        if(_recorder){
            _recorder.stop()            
        }

        setIsRecording(false)
    }

    useEffect(() => {
        console.log("Chat start",REF)
        dispatch(chatActions.markConversationAsRead(idConversation))
        chatEnd.current.scrollIntoView(true)
        if (messages == null || messages.length == 0) {                    
            onValue(
                REF,
                (snapshot) => {
                    console.log("once",snapshot)                                        
                    if (snapshot.exists()) {
                        var newMessages = [];
                        var newKeys = {};
                        snapshot.forEach((child) => {
                            let aMessage = child.val();
                            aMessage.key = child.key;
                            aMessage.sent = true;
                            aMessage.pending = false;
                            newMessages.push(aMessage);
                            messagesKeys.current[aMessage._id] = true;
                        });
                        let array = newMessages
                        console.log('the array',array)
                        setMessages(
                            newMessages
                        );
                        setLoadingMsg(false)
                    }
                },
                true
            )
            
            onChildAdded(
                query(REF,orderByChild('createdAt'),startAt(Date.now())),
                (snapshot) => {
                    var newMessage = snapshot.val();
                    newMessage.key = snapshot.key;
                    if (newMessage.user._id !== idUser) {
                        dispatch(chatActions.markConversationAsRead(idConversation))
                    }
                    newMessage.sent = true;
                    newMessage.pending = false;
                    console.log('onChildAdded',newMessage)
                    //If already in the messages list, UPDATE it
                    if (messagesKeys.current[newMessage._id] === true) {
                        console.log("child Added")
                        setMessages((previousMessages) => {
                        const foundIndex = previousMessages.findIndex(
                            (aMessage) => aMessage._id == newMessage._id,
                        );
                        const newArr = [...previousMessages];
                        newArr[foundIndex] = newMessage;
                        return newArr;
                        });
                    } else {
                        console.log("child Added")
                        //If it is not in the messages list, ADD it
                        messagesKeys.current[newMessage._id] = true;
                        setMessages([
                            ...messages,
                            newMessage
                        ]);
                    }
                    chatEnd.current.scrollIntoView(true)
                }
            ) 
          
            onChildChanged(
                REF,
                (snapshot) => {
                    var message = snapshot.val();
                    message.key = snapshot.key;
                    if (message.system == true) {
                        if (messagesKeys.current[message._id] === true) {
                        setMessages((previousMessages) => {
                            const foundIndex = previousMessages.findIndex(
                            (aMessage) => aMessage._id == message._id,
                            );
                            const newArr = [...previousMessages];
                            newArr[foundIndex] = message;
                            return newArr;
                        });
                        }
                    } else {
                        //Do nothing
                    }
                    chatEnd.current.scrollIntoView(true)
                }
            )              

            //   setRecorder(null);
            //   _reloadRecorder();

        }        
    
        return () => {
            off(REF)         
            if (recorder && recorder.mediaRecorder) {
                recorder.mediaRecorder.stop()
            }
        };        
    }, []);
    

    const handleRate = (mark) => {
        setRatingLoading(true)        
        dispatch(
            chatActions.checkConversationForRating(idConversation,!mark)
        ).then(
            (response) => {
                console.log('mark')
                setRatingLoading(false)
                dispatch(appActions.updateGuruConversationsMark(
                    idConversation,
                    !mark
                ))
                setMarkedForRating(!mark)
            },
            (response) => {
                setRatingLoading(false)                
            }
        )
    }


    useLayoutEffect(() => {
        console.log("scroll bottom")
        chatEnd.current.scrollIntoView()
    }, [messages]);
    
    useLayoutEffect(() => {
        console.log("scroll bottom")
        chatEnd.current.scrollIntoView()
    }, [isScrollDown]);

    // useEffect(() => {        
    //     chatEnd.current.scrollIntoView()
    // }, [scrollDown]);

    /*Effects for recorder*/
    useEffect(() => {
        if(recorder.mediaStream){
            setRecorder({
                ...recorder,
                mediaRecorder: new MediaRecorder(recorder.mediaStream)
            })
        }
    }, [recorder.mediaStream]);

    useEffect(() => {
       const _recorder = recorder.mediaRecorder
       let chunks = []
       if(_recorder && _recorder.state === "inactive"){
           try {
            _recorder.start()
            _recorder.ondataavailable = (e)=>{
                chunks.push(e.data)
            }
            _recorder.onstop = (e)=>{
                const blob = new Blob(chunks,{type:"audio/ogg; codecs=opus"})
                chunks = []

                if(recorder.mediaRecorder){
                    setRecorder({
                        ...recorder,
                        audio:blob,
                        audioUrl:URL.createObjectURL(blob)
                    })
                }else{
                    setRecorder({
                        minutes:0,
                        seconds:0,
                        mediaStream:null,
                        mediaRecorder:null,
                        audio:null,
                        audioUrl:""
                    })
                }
            }   
           } catch (error) {
               
           }
       } 

       return ()=>{
           if(_recorder) _recorder.stream.getAudioTracks().forEach((track)=>{track.stop()})
       }

    }, [recorder.mediaRecorder]);

    return (
        
        <div className='h-100'>    
            <div id="canvas_chat"></div>       
            {/* img helper form */}
            <form className='d-none'>
                <input 
                    type="file" 
                    name="chat_img" 
                    id="" 
                    accept="image/jpeg,image/png"
                    onChange={handleImageInputChange}
                    ref={imgRef} 
                    />
            </form>
            <div className="az-chat">
                <div className="az-chat__header row justify-content-between text-start">
                    <div className="col-7" style={{overflowX:'hidden'}}>
                        <div className="d-inline-block-middle">
                            <ChatImage
                                user={{
                                    idUser:otherUser,
                                    imageSufix,
                                    'guruLevel':otherGuruLevel,
                                    'isAGuru':otherIsAGuru,
                                    'isIndependent':otherIsIndependent,
                                }}                                 
                                conversationReadIndicator={0}
                                onClick={()=>{}}
                            >                    
                            </ChatImage>
                        </div>
                        <div className="d-inline-block-middle">
                            <h6>
                                {name}
                            </h6>
                            <p>
                                {rates>0?`${rates} ${t('GENERAL_reviews')}`:`${t('GENERAL_no_reviews')}`} <i className="fa fa-star ml-1"></i> {<FormatRating>{rating}</FormatRating>}
                            </p>
                            <AZButtonLink
                                underline
                                style="color"
                                size="sm"
                                onClick={()=>{handleCheckReviews(otherUser)}}
                            >
                                {t('GURU_request_label_check_reviews')}
                            </AZButtonLink> 
                           
                        </div>                            
                    </div>
                    <div className="col-auto">
                        {
                            enableRateRequest&&
                            <AZButtonIcon
                                rightIcon={isMarkedForRating?<h5><i className="fa fa-check text-white"></i></h5>:<h5><i className="fa fa-circle-thin text-white"></i></h5>}
                                style={isMarkedForRating?"color":"gray"}
                                size="xs"
                                onClick={()=>{handleRate(isMarkedForRating)}}
                                loading={isRatingLoading}
                            >
                                {t("CHAT_button_rate_me")}
                            </AZButtonIcon>
                        }
                    </div>                    
                </div>
                <div className="az-chat__messages-container" >
                    <div className="az-chat__messages">
                        {
                            loadingMsg&&<h3 className="text-purple text-center"><i className="fa fa-spin fa-spinner"></i></h3>
                        }
                        { messages.length > 0?
                            messages.map((m=>(
                                <AZChatMessage 
                                    key={m._id}
                                    message={m}
                                    myMessage={idUser==m.user._id}
                                />                                
                            )))                            
                            :
                            <div>
                                {t('')}
                            </div>
                        }
                        <div ref={chatEnd}></div>
                    </div>
                </div>
                <div className="az-chat__tools">
                    {
                        isRecording
                        ?
                        // Record audio buttons
                        <div className='d-flex align-items-center justify-content-between h-100 mx-3'>
                            <div className="col-auto mx-2 text-danger">
                                <h5>
                                    <i className="fa fa-microphone"></i>               
                                </h5>
                            </div>
                            <div className='col text-danger'>
                                <AZTimer 
                                    isRecording={isRecording}
                                    style="danger"                    
                                />
                            </div>
                            <div className="col-auto">
                                <AZButtonLink 
                                    onClick={handleStopRecording}
                                    type="button"
                                    style="danger"   
                                    disabled={sendingChat}                                                                                           
                                > 
                                    <h5>
                                        <i className="fa fa-stop-circle-o"></i>               
                                    </h5>
                                </AZButtonLink>
                            </div>
                        </div>
                        :(
                            recorder&&recorder.audio?
                            <div className='d-flex align-items-center justify-content-between h-100'>
                                <div className="col">                                   
                                    <audio src={recorder.audioUrl} controls ref={audioRef}></audio>
                                </div>
                                <div className='col-auto mx-2'>
                                    <AZButtonLink
                                        style="danger"    
                                        type="button"                                 
                                        onClick={()=>{
                                            setRecorder({
                                                ...recorder,
                                                audio:null,
                                                audioUrl:""
                                            })
                                        }}                                             
                                    >
                                        <i className="fa fa-trash"></i>
                                    </AZButtonLink>
                                </div>                              
                                <div className='col-auto mx-2'>
                                    <AZButtonLink
                                        style="color"    
                                        type="button"       
                                        loading={sendingChat}                             
                                        onClick={handleSendAudio}                                             
                                    >
                                        <i className="fa fa-paper-plane"></i>
                                    </AZButtonLink>
                                </div>                              
                            </div>
                            :<form className='w-100 h-100' onSubmit={handleSendText}>
                                <div className="d-flex align-items-center justify-content-between h-100">
                                    <div className='col-auto mx-2 d-none'>
                                        <AZButtonLink
                                            style="color"    
                                            type="button"                                    
                                            onClick={()=>{}} 
                                            disabled
                                        >
                                            <i className="fa fa-plus"></i>
                                        </AZButtonLink>
                                    </div>
                                    <div className='col ms-3'>
                                        <AZTextArea
                                            value={chatText}
                                            onChange={(e)=>{
                                                const {
                                                    value,                                        
                                                } = e.target
                                                setChatText(value)
                                            }}          
                                            onKeyPress={(e)=>{
                                                console.log(e)
                                                var key = e.charCode;
                                                // If the user has pressed enter
                                                if( key === 13 && !e.shiftKey){
                                                    if(chatText.trim()!==""){
                                                        handleSendText(e)
                                                        return true
                                                    }                                                    
                                                }                                                
                                            }}                                  
                                            rows={1}
                                        >
                                            {t('CHAT_message_placeholder')}
                                        </AZTextArea>
                                        {/*<AZField
                                            value={chatText}
                                            onChange={(e)=>{
                                                const {
                                                    value,                                        
                                                } = e.target
                                                setChatText(value)
                                            }}
                                        >
                                            {t('CHAT_message_placeholder')}
                                        </AZField>
                                        */}
                                    </div>
                                    {
                                        chatText.trim()!==""?
                                        <div className='col-auto mx-2'>
                                            <AZButtonLink
                                                style="color"    
                                                type="submit"                                    
                                                onClick={()=>{}}  
                                                loading={sendingChat}
                                            >
                                                <h5>
                                                    <i className="fa fa-paper-plane"></i> 
                                                </h5>
                                            </AZButtonLink>
                                        </div>
                                        :
                                        <div className='col-auto mx-2'>
                                            <div className="d-inline-block-middle mx-1">
                                                <AZButtonLink
                                                    style="color"    
                                                    type="button"                                    
                                                    onClick={handleSendImageClick}                                                                 
                                                >
                                                    <h5>
                                                        <i className="fa fa-camera"></i>
                                                    </h5>
                                                </AZButtonLink>
                                            </div>
                                            <div className="d-inline-block-middle mx-1">
                                                <AZButtonLink 
                                                    onClick={handleStartRecording}
                                                    type="button"
                                                    style="color"                                                                                                          
                                                > 
                                                    <h5>
                                                        <i className="fa fa-microphone"></i>               
                                                    </h5>
                                                </AZButtonLink>  
                                            </div>
                                        </div>
                                    }
                                </div>
                            </form>
                        )
                    }
                </div>
            </div>            
        </div>
    );
}

const mapPropsToState = (state)=>{
    const {
        auth
    } = state

    return {
        user:auth.user
    }
}

export default connect(mapPropsToState)(AZChat);
