import React,{ useState } from 'react';
import AZProfileImage from '../profile/AZProfileImage';
import AZButtonLink from '../form/AZButtonLink';
import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';
import FormatedDate from '../format/FormatedDate';

import HorizontalConversationList from './HorizontalConversationList';

const SeekerRequestItem = (props) => {
    const {
        model,        
        onView,
        onViewAsList,
        onClose,
        onConversationClick,
        onExploreGurus,
        onConversationNext                            
    } = props

    const {t,i18n} = useTranslation()

    const {
        requestId,
        title,
        description,
        address,
        lastUpdate,
        conversations,        
    } = model

    const handleItemView= (e) =>{
        onView(title,description,address)
    }

    const handleItemViewAsList = (e)=>{
        onViewAsList(requestId)
    }

    const handleItemClose = (e)=>{
        onClose(requestId,title)
    }   
    const handleExploreGurus = () => {
        onExploreGurus(
            requestId,
            title,
            description
        )
    }

    const handleConversationClick = (conversationData) =>{
        onConversationClick({
            ...conversationData,
            title,
            idRequest:requestId
        })
    }    
   

    return (  
        <div>
            <div className="az-list__content__item az-list__content__item--seeker-request align-items-center justify-content-end text-center text-sm-start" >                
                <div className="col-sm col-12 my-1">
                    <AZButtonLink
                        style="color"
                        type="button"
                        underline
                        onClick={handleItemView}                        
                    >
                        {title}
                    </AZButtonLink>             
                </div>
                {
                    conversations.length>0&&<div className="col-sm-auto mx-sm-2 text-center col-6 my-1 d-none">
                        <AZButtonLink
                                    style="color"
                                    underline                                
                                    onClick={handleItemViewAsList}                        
                                >
                            {t('SEEKER_REQUEST_view_as_list')}
                        </AZButtonLink>
                    </div>
                }
                <div className="col-sm-auto mx-sm-2 text-center col-6 my-1">
                    <AZButton
                        style="color"
                        size="sm"
                        onClick={handleItemClose}
                    >
                        {t('SEEKER_REQUEST_close')}
                    </AZButton>
                </div>
                {/* Conversations goes here */}
                <div className="col-12">
                    <HorizontalConversationList 
                        list={conversations}
                        idRequest={requestId}
                        onItemClick={handleConversationClick}
                        onNext={()=>{}}
                        noDataComponent={ 
                            <div className='p-2'> 
                                <p className='my-1'> 
                                    {t('SEEKER_text_empty_proposal')} 
                                </p> 
                                <div>
                                    <AZButtonLink 
                                        style="color" 
                                        underline 
                                        type="button"
                                        onClick={handleExploreGurus}>
                                        {t('SEEKER_button_empty_proposal')}                                     
                                    </AZButtonLink> 
                                </div> 
                            </div>
                        }
                    />
                </div>                                                              
            </div>            
        </div>      
    );
}

export default SeekerRequestItem;

