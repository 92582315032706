import C from "../constants"
import axios from "axios";
import authHeader from './auth-header';

const API_URL = C.ws + "conversations/";
const API_URL_GENERAL = C.ws + "general/";

class ChatService{
    constructor(){
        this.getImageTries = 0
    }

    checkConversationForRating(idConversation,checked){
        return axios
        .post(API_URL + "check-conversation-for-rating",{
            idConversation,
            checked
        },{            
            headers:{
                ...authHeader()
            }
        })
        .then((response) =>{          
          return response;
        })
    }  

    getPresignedUrl(idConversation,messageType){
        return axios
        .get(API_URL + "get-presigned-url",{
            params:{
                idConversation,
                messageType
            },
            headers:authHeader() 
        })
        .then((response) =>{             
          return response;
        })
    }

    markConversationAsRead(idConversation){
        return axios
        .post(API_URL + "mark-conversation-as-read",{
            idConversation
        },{           
            headers:authHeader() 
        })
        .then((response) =>{                    
          return response;
        })
    }

    markNotificationAsRead(type){
        return axios
        .post(API_URL_GENERAL + "mark-notification-as-read",{
            type
        },{           
            headers:authHeader() 
        })
        .then((response) =>{                    
          return response;
        })
    }

    sendAMessage(idConversation,idMessage,type,message,duration,amount){
        return axios
        .post(API_URL + "send-a-message",{
            idConversation,
            idMessage,
            type,
            message,
            duration,
            amount 
        },{
            headers:authHeader() 
        })
        .then((response) =>{          
          return response;
        })
    }    

    
}

export default new ChatService()