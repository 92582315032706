import React,{useEffect} from 'react';
import GuruNav from './GuruNav'
import SeekerNav from './SeekerNav'
import NavTypeItem from './NavTypeItem';
import AZProfileImage from '../profile/AZProfileImage';
import AZButtonIcon from '../form/AZButtonIcon';

import RP from '../../helpers/routePath';

import * as navActions from '../../actions/navbar.actions'
import * as appActions from '../../actions/app.actions'
import * as generalActions from '../../actions/general.actions'

import './index.css'
import c from "../../constants"

import MenuList from './MenuList';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory,useLocation } from 'react-router';

import AZButton from '../form/AZButton'
import AZModal from '../modal/AZModal';
import AZButtonLink from '../form/AZButtonLink';
import AZBarMenu from '../modal/AZBarMenu';

import { connect } from 'react-redux';

const DashboardNav = (props) => {
    const {
        user,
        navbar,
        dispatch,
        notifications
    } = props

    const {t} =  useTranslation()
    let history = useHistory()
    const location = useLocation()
    
    const [navType, setNavType] = useState(navbar.userAs);
    const [optionSelected, setOptionSelected] = useState(navbar.selected);
    const [showMenu, setShowMenu] = useState(false);
    const [newRequestLoading, setNewRequestLoading] = useState(false);

    const handleNavTypeChange = (type)=>{
        console.log("change",type)
        //setNavType(type)
        // if(type == navbar.userAs){
        //     return 
        // }
        dispatch(
            appActions.cleanConversations()
        )
        if(type == "SEEKER"){
            dispatch(navActions.switchToSeeker())            
        }else{
            dispatch(navActions.switchToGuru())
        }
        history.push(RP.getPath(t,RP.BASE))

    }
    const handleNewRequest = () =>{
        setNewRequestLoading(true)
        dispatch(generalActions.getRemainingRequests()).then(
            (response)=>{
                console.log('response',response)
                const requestRemaining = response.response
                if(requestRemaining>0){
                    history.push(RP.getPath(t,RP.NEW_SEARCH))
                }else{
                    dispatch(
                        appActions.setGlobalMessage(t('ERROR_0225'))
                    )
                }
                setNewRequestLoading(false)
            },
            (error)=>{
                console.log('error',error)
                setNewRequestLoading(false)
            }
        )
    }

    const handleOptionSelect = (option)=>{
        setOptionSelected(option)
    }
    const handleClickMenu = ()=>{
        setShowMenu(true)
    }

    const showComponentNav = (navSelected,option)=>{
        if(navSelected=="SEEKER"){
            return <SeekerNav
                dispatch={dispatch}
                optionSelected={optionSelected}
                selectedNav={navbar.selected_index}
                onChangeOption={handleOptionSelect} 
                onClickMenu={handleClickMenu}
            >
            </SeekerNav>
        }else{
            return <GuruNav
                optionSelected={optionSelected}
                onChangeOption={handleOptionSelect}
                selectedNav={navbar.selected_index}
                onClickMenu={handleClickMenu}                
            >
            </GuruNav>
        }
    }
    useEffect(() => {            
        setShowMenu(false)
    }, [location.pathname]);
    
    return (           
        <div className='az-navbar-wrapper sticky-top'>
            {console.log('user from nav',user)}
            <AZBarMenu
                show={showMenu}
                onClose={()=>{setShowMenu(false)}}
            >
                <div className="p-2">
                    <MenuList
                        nav={navbar.userAs}
                        dispatch={dispatch}
                        isGuru={user.isAGuru?user.isAGuru:false}
                    >
                    </MenuList>
                </div>
            </AZBarMenu>
            <div className="container-lg">
                <div className="row justify-content-center">
                    <div className="col-12 col-container">
                        <div className="az-navbar justify-content-between">
                            <div className="d-flex align-items-center">
                                <div className="az-navbar__logo d-none d-md-block" style={{"cursor":"pointer"}} onClick={()=>{ history.push(RP.getPath(t,RP.BASE));dispatch(navActions.switchToSeeker()) }}>
                                    <img src={`${c.resources.webpage}app/askshao.png`} alt="" />
                                </div>   
                                <div className="col-3 ms-2">
                                    <AZButtonLink
                                        style="color"
                                        type="button"
                                        onClick={handleClickMenu}>
                                            <h1 className='text-black'>
                                                <i className="fa fa-bars"></i>
                                            </h1>
                                    </AZButtonLink>
                                </div>                             
                                {
                                    //showComponentNav(navbar.userAs,optionSelected)
                                }    
                            </div>      
                            <div className="d-flex align-items-center justofy-content-end">
                                <div className={`az-navbar__items__item me-3`}>
                                    <AZButtonIcon
                                        leftIcon={<i className="fas fa-plus"></i>}
                                        onClick={handleNewRequest}
                                        loading={newRequestLoading}
                                        size="sm"
                                        onLoadtext={t('NAVBAR_SEEKER_new_search')}
                                    >
                                        {t('NAVBAR_SEEKER_new_search')}
                                    </AZButtonIcon>           
                                </div>
                                <div className="az-navbar__type">
                                    <NavTypeItem
                                        active={navbar.userAs=="SEEKER"}
                                        onClick={()=>{handleNavTypeChange("SEEKER")}}
                                        notifications={notifications.notifSeekers + notifications.seekerNewConversations}                                        
                                    >
                                        {t('PROFILE_tab_seeker_title')}   
                                    </NavTypeItem>
                                    <NavTypeItem
                                        active={navbar.userAs=="GURU"}
                                        onClick={()=>{handleNavTypeChange("GURU")}}
                                        notifications={notifications.notifRequests + notifications.notifConversations}
                                    >            
                                        {t('PROFILE_tab_guru_title')}        
                                    </NavTypeItem>
                                </div>            
                                <div className="az-navbar__user justify-self-end">
                                    <AZProfileImage 
                                        type={navbar.userAs} 
                                        user={user}    
                                        bordered
                                        onClick={()=>{ history.push(RP.getPath(t,RP.PROFILE)) }}           
                                    >                    
                                    </AZProfileImage>
                                </div>
                            </div>            
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    );
}

const mapStateToProps = (state)=>{
    const{
        navbar
    } = state

    return {
        navbar
    }
}

export default connect(mapStateToProps)(DashboardNav);
