import React from 'react';
import HorizontalConversationItem from './HorizontalConversationItem';

const HorizontalConversationList = (props) => {
    const {
        onItemClick,        
        list,      
        onNext,
        idRequest,
        hideResponseIcon=false,
        noDataComponent=null
    } = props

    return (
        <div>
            <div className="az-horizontal-list">
                {
                    list.length>0?
                    <div className="az-horizontal-list__content">
                        {
                            list.map((o)=>{
                                return <HorizontalConversationItem 
                                    key={o.idConversation}
                                    onClick={onItemClick}
                                    model={o}
                                    hideResponseIcon={hideResponseIcon}
                                    idRequest={idRequest}
                                />
                            })
                        }
                    </div>
                    :<div className="w-100 text-center">
                        {noDataComponent&&noDataComponent}
                    </div>
                }
            </div>
        </div>
    );
}

export default HorizontalConversationList;
