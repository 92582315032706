import React from 'react';
import SeekerRequestItem from './SeekerRequestItem';

import C from '../../constants' 

import "../../pages/index.css"

const SeekerRequestList = (props) => {
    const {        
        list,
        noData,
        itemsLoaded,
        loading,
        onScroll,
        onItemView,
        onItemViewAsList,
        onItemClose,
        onItemConversationClick,
        onExploreGurusClick
    } = props
    
    const handlePageScroll = (e) =>{
        console.log("page scroll")
        const element = e.target
        const childHeight =element.offsetHeight;
        const {scrollTop,scrollHeight,offsetHeight} = element        
        if (
            scrollHeight - scrollTop < (offsetHeight+2)            
            && !loading
            ) {
                console.log('on scroll!!');
                onScroll()
        }
    }


    return (
        <div className="az-list az-list--full">
            {loading&&<div className="az-list__loading">
                <i className="fa fa-spinner fa-spin"></i>
            </div>}
            <div className="az-list__content" onScroll={handlePageScroll}>
                {list&&list.length>0?
                    list.map(v=>(
                        <SeekerRequestItem
                            key={v.requestId}
                            model={v}
                            onView = {onItemView}
                            onViewAsList = {onItemViewAsList}
                            onClose = {onItemClose}
                            onConversationClick ={onItemConversationClick}
                            onConversationNext ={()=>{}}
                            onExploreGurus={onExploreGurusClick}
                        >
                        </SeekerRequestItem>
                    ))                    
                :<div>
                    {!loading?
                        <div className='row justify-content-center'>
                            <div className="col-12 my-2">
                                <h4 className="p-2 text-center text-muted">{noData}</h4>
                            </div>
                            <div className="col-4 col-md-6 mt-4">
                                <img className='w-100 p-2' src={`${C.resources.webpage}app/sinpreguntasactivas.png`} alt="" />
                            </div>
                        </div>
                    :
                    <h4></h4>  }                    
                </div>    
                }
            </div>
        </div>
    );
}

export default SeekerRequestList;

