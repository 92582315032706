import React,{useEffect,useState,useRef,useLayoutEffect} from 'react';

/**
 * 
 * @typedef {*} param0 
 * @returns 
 */

/**
 * useWWindowScrollApiList
 * 
 * @param {object} props
 * @param {!function} props.apiCall
 * @param {?Array[*]} [props.params]
 * @param {number} [props.rows=10]
 * @param {?function} props.onSuccess
 * @param {?function} props.onError
 * @param {boolean} [props.testing=false]
 * 
 * @type hook
 * 
 * @returns {[]} 
 */
const useWindowScrollApiList = ({
        apiCall,
        params=[],
        rows=10,
        onSuccess=undefined,
        onError=undefined,
        loadById=false,
        itemIdName="id",
        testing=false,
        preList=[],
        preLoaded=0,
        block=undefined,
        mapListFunction=null,
        fromStartFunction=null,
        shouldObserveParams=true,
        dispatch
    }) => {    
    
    const loadedItemsRef = useRef(loadById?-1:preLoaded)
    const paramsRef = useRef(params)
    const listRef = useRef(preList)  
    const blockRef = useRef(block!==undefined?block:false)  
    const hasLoadedAllRef = useRef(false) 
    const hasScrolledRef = useRef(false)
    const [hasScrolled, setHasScrolled] = useState(false);
    const [list, setList] = useState(preList);
    const [listLoading, setListLoading] = useState(false);
    const [hasLoadedAll, setHasLoadedAll] = useState(false);

    const thisLog = (text,object=null)=>{
        if(testing){
            let LOG_PREFIX = new Date().getDate() + '.' + new Date().getMonth() + '.' + new Date().getFullYear() + ' / ' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
            let formated_text = `${LOG_PREFIX}.useWindowScrollApiList: ${text}`
            console.log.apply(console,[formated_text,object])
        }
    }

    const getApi = (fromStart=false)=>{         
        thisLog('get api hooks',apiCall)
        let hasLoadedAllVar = hasLoadedAllRef.current
        let loadedItems = loadedItemsRef.current  
        let actualList = listRef.current
        //check if blocked
        if(blockRef.current){
            return
        }
        if(listLoading){
            return
        }
        if(hasLoadedAllVar&&!fromStart){
            thisLog('has loaded all')
            return
        }

        if(fromStart){            
            loadedItemsRef.current = 0
            setHasLoadedAll(false)            
            loadedItems = loadById?-1:0
            actualList = []
        }

        setListLoading(true)                    
               
        const onSuccessApi = (response)=>{
            thisLog('matching response...',response)
            const responseList = response.list?response.list:response.requests?response.requests:response.reviews?response.reviews:[]
            if(responseList.length>0){                     
                thisLog('actual list?',actualList)
                thisLog('list?',responseList)
                thisLog(`list vs rows ${responseList.length} ${rows}`)
                if(mapListFunction){
                    setList([...actualList,...responseList.map(mapListFunction)])
                }else{
                    setList([...actualList,...responseList])
                }
                loadedItemsRef.current=loadById?responseList[responseList.length-1][itemIdName]:loadedItems+responseList.length
                if(rows > responseList.length){
                    setHasLoadedAll(true)                   
                }
            }else{                
                setHasLoadedAll(true)  
                setList(actualList)                                  
            }
            setListLoading(false)   
            if(onSuccess){
                onSuccess(response)
            }        
        }
        const onErrorApi = (error)=>{
            thisLog(error)            
            setListLoading(false)
            if(onError){
                onError(error)
            }            
        }
        if(dispatch){
            dispatch(
                apiCall.apply(
                    null,
                    [
                        ...paramsRef.current,
                        loadedItems,
                        rows
                    ]
                )
            ).then(
                onSuccessApi,
                onErrorApi
            )
        }else{
            apiCall.apply(null,[
                ...paramsRef.current,
                loadedItems,
                rows
            ]).then(
                onSuccessApi,
                onErrorApi
            )
        } 
    }

    const handleWindowScroll = (e)=>{                
        const {
            innerHeight,
            pageYOffset
        } = window
        const {
            offsetHeight 
        } = document.body        
        thisLog(`${innerHeight}+${pageYOffset})>=${offsetHeight}&&${!listLoading}`)
        if((innerHeight+pageYOffset+10)>=offsetHeight&&!listLoading){            
            getApi()
        }        
        //thisLog(hasScrolledRef.current)
        // if(pageYOffset>10 && hasScrolledRef.current == false){
        //     setHasScrolled(true)
        // }
        // if(pageYOffset<10 && hasScrolledRef.current == true){
        //     setHasScrolled(false)
        // }
    }

    useEffect(() => {      
        let fromStartVar = fromStartFunction?fromStartFunction(list):true  
        getApi(fromStartVar)        
        window.addEventListener('scroll',handleWindowScroll)
        return ()=>{            
            window.removeEventListener('scroll',handleWindowScroll)            
        }
    }, []);
    
    useEffect(() => {        
        listRef.current = list
    }, [list]);
    useEffect(() => {        
        blockRef.current = block
    }, [block]);
    useEffect(() => {
        thisLog('change is scrolling',hasScrolled)
        hasScrolledRef.current = hasScrolled
    }, [hasScrolled]);
    useEffect(() => {        
        hasLoadedAllRef.current = hasLoadedAll
    }, [hasLoadedAll]);
    useEffect(() => {
        thisLog('the params',params)
        let shouldGetApi = false        
        for (let index = 0; index < params.length; index++) {
            const element = params[index];
            const elementRef = paramsRef.current[index]
            thisLog(`is equal? ${element} ${elementRef}`)
            if(element != elementRef){
                shouldGetApi = true
            }                        
        }
        paramsRef.current = [...params]
        if(shouldGetApi&&shouldObserveParams){
            getApi(true)
        }        
    }, [params]);
    

    return [
        list,
        listLoading,
        hasScrolled,
        setList,
        loadedItemsRef.current
    ]
}

export default useWindowScrollApiList;
