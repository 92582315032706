import React,{ useState,useEffect,useRef } from 'react';
import ChatImage from './ChatImage';
import AZButtonLink from '../form/AZButtonLink';
import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';
import FormatedDate from '../format/FormatedDate';
import FormatRating from '../format/FormatRating';

//firebase
import { getDatabase,ref,onChildAdded,off,onChildChanged,onValue } from "firebase/database";
import moment from 'moment';


const ConversationsItem = (props) => {
    const {
        model,
        onOptionsClick,        
        onViewReviews,
        onItemClick,
        onReorganizeConversations
    } = props

    const {t,i18n} = useTranslation()

    const lastMessageRef = useRef(null)

    const [conversationData, setConversationData] = useState(model);
    const [shouldReorganize, setShouldReorganize] = useState(false);

    const {                                 
        title,    
        idUser,
        guruLevel,
        isAGuru,
        isIndependent,
        idConversation,
        idRequest,
        description,
        name,
        rates,
        rating,
        date,	
        imageSufix,             
        conversationStatus,    
        notReadByGuru,
        notReadBySeeker,
        lastMessageTime=null,
        lastMessage,
        lastMessageIdUser,
        lastMessageType,
        markedForRating,        
    } = model
    
    const REF = ref(getDatabase(),`/chats/${idRequest}/info/${idConversation}`)
    

    const showLastMessage = ()=>{
        let lastMessageUser = lastMessageIdUser == idUser?`${name}: `:t('GENERAL_you')
        console.log('lastMessage',lastMessage)
        switch (lastMessageType) {
            case 0:
                return <p><b>{lastMessageUser}</b>{
                    lastMessage&&lastMessage.length>50?
                    `${lastMessage.substring(0,50)}...`:
                    lastMessage
                }</p>
            case 1:
                return <p><b>{lastMessageUser}</b> <i className="fa fa-picture-o mr-1"></i> {t('GENERAL_image')} </p>
            case 2:
                return <p><b>{lastMessageUser}</b> <i className="fa fa-microphone mr-1"></i> {t('GENERAL_voice')} </p>
            case 3:
                return <p><b>{lastMessageUser}</b> <i className="fa fa-map-marker mr-1"></i> {t('GENERAL_location')} </p>
            case 4:
                return <p><b>{lastMessageUser}</b> <i className="fa fa-map-marker mr-1"></i> {t('GENERAL_location')} </p>
            default:
                return <p><b>{lastMessageUser}</b>{
                    lastMessage&&lastMessage.length>50?
                    `${lastMessage.substring(0,50)}...`:
                    lastMessage
                }</p>                
        }
    }
    const setConversationStatus= ()=>{
        return(lastMessageIdUser == idUser?(notReadByGuru>0?0:1):2)
    }    

    const handleOptionsClick = (e)=>{
        //onOptionsClick(idRequest,title)
    }
   

    const handleItemClick = (e) => {    
        console.log('handle item click',{...conversationData})
        onItemClick({
            ...conversationData,
            markedForRating
        })    
    }  

    useEffect(() => {
        onChildChanged(REF,(data) => {
        // console.log("data.key ConversationItem Guru",data.key);
            console.log("data Val",data.val());
            if (data.key == "data") {
                let newlastMessage = data.val().lastMessage
                let newConversationData = {
                    ...conversationData,
                    notReadBySeeker:data.val().notReadBySeeker!==undefined?data.val().notReadBySeeker:conversationData.notReadBySeeker,
                    notReadByGuru:data.val().notReadByGuru!==undefined?data.val().notReadByGuru:conversationData.notReadByGuru,
                    lastMessageTime:data.val().lastMessage!==undefined?data.val().lastMessage:conversationData.lastMessageTime,
                    lastMessage:data.val().lastMessageText!==undefined?data.val().lastMessageText:conversationData.lastMessage,
                    lastMessageType:data.val().lastMessageType!==undefined?data.val().lastMessageType:conversationData.lastMessageType,                        
                    lastMessageIdUser:data.val().lastMessageIdUser!==undefined?data.val().lastMessageIdUser:conversationData.lastMessageIdUser,                    
                }
                setConversationData(newConversationData)                 
                setShouldReorganize(true)   
                console.log('reorganize:newMessage',newConversationData)  
                console.log('reorganize:oldmessage',lastMessageRef.current)  
                if(lastMessageRef.current!==newlastMessage){
                    lastMessageRef.current = newlastMessage
                    onReorganizeConversations(newConversationData)
                }else{
                    onReorganizeConversations(newConversationData,false)
                }              
                                   
            }
        })
        return () => {
            off(REF)
        };
    }, []);

    useEffect(() => {
        console.log('change update firebase',conversationData)
        // setConversationData({
        //     ...conversationData,
        //     markedForRating
        // })
    }, [conversationData]);

    return (  
        <div>                        
            <div className="az-list__content__item az-list__content__item--conversations align-items-start" onClick={handleItemClick}>
                <div className="az-list__content__item__info">
                    <div className="row justify-content-between align-items-start w-100">
                        <div className="col-11">
                            <div className="d-inline-block-middle">
                                <ChatImage
                                    user={{
                                        idUser,
                                        imageSufix,
                                        guruLevel,
                                        isAGuru,
                                        isIndependent,
                                    }}
                                    onClick={()=>{}}
                                    conversation
                                    conversationReadIndicator={setConversationStatus()}
                                >
                                </ChatImage>
                            </div>
                            <div className="d-inline-block-middle">
                                <h6>
                                    {name}
                                </h6>
                                <p>
                                    {
                                    title.length>50?
                                    `${title.substring(0,50)}...`:
                                    title
                                    }
                                </p>
                                <p className="text-muted">
                                    {                                        
                                        <FormatedDate>
                                            {lastMessageTime?lastMessageTime:date}
                                        </FormatedDate>                                        
                                    }
                                    
                                </p>
                            </div>
                        </div>
                        <div className="col-1 d-none">
                            <AZButtonLink
                                style="color"
                                onClick={handleOptionsClick}
                            >
                                <i className="fa fa-ellipsis-h"></i>
                            </AZButtonLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {showLastMessage()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConversationsItem;