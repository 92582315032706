import React,{useState,useEffect,useRef} from 'react';
import {useStripe, useElements, CardElement,PaymentElement,CardNumberElement,CardExpiryElement,CardCvcElement} from '@stripe/react-stripe-js';
import { LOGIN_SUCCESS } from '../../actions/types';

import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';

const AddPaymentMethodForm = (props) => {
    const {
        user,
        onSubmit,
        loading=false,
        onError,
        errors=[]        
    } = props

    const {t} = useTranslation()

    const stripe = useStripe()    
    const elementRef = useRef(null)

    const [elementReady, setElementReady] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault()
        setElementReady(false)
        if(stripe){
            if(elementRef.current){
                stripe.createPaymentMethod({
                    type:"card",
                    card:elementRef.current,
                    billing_details:{
                        name:`${user.name} ${user.lastName}`,
                        email:user.email,                        
                    }
                })
                .then(
                    (response) => { 
                        setElementReady(true)
                        const {paymentMethod,error} = response 
                        console.log('submit card form',response)
                        if(paymentMethod){
                            onSubmit(paymentMethod)
                        }else{
                            console.log('why i don use thos error?')
                            onError([error.message])
                        }                
                    },
                    (response) => {
                        console.log('on error',response)
                        return 
                    }
                )

            }            
        }
        return 
    }

    return (
        <form onSubmit={handleSubmit}>    
            {console.log('payment user',user)}      
            <div className="row my-2 mb-5">
                {
                    !elementReady&&!loading&&
                    <div className="col-12 text-center">
                        <h1 className="i fa fa-spinner fa-spin"></h1>
                    </div>
                }   
                    
                <div className="col-12">
                    <CardElement onReady={(element)=>{console.log('element ready');elementRef.current = element;setElementReady(true)}}/>
                </div>
                
            </div>
            {errors.length>0&&!loading&&<div className="row my-2">
                <div className="col-12 text-danger">                                            
                    <ul className='w-100'>
                        {
                            errors.map((e,index)=> <li key={index}>{e}</li> )
                        }
                    </ul>
                </div>
            </div>}
            <div className="row mt-3 justify-content-center">
                <div className="col-md-auto text-center">
                    <AZButton 
                        onClick={()=>{}}
                        type="submit"
                        disabled={!elementReady||loading}
                        loading={loading}
                    >
                        {t('GENERAL_save')}
                    </AZButton>
                </div>
            </div>
        </form>
    );
}

export default AddPaymentMethodForm;
