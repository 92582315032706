import React,{ useState } from 'react';
import AZProfileImage from '../profile/AZProfileImage';
import AZButtonLink from '../form/AZButtonLink';
import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';
import FormatedDate from '../format/FormatedDate';
import FormatRating from '../format/FormatRating';

const GuruRequestItem = (props) => {
    const {
        model,
        onOptionsClick,
        onSendMessage,
        onViewReviews,
        onItemClick
    } = props

    const {
        requestId,                
        userId,        
        title,
        willingToPay,
        description,
        name,
        rates,
        rating,
        date,	
        imageSufix,        
    } = model

    const {t,i18n} = useTranslation()

    const handleViewReviews = (e) =>{
        onViewReviews(userId)
    }

    const handleOptionsClick = (e)=>{
        onOptionsClick(requestId,title,{
            requestId,
            userId,            
            title,
            description,
            model
        })
    }

    const handleSendMessage = (e)=>{
        onSendMessage(
            requestId,
            userId,            
            title,
            description,
            model
        )
    }

    const handleItemClick = (e) => {    
        onItemClick()    
    }  


    return (  
        <div>                        
            <div className="az-list__content__item az-list__content__item--guru-request align-items-center" onClick={handleItemClick}>
                <div className='p-1'>
                    <AZProfileImage
                        user={{
                            'idUser':userId,
                            imageSufix
                        }}
                        bordered={false}
                        size="sm"
                    >                    
                    </AZProfileImage>
                </div>

                <div className="az-list__content__item__info">
                    <div className="row justify-content-between align-items-center">
                        <div className="col">
                            <div className="d-inline-block-middle">
                                <h6>
                                    {name}
                                </h6>
                            </div>
                            <div className="d-inline-block-middle ms-2">
                                <p>
                                    {rates>0?`${rates} ${t('GENERAL_reviews')}`:`${t('GENERAL_no_reviews')}`}
                                </p>
                            </div>
                            <div className="d-inline-block-middle ms-2">
                                <h6><i className="fa fa-star mr-1"></i> {<FormatRating>{rating}</FormatRating>}</h6>
                            </div>
                            {willingToPay&&<div className="d-inline-block-middle ms-1 text-success">
                                <h6>
                                    <i className="fa fa-usd"></i>
                                </h6>
                            </div>}
                        </div>
                        <div className="col-auto">
                            <div className="d-inline-block-middle mx-2 d-none d-sm-inline-block">
                                <AZButtonLink
                                    style="color"
                                    underline
                                    onClick={handleViewReviews}                        
                                >
                                    {t('GURU_request_label_check_reviews')}
                                </AZButtonLink>
                            </div>
                            <div className="d-inline-block-middle mx-2 d-none d-sm-inline-block">
                                <AZButton 
                                    onClick={handleSendMessage}
                                    type="button"
                                    style="color"     
                                    size="sm"               
                                > 
                                    {t('GURU_button_i_can_help')}                 
                                </AZButton>  
                            </div>
                            <div className="d-inline-block-middle mx-2">
                                <AZButtonLink
                                    style="color"                                    
                                    onClick={handleOptionsClick}                        
                                >
                                    <i className="fa fa-ellipsis-h"></i>
                                </AZButtonLink>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h5><b>{title}</b></h5>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="row mt-1 justify-content-end">
                        <div className="col-auto">
                            <p className="text-muted">
                                <FormatedDate>
                                    {date}
                                </FormatedDate>
                            </p>
                        </div>
                    </div>
                </div>                                              
            </div>            
        </div>      
    );
}

export default GuruRequestItem;