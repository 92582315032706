import { useRef } from "react"
import "./index.css"


const AZButtonIcon = (props) => {
    const {
        type,
        style="color",
        children,
        onLoadText="",
        disabled,
        customClass="",
        loading=false,
        onClick,
        leftIcon="",
        rightIcon="",
        size="md"
    } = props

    const button = useRef(null)
    
    const buttonStyle = {
        color:"btn-az-icon btn-az-icon--color",
        gray:"btn-az-icon btn-az-icon--gray",
        danger:"btn-az-icon btn-az-icon--danger"
    }

    const handleClick = (e)=>{        
        onClick()
    }

    return (
        <div className={`${buttonStyle[style]} ${customClass} btn-az-icon--${size} ${loading||disabled?"btn-az-icon--disabled":""}`} onClick={()=>{button.current.click() }}>            
            {
                leftIcon!==""&&!loading&&
                <div className="btn-az-icon__left-icon">
                    {leftIcon}
                </div>
            }
            <div className='btn-az-icon__label' 
                    type={type}
                    disabled={loading||disabled}                    
                    >
                {
                   
                    loading&&
                    onLoadText!==""?
                    <span>
                        {onLoadText}
                    </span>
                    :
                    <span>
                        {children}
                    </span>
                }            
            </div>   
            <button
                className="d-none"
                type={type}
                onClick={handleClick}
                ref={button}
            >
            </button>     
            {
                rightIcon!==""&&!loading&&
                <div className="btn-az-icon__right-icon">
                    {rightIcon}
                </div>
            }
            {
                loading&&<div className="btn-az-icon__right-icon">                    
                    <i className="fa fa-spinner fa-spin text-white"></i>                                           
                </div>
            }
        </div>
             
    );
}

export default AZButtonIcon;
