import React from 'react';

import { useState,useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {format} from 'react-string-format'

import AZButtonLink from '../form/AZButtonLink';
import AZButtonIconLink from '../form/AZButtonIconLink';
import AZProfileImage from '../profile/AZProfileImage';
import AZButton from '../form/AZButton';

const ProfileInfo = (props) => {
    const { t, i18n } = useTranslation();
    const {
        profile,
        onCheckReviews,
        onCategoriesEdit,
        onLanguagesEdit,
        onIsIndependentEdit,
        onLevelEdit,
        onPlaceEdit,
        onOptionsClick
    }= props

    const showFormattedTags=(tags)=>{
        let newTags = []
        tags.map((tag)=>{
            if(newTags[tag.category]){
                newTags[tag.category]=[
                    ...newTags[tag.category],
                    tag.tag
                ]
            }else{
                newTags[tag.category]=[
                    tag.tag
                ]
            }
        });

        let returnString = Object.keys(newTags).map((tag)=>{
            return `${tag} : ${newTags[tag].join(', ')}`
        }).join(' • ')                             

        return returnString;
    }
    
    return (
        <div className="h-100">            
            <div className="row justify-content-md-start align-items-center">
                <div className="row align-items-start justify-content-between justify-content-md-start col-md-auto">
                    <div className="col-auto">
                        <AZProfileImage
                            user={profile}
                            size="md"
                            bordered
                            onClick={()=>{}}
                        ></AZProfileImage>
                    </div>
                    <div className="col-auto d-md-none mt-2">
                        <AZButtonLink
                            onClick={()=>{onOptionsClick()}}
                            type="button"
                            style="color"
                        >                                                            
                            <i className="fa fa-ellipsis-h text-purple">
                            </i>                                                            
                        </AZButtonLink>
                    </div>
                </div>
                <div className="col-md">
                    <h5 className="text-bolder">
                        {profile.name} {profile.lastName}
                    </h5>
                    <h5 className="text-muted">
                        @{profile.nickname}
                    </h5>
                    <AZButtonLink
                        underline
                        style="color"
                        onClick={onCheckReviews}
                    >
                        {t('GURU_request_label_check_reviews')}
                    </AZButtonLink>                    
                </div>
            </div> 
            {profile.isAGuru&&profile.tags!==undefined&&<div className="mt-3">
                <div className="row">
                    <div className="col-12 my-2">
                        <h5><b>{format(t('PROFILE_my_expertise_areas'),profile.tags.length)}</b></h5>
                        <AZButtonIconLink
                                style="gray"
                                rightIcon={<i className="text-purple fa fa-pencil"></i>}
                                onClick={onCategoriesEdit}
                            >                                        
                            {profile.tags?showFormattedTags(profile.tags):""}                                                       
                        </AZButtonIconLink>
                    </div>
                </div>
                <div className="divider bg-gray w-100">
                </div>
                <div className="row">
                    <div className="col-12 my-1">
                        <AZButtonIconLink
                            style="gray"
                            rightIcon={<i className="text-purple fa fa-pencil"></i>}
                            onClick={onLanguagesEdit}
                        >            
                            
                            {profile.languages?profile.languages.map((lang)=>{
                                return t(`languagues.${lang}`)
                            }).join(' • '):""}                                                         
                        </AZButtonIconLink>

                        <AZButtonIconLink
                            style="gray"
                            rightIcon={<i className="text-purple fa fa-pencil"></i>}
                            onClick={onIsIndependentEdit}
                        >            
                            
                            {profile.isIndependent!==undefined?(profile.isIndependent?t('PROFILE_update_TYPE_option_is_independent_title'):`${t('PROFILE_update_TYPE_option_not_independent_title')} ( ${profile.companyName?profile.companyName:t('BG_TYPE_not_independent_prompt_title')} )`):""}                                                         
                        </AZButtonIconLink>

                        <AZButtonIconLink
                            style="gray"
                            rightIcon={<i className="text-purple fa fa-pencil"></i>}
                            onClick={onLevelEdit}
                        >            
                            
                            {profile.guruLevel!==undefined?t(`PROFILE_edit_level_instructions_title_${profile.guruLevel}`):""}                                                         
                        </AZButtonIconLink>

                        <AZButtonIconLink
                            style="gray"
                            rightIcon={<i className="text-purple fa fa-pencil"></i>}
                            onClick={onPlaceEdit}
                        >            
                            
                            {profile.location?profile.location:""}                                                         
                        </AZButtonIconLink>
                    </div>
                </div>            
            </div>}                      
        </div>
    );
}

export default ProfileInfo;
