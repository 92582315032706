import React, { useEffect, useState, useRef,useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AZButton from '../../components/form/AZButton';
import AZButtonLink from '../../components/form/AZButtonLink';

import useWindowScrollApiList from '../../hooks/useWindowScrollApiList';

import ArchivedRequestList from '../../components/seeker/ArchivedRequestList'

import * as seekerActions from '../../actions/seeker.actions'
import * as appActions from '../../actions/app.actions'


const ArchivedRequests = (props) => {
    const {dispatch}=props

    const rows = 10

    const {t} = useTranslation()
    const history = useHistory()
 
    const [
        archivedRequestList,
        archivedRequestListLoading,
        isScrolling,
    ] = useWindowScrollApiList({ 
        apiCall:seekerActions.getArchivedRequests,        
        'rows':rows,    
        onSuccess:(response)=>{
            console.log("response after hook",response)
        },
        onError:(error)=>{
            console.log("error after hook",error)
        },
        testing:false,
        dispatch
    }) 

    const handleConversationClick = (conversationData)=>{

        dispatch(
            appActions.openConversation(
                conversationData
            )
        )
    }


    return (
        <div className="container-lg py-1 bg-transparent position-relative">
            <div className='position-relative'>
                <div className={`row row-hover w-100 pt-3 bg-gray-soft ${isScrolling?"position-fixed":"position-relative"}`} >
                    <div className="col-auto">
                        <AZButtonLink
                                style="color"
                                type="button"
                                underline
                                onClick={()=>{history.goBack()}}
                            >                 
                            {t('GENERAL_back')}      
                        </AZButtonLink>
                    </div>
                </div>
            </div>
            <div className="row my-3">
                <div className="col-12">
                    <h2><b>{t('SEEKER_ARCHIVED_header')}</b></h2>
                </div>
            </div>            
            <div className="row align-content-between mt-2">                
                <div className="col-12 col-container">
                    <div className="az-card-container az-card-container--no-padding">
                        <ArchivedRequestList
                            list={archivedRequestList}
                            itemsLoaded={0}
                            hideResponseIcon
                            loading={archivedRequestListLoading}
                            onScroll={()=>{}}
                            noData={t('SEEKER_no_requests_archived_found')} 
                            onItemConversationClick={handleConversationClick}                       
                        />
                    </div>
                </div>               
            </div>

        </div>
    );
}

const mapStateToProps = (state)=>{
    return {

    }
}

export default connect(mapStateToProps)(ArchivedRequests);
