import {
    OPEN_CONVERSATION,
    CLOSE_CONVERSATION, 
    CLEAN_CONVERSATIONS, 
} from "../actions/types"

const initialState = {
    list:{},
    ids:[]
}

export default (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    switch (type) {     
        case OPEN_CONVERSATION:
            const {
                conversationData
            } = payload

            if(state.list[conversationData.idConversation]){
                let conversationsIds =  state.ids.filter(v=>v!==conversationData.idConversation)            
                console.log('scroll?',state.list[conversationData.idConversation].isScrollDown)
               
                return {
                    ...state,
                    "list":{
                        //...state.list,       
                        [conversationData.idConversation]:{
                            ...conversationData,
                            isScrollDown:state.list[conversationData.idConversation].isScrollDown+1,
                            isOpen:state.list[conversationData.idConversation].isScrollDown+1
                        }
                    },
                    "ids":[
                        //...conversationsIds,
                        conversationData.idConversation
                    ]
                }
                         
            }else{
                return {
                    ...state,
                    "list":{
                        //...state.list,       
                        [conversationData.idConversation]:{
                            ...conversationData,
                            isScrollDown:0,                    
                            isOpen:0,                    
                        }
                    },
                    "ids":[
                        //...state.ids,
                        conversationData.idConversation
                    ]
                }              
            }
        case CLOSE_CONVERSATION:
            const {
                idConversation
            }= payload
            let activeConversationsNew = {...state.list}
            activeConversationsNew[idConversation] = false
            return {
                "list":activeConversationsNew,
                "ids":[
                    ...state.ids.filter(v=>v!==idConversation)
                ]
            }           
        case CLEAN_CONVERSATIONS:
            return initialState    
        default:
        return state;
    }
}