import { combineReducers } from "redux";
import {
    signPage
} from "./signPage"
import auth from "./auth"
import cache from "./cache"
import navbar from "./navbar"
import globalMessage from "./globalMessage"
import globalLoading from "./globalLoading"
import currentRequest from "./currentRequest";
import activeConversations from "./activeConversations";
import searchedGurus from "./searchedGurus";
import defaultPaymentMethod from "./defaultPaymentMethod";
import paymentsMethods from "./paymentsMethods";
import pendingRates from "./pendingRates";
import notifications from "./notifications";
import guruConversations from "./guruConversations";

export default combineReducers({
    auth,
    signPage,
    cache,
    navbar,
    globalMessage,
    globalLoading,
    currentRequest,
    activeConversations,
    searchedGurus,
    defaultPaymentMethod,
    paymentsMethods,
    pendingRates,
    notifications,
    guruConversations
})