import React from 'react';

import {format} from 'react-string-format'

import ProfileInfo from '../../components/profile/ProfileInfo';
import ProfileMenu from '../../components/profile/ProfileMenu';

import AZModalTitle from '../../components/modal/AZModalTitle';
import ChangePasswordForm from '../../components/profile/ChangePasswordForm';
import AZLoading from '../../components/modal/AZLoading';
import AZDesicionModal from '../../components/modal/AZDesicionModal';
import AZModal from '../../components/modal/AZModal';

import RP from '../../helpers/routePath';

import * as authActions from '../../actions/auth.actions'
import * as generalActions from '../../actions/general.actions'
import * as appActions from '../../actions/app.actions'
import * as navActions from '../../actions/navbar.actions'

import { formValidateField } from '../../helpers/formvalidation';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useState,useEffect} from 'react';
import '../index.css'

//Components
import SelectCategory from '../../components/guru/SelectCategory';
import SelectGuruLevel from '../../components/guru/SelectGuruLevel';
import SelectGuruIndepent from '../../components/guru/SelectGuruIndepent';
import SelectLanguages from '../../components/guru/SelectLanguages';
import SelectPlaces from '../../components/guru/SelectPlaces';

import SendABugForm from '../../components/profile/SendABugForm';

const MyProfile = (props) => {
    const { t, i18n } = useTranslation();  
    
    const {
        auth,
        dispatch,
        user
    } = props

    
    let history = useHistory()
    const location = useLocation()


    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

    const [showChangePassword, setShowChangePassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);

    const [selectedComponent, setSelectedComponent] = useState("FORM");   
    const [showLangModal, setShowLangModal] = useState(false);
    const [showPlacesModal, setShowPlacesModal] = useState(false);

    const [categories, setCategories] = useState([]);
    
    const [guruData,setGuruData] = useState({})

    const [locationKeys, setLocationKeys] = useState([]);

    const [showOptions, setShowOptions] = useState(false);

    const [messageModal, setMessageModal] = useState("");

    const [showSendABug, setShowSendABug] = useState(false);

    const handleCheckReviews=()=>{
        history.push(RP.getPath(t,RP.REVIEWS))
    }

    const handleChangePasswordSubmit=(params,onSuccess,onError)=>{
        const {
            newPassword,
            currentPassword          
        }= params         
        setUpdatePasswordLoading(true)
        console.log(params) 
        let errors = {}
        let validForm = true;

        if(!formValidateField(newPassword.value,'required')){
            validForm = false
            errors['newPassword'] = {
                ...newPassword,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_new_password"
            }
        }             
        if(!formValidateField(currentPassword.value,'required')){
            validForm = false
            errors['currentPassword'] = {
                ...currentPassword,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_current_password"
            }
        }       
        if(!formValidateField(newPassword.value,'password')){
            validForm = false
            errors['newPassword'] = {
                ...newPassword,
                error:true,
                errorText:"FORM_ERROR_PASSWORD_password"
            }
        }         
        if(!formValidateField(currentPassword.value,'password')){
            validForm = false
            errors['currentPassword'] = {
                ...currentPassword,
                error:true,
                errorText:"FORM_ERROR_PASSWORD_password"
            }
        }         

        if(validForm){
            dispatch(
                generalActions.updatePassword({
                    'currentPassword':currentPassword.value,
                    'newPassword':newPassword.value,
                })
            ).then(
            (response)=>{
                console.log('update profile',response)
                setUpdatePasswordLoading(false)
                history.push(RP.getPath(t,RP.PROFILE))
                onSuccess(response)
                dispatch(appActions.setGlobalMessage(t('CHANGE_PASSWORD_success')))
            },
            (error)=>{      
                console.log('log re')
                console.log(error)
                setUpdatePasswordLoading(false)          
                onError(error,errors)
            })
        }else{
            setUpdatePasswordLoading(false)
            onError(null,errors)
        }
    }

    

    // profileGuruHandles
    

    const handleLogout = ()=>{
        setLoading(true)
        dispatch(
            authActions.logout()
        ).then(
            ()=>{ 
                setLoading(false)
                console.log('then logout') 
                history.push(RP.getPath(t,RP.BASE))
            },
            ()=>{ 
                setLoading(false)
                console.log('then logout fail');
            }
        ) 
    }

    const getMyProfile = (loadCategories=true)=>{
        console.log("get The profile")
        setLoading(true)
        dispatch(
            generalActions.getMyProfile()
        ).then((response)=>{
            console.log("profileResponse",response)
            if(loadCategories){
                getCategories()
            }else{
                setLoading(false)
            }
        },(error)=>{
            console.log("profileError",error)
            setLoading(false)
        })
    }    

    const getCategories = () => {
        generalActions.getCategories().then(
            (data)=>{
                console.log(data)

                setCategories(data.list.map((c)=>(
                    {
                        "id":c.categoryId,
                        "value":c.name
                    }
                )))
                setLoading(false)
            },
            (error)=>{
                console.log(error)
                setLoading(false)
            }
        )
    }

    const showProfileView=()=>{
        return <div className="">
                <div className="row my-3">
                    <div className="col-12">
                        <h2><b>{t('GENERAL_my_profile')}</b></h2>
                    </div>
                </div>
                <div className="row align-content-between mt-3">
                    <div className="col-md-8 mt-1">
                        <div className="az-card-container az-card-container--extra-padding h-100">
                            <ProfileInfo
                                profile={user}
                                onCheckReviews={handleCheckReviews}
                                onCategoriesEdit={()=>{setSelectedComponent("CATEGORIES")}}
                                onLanguagesEdit={()=>{setShowLangModal(true)}}
                                onIsIndependentEdit={()=>{setSelectedComponent("INDEPENT")}}
                                onLevelEdit={()=>{setSelectedComponent("LEVEL")}}
                                onPlaceEdit={()=>{setShowPlacesModal(true)}}
                                onOptionsClick={()=>{setShowOptions(true)}}
                            ></ProfileInfo>
                        </div>
                    </div>
                    <div className="col-md-4 mt-1 d-none d-md-block">
                        <div className="az-card-container h-100">
                            <ProfileMenu
                                isAGuru={user.isAGuru}
                                onEditProfile={()=>{history.push(RP.getPath(t,RP.PROFILE_EDIT))}}
                                onUpdatePassword={()=>{setShowChangePassword(true)}}
                                onAcceptStripe={()=>{history.push(RP.getPath(t,RP.CONFIG_STRIPE))}}
                                onViewBloquedUsers={()=>{history.push(RP.getPath(t,RP.BLOQUED_USERS))}}
                                onLogout={()=>{setShowLogoutConfirmation(true)}}
                                onSendABug={()=>{setShowSendABug(true)}}
                            ></ProfileMenu>
                        </div>
                    </div>
                </div>
            </div>
    }

    const showSelectedComponent = ()=>{
        switch (selectedComponent) {
            case "FORM":                                
                return showProfileView()
            case "CATEGORIES":
                return <div className='p-2 bg-white col-12'>
                    <SelectCategory
                        categories={categories}
                        backCategorySelect={guruData.categorySelect?guruData.categorySelect:undefined}
                        backTags={guruData.tags?guruData.tags:[]}
                        backSelectedTags={user.tags?Object.fromEntries(user.tags.map((tag)=>([tag.tagId,tag.tag]))):{}}
                        backTagsNumber={user.tags?user.tags.length:0}
                        onBack={()=>{setSelectedComponent("FORM")}}
                        onOk={handleUpdateSpecialities}
                        update                      
                    >
                    </SelectCategory>
                </div>
            case "LEVEL":
                return <div className='p-2 bg-white col-10 col-sm-8 col-md-6'>
                    <SelectGuruLevel                        
                        onOk={handleUpdateGuruLevel}
                        backLevel={user.guruLevel!==undefined?user.guruLevel:-1}
                        onBack={()=>{setSelectedComponent("FORM")}}
                        update
                    >
                    </SelectGuruLevel>
                </div>
            case "INDEPENT":
                return <div className='p-2 bg-white col-10 col-sm-8 col-md-6'>
                    <SelectGuruIndepent                        
                        onOk={handleUpdateIsIndepedent}
                        backCompanyName={user.companyName?user.companyName:""}
                        backIsIndependent={user.isIndependent!==undefined?user.isIndependent:-1}
                        onBack={()=>{setSelectedComponent("FORM")}}
                        update
                    >
                    </SelectGuruIndepent>
                </div>
            default:
                break;
        }
    }

    const handleUpdateSpecialities = (data)=>{
        const {
            selectedTags
        }=data

        console.log('send specialities data',data)

        setLoading(true)
    
        dispatch(
            generalActions.updateSpecialities(Object.keys(selectedTags).filter((tag)=>(selectedTags[tag]!==false)))
        ).then((response)=>{
            setLoading(false)
            setSelectedComponent("FORM")
            setMessageModal(t('PROFILE_edit_specialities_success'))
            getMyProfile(false)
        },(error)=>{
            setLoading(false)
            setSelectedComponent("FORM")
            setMessageModal(t('ERROR_'+error.data.codeNumber))

        })
    }

    const handleUpdateLanguages = (data)=>{
        const {
            languages
        }=data

        console.log('send languages data',data)

        setLoading(true)
    
        dispatch(
            generalActions.updateLanguages(languages.value)
        ).then((response)=>{
            setLoading(false)
            setShowLangModal(false)
            setMessageModal(t('PROFILE_edit_languages_success'))
            getMyProfile(false)
        },(error)=>{
            setLoading(false)
            setShowLangModal(false)
            setMessageModal(t('ERROR_'+error.data.codeNumber))

        })
    }
    
    const handleUpdateLocation = (data)=>{        
        console.log('send location data',data)
        const {
            isCountry=undefined,
            ...dataRest
        } = data

        let newData  = {
            ...dataRest,
            'lat':data.lat(),
            'lon':data.lon(),
        }
        console.log(newData)

        setLoading(true)
    
        dispatch(
            generalActions.updateLocation(newData)
        ).then((response)=>{
            setLoading(false)
            setShowPlacesModal(false)
            setMessageModal(t('PROFILE_location_success'))
            getMyProfile(false)
        },(error)=>{
            setLoading(false)
            setShowPlacesModal(false)
            setMessageModal(t('ERROR_'+error.data.codeNumber))

        })
    }

    const handleUpdateIsIndepedent = (data)=>{
        const {
            isIndependent,
            companyName
        }=data

        console.log('send independent data',data)

        setLoading(true)
    
        dispatch(
            generalActions.updateIsIndependent(isIndependent,companyName)
        ).then((response)=>{
            setLoading(false)
            setSelectedComponent("FORM")
            setMessageModal(t('PROFILE_update_is_independent_success'))
            getMyProfile(false)
        },(error)=>{
            setLoading(false)
            setSelectedComponent("FORM")
            setMessageModal(t('ERROR_'+error.data.codeNumber))

        })
    }

    const handleUpdateGuruLevel = (data)=>{
        const {
            level
        }=data

        console.log('send level data',data)

        setLoading(true)
    
        dispatch(
            generalActions.updateGuruLevel(level)
        ).then((response)=>{
            setLoading(false)
            setSelectedComponent("FORM")
            setMessageModal(t('PROFILE_edit_level_success'))
            getMyProfile(false)
        },(error)=>{
            setLoading(false)
            setSelectedComponent("FORM")
            setMessageModal(t('ERROR_'+error.data.codeNumber))

        })
    }

    const handleSendABug = (data,onSuccess,onError)=>{
        setLoading(true)
        dispatch(generalActions.sendABugReport(data.message,data.image)).then(
            (response) => {
                setLoading(false)
                setShowSendABug(false)
                dispatch(appActions.setGlobalMessage(t('REPORT_A_PROBLEM_success')))
            },
            (error) => {
                console.log('send a bug error',error)
                setLoading(false)
                onError(error)
            }
        )
    }

    useEffect(() => {
        getMyProfile()       
        dispatch(navActions.switchToNone())
    }, []);
   

    useEffect(() => {
        console.log('checkHistory function',history.listen(x=>{}))
        const unblock = history.block((location, action) => {
            console.log('checkHistory from block'+history.length)
            
            if(action == "POP"){
                if (selectedComponent!=='FORM') {
                    return false
                 }
            }
            return true;
        });

        return history.listen(locationR => {             
            if(selectedComponent!=='FORM'){
                console.log('cant set the component')                    
                console.log('history back? form',location.pathname)
                
                setSelectedComponent('FORM')                    
                
            }  
            unblock();                                  
        });
    }, [selectedComponent]);
    useEffect(() => {
        console.log('checkHistory'+history.length)
        return () => {
            console.log('goback')
        };
    }, []);
      

    return (
        <div>
            <AZLoading
                show={loading}
            >
            </AZLoading>
            {showChangePassword&&
            <AZModalTitle 
                onClose={()=>{setShowChangePassword(false)}} 
                show={showChangePassword}                                                      
                title={t('PROFILE_update_password_title')}
            >
                <ChangePasswordForm
                    onSubmit={handleChangePasswordSubmit}                     
                    profile={auth.profile} 
                      
                    loading={updatePasswordLoading}
                    onClose={()=>{setShowChangePassword(false);setUpdatePasswordLoading(false)}}       
                >
                </ChangePasswordForm>                
            </AZModalTitle>
            }

            <AZModalTitle 
                onClose={()=>{setShowSendABug(false)}} 
                show={showSendABug}                                                      
                title={t('MORE_OPTIONS_report_a_problem')}
            >
                {showSendABug&&
                    <SendABugForm 
                        onSubmit={handleSendABug}
                        onCancel={()=>{setShowSendABug(false)}}
                        loading={false}
                    />        
                }
            </AZModalTitle>
            
            <AZModalTitle 
                onClose={()=>{setShowOptions(false)}} 
                show={showOptions}                                                      
                title={t('MORE_OPTIONS_title')}
            >
                {showOptions&&
                    <div className="p-2">
                        <ProfileMenu
                            isAGuru={user.isAGuru}
                            onEditProfile={()=>{setShowOptions(false); history.push(RP.getPath(t,RP.PROFILE_EDIT))}}
                            onUpdatePassword={()=>{setShowOptions(false); setShowChangePassword(true)}}
                            onAcceptStripe={()=>{ setShowOptions(false); history.push(RP.getPath(t,RP.CONFIG_STRIPE)) }}
                            onViewBloquedUsers={()=>{setShowOptions(false); history.push(RP.getPath(t,RP.BLOQUED_USERS))}}
                            onLogout={()=>{setShowOptions(false); setShowLogoutConfirmation(true)}}
                            onSendABug={()=>{setShowOptions(false); setShowSendABug(true)}}
                        ></ProfileMenu>
                    </div>      
                }
            </AZModalTitle>

            <AZDesicionModal
                show={showLogoutConfirmation}
                onClose={()=>{setShowLogoutConfirmation(false)}}
                noOptionText={false}
                onNoOption={false}    
                title={t('LOGOUT_WARINING_title')}            
                yesOptionText={t('PROFILE_my_options_logout')}
                onYesOption={handleLogout}                
            >      
                <div className="p-5 text-center">
                    <h6 className="text-muted">
                        {t('LOGOUT_WARINING_description')}
                    </h6>         
                </div>          
            </AZDesicionModal>

            <AZModalTitle 
                show={showLangModal}
                title={t('BECOME_GURU_form_languagues_title')}
                onClose={()=>{setShowLangModal(false)}}
            >               
                {showLangModal?<SelectLanguages
                    onOk={handleUpdateLanguages}
                    backLanguages={user.languages?user.languages:[]}
                    backLangList={user.languages?user.languages.map((l)=>({'id':l,'value':t(`languagues.${l}`),'selected':true})):[]}
                    backLangListSelected={user.languages?user.languages.map((lang)=>(t(`languagues.${lang}`))).join(', '):""}
                    update
                >                    
                </SelectLanguages>:<div></div>}
            </AZModalTitle>

            <AZModal
                show={showPlacesModal}
                onClose={()=>{setShowPlacesModal(false)}}                
            >
                {showPlacesModal?<SelectPlaces                    
                    onOk={handleUpdateLocation}
                    onSelectPlace={(data)=>{}}
                >                    
                </SelectPlaces>: <div></div> } 
            </AZModal>

            {/* messages modal */}
            <AZModal
                show={messageModal!==""}
                onClose={()=>{setMessageModal("")}}
            >
                <div className="p-5">
                    <h5 className="text-center text-purple">
                        {messageModal}
                    </h5>
                </div>                
            </AZModal>
            <div className="container-lg py-1 bg-transparent">
                <div className="row justify-content-center">
                    <div className="col-12 row col-container justify-content-center">
                        {showSelectedComponent()}
                    </div>
                </div>
            </div>        
        </div>
    );
}

const mapStateToProps = (state)=>{
    const {
        auth
    } = state
    console.log('the profile',auth.user)
    return {
        auth,
        "user":auth.user
    }
}

export default connect(mapStateToProps)(MyProfile);
