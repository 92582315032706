import React,{ useState } from 'react';
import AZProfileImage from '../profile/AZProfileImage';
import AZButtonLink from '../form/AZButtonLink';
import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';
import FormatedDate from '../format/FormatedDate';
import FormatRating from '../format/FormatRating';

const NewRequestMatchedGuruItem = (props) => {
    const {
        model,        
        onViewReviews,
        onSend,
        onItemClick,
        blocked
    } = props

    const {t,i18n} = useTranslation()

    const {
        idUser,
        name,
        description,
        imageSufix,
        level,
        isIndependent,
        rates,
        rating,
        status
    } = model

    const handleViewReviews = (e) =>{
        onViewReviews(idUser)
    }

    const handleItemSend = (e)=>{
        onSend(idUser,model)
    }

    const handleItemClick = (e) => {    
        onItemClick()    
    }    

    const renderSendStatus = (theStatus) =>{
        return <AZButton
            loading={theStatus=="sending"}
            type="button"
            style="color"
            disabled={theStatus=="sent"||blocked}
            onLoadText={t('SEEKER_modal_direct_message_progress_button')}
            onClick={handleItemSend}
        >
            {theStatus=="send"?t('SEEKER_send'):t('SEEKER_sent')}            
        </AZButton>        
    }

    return (  
        <div>
            <div className="az-list__content__item az-list__content__item--request-matched-guru align-items-center" onClick={handleItemClick}>                
                <div className="col-sm col-12">
                    <div className="d-inline-block-middle">
                        <AZProfileImage
                            user={{
                                ...model,
                                isAGuru:true,
                                isIndependent,
                                guruLevel:level
                            }}
                            bordered={true}
                            size="sm"
                        >                    
                        </AZProfileImage>
                    </div>
                    <div className="d-inline-block-middle">
                        <h5>
                            {name}
                        </h5>                        
                        <h6 className="">
                            <i className="fa fa-star ms-1 me-1"></i>
                            {rating==0?t('GENERAL_no_reviews'):
                                <span>
                                    <FormatRating>{rating}</FormatRating>
                                    <span>
                                        {` · ${rates} ${t('GENERAL_reviews')}`}
                                    </span>
                                </span>
                            }                        
                        </h6>
                    </div>                        
                </div>
                <div className="col-sm-auto mx-sm-2 text-center col-6">
                    <AZButtonLink
                                style="color"
                                underline
                                onClick={handleViewReviews}                        
                            >
                        {t('GURU_request_label_check_reviews')}
                    </AZButtonLink>
                </div>
                <div className="col-sm-auto mx-sm-2 text-center col-6">
                    {renderSendStatus(status)}
                </div>                                                               
            </div>            
        </div>      
    );
}

export default NewRequestMatchedGuruItem;

