import React from 'react';

import c from "../../constants"

import { useTranslation } from 'react-i18next';

const LandingFooter = () => {
    //Use translations
    const { t, i18n } = useTranslation();

    return (            
        <div className="row bgfooter">
            <footer className=" col-12 text-white">
                <div className="row p-4">
                    <div className="col-sm-12 col-md-8 terms">
                        {t('HOME_FOOTER_COPYRIGHT')}
                       <a className="termuse" href="./terms"> {t('HOME_FOOTER_TERMS')}</a>
                    </div>
                    <div className="col-sm-12 col-md-4 icons">                        
                        <a href="#" className="">
                          <i className="iconF"><img src={`${c.resources.webpage}iconFacebook.svg`}/></i>
                        </a>                        
                        <a href="#" className="">
                          <i className="iconT"><img src={`${c.resources.webpage}iconTwitter.svg`}/></i>
                        </a>
                    </div>
                </div>                 
            </footer>    
        </div>    
    );
}

export default LandingFooter;
