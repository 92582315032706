import C from "../constants"
import axios from "axios";
import authHeader from './auth-header';

const API_URL = C.ws + "auth/";

class UserAuthService{    
    API_URL = C.ws + "auth/";

    checkEmailAvaiable(email){
        const param = new URLSearchParams();
        param.append('email',email);        
        return axios
        .get(this.API_URL + "is-email-available",{
            headers:{
                'Content-type': 'application/json;charset=utf-8',            
            },
            params:{
                email
            },
        })
        .then((response) =>{   
            console.log(response)       
            return response;
        })
    }

    createNewUserProfile(
        params 
    ) {    
        const {
            device,
            email,
            lang,
            languages,
            lastName,
            name,
            password,
            phoneCodeId,
            phoneNumber,
            profileDescription,
            so,
        }=params          

        console.log('the send body...',params)

        return axios
        .post(this.API_URL + "create-account-with-email", { 
            device,
            email,
            lang,
            languages,
            lastName,
            name,
            password,
            phoneCodeId,
            phoneNumber,
            profileDescription,
            so,
         },{
            headers:{
                "Content-Type":"application/json"
            }
         })
        .then((response) => {                        
            return response
        })
    }

    getPhoneCodes(){
        return axios
        .get(this.API_URL + "get-phone-codes")
        .then((response) =>{          
          return response;
        }).catch((error)=>{
            return error
        })
    }   


    loginUsingMailCredentials(
            params
        ){        
        const{
            so,
            device,
            email,
            password,
            lang
        } = params
        
        return axios
        .post(this.API_URL + "login-with-email",{
            email,
            password,
            so,
            lang,
            device
        },{
            headers:{
                "Content-Type":"application/json"
            }
        })
        .then((response) => {
            return response          
        })
    }
    

    logout(){
        return axios
        .post(this.API_URL + "logout",{},{ 
            headers:{
                ...authHeader(),
                "Content-Type":"application/json;charset=utf-8"
            }
        })
        .then((response) => {
            localStorage.removeItem("auth");
        })
    }

    passwordRecovery(email,lang){        
        return axios
        .post(this.API_URL + "recover-password", {
             email, 
             lang 
        })
        .then((response) => {
            return response
        })
    }

    updateSession(
        so,
        device,
        lang,
    ){                        
        return axios
        .post(this.API_URL + "update-session",{            
            so,
            lang,
            device
        },{
            headers:{
                ...authHeader(),
                "Content-Type":"application/json"
            }
        })
        .then((response) => {
            return response          
        })
    }

    uploadProfilePhoto(url,profilePhoto){
        console.log('file upload',profilePhoto)
        return axios
        .put(url,profilePhoto,{            
            headers:{
                'Content-Type': 'image/jpeg',
            }
        })
        .then((response) => {
            return response
        })
    }
        
    
}

export default new UserAuthService()