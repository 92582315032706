import React from 'react';

const AZGuruBadge = (props) => {
    const {
        guruLevel=1,
        isIndependent=false,
        size="sm",
        top=false
    } = props
    return (       
        <div className={`az-guru-badge az-guru-badge--${size} ${top&&"az-guru-badge--up"}  ${isIndependent&&"az-guru-badge--independent"}`}>
            <div className="az-guru-badge__wrapper">
                <i className={`${guruLevel==0?"fa fa-heart":"fas fa-crown"}`}></i>
            </div>
        </div>        
    );
}

export default AZGuruBadge;
