import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import AZButton from '../form/AZButton';
import { formValidateField } from '../../helpers/formvalidation';

import AZSelectMultiple from '../form/AZSelectMultiple';


const SelectLanguages = (props) => {

    const {
        onOk,
        backLangList=[],
        backLangListSelected="",
        backLanguages=[],
        update
    } = props

    const {t} = useTranslation()

    const [langList, setLangList] = useState(backLangList);    
    const [langListSelected, setLangListSelected] = useState(backLangListSelected);    
    const [langListOpen, setLangListOpen] = useState(false);
    const [langListLoading, setLangListLoading] = useState(false); 
    const [form, setForm] = useState({
        "languages":{
            name:"languages",
            value:backLanguages,
            error:false,
            errorText:""
        }
    });    
    
    const handleOk = ()=>{
        onOk({
            "languages":form.languages,
            langList,
            langListSelected
        })
    }

    const handleLangListClick = () =>{      
        if(langListOpen){
            setLangListOpen(false)
        }else{
            let languagues = t('languaguesArray')
            console.log(languagues)
            let prevLangList = langList
            let tempLangList = languagues.map((l,index)=>(
                {
                    id:l.value,
                    value:l.label,
                    selected:prevLangList[index]!==undefined?prevLangList[index].selected:false,
                }
            ))
            setLangList([
                ...tempLangList
            ])   

            setLangListLoading(false) 
            setLangListOpen(true)               
        }        
    }

    const handleLangSelect = (id,selected) =>{    
        setLangList(
            langList.map((lang)=>{
                return lang.id == id?{...lang,selected}:{...lang}
            })
        )                   
    } 

    //item select effect 
    useEffect(() => {                
        setLangListSelected(
            langList.filter((lang)=>{
                console.log(lang)
                return lang.selected
            }).map(lang => lang.value).join(', ')
        ) 
        setForm({
            ...form,
            "languages":{
                ...form['languages'],               
                value:langList.filter((lang)=>{
                    console.log(lang)
                    return lang.selected
                }).map(lang => lang.id)
            }
        })  
        console.log("thshow",form)

    }, [langList]);    

 
    return (
        <div>                       
            <div className="row justify-content-center my-2">
                <div className="col-md-10 my-2">
                    <h4 className='text-center'>{update?t('PROFILE_edit_languages_title'):t('BG_LANGS_title')}</h4>
                </div>

                <div className="col-md-10 my-2">
                    <AZSelectMultiple
                        {...form.languages}                       
                        title={langListSelected==""?t('CREATE_ACCOUNT_langs_you_speak'):langListSelected}
                        list={langList}
                        isListOpen={langListOpen}                        
                        onToggle={handleLangListClick}
                        onItemClick={handleLangSelect}
                        loading={langListLoading}
                    >
                        {t('FORM_LANGUAGES')}
                    </AZSelectMultiple>
                </div>
            </div>           
            <div className="row my-4 justify-content-center">
                <div className="col-auto">
                    <AZButton
                        style="color"
                        disabled={langListSelected==""}
                        onClick={handleOk}
                    >
                        {update?t('PROFILE_edit_languages_ok_button'):t('BG_LANGS_button_done')}                        
                    </AZButton>
                </div>
            </div>
        </div>
    );
}

export default SelectLanguages;
