import { useState,useRef } from "react";
import { useTranslation } from "react-i18next";


import icon from  "../../img/icon_password_view.svg" 


const AZPassword = (props) => {
    const {        
        name,
        error,
        errorText,
        focus,
        placeholder,
        value,
        children,
        onChange,
    }=props

    const inputValue = useRef()
    const {t,i18n} = useTranslation()

    const [showPasswordText,setShowPasswordText] = useState(false)

    return (
        <div>
            <div className={`az-password ${error?"az-password--error":""} ${focus?"az-password--focus":""}`}>
                <div className="az-password__textfield">
                    <input 
                        ref={inputValue}
                        name={name}
                        value={value}
                        placeholder={children}
                        onChange={onChange}
                        autoComplete={'false'}
                        readOnly={true}
                        onFocus={()=>{inputValue.current.removeAttribute('readonly') }}
                        type={showPasswordText?"text":"password"}                  
                    />
                </div>
                <button 
                    className="az-password__icon" 
                    type="button"                
                    onClick={()=>(setShowPasswordText(!showPasswordText))}
                >
                    {!showPasswordText?<i className="fa fa-eye"></i>: <i className="fa fa-eye-slash"></i> }
                </button>
            </div>
            {
                error&&
                <div className="az-password__error text-danger">
                    <p>{t(errorText)}</p>
                </div>
            }
        </div>
    );
}

export default AZPassword;
