import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { useEffect } from "react";
import {BrowserRouter as Router} from "react-router-dom"
import C from "./constants"
import { initializeApp } from "@firebase/app";
import LandingRouter from "./routers/LandingRouter"
import AppRouter from "./routers/AppRouter"

import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe(C.stripe.publishableKey)


function App(props) {
  const {auth} = props 

  //This code will not be necesary anymore useEffect(() => {
  //   firebaseInit()
  // }, []);
  // const firebaseInit = async ()=>{  
  //     console.log("init firebase")  
  //     initializeApp(C.firebase);
  // }
  const options = {
    // passing the client secret obtained from the server
    //clientSecret: '{{CLIENT_SECRET}}',
  };
  

  const showRouter = (isLogin)=>{
    console.log('isLogged',isLogin)
    return isLogin?      
      
        <AppRouter/>        
    :  
      <LandingRouter/>
    
  }

  return (        
      
       showRouter(auth.isLoggedIn)
         
  );
}

const mapStateToProps = (state) =>{
  const {auth} = state

  return {
    auth
  }
}

export default connect(mapStateToProps)(App);
