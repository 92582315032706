import React from 'react';

import AZTutorialDots from './AZTutorialDots';
import AZButton from '../form/AZButton'

import "./index.css"
import { useTranslation } from 'react-i18next';

const AZTutorial = (props) => {
    const {
        elements,
        step,
        onBack,
        onNext,
        onFinish,
        nextText,
        finishText,
        onDotClick
    } = props
    const {
        t
    }= useTranslation()

    //get the element info    

    const handleBack = ()=>{
        onBack()
    }
    const handleNext = ()=>{
        console.log('on next',step)
        if(step == elements.length-1){
            onFinish()
        }else{
            onNext()
        }
    }

    const handleDotClick = (index)=>{
        onDotClick(index)
    }

    


    return (
        <div>
            <div className="az-tutorial">                
                <div className="az-tutorial__header">
                    <div className="row justify-content-center">
                        <div className="col-6 col-sm-4">
                            <img className='w-100' src={elements[step].image} alt={`tutorial image ${step}`}/> 
                        </div>
                    </div>
                    {/* <div className="az-tutorial__header__img">
                        <img src={elements[step].image} alt={`tutorial image ${step}`}/> 
                    </div>                     */}
                </div>
                <div className="az-tutorial__buttons">
                    {
                        step>0&&<div className="az-tutorial__buttons__button az-tutorial__buttons__button--left" onClick={handleBack}>
                            <h1><i className="text-purple fa fa-chevron-left"></i></h1>
                        </div>
                    }
                    <div className="az-tutorial__buttons__button az-tutorial__buttons__button--right" onClick={handleNext}>
                        <h1><i className="text-purple fa fa-chevron-right"></i></h1>
                    </div>
                </div>
                <div className="az-tutorial__content">
                    <h4>
                        {elements[step].title}
                    </h4>     
                    <p>
                        {elements[step].description}
                    </p>                                                                                                   
                </div>
                <div className="my-2 d-flex justify-content-center">
                    <AZTutorialDots
                        step={step}
                        elements={elements}
                        onItemClick={handleDotClick}
                    >
                    </AZTutorialDots>
                </div>

                {console.log('the step',step)}
                {console.log('the length',elements.length)}

                <div className="mt-3 my-2 row justify-content-center">
                    {
                        step!==0&&
                        <div className='col-auto'>
                            <AZButton
                                onClick={handleBack}
                            >
                                {t('GENERAL_back')}
                            </AZButton>                        
                        </div>
                    }
                    <div className="col-auto">
                        <AZButton
                            onClick={handleNext}
                        >
                            {step==elements.length-1?finishText:nextText}
                        </AZButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AZTutorial;
