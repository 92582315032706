export default function authHeader() {
    const auth = JSON.parse(localStorage.getItem('auth'));

    if (auth && auth.session && auth.user) {
        const {
            user,
            session
        } = auth
        return { auth:`${user.id} ${session}`};
    } else {
        return {};
    }
}