import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GuruRequestList from './GuruRequestList';

import RP from '../../helpers/routePath';

import useWindowScrollApiList from '../../hooks/useWindowScrollApiList';

import AZModalTitle from '../modal/AZModalTitle';
import AZModal from '../modal/AZModal';

import ReportRequestForm from './ReportRequestForm';
import SendMessageForm from './SendMessageForm';
import RequestOptions from './RequestOptions';

import * as guruActions from '../../actions/guru.actions'
import * as generalActions from '../../actions/general.actions'
import * as appActions from '../../actions/app.actions'
import * as navActions from '../../actions/navbar.actions'

import { merge2ArraysById } from '../../helpers/arrays';



const GuruDashboard = (props) => {
    const {
        user,
        dispatch,
        guruRequestsAvaiables,
        notifications
    } = props

    const {t} = useTranslation()
    const history = useHistory()

    const rows = 15;
    //const [lastRequestId, setLastRequestId] = useState(-1);

    const [showOptions, setShowOptions] = useState(false);
  
    const [showSendMessageForm, setShowSendMessageForm] = useState(false);
    const [sendMessageLoading, setSendMessageLoading] = useState(false);
  
    const [showReportForm, setShowReportForm] = useState(false);
    const [sendReportLoading, setSendReportLoading] = useState(false);

    const [selectedRequest, setSelectedRequest] = useState({});

    const [messageControl, setMessageControl] = useState("");

    const [
        requestList,
        requestLoading,
        isScrolling,
        setRequestList
    ] = useWindowScrollApiList({ 
        apiCall:guruActions.getRequests,
        'rows':rows,    
        onSuccess:(response)=>{
            console.log("response after hook",response)
        },
        onError:(error)=>{
            console.log("error after hook",error)
        },
        testing:true,
        loadById:true,
        dispatch,
        itemIdName:"requestId"
    }) 
 
    const handleItemCheckReview=(idUser)=>{
        history.push(RP.getPath(t,RP.PROFILE_USER,idUser),{'reviewAs':'SEEKER'})
    }
    
    const handleOptionsClick=(requestId,title,model)=>{
        setSelectedRequest({
            ...model,
            requestId,
            title
        })        
        setShowOptions(true)
    }

    const handleSendMessageClick = (
        requestId,
        userId,            
        title,
        description,
        model
    ) =>{
        console.log('messages click',requestId)
        setSelectedRequest({
            requestId,
            userId,            
            title,
            description,
            model
        })
        setShowSendMessageForm(true)
    }

    const handleSendMessage=(data,onSuccess,onError)=>{
        const {
            message
        }=data
        console.log("the message",message)
        console.log("the data",selectedRequest)
        setSendMessageLoading(true)

        guruActions.sendProposalToSeeker(selectedRequest.requestId,message,0).then(
            (response)=>{

                const {
                    idConversation,
                    idMessage,                    
                } = response
                // dispatch(
                //     appActions.addGuruConversations({
                //         ...selectedRequest,
                //         ...selectedRequest.model,
                //         'idUser':selectedRequest.model.userId,
                //         idConversation,
                //         idRequest:selectedRequest.requestId,                        
                //         idMessage,                        
                //         'lastMessageIdUser':user.idUser,                                                
                //         notReadByGuru: 0,
                //         notReadBySeeker: 1,
                //         lastMessage:message,
                //         lastMessageType:0
                //     })
                // )
                dispatch(appActions.addNotifications({
                    'notifConversations':notifications.notifConversations+1, 
                    //'notifRequests':notificationsRef.current.notifRequests+1,
                    'guruNewConversations':notifications.guruNewConversations+1,
                    //'guruRequestsAvaiables':notificationsRef.current.guruRequestsAvaiables+1,
                })) 

                setRequestList([
                    ...requestList.filter((r)=>{
                        return selectedRequest.requestId !== r.requestId
                    })
                ])
                //onSuccess(response)
                setShowSendMessageForm(false)
                setMessageControl(t('GURU_proposal_sent_description'))
                setSendMessageLoading(false)
            },(response)=>{
                onError(response)
                setSendMessageLoading(false)
            }
        )
        
    }

    const handleReportRequest=(data,onSuccess,onError)=>{
        const {
            message,
            option
        }=data
        console.log("the message",message)
        console.log("the data",selectedRequest)
        setSendReportLoading(true)

        generalActions.reportRequest(selectedRequest.requestId,option,message).then(
            (response)=>{
                setShowReportForm(false)
                setMessageControl(t('GURU_report_sent_success'))
                //onSuccess(response)
                setSendReportLoading(false)
            },(response)=>{
                onError(response)
                setSendReportLoading(false)
            }
        )

        
    }

    useEffect(() => {
        console.log('guruRequestsAvaiables ok',`requestAvaiable-${guruRequestsAvaiables}>0&&requestList-${requestList.length}>0&&requestLoading-!${requestLoading}`)
        if(guruRequestsAvaiables>0&&requestList.length>0&&!requestLoading){            
            dispatch(guruActions.getRequests(-1,5)).then(
                (response) => {
                    console.log('guruRequestsAvaiables response',response)                    
                    const newList = response.list
                    //requestId
                    if(newList.length > 0){
                        const mergedList = merge2ArraysById(newList,requestList,'requestId')                        
                        setRequestList([
                            ...mergedList
                        ])
                    }   
                    dispatch(appActions.addNotifications({                        
                        'guruRequestsAvaiables':0,
                    }))                 
                },
                (response) => {
                    return 
                }
            )
        }        
    }, [guruRequestsAvaiables]);
    
    useEffect(() => {
        dispatch(
            navActions.switchToGuru()
        )
        dispatch(appActions.addNotifications({
            'notifRequests':0,            
            'guruRequestsAvaiables':0,
        }))
        return ()=>{
            dispatch(appActions.addNotifications({
                'notifRequests':0,            
                'guruRequestsAvaiables':0,
            }))
        }
    }, []);

    return (
        <div>
            <AZModalTitle
                title={selectedRequest.title?selectedRequest.title:""}
                show={showOptions}
                onClose={()=>{setShowOptions(false);setSelectedRequest({})}}
            >
                {showOptions?<RequestOptions
                    onReport={()=>{setShowReportForm(true);setShowOptions(false);}}
                    onView={()=>{ handleItemCheckReview(selectedRequest.userId) }}
                    onAnswer={()=>{ handleSendMessageClick(
                        selectedRequest.requestId,
                        selectedRequest.userId,            
                        selectedRequest.title,
                        selectedRequest.description,
                        );setShowOptions(false);
                    }}
                    onCancel={()=>{setShowOptions(false);setSelectedRequest({})}}
                >                    
                </RequestOptions>:<div></div>}
            </AZModalTitle>

            <AZModalTitle
                title={selectedRequest.title?selectedRequest.title:""}
                show={showReportForm}
                onClose={()=>{setShowReportForm(false);setSelectedRequest({});setSendReportLoading(false);}}
            >
                {showReportForm?<ReportRequestForm
                    loading={sendReportLoading}                                        
                    onSubmit={handleReportRequest}
                    onCancel={()=>{setShowReportForm(false);setSelectedRequest({});setSendReportLoading(false);}}
                >                    
                </ReportRequestForm>:<div></div>}
            </AZModalTitle>

            <AZModalTitle
                title={selectedRequest.title?selectedRequest.title:""}
                show={showSendMessageForm}
                onClose={()=>{setShowSendMessageForm(false);setSelectedRequest({});setSendMessageLoading(false);}}
            >
                {showSendMessageForm?<SendMessageForm
                    loading={sendMessageLoading}
                    description={selectedRequest.description?selectedRequest.description:""}
                    onSubmit={handleSendMessage}
                    onCancel={()=>{setShowSendMessageForm(false);setSelectedRequest({});setSendMessageLoading(false);}}
                >                    
                </SendMessageForm>:<div></div>}
            </AZModalTitle>

            <AZModal
                show={messageControl!==""}
                onClose={()=>{setMessageControl("")}}
            >
                <div className="p-5">
                    <h5 className="text-center text-purple">
                        {messageControl}
                    </h5>
                </div>                
            </AZModal>

            {/* HEADER */}
            <div className="w-100 bg-purple-strong p-1 p-md-3">
                <h2 className="text-white ">{t('GURU_title_tab_request')}</h2>
            </div>
            <div className="w-100 bg-white">
                <GuruRequestList
                    list={requestList}                    
                    loading={requestLoading}
                    onScroll={()=>{}}
                    noData={t('GURU_no_requests_found')}
                    onItemClick={()=>{}}
                    onViewReviews={handleItemCheckReview}
                    onSendMessage={handleSendMessageClick}
                    onOptionsClick={handleOptionsClick}
                ></GuruRequestList>
            </div>
        </div>
    );
}

const mapStateToProps = (state)=>{
    const{
        auth,
        notifications
    } = state
    return {
        user:auth.user,
        guruRequestsAvaiables:notifications.guruRequestsAvaiables,
        notifications
    }
}

export default connect(mapStateToProps)(GuruDashboard);
