import React from 'react';
import ReviewItem from './ReviewItem';
import "../../pages/index.css"

const ReviewsList = (props) => {
    const {
        full=false,
        list,
        noData,
        itemsLoaded,
        loading,
        onScroll,
        onItemViewReviews,
        onItemClick
    } = props
    
    const handlePageScroll = (e) =>{
        console.log("page scroll")
        const element = e.target
        const childHeight =element.offsetHeight;
        const {scrollTop,scrollHeight,offsetHeight} = element        
        if (
            scrollHeight - scrollTop < (offsetHeight+2)            
            && !loading
            ) {
                console.log('on scroll!!');
                onScroll()
        }
    }


    return (
        <div className={`az-list ${full&&'az-list--full'}`}>
            {loading&&<div className="az-list__loading">
                <i className="fa fa-spinner fa-spin"></i>
            </div>}
            <div className="az-list__content" onScroll={handlePageScroll}>
                {list&&list.length>0?
                    list.map(v=>(
                        <ReviewItem
                            key={v.id}
                            model={v}
                            onViewReviews = {onItemViewReviews}
                            onItemClick = {onItemClick}
                        >
                        </ReviewItem>
                    ))
                :<div>
                    {!loading?<h4 className="p-3">{noData}</h4>: <h4></h4>  }                    
                </div>    
                }
            </div>
        </div>
    );
}

export default ReviewsList;

