import {
    SHOW_LOGIN_FORM,
    SHOW_PASSWORD_FORM,
    SHOW_REGISTER_FORM,
    UPDATE_CACHE,
    CLEAN_CACHE
} from "./types"

export const cacheEmail = (cached,email)=> (dispatch) =>{
    if(cached){
        dispatch({
            type:UPDATE_CACHE,
            payload:{
                cachedEmail:email,            
            }
        })
        let theCache =  JSON.parse(localStorage.getItem('cache'))
        const cache = theCache?theCache:{};            
        localStorage.setItem("cache", JSON.stringify({
           ...cache,
           'cachedEmail':email,
           'isEmailCached':true                      
        }));
    }else{
        dispatch({
            type:CLEAN_CACHE,           
        })
        localStorage.removeItem("cache");
    }
    return Promise.resolve
}

export const showLoginForm = (show = false)=> (dispatch) =>{
    dispatch({
        type:SHOW_LOGIN_FORM,
        payload:show
    })
    return Promise.resolve
}
export const showRegisterForm = (show = false)=> (dispatch) =>{
    dispatch({
        type:SHOW_REGISTER_FORM,
        payload:show
    })
    return Promise.resolve
}
export const showPasswordRecoveryForm = (show = false)=> (dispatch) =>{
    dispatch({
        type:SHOW_PASSWORD_FORM,
        payload:show
    })
    return Promise.resolve
}
