import { useState,useEffect } from "react"

import AZField from "../form/AZField"
import AZImgInput from "../form/AZImgInput"
import AZSelectMultiple from "../form/AZSelectMultiple"
import AZButton from "../form/AZButton"
import AZTextArea from "../form/AZTextArea"
import AZCheckbox from "../form/AZCheckbox"

import { useTranslation } from 'react-i18next';

const ProfileSimpleForm = (props) => {
    const {
        profile,
        onSubmit,
        loading
    } = props

    const { t, i18n } = useTranslation();  

    const [error, setError] = useState("");
    const [form, setForm] = useState({        
        "image":{
            name:"image",
            value:"",
            error:false,
            errorText:"",
            img:[]
        },
        "profileDescription":{
            name:"profileDescription",
            value:"",
            error:false,
            errorText:""
        },
        "languages":{
            name:"languages",
            value:[],
            error:false,
            errorText:""
        },
    });

    const [langList, setLangList] = useState([]);    
    const [langListSelected, setLangListSelected] = useState("");    
    const [langListOpen, setLangListOpen] = useState(false);
    const [langListLoading, setLangListLoading] = useState(false);    
   
    //item select effect 
    useEffect(() => {                
        setLangListSelected(
            langList.filter((lang)=>{
                console.log(lang)
                return lang.selected
            }).map(lang => lang.value).join(', ')
        ) 
        setForm({
            ...form,
            "languages":{
                ...form['languages'],               
                value:langList.filter((lang)=>{
                    console.log(lang)
                    return lang.selected
                }).map(lang => lang.id)
            }
        })  
        console.log("thshow",form)

    }, [langList]);

    const handleChange = (e)=>{
        console.log("change")    
        let formName =  e.target.name
        let value = e.target.type === 'checkbox' ? e.target.checked: e.target.value
        setForm({  
            ...form,                      
            [formName]:{
                ...form[formName],
                value
            }                               
        })        
    }

    const handleSubmit = (e)=>{
        e.preventDefault()    
        setError("")
        Object.keys(form).map(i=>form[i]={
            ...form[i],
            error:false,
            errorText:""
        })    
        onSubmit(
            form,
            (error,errors)=>{
                if(errors!==null){
                    console.log(errors)
                    setForm({
                        ...form,
                        ...errors
                    })
                }   
                if(error){
                    setError("ERROR_"+error.data.codeNumber)
                }         
            }
        )
    }

    const handleLangListClick = () =>{      
        if(langListOpen){
            setLangListOpen(false)
        }else{
            let languagues = t('languaguesArray')
            console.log(languagues)
            let prevLangList = langList
            let tempLangList = languagues.map((l,index)=>(
                {
                    id:l.value,
                    value:l.label,
                    selected:prevLangList[index]!==undefined?prevLangList[index].selected:false,
                }
            ))
            setLangList([
                ...tempLangList
            ])   

            setLangListLoading(false) 
            setLangListOpen(true)               
        }        
    }

    const handleLangSelect = (id,selected) =>{    
        setLangList(
            langList.map((lang)=>{
                return lang.id == id?{...lang,selected}:{...lang}
            })
        )                   
    } 


    
    return (
        <form onSubmit={handleSubmit}>
            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <AZImgInput
                        error={form.image.error}
                        errorText={form.image.errorText}
                        src={form.image.value}   
                        square                     
                        onChange={(image,file)=>{
                            setForm({
                                ...form,
                                "image":{
                                    ...form["image"],
                                    "value":image,
                                    "img":file
                                }
                            })
                        }}
                    >                    
                    </AZImgInput>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZTextArea      
                        {...form.profileDescription} 
                        onChange={handleChange}             
                    >   
                        {t('CREATE_ACCOUNT_placeholder_input_description')}
                    </AZTextArea>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZSelectMultiple
                        name={form.languages.name}
                        title={langListSelected==""?t('CREATE_ACCOUNT_langs_you_speak'):langListSelected}
                        list={langList}
                        isListOpen={langListOpen}
                        error={form.languages.error}
                        errorText={form.languages.errorText}
                        onToggle={handleLangListClick}
                        onItemClick={handleLangSelect}
                        loading={langListLoading}
                    >
                        {t('FORM_LANGUAGES')}
                    </AZSelectMultiple>
                </div>
            </div>  
            {
                error!==""&&
                <div className="row">
                    <div className="col-12">
                        <p className="my-2 text-danger">
                            {t(error)}
                        </p>
                    </div>
                </div>
            }       
            <div className="row justify-content-center mb-5">
                <div className="col-auto">
                    <AZButton
                        onClick={()=>{}}
                        loading={loading}
                        type="submit"
                        onLoadText={t('LOADING')}
                    >
                        {t('CREATE_ACCOUNT_button_save')}
                    </AZButton>
                </div>
            </div>
        </form>
    );
}

export default ProfileSimpleForm;
