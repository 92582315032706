import React from 'react';

import { useTranslation } from 'react-i18next';

const NewRequestSendHints = (props) => {
    const {t} = useTranslation()

    return (
        <div>
            <div className="row text-start my-1">
                <div className="col-12">
                    <h4 className='my-1'>
                        {t('NEW_REQUEST_send_hint_step1_title')}
                    </h4>
                    <p className="">
                        {t('NEW_REQUEST_send_hint_step1_text')}
                    </p>
                </div>
            </div>            
            <div className="row text-start my-1">
                <div className="col-12">
                    <h4 className='my-1'>
                        {t('NEW_REQUEST_send_hint_step2_title')}
                    </h4>
                    <p className="">
                        {t('NEW_REQUEST_send_hint_step2_text')}
                    </p>
                </div>
            </div>            
            <div className="row text-start my-1">
                <div className="col-12">
                    <h4 className='my-1'>
                        {t('NEW_REQUEST_send_hint_step3_title')}
                    </h4>
                    <p className="">
                        {t('NEW_REQUEST_send_hint_step3_text')}
                    </p>
                </div>
            </div>                             
        </div>
    );
}

export default NewRequestSendHints;
