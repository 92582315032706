export const LOGIN_SUCCESS="LOGIN_SUCCESS";
export const LOGIN_FAIL="LOGIN_FAIL"; 
export const LOGOUT="LOGOUT";
export const LOGOUT_WITH_MESSAGE="LOGOUT_WITH_MESSAGE";
export const DELETE_SESSION="DELETE_SESSION";

export const SET_FORM_ERRORS="SET_FORM_ERRORS";  

export const SHOW_LOGIN_FORM="SHOW_LOGIN_FORM";
export const SHOW_REGISTER_FORM="SHOW_REGISTER_FORM";
export const SHOW_PASSWORD_FORM="SHOW_PASSWORD_FORM";

// PROFILE

export const UPDATE_PROFILE="UPDATE_PROFILE";
export const CLEAN_PROFILE="CLEAN_PROFILE";

//CACHE
export const CLEAN_CACHE="CLEAN_CACHE";
export const UPDATE_CACHE="UPDATE_CACHE";

//NAVBAR
export const NAV_GURU = "NAV_GURU";
export const NAV_NONE = "NAV_NONE";
export const NAV_SEEKER = "NAV_SEEKER";
export const NAV_SELECT_INDEX = "NAV_SELECT_INDEX";

//APP actions
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAN_MESSAGE = "CLEAN_MESSAGE";
export const SET_REQUEST = "SET_REQUEST";
export const CLEAN_REQUEST = "CLEAN_REQUEST";
export const SET_LOADING = "SET_LOADING";

export const SET_SEARCHED_GURUS = "SET_SEARCHED_GURUS";
export const CLEAN_SEARCHED_GURUS = "CLEAN_SEARCHED_GURUS";

export const OPEN_CONVERSATION = "OPEN_CONVERSATION";
export const CLOSE_CONVERSATION = "CLOSE_CONVERSATION";
export const CLEAN_CONVERSATIONS = "CLEAN_CONVERSATIONS";

export const SET_DEFAULT_PAYMENT_METHOD = "SET_DEFAULT_PAYMENT_METHOD";
export const CLEAN_DEFAULT_PAYMENT_METHOD = "CLEAN_DEFAULT_PAYMENT_METHOD";

export const ADD_PAYMENT_METHODS = "ADD_PAYMENT_METHODS";
export const REMOVE_PAYMENT_METHODS = "REMOVE_PAYMENT_METHODS";
export const SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS";
export const CLEAN_PAYMENT_METHODS = "CLEAN_PAYMENT_METHODS";

//PendingRate
export const SET_PENDING_RATES = "SET_PENDING_RATES";
export const POP_CURRENT_RATE = "POP_CURRENT_RATE";
export const UPDATE_CURRENT_RATE = "UPDATE_CURRENT_RATE";

//Notifications
export const NOTIFICATIONS_ADD = "NOTIFICATIONS_ADD";
export const NOTIFICATIONS_CLEAN = "NOTIFICATIONS_CLEAN";

//Guru conversations
export const GURU_CONVERSATIONS_SET = "GURU_CONVERSATIONS_SET";
export const GURU_CONVERSATIONS_ADD = "GURU_CONVERSATIONS_ADD";
export const GURU_CONVERSATIONS_UPDATE = "GURU_CONVERSATIONS_UPDATE";
export const GURU_CONVERSATIONS_UPDATE_MARK = "GURU_CONVERSATIONS_UPDATE_MARK";
export const GURU_CONVERSATIONS_CLEAN = "GURU_CONVERSATIONS_CLEAN";