import React from 'react';
import TipsReceivedItem from './TipsReceivedItem';
import "../../pages/index.css"

const TipsReceivedList = (props) => {
    const {
        list,
        noData,        
        loading,
        onItemClick,  
        onItemReview,             
        full=false
    } = props

    return (
        <div className={`az-list ${full&&"az-list--full"}`}>
            {loading&&<div className="az-list__loading">
                <i className="fa fa-spinner fa-spin"></i>
            </div>}
            <div className="az-list__content" >                
                {list&&list.length>0?
                    list.map(v=>(
                        <TipsReceivedItem
                            key={v.id}
                            model={v}                                                      
                            onItemClick = {onItemClick}
                            onItemReview = {onItemReview}
                        >
                        </TipsReceivedItem>
                    ))
                :<div>
                    {!loading&&list.length==0?<h4 className="p-2 mt-3 text-muted">{noData}</h4>: <h4></h4>  }                    
                </div>    
                }                
            </div>
        </div>
    );
}

export default TipsReceivedList;
