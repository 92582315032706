import { connect } from 'react-redux';

import './index.css';
import c from '../../constants'; 
import LandingPage from './LandingPage';

import * as signActions from '../../actions/sign-ui.actions'
import * as authActions from '../../actions/auth.actions'

import { getMessaging,getToken } from "@firebase/messaging";
import { getApp } from "@firebase/app";

import { useTranslation } from 'react-i18next';

import { useState,useEffect } from 'react';

import RegisterSecuence from '../../components/secuence/RegisterSecuence'
import AZModal from '../../components/modal/AZModal'
import AZModalTitle from '../../components/modal/AZModalTitle'
import SignInForm from '../../components/sign/SignInForm'
import PasswordRecoveryForm from '../../components/sign/PasswordRecoveryForm'

import {formValidateField} from "../../helpers/formvalidation"

const Landing = (props) => {
    const {
        dispatch,
        signPage,
        cache
    } = props

    const { t, i18n } = useTranslation();

    const [recoveryLoading, setRecoveryLoading] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);
    const [loginCredentials, setloginCredentials] = useState({
        so:"WEB"
    });

    const handleLoginModal = (show) => {
        console.log(`JOIN ${signPage.showLogin}`)        
        dispatch(
            signActions.showLoginForm(show)
        )
    }
    const handleRegisterModal = (show) =>{
        console.log("show secuence")
        dispatch(
            signActions.showRegisterForm(show)
        )
    }
    const handlePasswordRecoveryModal = (show) =>{
        console.log("show password")
        dispatch(
            signActions.showPasswordRecoveryForm(show)
        )
    }

    const handlePasswordRecoverySubmit = (form,onSuccess,onError) =>{
        const lang = i18n.language;
        const {
            email
        }=form;
        let errors = {}
        let validForm = true;
        setRecoveryLoading(true)       

         if(!formValidateField(email.value,'required')){
            validForm = false
            errors['email'] = {
                ...email,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_email"
            }
        }        
        
        if(!formValidateField(email.value,'email')){
            validForm = false
            errors['email'] = {
                ...email,
                error:true,
                errorText:"FORM_ERROR_EMAIL_email"
            }
        }

        if(validForm){
            authActions.passwordRecovery(email.value,lang).then(
                (data)=>{
                    setRecoveryLoading(false)
                    onSuccess(data)
                },
                (error)=>{
                    setRecoveryLoading(false)
                    onError(error,errors)
                }
            )
        }else{
            setRecoveryLoading(false)
            onError(null,errors)
        }

    }
    const handleRegisterSubmit = () =>{
       
    }
    const handleLoginSubmit = async (form,onSuccess,onError) => {
        const lang = i18n.language;
        const {
            email,
            password,
            remember_me
        } = form;
        let errors = {}
        let validForm = true;
        console.log("click")
        setLoginLoading(true)

         if(!formValidateField(email.value,'required')){
            validForm = false
            errors['email'] = {
                ...email,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_email"
            }
        }
         if(!formValidateField(email.value,'email')){
            validForm = false
            errors['email'] = {
                ...email,
                error:true,
                errorText:"FORM_ERROR_EMAIL_email"
            }
        }
        if(!formValidateField(password.value,'required')){
            validForm = false
            errors['password'] = {
                ...password,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_password"
            }
        }

        if(validForm){
            try {
                if(remember_me.value){
                    dispatch(
                        signActions.cacheEmail(true,email.value)
                    )
                }else{
                    dispatch(
                        signActions.cacheEmail(false)
                    )
                }

                let permission =  await Notification.requestPermission();
                                

                if (permission !== "granted") {
                    onError({
                        data:{
                            codeNumber:"notifications"
                        }
                    },errors)
                    setLoginLoading(false)
                    return
                }

                getToken(getMessaging(getApp())).then((token)=>{
                    setloginCredentials({
                        ...loginCredentials,  
                        "email":email.value,                              
                        "password":password.value,                              
                        "device":token
                    })

                    const sendCredentials = {
                        ...loginCredentials,                        
                    }

                    console.log('sendCredentials',sendCredentials)
                    console.log('device',token)                    

                    dispatch(authActions.login({
                        ...loginCredentials,  
                        "so":"WEB",
                        "email":email.value,                              
                        "password":password.value,                              
                        "device":token
                    })).then(
                        (data)=>{                                    
                            console.log("loginSuccess")
                            console.log(data)
                            setLoginLoading(false)
                            //Go to dashboard
                        },
                        (error)=>{
                            console.log('the error?',error.response)
                            onError(error.response,errors)
                            setLoginLoading(false)
                        }                               
                    )
                    
                }) 
                             
            } catch (error) {
                setLoginLoading(false)
                console.log(error)
                onError(null,errors)
            }
        }else{
            setLoginLoading(false)
            onError(null,errors)
        }
        authActions.login()
    }

    useEffect(() => {
        setloginCredentials({
            ...loginCredentials,
            "lang":i18n.language.substr(0,2)
        })
    },[]);

    return (
        <div>
            {/* <!-- Boton hacia arriba --> */}
            <a className="ir-arriba"  href="javascript:void(0)" title="Volver arriba">
                <i className=""><img src={c.resources.webpage+"up.svg"}/></i>
            </a>
            {/* Sign form */}
            <AZModal
                show={signPage.showLogin}
                onClose={()=>{handleLoginModal(false)}}
            >
                <SignInForm
                    onSubmit = {handleLoginSubmit}
                    onCreateAccountClick = {()=>{handleRegisterModal(true)}}
                    onForgetPasswordClick = {()=>{handlePasswordRecoveryModal(true)}}
                    loading={loginLoading}
                    cache={cache}
                >                    
                </SignInForm>
            </AZModal>
            {/* Password recovery form */}
            <AZModalTitle
                show={signPage.showPasswordRecovery}
                title={t('RECOVERY_PASS_title')}
                onClose={()=>{handlePasswordRecoveryModal(false)}}
            >                
                <PasswordRecoveryForm
                    onSubmit={handlePasswordRecoverySubmit}
                    loading={recoveryLoading}
                >                    
                </PasswordRecoveryForm>
            </AZModalTitle>
            
            {/* Register secuence */}
            <RegisterSecuence
                show={signPage.showRegister}
                onClose={()=>{handleRegisterModal(false)}}
                onSubmit={handleRegisterSubmit}
            >                
            </RegisterSecuence>

            <LandingPage 
                {...props}
                onJoinClick={()=>{handleLoginModal(true)}}             
            />
        </div>
    );
}

function mapStateToProps(state){    
    console.log("the state",state)
    const {
        signPage,
        cache
    } = state
    return {
        signPage,
        cache
    }
}

export default connect(mapStateToProps)(Landing);
