import React from 'react';
import { useTranslation } from 'react-i18next';
import FormatRating from '../format/FormatRating';
import "./index.css"

const ReviewTabPanel = (props) => {
    const {
        reviewAs,
        ratesAsGuru,
        ratesAsSeeker,
        ratingAsGuru,
        ratingAsSeeker,
        disabled=false,
        onItemClick=undefined       
    }=props
    const {t} = useTranslation()

    const handleItemClick = (as) =>{
        if(onItemClick){
            onItemClick(as)
        }
    }

    return (
        <div className={`review-tab-panel ${disabled&&"review-tab-panel--disabled"} w-100`}>
            <div className={`review-tab-panel__item ${reviewAs=="SEEKER"&&"review-tab-panel__item--selected"}`} onClick={()=>{handleItemClick('SEEKER')}}>
                <h6>
                    <i className="fa fa-star mr-1"></i>
                    <b className='ms-1 text-blue'><FormatRating>{ratingAsSeeker}</FormatRating></b>
                    <b className='ms-1'>{`${t('PROFILE_tab_seeker_title')} (${ratesAsSeeker})`}</b>                           
                </h6>    
            </div>
            <div className={`review-tab-panel__item ${reviewAs=="GURU"&&"review-tab-panel__item--selected"}`} onClick={()=>{handleItemClick('GURU')}}>
                <h6>
                    <i className="fa fa-star mr-1"></i>
                    <b className='ms-1 text-blue'><FormatRating>{ratingAsGuru}</FormatRating></b>
                    <b className='ms-1 '>{`${t('PROFILE_tab_guru_title')} (${ratesAsGuru})`}</b>                
                </h6>
            </div>
        </div>
    );
}

export default ReviewTabPanel;
