import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useState } from 'react';

import AZButtonLink from '../../components/form/AZButtonLink';
import EditProfileForm from '../../components/profile/EditProfileForm';
import AZModalTitle from '../../components/modal/AZModalTitle';
import ChangePasswordForm from '../../components/profile/ChangePasswordForm';
import AZDesicionModal from '../../components/modal/AZDesicionModal';

import RP from '../../helpers/routePath';

import {formValidateField} from "../../helpers/formvalidation"

import * as authActions from '../../actions/auth.actions'
import * as generalActions from '../../actions/general.actions'
import { useHistory } from 'react-router';

const EditProfile = (props) => {
    const {
        auth,
        dispatch,
    }=props
    let history = useHistory();
    const { t,i18next } = useTranslation()

    const [loading, setLoading] = useState(false);
    const [closeAccountModalVisible, setCloseAccountModalVisible] = useState(false);
    const [closeAccountLoading, setCloseAccountLoading] = useState(false);
    const [closeAccountMessages, setCloseAccountMessages] = useState({
        error:false,
        success:false
    });

    const handleSubmit = (params,onSuccess,onError) =>{
        const {
            image,
            name,
            lastName,
            nickname,
            description,            
        }= params                   
        console.log(params) 
        let errors = {}
        let validForm = true;
        setLoading(true)

        if(!formValidateField(name.value,'required')){
            validForm = false
            errors['name'] = {
                ...name,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_name"
            }
        }             
        if(!formValidateField(lastName.value,'required')){
            validForm = false
            errors['lastName'] = {
                ...lastName,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_lastname"
            }
        }                
        if(!formValidateField(nickname.value,'required')){
            validForm = false
            errors['nickname'] = {
                ...nickname,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_nickname"
            }
        }                
        if(!formValidateField(description.value,'required')){
            validForm = false
            errors['description'] = {
                ...description,
                error:true,
                errorText:"FORM_ERROR_REQUIRED_profile_description"
            }
        }  
        if(validForm){
            dispatch(
                generalActions.updateGeneralInfo({
                    image:image.img,
                    name:name.value,
                    lastName:lastName.value,
                    nickname:nickname.value,
                    description:description.value,  
                    returnURL:image.hasImage,  
                })
            ).then((response)=>{
                setLoading(false)
                console.log('update profile',response)
                history.push(RP.getPath(t,RP.PROFILE))
                onSuccess(response)
            },(error)=>{
                setLoading(false)                
                onError(error.response,errors)
            })
        }else{
            setLoading(false)
            onError(null,errors)
        }
    }
    const handleNicknameChange = (nickname) =>{
        console.log('nickname',nickname)                
        return dispatch(generalActions.checkNicknameValid(nickname))
    }
    // UI Modal control
    const handleCloseAccountClick = () =>{
        setCloseAccountModalVisible(true)
    }
    const handleCloseAccountModalClose = () =>{
        setCloseAccountMessages({error:false,success:false})
        setCloseAccountModalVisible(false)
    }
    //Close account
    const handleCloseAccount = () => {
        //clean messages
        setCloseAccountMessages({error:false,success:false})
        setCloseAccountLoading(true)
        dispatch(
            generalActions.deleteAccount()
        ).then(
            (response)=>{
                setCloseAccountLoading(false)
                setCloseAccountMessages({error:false,success:t('GURU_OK_delete_account_text')})
            },(error)=>{
                if(error!==undefined){
                    setCloseAccountMessages({success:false,error:t(`ERROR_${error.data.codeNumber}`)})
                }else{
                    setCloseAccountMessages({success:false,error:t(`ERROR_undefined`)})
                }
                setCloseAccountLoading(false)
            }
        )        
    }


    return (     
        <div>
            <AZDesicionModal
                show={closeAccountModalVisible}
                onClose={handleCloseAccountModalClose}  
                yesOptionText={t('GURU_delete_account_button')} 
                noOptionText={t('GENERAL_cancel')} 
                onYesOption={handleCloseAccount}
                onNoOption={handleCloseAccountModalClose}
                title={t('GURU_delete_account_title')}
                loading={closeAccountLoading}
            >
                <div className="p-5">
                    <p>
                        {t('GURU_delete_account_text')}
                    </p>
                    
                    {closeAccountMessages.error&&<p className="text-danger">{t(closeAccountMessages.error)}</p>}                    
                    {closeAccountMessages.success&&<p className="text-success">{t(closeAccountMessages.success)}</p>}                    
                </div>
            </AZDesicionModal>
            <div className="container-lg py-1 bg-transparent">
                
                <div className="row justify-content-center">
                    <div className="col-12 col-container">
                        <div className="row my-3">
                            <div className="col-12 text-center">
                                <h2><b>{t('UPDATE_PROFILE_title')}</b></h2>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-3">
                            <div className="col-xl-10">
                                <div className="az-card-container az-card-container--extra-padding">
                                    <EditProfileForm
                                        profile={auth.user}
                                        loading={loading}
                                        onSubmit={handleSubmit}
                                        onNicknameChange={handleNicknameChange}
                                    ></EditProfileForm>
                                    <div className="my-3 d-flex justify-content-end w-100">
                                        <AZButtonLink
                                            style="danger"
                                            onClick={handleCloseAccountClick}
                                            underline
                                        >
                                            {t('GURU_delete_button')}
                                        </AZButtonLink>
                                    </div>
                                </div>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );

    
}
const mapStateToProps = (state)=>{
    const {
        auth
    }=state
    
    return {
        auth
    }
}

export default connect(mapStateToProps)(EditProfile);
