import { useRef } from "react"
import "./index.css"

const AZButtonIconLink = (props) => {
    const {        
        style,
        type,
        full=false,
        underline,
        children,    
        onClick,
        error=false,
        disabled,
        leftIcon="",
        rightIcon="",
        fixMargin
    } = props

    const button = useRef(null)
    
    const buttonStyle = {
        color:"btn-az-icon-link btn-az-icon-link--color",
        white:"btn-az-icon-link btn-az-icon-link--white",
        gray:"btn-az-icon-link btn-az-icon-link--gray",
        danger:"btn-az-icon-link btn-az-icon-link--danger"
    }

    const handleClick = (e)=>{
        if(e !== undefined){
            e.stopPropagation()
        }
        onClick()
    }

    return (
        <div
        className={`${buttonStyle[style]} ${underline?"btn-az-icon-link--underline":""} ${disabled?"btn-az-icon-link--disabled":""} ${error?"btn-az-icon-link--danger":""} ${fixMargin&&'btn-az-icon-link--fix-margin'}`}
            onClick={()=>{button.current.click() }}
        >
            {
                leftIcon!==""&&
                <div className="btn-az-icon-link__left-icon">
                    {leftIcon}
                </div>
            }
            <div className={`btn-az-icon-link__label ${full&&"w-100"}`}
                    >
                {
                    children
                }            
            </div> 
            <button
                className="d-none"
                type={type}
                onClick={handleClick}
                ref={button}
                disabled={disabled}
            >
            </button>               
            {
                rightIcon!==""&&
                <div className="btn-az-icon-link__right-icon">
                    {rightIcon}
                </div>
            }
        </div>

    );
}

export default AZButtonIconLink;
