import React,{useState,useEffect,useRef} from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'react-string-format';

import * as paymentActions from '../../actions/payments.actions'

import AZButton from '../form/AZButton';
import AZButtonIcon from '../form/AZButtonIcon';
import AZField from '../form/AZField';

import CardItem from './CardItem';

import RP from '../../helpers/routePath';

import C from '../../constants'

import PaymentsMethodsItems from './PaymentsMethodsItem';

import moment from 'moment';
import 'moment/locale/es'
import FormatedDate from '../format/FormatedDate';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



const GiveTipForm = (props) => {
    const {
        myUser,
        user,
        //paymentMethod,
        onCancel=()=>{},
        onGiveProps,
        errors,
        loading,
        dispatch,
        preData
    } = props    

    const focusRef = useRef(null)
    const history = useHistory()
    const location = useLocation()   

    const [selectedButton, setSelectedButton] = useState(preData.selectedButton);
    const [tip, setTip] = useState(preData.tip);

    const [lastPay, setLastPay] = useState(preData.lastPay);

    const [paymentMethod, setPaymentMethod] = useState(preData.paymentMethodForTip);

    const {t,i18n} = useTranslation()

    const handleOnType = (e)=>{
        const {
            value
        } = e.target
        if(value.match(/^\d+$/) || value=="" ){
            setTip(value)
        }
    }

    const  getPaymentMethods = () =>{        
        dispatch(
            paymentActions.getPaymentMethods()
        ).then(
            (response) => {
                const {
                    list
                } = response
                let pm = list.find((m)=>(m.isDefault))
                console.log('pm-q',pm)
                if(pm){
                    setPaymentMethod(pm)
                }else{
                    setPaymentMethod({
                        id:0
                    })                    
                }                
                console.log('get payments list response',response)               
                return 
            },
            (error) => {
                console.log('get payments list error',error)                
                return 
            }
        )
    } 

    const getLastPay = ()=>{
        dispatch(
            paymentActions.getLastPayment(user.idUser)
        ).then(
            (response) => {
                const {
                    codeNumber="",
                    codeDescription="",
                    ...restResponse
                } = response
                setLastPay({                    
                    ...restResponse,
                    'loading':false
                })
                console.log('get last',restResponse)
                return 
            },
            (error) => {
                setLastPay({  
                    ...lastPay,                
                    'loading':false
                })
                console.log('get last error',error)
                return 
            }
        )
    }
    
    const renderLastPayDate = (date)=>{
        moment.locale(i18n.language.substr(0,2))
        let newDate=moment.utc(date);
        if(newDate.isValid()){
            return newDate.local().fromNow()            
        }
        return " "
    }

    const handleGiveProps = ()=>{
        if(onGiveProps){
            onGiveProps({
                paymentMethodId:paymentMethod.id,
                amount:tip,
                idGuru:user.idUser,
            })
        }        
    }

    const handleSelectPaymentMethod = ()=>{
        history.push(RP.getPath(t,RP.ADD_PAYMENT_METHOD),{
            'selectionMode':true,
            'backpath':location.pathname,
            'giveTipData':{
                selectedButton,
                lastPay,
                tip,
            }
        })
    }
   
    useEffect(() => {
        console.log('cached payment method',location)
        getLastPay()
        if(paymentMethod.id == -1){
            getPaymentMethods()
        }
        console.log('locale',i18n.language.substr(0,2))
    },[]);

    return (
        <div className='p-3'>
            <div className="row my-2">
                <div className="col-12 text-center">
                    <h4>
                        {format(t('RATE_tip_service_title'),user.name)}
                    </h4>
                    {
                        lastPay.loading&&user.name?
                        <h5 className='text-center'><i className="fa fa-spinner fa-spin"></i></h5>
                        :lastPay.dateLastTip!==null&&<h5 className='text-danger my-1'>
                            {console.log(`${lastPay.amountLastTip},${lastPay.currencyLastTip},${user.name},${renderLastPayDate(lastPay.dateLastTip)}`)}
                            {format(t('RATE_tip_service_already_tipped'),lastPay.amountLastTip,lastPay.currencyLastTip,user.name,renderLastPayDate(lastPay.dateLastTip))}
                        </h5>
                    }
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12 my-1">
                    <AZButton
                            style={selectedButton=='FIRST'?"color":"graySoft"}
                            onClick={()=>{setTip(2);setSelectedButton('FIRST')}}
                            type="button"
                            customClass={`w-100 ${selectedButton!=='FIRST'&&"text-purple"}`}
                        >
                        $2.00 USD
                    </AZButton>
                </div>
                <div className="col-12 my-1">
                    <AZButton
                        style={selectedButton=='SECOND'?"color":"graySoft"}
                        onClick={()=>{setTip(5);setSelectedButton('SECOND')}}
                        type="button"
                        customClass={`w-100 ${selectedButton!=='SECOND'&&"text-purple"}`}
                    >
                        $5.00 USD
                    </AZButton>
                </div>
                <div className="col-12 my-1">
                    <AZButton
                        style={selectedButton=='CUSTOM'?"color":"graySoft"}
                        onClick={()=>{setSelectedButton('CUSTOM');setTip("");}}
                        type="button"
                        customClass={`w-100 ${selectedButton!=='CUSTOM'&&"text-purple"}`}
                    >
                        {t('RATE_custom')}
                    </AZButton>
                </div>
            </div>
            {
                selectedButton=='CUSTOM'&&
                <div className="row my-2">
                    <div className="col-12 my-1">
                        <p className='text-center'>
                            {t('RATE_custom_description')}
                        </p>
                    </div>
                    <div className="col-12">
                        <AZField
                            onChange={handleOnType}
                            name="tip"
                            value={tip}
                            type="number"
                            ref={focusRef}
                        >
                            {t('RATE_custom_placeholder')}
                        </AZField>
                    </div>
                </div>
            }
            {
                paymentMethod.id!==0&&paymentMethod.id!==-1?
                <div className='row my-2 cursor-pointer' onClick={handleSelectPaymentMethod}>
                    <div className="col-12">
                        <CardItem 
                            {...paymentMethod}
                            selectionMode={false}
                        />

                    </div>
                    <div className="col-12">
                        <div className="divider bg-gray"></div>
                    </div>                    
                </div>:paymentMethod.id==0?
                    <PaymentsMethodsItems
                    key={-1}
                    isIddle    
                    selectioMode={true}                       
                    onItemClick = {handleSelectPaymentMethod}
                >
                </PaymentsMethodsItems>:                
                <div className="row">
                    <div className="col-12 text-center">
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>
                </div>
            
            }
            {
                errors!==""&&
                <div className="row my-2">
                    <div className="col-12">
                        <p className='text-danger'>{errors}</p>
                    </div>
                </div>
            }
            <div className="row my-2 justify-content-center">
                <div className="col-md-auto col-4">
                    <AZButton
                        style="gray"
                        onClick={onCancel}
                        type="button"
                        customClass="w-100"
                    >
                        {t('GENERAL_cancel')}
                    </AZButton>
                </div>
                <div className="col-auto col-8">
                    <AZButton
                        style="color"
                        onClick={handleGiveProps}
                        type="button"
                        customClass="w-100"
                        loading={loading}
                        disabled={paymentMethod.id==-1||tip==0}
                    >
                        {t('TIP_ok_button')}
                    </AZButton>
                </div>
            </div>
        </div>
    );
}

export default GiveTipForm;
