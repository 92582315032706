import React from 'react';
import { BrowserRouter as Router,Switch,Route,Link,Redirect} from "react-router-dom";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'

import RP from '../helpers/routePath';

import Landing from '../pages/Landing';
import Home from '../pages/Home';
import ConfirmAccount from '../pages/ConfirmAccount';
import RecoverPassword from '../pages/RecoverPassword';

import * as appActions from "../actions/app.actions"
import AZModal from "../components/modal/AZModal";

const LandingRouter = (props) => {
    const {
        auth,
        globalMessage,
        dispatch
    } = props

    const {t} = useTranslation()


    let history = useHistory()
    // useEffect(() => {
    //     history.push(RP.getPath(t,RP.BASE))   
    // },[auth.isLoggedIn]);

    return (    
        <div>
            <AZModal
                show={globalMessage.message!==""}
                onClose={()=>{dispatch(appActions.cleanGlobalMessage())}}
            >
                <div className="p-2">
                    {
                        globalMessage.translate?
                        t(globalMessage.message):
                        globalMessage.message
                    }                    
                </div>
            </AZModal>
            <Switch>
                <Route exact strict path={[RP.getPath(t,RP.BASE)]} component={Home} /> 
                <Route path={[RP.getPath(t,RP.CONFIRM_ACCOUNT_ID)]} component={ConfirmAccount} />                               
                <Route path={[RP.getPath(t,RP.RECOVER_PASSWORD_ID)]} component={RecoverPassword} />                               
                <Redirect to={RP.getPath(t,RP.BASE)} />
            </Switch> 
        </div> 
    )
}

const mapStateToProps = (state)=>{
    const {
        auth,
        globalMessage
    } = state

    return {
        auth,
        globalMessage
    }
}

export default connect(mapStateToProps)(LandingRouter);
