import React,{ useState,useEffect,useRef } from 'react';

import AZSelect from '../form/AZSelect';
import AZSelectMultiple from '../form/AZSelectMultiple';
import AZField from '../form/AZField';
import AZButton from '../form/AZButton';
import AZButtonLink from '../form/AZButtonLink';
import AZSwitch from '../form/AZSwitch';
import AZVerticalCheck from '../form/AZVerticalCheck';
import AZModal from '../modal/AZModal';
import AZDesicionModal from '../modal/AZDesicionModal';
import AZAreaBubble from '../form/AZAreaBubble';
import AZTextArea from '../form/AZTextArea';

import SelectPlaces from '../guru/SelectPlaces';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { formValidateField } from '../../helpers/formvalidation';

import * as generalActions from '../../actions/general.actions'
import * as appActions from '../../actions/app.actions'

import RP from '../../helpers/routePath'


import { Form } from 'react-bootstrap';

const NewRequestForm = (props) => {
    const {
        dispatch,
        backData,
        onSubmit
    } = props

    const {t} = useTranslation()
    const history = useHistory()
    const shouldLeaveRef = useRef(false)

    const [categories, setCategories] = useState(backData.categories);
    
    const [categoryLoading, setCategoryLoading] = useState(false);
    const [tagLoading, setTagLoading] = useState(false);

    const [tags, setTags] = useState(backData.tags);
    const [bubbleTags, setBubbleTags] = useState(backData.bubbleTags);
    
    const [categorySelect, setCategorySelect] = useState(backData.categorySelect);
    const [tagSelect, setTagSelect] = useState(backData.tagSelect);

    const [selectedTags, setSelectedTags] = useState(backData.selectedTags);
    const [showPlacesModal, setShowPlacesModal] = useState(false);
    const [tagsNumber, setTagsNumber] = useState(backData.tagsNumber);
    const [userlocation, setUserlocation] = useState({});
    const [userLocationEnabled, setUserLocationEnabled] = useState(false);
    const [step3Value, setStep3Value] = useState(backData.step3Value);
    const [isWillToPay, setIsWillToPay] = useState(backData.willingToPay);
    const [requestData, setRequestData] = useState({...backData});
    const [errors, setErrors] = useState({});
    const [loadingGeolocation, setloadingGeolocation] = useState(false);
    
    const [prompt, setprompt] = useState({
        show:false,
        redirectUrl:false,
        shouldRedirect:false
    });

    const [messageModal, setMessageModal] = useState("");

    const [showSuggestModal, setShowSuggestModal] = useState({
        show:false,
        type:"category",
        successMessage:false,
        errorMessage:false
    });
    const [sendSuggestionLoading, setSendSuggestionLoading] = useState(false);

    const [form, setForm] = useState({
        'suggestion':{
            'name':'suggestion',
            'value':'',
            'error':false
        },
        'title':{
            'name':'title',
            'value':backData.title,
            'error':false,
            'errorText':""
        },
        'description':{
            'name':'description',
            'value':backData.description,
            'error':false,
            'errorText':""
        },
        'tags':{
            'name':'tags',                
            'error':false,
            'errorText':""
        }
    });

    const categoriesListMenu=[
        {
            id:"tip",
            value:t('NEW_REQUEST_make_suggestion_soon_available'),
            disabled:true
        },
        {
            id:"suggestion",
            value:t('NEW_REQUEST_make_suggestion')
        }
    ]    
    const subCategoriesListMenu=[
        {
            id:"tip",
            value:t('NEW_REQUEST_make_suggestion_subcategories_soon_available'),
            disabled:true
        },
        {
            id:"suggestion",
            value:t('NEW_REQUEST_make_suggestion')
        }
    ]    

    const getCategories = () => {
        setCategoryLoading(true)
        generalActions.getCategories().then(
            (data)=>{
                console.log(data)
                setCategoryLoading(false)
                setCategories(data.list.map((c)=>(
                    {
                        "id":c.categoryId,
                        "value":c.name
                    }
                )))
            },
            (error)=>{
                console.log(error)
                if(error){
                    appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')                                          
                }
            }
        )
    }

    const getTags = ()=>{
        console.log('get the tags...')
        setTagLoading(true)
        generalActions.getTagsCategory(categorySelect.selectedId).then(
            (response)=>{
                setTagLoading(false)
                console.log("get tags...",response)
                const theTags = response.list.map((t)=>(
                    {
                        "id":t.tagId,
                        "value":t.name,
                        "selected":selectedTags[t.tagId]?true:false 
                    }
                ));           
                
                if(theTags.length>0){
                    setTags([...theTags,...subCategoriesListMenu])
                }else{
                    setTags(theTags)
                }
            },
            (error)=>{           
                setTagLoading(false)     
                if(error){
                    appActions.setGlobalMessage(t("ERROR_"+error.data.codeNumber),'error')                                          
                }
            }
        )
    }

    const handleChange = (e)=>{
        const {
            name,
            value
        } = e.target

        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })
    }

    const handleSuggestionModalClose=()=>{
        const {
            suggestion
        } = form
        setForm({
            ...form,
            'suggestion':{
                ...suggestion,
                value:"",
                error:false
            }
        })
        setShowSuggestModal({type:"category",show:false,successMessage:false,errorMessage:false})
    }

    const handleSendSuggestion=(type)=>{
        const {
            suggestion
        } = form

        setForm({
            ...form,
            'suggestion':{
                ...suggestion,
                error:false
            }
        })
        setShowSuggestModal({
            ...showSuggestModal,
            successMessage:false,
            errorMessage:false
        })
        setSendSuggestionLoading(true)
                
                

        if(!formValidateField(suggestion.value,'required')){
            setForm({
                ...form,
                'suggestion':{
                    ...suggestion,
                    error:true
                }
            })
            setSendSuggestionLoading(false)
            return false
        }

        if(type=="category"){
            generalActions.sendSuggestion(form.suggestion.value).then(
                (response)=>{
                    setShowSuggestModal({
                        ...showSuggestModal,
                        show:false,
                        successMessage:false,
                        errorMessage:false
                    })
                    setForm({
                        ...form,
                        'suggestion':{
                            ...suggestion,
                            'value':"",
                            error:false
                        }
                    })
                    setMessageModal(t('SUGGESTION_MODAL_success'))
                    setSendSuggestionLoading(false)
                },
                (response)=>{
                    if(response){
                        setShowSuggestModal({
                            ...showSuggestModal,
                            successMessage:false,
                            errorMessage:t("ERROR_"+response.data.codeNumber)
                        })                        
                    }
                    setSendSuggestionLoading(false)
                }
            )
        }else{
            generalActions.sendSubcategorySuggestion(form.suggestion.value,categorySelect.selectedId).then(
                (response)=>{
                    setShowSuggestModal({
                        ...showSuggestModal,
                        show:false,
                        successMessage:false,
                        errorMessage:false
                    })
                    setForm({
                        ...form,
                        'suggestion':{
                            ...suggestion,
                            'value':"",
                            error:false
                        }
                    })
                    setMessageModal(t('SUGGESTION_MODAL_success'))
                    setSendSuggestionLoading(false)
                },
                (response)=>{
                    if(response){
                        setShowSuggestModal({
                            ...showSuggestModal,
                            successMessage:false,
                            errorMessage:t("ERROR_"+response.data.codeNumber)
                        })                        
                    }
                    setSendSuggestionLoading(false)
                }
            )
        }

    }

    const handleCategoryClick = (id,value)=>{
        console.log('category click?',id)
        if(id=="suggestion"){
            console.log('why?')
            setShowSuggestModal({
                ...showSuggestModal,
                show:true,
                type:"category"
            })
            return false
        }

        setCategorySelect({
            "isOpen":false,
            "selectedId":id,
            "selectedValue":value
        })

    }

    const handleTagClick = (id,selected,value)=>{
        console.log(`${id} ${selected} ${value}`)
        if(id=="suggestion"){            
            setShowSuggestModal({
                ...showSuggestModal,
                show:true,
                type:"subcategory"
            })
            return false
        }
        if(selected && tagsNumber<5){
            setForm({
                ...form,
                'tags':{
                    'name':'tags',                
                    'error':false,
                    'errorText':""
                }                
            })
            setSelectedTags({
                ...selectedTags,
                [id]:value
            })
            setTagsNumber(tagsNumber+1)
        }else{
            console.log('the id',id)                               
            setSelectedTags({
                ...selectedTags,
                [id]:false
            })
            setTagsNumber(tagsNumber-1)
        }
    }

    const handleStep3Click = (value,isOn)=>{ 

        switch (value) {
            case 1:  
                console.log('step3 one')
                setStep3Value(value)
                setUserlocation({
                    lon:null,
                    lat:null
                })     
                setRequestData({...requestData,   
                    'country':null,
                    'isCountry': null,
                    'googlePlaceId' :null,                        
                    'lat':null,'lon':null                           
                });  
                setStep3Value(value)
                break;           
            case 2:   
                console.log('step3 two')     
                setStep3Value(2,true)                        
                setloadingGeolocation(true)
                navigator.geolocation.getCurrentPosition(
                    (position)=>{
                        setUserlocation({
                            lat:position.coords.latitude,
                            lon:position.coords.longitude
                        })
                        setRequestData({...requestData, 
                            'country':null,
                            'isCountry': null,
                            'googlePlaceId' :null,                           
                            'lat':position.coords.latitude,
                            'lon':position.coords.longitude                         
                        });
                        //if(step3Value===1){
                            //setStep3Value(2,true)
                        //} 
                        setUserLocationEnabled(true)
                        setloadingGeolocation(false)                        
                    },
                    (error)=>{
                        setUserLocationEnabled(false)
                        dispatch(appActions.setGlobalMessage(t('ERROR_geolocation'),'error'))
                        setloadingGeolocation(false)
                    }
                );
                break                                        
            case 3:
                setShowPlacesModal(true)                 
            default:
                break;
        }        
    }

    const handleSubmit =(submitType="SEND_REQUEST")=>{
        const resetForm = () => {
            return {
                ...form,
                'title':{
                    ...form['title'],
                    'error':false,                    
                },
                'description':{
                    ...form['description'],
                    'error':false,                    
                },
                'tags':{
                    ...form['tags'],
                    'error':false,
                }
            }
        }

        const formErrors = {}    
        const validForm = resetForm()
        let valid = true

        const {
            title,
            description
        } = form
        
        if(!formValidateField(title.value,'required')){
            formErrors['title'] = {
                ...title,
                'error':true,
                'errorText':t('FORM_ERROR_REQUIRED_request_title')
            }            
            valid = false
        }

        if(!formValidateField(description.value,'required')){
            formErrors['description'] = {
                ...description,
                'error':true,
                'errorText':t('FORM_ERROR_REQUIRED_request_description')
            }   
            valid = false
        }
        if(tagsNumber==0){
            formErrors['tags'] = {                
                'error':true,
                'errorText':t('FORM_ERROR_REQUIRED_request_tags')
            }   
            valid = false
        }       

        if(valid){
                shouldLeaveRef.current = true
                console.log("userlocation",userlocation)
                setForm(
                    {
                        ...form,
                        ...validForm,                                            
                    }
                )                
                onSubmit({
                    ...requestData,
                    "tags":Object.keys(selectedTags).filter(
                        (v)=>(selectedTags[v])
                    ),
                    "tagsNames":Object.keys(selectedTags).filter(
                        (v)=>(selectedTags[v])
                    ).map((v)=>{return selectedTags[v]}).join(', '),
                    'tagSelect':{
                        ...tagSelect,
                        "selectedId":categorySelect.selectedId,
                        isOpen:false
                    },
                    bubbleTags,
                    tagsNumber,
                    selectedTags,
                    categorySelect:{
                        ...categorySelect,
                        isOpen:false
                    },
                    categories,
                    step3Value,
                    'title':title.value,
                    'description':description.value,
                    //...userlocation,
                    'willingToPay':isWillToPay
                },
                submitType,
                (error)=>{
                    shouldLeaveRef.current = false
                    //handleError
                }
            )
        }else{
            setForm(
                {
                    ...form,
                    ...validForm,
                    ...formErrors                    
                }
            )
        }
    }

    // Effects

    useEffect(() => {
        if(categories.length == 0){
            getCategories()
        }   

        if(navigator.geolocation) {
            setloadingGeolocation(true)
            navigator.geolocation.getCurrentPosition(
                (position)=>{                       
                    // if(step3Value===1){
                    //     setStep3Value(2,true)
                    // }                     
                    setloadingGeolocation(false)
                    if(step3Value===0 || step3Value===2){
                        setStep3Value(2,true)
                        setUserlocation({
                            lat:position.coords.latitude,
                            lon:position.coords.longitude
                        })
                        setRequestData({...requestData, 
                            'country':null,
                            'isCountry': null,
                            'googlePlaceId' :null,                           
                            'lat':position.coords.latitude,
                            'lon':position.coords.longitude                         
                        });
                    } 
                    setUserLocationEnabled(true)
                },
                (error)=>{
                    setUserLocationEnabled(false)
                    setloadingGeolocation(false)
                    dispatch(appActions.setGlobalMessage(t('ERROR_geolocation'),'error'))
                }
            );
        }else{         
            console.log('no geo')   
            setUserlocation({
                lat:null,
                lon:null
            })
            setloadingGeolocation(false)
            //setStep3Value(1,true)            
            dispatch(appActions.setGlobalMessage(t('ERROR_geolocation'),'error'))
        }
    }, []);
     
    useEffect(() => {
        if(categorySelect.selectedId!==tagSelect.selectedId||categorySelect.selectedId!==backData.categorySelect.selectedId){
            setSelectedTags([])
            setTagsNumber(0)
            setTagSelect({
                ...tagSelect,
                'isOpen':false
            })
        }       
        getTags()
    },[categorySelect.selectedId]);     
    

    useEffect(() => {
        console.log('selected tags...')
        setTags([
            ...tags.map((t)=>({                
                "id":t.id,
                "value":t.value,
                "disabled":t.disabled,
                "selected":selectedTags[t.id]?true:false              
            })),            
        ])       
        let bubbleTagsArray = Object.keys(selectedTags).filter((tag)=>{
            return selectedTags[tag]!==false
        }).map((newTag)=>{
            return {
                id:newTag,
                value:selectedTags[newTag]
            }
        }) 
        setBubbleTags([...bubbleTagsArray])
        setTagSelect({
            ...tagSelect,
            selectedValue:bubbleTagsArray.map((tag)=>(tag.value)).join(", "),
            selectedId:categorySelect.selectedId
        })

        console.log("tags",[...Object.keys(selectedTags).filter((tag)=>{
            return selectedTags[tag]!==false
        }).map((newTag)=>{
            return {
                id:newTag,
                value:selectedTags[newTag]
            }
        })])

    },[selectedTags]);

    useEffect(() => {
        console.log('historyCheck no')
        const unblock = history.block((locationR, action) => {
            console.log(`historyCheck start ${shouldLeaveRef.current} ${locationR.pathname} ${form.title.value} ${form.description.value} ${tagsNumber}`)
            if(shouldLeaveRef.current){
                return true
            }
            if(locationR.pathname == RP.getPath(t,RP.NEW_SEARCH_GURUS)){
                return true
            }
            if((form.title.value!==""||
                form.description.value!==""||
                tagsNumber>0                
                )){
                    console.log('historyCheck A donde vas?')
                    shouldLeaveRef.current = false
                    setprompt({
                        show:true,
                        redirectUrl:locationR.pathname,
                        shouldRedirect:false
                    })
                    return false
            }
            console.log('historyCheck  voy')
            return true                      
        });
        return () => {
            unblock()
        };
    }, [form.title.value,form.description.value,tagsNumber]);

    useEffect(() => {
        console.log('historyCheck prompt')
        if(prompt.shouldRedirect){
            console.log('historyCheck should')
            shouldLeaveRef.current = true
            history.push(prompt.redirectUrl)
        }
    }, [prompt.shouldRedirect]);

    return (
        <div className='w-100'> 
            <AZDesicionModal
                show={prompt.show}
                onClose={()=>{setprompt({
                    ...prompt,
                    show:false
                })}}  
                yesOptionText={t('GENERAL_ok')} 
                noOptionText={t('GENERAL_cancel')} 
                onYesOption={()=>{
                    setprompt({
                        ...prompt,
                        show:false,
                        shouldRedirect:true
                    })
                }}
                onNoOption={()=>{
                    setprompt({
                        ...prompt,
                        show:false
                    })
                }}
                title={t('NEW_REQUEST_prompt_title')}
                loading={false}
            >
                <div className="p-3">
                    <p > 
                        {t('NEW_REQUEST_prompt_description')}
                    </p>                                        
                </div>
            </AZDesicionModal>
             <AZModal
                show={showPlacesModal}
                onClose={()=>{setShowPlacesModal(false);}}                
            >
                {showPlacesModal?<SelectPlaces
                    onOk={(data)=>{
                        if(data == undefined){
                            return
                        }
                        setRequestData({...requestData,...data,                            
                            'lat':data.lat(),'lon':data.lon()                            
                        });
                        setShowPlacesModal(false);
                        setErrors({...errors,"selectPlaces":false});
                        setStep3Value(3)
                    }}
                    onSelectPlace={(data)=>{}}
                >
                </SelectPlaces>:<div></div> }
            </AZModal> 
            {/* messages modal */}
            <AZModal
                show={messageModal!==""}
                onClose={()=>{setMessageModal("")}}
            >
                <div className="p-5">
                    <h5 className="text-center text-purple">
                        {messageModal}
                    </h5>
                </div>                
            </AZModal>
            <AZDesicionModal 
                title={showSuggestModal.type=="category"?t('SUGGESTION_MODAL_category_title'):t('SUGGESTION_MODAL_subcategory_title')}
                show={showSuggestModal.show}
                onClose={handleSuggestionModalClose}  
                noOptionText={t('GENERAL_cancel')}
                yesOptionText={t('SUGGESTION_MODAL_send')}
                onYesOption={()=>{handleSendSuggestion(showSuggestModal.type)}}
                onNoOption={handleSuggestionModalClose}
                loading={sendSuggestionLoading}
                loadingText={t('SUGGESTION_MODAL_sending')}              
            >
                {showSuggestModal.show?
                    showSuggestModal.type=="category"?
                    <div className="p-5">
                        <AZField
                            {...form.suggestion}
                            onChange={handleChange}
                        >
                            {t('SUGGESTION_MODAL_category_placeholder_input_message')}
                        </AZField>
                        {
                            showSuggestModal.successMessage&&<p className="text-purple my-2">{showSuggestModal.successMessage}</p>
                        }
                        {
                            showSuggestModal.errorMessage&&<p className="text-danger my-2">{showSuggestModal.errorMessage}</p>
                        }
                    </div>:
                    <div className="p-5">
                        <AZField
                            {...form.suggestion}
                            onChange={handleChange}
                        >
                            {t('SUGGESTION_MODAL_subcategory_placeholder_input_message')}
                        </AZField>
                        {
                            showSuggestModal.successMessage&&<p className="text-purple my-2">{showSuggestModal.successMessage}</p>
                        }
                        {
                            showSuggestModal.errorMessage&&<p className="text-danger my-2">{showSuggestModal.errorMessage}</p>
                        }
                    </div>
                    :<div></div>
                }                
            </AZDesicionModal>                            
            <form action="" onSubmit={(e)=>{e.preventDefault()}}>
                <div className="row">

                    {/*  */}
                    <div className={`col-12 my-2`}>
                        <AZSelect
                            title={categorySelect.selectedValue!==""?categorySelect.selectedValue:t('NEW_REQUEST_step1_title')}
                            // list={[{
                            //     id:"title",
                            //     value:t('NEW_REQUEST_step1_placeholder_select_category'),
                            //     disabled:true
                            // },...categories,...categoriesListMenu]}
                            list={[...categories,...categoriesListMenu]}
                            isListOpen={categorySelect.isOpen}
                            style="color"
                            loading={categoryLoading}
                            loadingText={t('GENERAL_loading')}
                            disabled={categoryLoading}
                            onToggle={()=>{setCategorySelect({...categorySelect,isOpen:!categorySelect.isOpen})}}
                            onItemClick={handleCategoryClick}                            
                        >
                        </AZSelect>
                    </div>   
                    {
                        bubbleTags&&bubbleTags.length>0&&
                        <div className="col-12">                        
                            <AZAreaBubble                            
                                list={bubbleTags}
                                onItemClick={()=>{}}
                                onItemRemove={(id)=>{handleTagClick(id,false,false)}}
                            >                        
                            </AZAreaBubble>                        
                        </div>
                    }
                   

                    <div className={`col-12 my-1`}>
                        <AZSelectMultiple
                            title={tagSelect.selectedValue!==""?tagSelect.selectedValue:t('NEW_REQUEST_step2_title_selected_category')}
                            // list={[
                            //     {
                            //         id:"title",
                            //         value:t('NEW_REQUEST_step2_title_selected_category'),
                            //         disabled:true
                            //     }
                            //     ,
                            //     ...tags
                            // ]}
                            list={tags!==undefined&&Array.isArray(tags)?tags:[]}
                            selectedValues={tagsNumber}
                            closeDivIcon={<p className="text-pruple"><u>{t('GENERAL_ok')}</u></p>}
                            loading={tagLoading}
                            loadingText={t('NEW_REQUEST_step2_loading_tags')}
                            onToggle={()=>{setTagSelect({...tagSelect,isOpen:!tagSelect.isOpen})}}
                            disabled={categorySelect.selectedId == -1 || tags.length==0}
                            isListOpen={tagSelect.isOpen}
                            onItemClick={handleTagClick}
                            {...form.tags}
                        >                        
                        </AZSelectMultiple>
                    </div>
                   <div className="col-12 my-1">
                        <div className="divider bg-gray"></div>
                   </div>

                    <div className="col-12 my-2">
                        <AZField
                            {...form.title}
                            onChange={handleChange}
                        >
                            { t('NEW_REQUEST_step3_placeholder_input_title') }
                        </AZField>
                    </div>

                    <div className="col-12 my-2">
                        <AZTextArea
                            {...form.description}
                            onChange={handleChange}
                        >
                            { t('NEW_REQUEST_step3_placeholder_input_description') }
                        </AZTextArea>
                    </div>

                    {/*  */}

                    <div className="col-12 my-2">
                        <div className="row justify-content-center my-2">
                            <div className="col-auto">
                                <h5 className="text-muted text-center">
                                    {t('NEW_REQUEST_step3_label_location')}
                                </h5>
                            </div>
                        </div>

                        <div className="row justify-content-center my-1">
                            <div className="col-auto mx-2 my-2">
                                <AZVerticalCheck
                                    isOn={step3Value==2}
                                    value={2}
                                    onClick={handleStep3Click}
                                    disabled={!userLocationEnabled}
                                >
                                    <h6>
                                        {t('NEW_REQUEST_step3_radio2')}
                                    </h6>
                                </AZVerticalCheck>
                            </div>
                            <div className="col-auto mx-2 my-2">
                                <AZVerticalCheck
                                    isOn={step3Value==3}
                                    value={3}
                                    onClick={handleStep3Click}
                                >
                                    <h6>
                                        {t('NEW_REQUEST_step3_radio3')}
                                    </h6>
                                </AZVerticalCheck>
                            </div>   
                            <div className="col-auto mx-2 my-2">
                                <AZVerticalCheck
                                    isOn={step3Value==1}
                                    value={1}
                                    onClick={handleStep3Click}
                                >
                                    <h6>
                                        {t('NEW_REQUEST_step3_radio1')}
                                    </h6>
                                </AZVerticalCheck>
                            </div>                                                                                 
                            {step3Value===3&&<div className="col-12 my-2">
                                <p className='text-center'>
                                    {requestData.address}
                                </p>
                            </div>}
                        </div>                        

                        <div className="row justify-content-center my-2">
                            <div className="col-auto">
                                <AZSwitch
                                    isOn={isWillToPay}
                                    onClick={()=>{setIsWillToPay(!isWillToPay)}}
                                >
                                    <h5 className='text-muted'>{t('NEW_REQUEST_step3_willing_to_pay')}</h5>
                                </AZSwitch>
                            </div>
                        </div>
                    </div>

                    {/* Buttons */}

                    <div className="col-12">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-auto col-12 my-2">
                                <AZButton
                                    style="color"
                                    type="button"
                                    onClick={()=>{handleSubmit('SEND_REQUEST')}}
                                    disabled={loadingGeolocation}
                                >
                                    {t('NEW_REQUEST_step3_button_submit')}
                                </AZButton>
                            </div>
                            <div className="col-md-auto col-12 my-2">
                                <AZButtonLink
                                    style="color"
                                    type="button"
                                    onClick={()=>{handleSubmit('SEND_TO_GURUS')}}
                                    underline
                                    disabled={loadingGeolocation}
                                >
                                    {t('NEW_REQUEST_step3_button_browse_gurus')}
                                </AZButtonLink>
                            </div>
                        </div>
                    </div>

                </div> 
            </form>
        </div>
    );
}

export default NewRequestForm;
