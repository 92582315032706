import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'react-string-format';

import AZField from '../form/AZField';
import AZButton from '../form/AZButton';
import { formValidateField } from '../../helpers/formvalidation';
import AZSelect from '../form/AZSelect';
import AZTextArea from '../form/AZTextArea';



const UserBlockForm = (props) => {
    const {
        username="",
        onSubmit,
        onCancel,
        loading,        
    }=props

    const {t} = useTranslation();

    const selectData = [
        {
            "value":t('PROFILE_block_option_1'),
            "id":1
        },
        {
            "value":t('PROFILE_block_option_2'),
            "id":2
        },
        {
            "value":t('PROFILE_block_option_3'),
            "id":3
        },        
        {
            "value":t('PROFILE_block_option_0'),
            "id":0
        },
    ]

    const [form, setForm] = useState({
        "message":{
            "name":"message",
            "value":"",
            "error":false,
            "errorText":""
        }
    });

    const [showInput, setShowInput] = useState(false);

    const [selectControl, setSelectControl] = useState({
        "open":false,
        "selectedValue":"",
        "selectedId":-1,
        "error":false
    });

    const [messages, setMessages] = useState({
        "success":false,
        "error":false
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        const { 
            message
        } = form
        const {
            selectedId
        } = selectControl

        let valid = true

        setForm({
            ...form,
            "message":{
                ...form['message'],
                error:false
            }
        })
        setMessages({
            "success":false,
            "error":false
        })
        setSelectControl({
            ...selectControl,
            error:false
        })

        let errors = {}

        if(showInput){
            if(!formValidateField(message.value,'required')){
                valid = false
                errors["message"] = {
                    ...form["message"],
                    error:true,    
                }
            }
        }

        if(selectedId < 0){
            valid = false
            setSelectControl({
                ...selectControl,
                error:true
            })
        }

        if(valid){
            onSubmit(
                {
                    "message":message.value,
                    "option":selectedId
                },
                (response)=>{
                    console.log(response)
                    setMessages({
                        'success':t('PROFILE_block_sent_success'),
                        'error':false
                    })
                },
                (error)=>{
                    console.log('error block',error)
                    if(error){
                        setMessages({
                            'success':false,
                            'error':t("ERROR_"+error.data.codeNumber)
                        })
                    }
                }
            )
        }else{
            setForm({
                ...form,
                ...errors
            })
        }
    }

    const handleItemClick=(id,value)=>{ 
        console.log('selectedValue',id)       
        if(id==0){
            setShowInput(true)
        }else{
            setShowInput(false)
        }
        setSelectControl({...selectControl,'open':false,'selectedId':id,'selectedValue':value,'error':false})
    }

    const handleChange = (e)=>{
        const{
            name,
            value
        } = e.target

        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })
    }

    return (
        <div className='p-3'>
            <form onSubmit={handleSubmit}>
                <div className="row my-2">
                    <div className="col-12 my-2">
                        <p>{format(t('PROFILE_block_description'),username)}</p>    
                    </div>
                    <div className="col-12 my-2">
                        <AZSelect
                            style="gray"
                            title={selectControl.selectedValue!==""?selectControl.selectedValue:t('GURU_request_report_placeholder_select')}
                            list={selectData}
                            isListOpen={selectControl.open}
                            onItemClick={handleItemClick}
                            onToggle={()=>{setSelectControl({...selectControl,'open':!selectControl.open})}}
                            error={selectControl.error}
                        >                            
                        </AZSelect>
                    </div>
                    {showInput&&<div className="col-12 my-2">
                        <AZTextArea 
                            {...form.message}
                            onChange={handleChange}
                        >
                            {t('PROFILE_report_option_placeholder_input')}
                        </AZTextArea>
                    </div>}
                    <div className="col-12 my-2">
                        {messages.success&&<p className='text-purple'>{messages.success}</p>}
                        {messages.error&&<p className='text-danger'>{messages.error}</p>}
                    </div>
                </div>
                <div className="row my-2 justify-content-between">
                    <div className="col-sm-auto">
                        <AZButton
                            style="gray"
                            onClick={onCancel}
                        >
                            {t('GENERAL_cancel')}
                        </AZButton>
                    </div>
                    <div className="col-sm-auto">
                        <AZButton
                            style="color"
                            type="submit"
                            onClick={()=>{}}
                            loading={loading}
                            onLoadText={t('SUGGESTION_MODAL_sending')}
                        >
                            {t('PROFILE_block_option_send')}
                        </AZButton>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default UserBlockForm;

