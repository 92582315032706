export const merge2ArraysById = (array1,array2,idString) => {
    var hash = new Map();
    array1.concat(array2).forEach(function(obj) {
        hash.set(obj[idString], Object.assign(hash.get(obj[idString]) || {}, obj))
    });
    return Array.from(hash.values());
}

export const mergeSeekerRequest = (oldArray,newArray) => {
    const pivotObject = {}
    newArray.map((pivot)=>{
        pivotObject[pivot.requestId] = {
            ...pivot
        }
    })
    const mergedArray = merge2ArraysById(oldArray,newArray,'requestId')

    const returnArray = mergedArray.map((concatenated)=>{
        return {
            ...concatenated,
            ...pivotObject[concatenated.requestId]
        }
    })

    return returnArray;
}
