import React,{ useState } from 'react';
import AZProfileImage from './AZProfileImage';
import AZButtonLink from '../form/AZButtonLink';
import AZButton from '../form/AZButton';
import { useTranslation } from 'react-i18next';
import FormatedDate from '../format/FormatedDate';
import FormatRating from '../format/FormatRating';

const ReviewItem = (props) => {
    const {
        model,
        onUnblocked,
        onViewReviews,
        onItemClick
    } = props

    const {t,i18n} = useTranslation()

    const handleViewReviews = (e) =>{
        onViewReviews(model.idUser)
    }

    const handleItemClick = (e) => {    
        onItemClick()    
    }    

    return (  
        <div>
            <div className="az-list__content__item az-list__content__item--review align-items-center" onClick={handleItemClick}>
                <div>
                    <AZProfileImage
                        user={model}
                        bordered={true}
                        size="sm"
                    >                    
                    </AZProfileImage>
                </div>

                <div className="az-list__content__item__info">
                    <div className="text-end mt-3">
                        <h6 className="">
                            <i className="fa fa-star me-1"></i>
                            <FormatRating>
                                {model.rating}
                            </FormatRating>
                        </h6>
                    </div>                    
                    <div className="text-start ms-3">
                        <h6>
                            <b>
                                {model.requestTitle}
                            </b>
                        </h6>
                        <h6>
                            {model.name} {model.lastName}
                        </h6>
                        <AZButtonLink
                            style="color"
                            underline
                            onClick={handleViewReviews}                        
                        >
                            {t('GURU_request_label_check_reviews')}
                        </AZButtonLink>
                    </div>                    
                    <p className="text-muted ms-3">
                        {model.comment}
                    </p>
                    <p className="text-muted text-end">
                        <FormatedDate                            
                        >            
                            {model.date}
                        </FormatedDate>
                    </p>
                </div>                                              
            </div>            
        </div>      
    );
}

export default ReviewItem;

