import React,{useState,useEffect,useRef} from 'react';

import "./index.css"

const ConversationHolder = (props) => {
    const { 
        children,
        header,
        headerRef,
        show=false,
        chat=false,
        onScroll=()=>{},        
        loading=false,
        scrollTopBack=0 
    }= props

    const [scrollTop, setScrollTop] = useState(scrollTopBack);
    const topRef = useRef(null)

    const handlePageScroll = (e) =>{
        console.log("page scroll")
        const element = e.target
        const childHeight =element.offsetHeight;
        const {scrollTop,scrollHeight,offsetHeight} = element        
        if (
            scrollHeight - scrollTop < (offsetHeight+2)            
            && !loading
            ) {
                console.log('on scroll!!');
                onScroll()
        }
    }

    useEffect(() => {
        if(scrollTop>0){
            setScrollTop(0)
            topRef.current.scrollIntoView(true)
        }
    }, [scrollTop]);

    return (
        <div>
            <div className={`conversation-holder ${chat&&"conversation-holder--chat"} ${show&&"conversation-holder--show"}`}>                
                <div className="conversation-holder__header" ref={headerRef}>
                    {header!==undefined?header:<div></div>}
                </div>                                    
                <div className="conversation-holder__content" onScroll={handlePageScroll}>
                    <div ref={topRef}></div>
                    {children!==undefined?children:<div></div> }
                </div>            
            </div>           
        </div>
    );
}

export default ConversationHolder;
