import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AZTutorial from '../../components/secuence/AZTutorial';
import AZButtonLink from '../../components/form/AZButtonLink';
import BecomeGuruForm from '../../components/guru/BecomeGuruForm';
import GuruDashboard from '../../components/guru/GuruDashboard';
import SeekerRequestList from '../../components/seeker/SeekerRequestList';

import RP from '../../helpers/routePath'

import { Redirect } from 'react-router-dom';

import SeekerDashboard from '../../components/seeker/SeekerDashboard';

import * as seekerActions from '../../actions/seeker.actions'

import C from "../../constants"


const Dashboard = (props) => {
    const {
        user,
        navbar
    } = props
    const {t} = useTranslation()
    
    const rows = 10

    const tutorialElements = [
        {
            image:`${C.resources.webpage}app/guru/iconScreen1.png`,
            title:t('BECOME_GURU_slide1_title'),
            description:t('BECOME_GURU_slide1_text')
        },        
        {
            image:`${C.resources.webpage}app/guru/iconScreen2.png`,
            title:t('BECOME_GURU_slide2_title'),
            description:t('BECOME_GURU_slide2_text')
        },        
        {
            image:`${C.resources.webpage}app/guru/iconScreen3.png`,
            title:t('BECOME_GURU_slide3_title'),
            description:t('BECOME_GURU_slide3_text')
        },        
        {
            image:`${C.resources.webpage}app/guru/iconScreen4.png`,
            title:t('BECOME_GURU_slide4_title'),
            description:t('BECOME_GURU_slide4_text')
        },        
        {
            image:`${C.resources.webpage}app/guru/iconScreen5.png`,
            title:t('BECOME_GURU_slide5_title'),
            description:t('BECOME_GURU_slide5_text')
        },        
        {
            image:`${C.resources.webpage}app/guru/iconScreen6.png`,
            title:t('BECOME_GURU_slide6_title'),
            description:t('BECOME_GURU_slide6_text')
        },        
    ]

    const [tutorialStep, setTutorialStep] = useState(0);
    const [tutorialFinish, setTutorialFinish] = useState(true);
    

    const showDashboardComponent = (userAs) =>{
        if(userAs == "SEEKER"){
            return <SeekerDashboard />            
        }

        //GURU

        return <div>
            {
                user.isAGuru?
                <GuruDashboard>                    
                </GuruDashboard>
                :
                tutorialFinish?
                <div>
                    <div className="row my-3">
                        <div className="col-12 text-start">
                            <h2><b className='text-purple'>{t('BECOME_GURU_title1')}</b> <b >{t('BECOME_GURU_title2')}</b></h2>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-12">
                            <div className="az-card-container">
                                <div className="row justify-content-center">
                                    <div className="col-md-8 my-3">
                                        <BecomeGuruForm>
                                        </BecomeGuruForm>                                
                                    </div>
                                </div>
                            </div>                        
                        </div>
                    </div>                                 
                </div>  
                : <div className="row justify-content-center">
                    <div className="col-12 col-sm-8 col-md-6 col-xl-4">
                        <AZTutorial
                            elements={tutorialElements}
                            step={tutorialStep}
                            onBack={()=>{setTutorialStep(tutorialStep-1)}}
                            onNext={()=>{setTutorialStep(tutorialStep+1)}}
                            onFinish={()=>{setTutorialFinish(true)}}
                            nextText={t('BECOME_GURU_button_continue_slide')}
                            finishText={t('BECOME_GURU_button_become')}
                            onDotClick={(index)=>{setTutorialStep(index)}}
                        >
                        </AZTutorial>
                    </div>
                </div>   
                
            }
        </div>
    }

    useEffect(() => {
        // Use effect
        setTutorialStep(0)
        setTutorialFinish(false)
    },[navbar.userAs]);
 


    return (        
        <div>
            <div className="container-lg py-1 bg-transparent">
                <div className="row justify-content-center">                    
                    {showDashboardComponent(navbar.userAs)}                    
                </div>
            </div>   
        </div>
    );
}

const mapStateToProps = (state) => {
    const{
        auth,
        navbar
    }=state
    return {
        auth,
        user:auth.user,
        navbar
    }
}

export default connect(mapStateToProps)(Dashboard);
