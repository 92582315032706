import AZField from "../form/AZField"
import AZPassword from "../form/AZPassword"
import AZCheckbox from "../form/AZCheckbox"
import AZButton from "../form/AZButton"
import AZButtonLink from "../form/AZButtonLink"
import TextWithLine from "../ui/TextWithLine"
import "./index.css"
import c from "../../constants"

import { useState } from 'react'

import { useTranslation } from 'react-i18next';
const SignInForm = (props) => {
    const { t, i18n } = useTranslation();  
    
    const {
        onSubmit,
        onCreateAccountClick,
        onForgetPasswordClick,
        loading,
        cache
    } = props

    const [form, setForm] = useState({
        "email":{
            name:"email",
            value:cache&&cache.isEmailCached?cache.cachedEmail:"",
            error:false,
            errorText:"",
        },
        "password":{
            name:"password",
            value:"",
            error:false,
            errorText:"",
        },
        "remember_me":{
            name:"remember_me",
            value:cache?cache.isEmailCached:false,
            error:false,
            errorText:"",
        }
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleSubmit = (e) =>{
        e.preventDefault()
        setErrorMessage("")
        Object.keys(form).map(i=>form[i]={
            ...form[i],
            error:false,
            errorText:""
        })
        onSubmit(
            form,
            (response)=>{

            },
            (response,errors)=>{
                console.log(response)                
                if(errors!==null){
                    console.log(errors)
                    setForm({
                        ...form,
                        ...errors
                    })
                }
                if(response){
                    setErrorMessage("ERROR_"+response.data.codeNumber)
                }
            }
        );
    }

    const handleChange = (e)=>{
        const{
            type,
            name,            
        } = e.target
     
        const value = type === 'checkbox' ? e.target.checked:e.target.value
        setForm({
            ...form,
            [name]:{
                ...form[name],
                value
            }
        })
    }

    return (        
        <form onSubmit={handleSubmit}>            
            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <h2>
                        {t('START_button_sing_in')}
                    </h2>
                </div>
            </div>
            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <div className="az-logo">
                        <img src={`${c.resources.webpage}logoHide.svg`} alt="" />
                    </div>                    
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZField
                        name={form.email.name}
                        value={form.email.value}
                        error={form.email.error}
                        errorText={form.email.errorText}
                        type="email"
                        onChange={handleChange}
                    >
                        {t('LOGIN_placeholder_input_email')}
                    </AZField>
                </div>
            </div>
            <div className="row my-2">
                <div className="col-12">
                    <AZPassword
                        name={form.password.name}
                        value={form.password.value}
                        error={form.password.error}
                        errorText={form.password.errorText}                        
                        onChange={handleChange}
                    >
                        {t('LOGIN_placeholder_input_password')}
                    </AZPassword>
                </div>
            </div>
            <div className="row my-1">
                <div className="col-12">
                    <p className="text-danger">
                        {t(errorMessage)}
                    </p>
                </div>
            </div>
            <div className="row justify-content-center mb-3">
                <div className="col-6">                    
                    <AZButton
                        customClass="w-100"
                        loading={loading}
                        onLoadText={t('LOADING')}
                        onClick={()=>{}}
                        type="submit"
                    >
                        {t('INTRO_button_continue_slide')}                      
                    </AZButton>                    
                </div>
            </div>
            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <AZCheckbox
                        name={form.remember_me.name}
                        checked={form.remember_me.value}
                        error={form.remember_me.error}
                        errorText={form.remember_me.errorText}                        
                        onChange={handleChange}
                    >
                        {t('LOGIN_remember_me')}
                    </AZCheckbox>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TextWithLine>
                        {t('LOGIN_text_divider_action')}
                    </TextWithLine>
                </div>
            </div>
            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <AZButtonLink
                        style="color"
                        type="button"
                        onClick={onCreateAccountClick}
                        underline
                    >
                        {t('LOGIN_button_create_account')}
                    </AZButtonLink>
                </div>
            </div>            
            <div className="row my-2">
                <div className="col"></div>
            </div>
            <div className="row justify-content-center my-2">
                <div className="col-auto">
                    <AZButtonLink
                        style="gray"
                        type="button"                        
                        onLoadText={t('LOADING')}
                        onClick={onForgetPasswordClick}
                        underline
                    >
                        {t('LOGIN_button_recovery_password')}
                    </AZButtonLink>
                </div>
            </div>
        </form>
    );
}

export default SignInForm;
